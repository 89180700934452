const messages = {
    trade: {
        text1: '交易品种名称或代码',
        text2: '自选',
        text3: '品种',
        text4: '卖价',
        text5: '点差',
        text6: '最新价',
        text7: '买价',
        text8: '涨跌幅',
        text9: '已删除自选',
        text10: '已添加自选列表',
        text11: '最高',
        text12: '最低',
        text13: '报价',
        text14: '规则',
        text15: '分析',
        text16: '交易时间段 (UTC+8)',
        text17: '天',
        text18: '报价',
        text19: '交易',
        text20: '多空分析',
        text21: '空',
        text22: '多',
        text23: '指标解读',
        text24: '分',
        text25: '时',
        text26: '天',
        text27: '周',
        text28: '1月',
        text29: '总结',
        text30: '买入',
        text31: '强烈买入',
        text32: '卖出',
        text33: '强烈卖出',
        text34: '中立',
        text35: '震荡指标',
        text36: '强烈',
        text37: '名称',
        text38: '数值',
        text39: '信号',
        text40: '移动平均线',
        text41: '名称',
        text42: '更多',
        text43: '枢轴点',
        text44: '经典',
        text45: '斐波那契',
        text46: '实盘账号',
        text46_1: '模拟账号',
        text47: '持仓',
        text48: '挂单',
        text49: '全部平仓',
        text50: '品种',
        text51: '类型',
        text52: '手数',
        text53: '开仓价格',
        text54: '最新价格',
        text55: '浮动盈亏',
        text56: '已用预付款',
        text57: '隔夜利息',
        text58: '止盈价格',
        text59: '止损价格',
        text60: '开仓时间',
        text61: '单号',
        text62: '操作',
        text63: '平仓',
        text64: '一键反手',
        text65: '止盈止损',
        text66: '暂无数据',
        text67: '登录',
        text68: '平仓品种',
        text69: '持仓手数',
        text70: '平仓手数',
        text71: '浮动盈亏',
        text72: '取消',
        text73: '确定',
        text74: '赠送品种已锁定，实名认证后可解锁',
        text75: '订单详情',
        text76: '平仓盈亏',
        text77: '平仓价格',
        text78: '交易返佣',
        text79: '隔夜利息',
        text80: '最少可平仓',
        text81: '手',
        text82: '最多可平仓',
        text83: '确定全部平仓吗？',
        text84: '好的',
        text85: '确定操作一键反手吗？',
        text86: '成功',
        text87: '挂单价格',
        text88: '预计占用',
        text89: '下单时间',
        text90: '截止时间',
        text91: '修改订单',
        text92: '撤单',
        text93: '修改订单',
        text94: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_buy_red%402x.png',
        text94_green: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_buy_cn.png',
        text94_blue: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_blue_up_buy_cn.png',
        text95: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_sell_green%402x.png',
        text95_green: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_sell_cn.png',
        text95_blue: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_sell_cn.png',
        text96: '价格',
        text97: '或',
        text98: '预计收益',
        text99: '预计亏损',
        text100: '挂单截止',
        text101: '请输入挂单价格',
        text102: '止损价格',
        text103: '止盈价格',
        text104: '未设置',
        text105: '挂单价格无效',
        text106: '挂单截止时间应大于目前最新时间',
        text107: '确定撤单吗？',
        text108: '实盘账号',
        text109: '余额',
        text110: '净值',
        text111: '浮动盈亏',
        text112: '可用预付款',
        text113: '已用预付款',
        text114: '信用赠金',
        text115: '预付款比例',
        text116: '类型',
        text117: '市价',
        text118: '挂单',
        text119: '交易手数',
        text120: '挂单价格',
        text121: '休市',
        text122: '请输入交易密码',
        text123: '交易品种',
        text124: '忘记密码',
        text125: '可用预付款不足',
        text126: '交易密码已被冻结',
        text127: '请重置交易密码',
        text128: '找回交易密码',
        text129: `交易手数不能小于`,
        text130: `交易手数不能大于`,
        text131: `请输入挂单价格`,
        text132: `需入金后才能使用赠金交易`,
        text133: `止盈止损`,
        text134: `平仓时间`,
        text135: `1分`,
        text136: `5分`,
        text137: `15分`,
        text138: `30分`,
        text139: `1时`,
        text140: `4时`,
        text141: `日K`,
        text142: `周K`,
        text143: `月K`,
        text144: `下单确认`,
        text145: `买`,
        text146: `卖`,
        text147: `一键交易`,
        text148: `模拟交易已到期`,
        text149: '部分平仓',
        text150: '快速交易开启成功',
        text151: '点击展开',
        text152: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_zk_right.png',
        text153: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_sq_right.png',
        text154: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_zk_left.png',
        text155: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_sq_left.png',
    },
    signUp: {
        text1: '立即注册',
        text2: '开启您的投资之旅',
        text3: '支持跟单交易 — 与顶级交易者分享利润',
        text4: '资金安全 — 安全、快速存取款',
        text5: '用户友好 — 满足不同阶段的投资者需求',
        text6: '免费资源 — 优秀的交易策略、行情分析，1对1入门指导',
        text7: '手机号验证码登录',
        text8: '新用户可直接注册登录',
        text9: '手机号登录',
        text10: '邮箱登录',
        text11: '点击按钮即表示您同意并遵循',
        text12: '《Advantrade用户协议》',
        text13: '请输入手机号码',
        text14: '选择国家/地区',
        text15: '请输入验证码',
        text16: '获取验证码',
        text17: '重新发送',
        text18: '正在发送',
        text19: '发送成功',
        text20: '请输入您的手机号',
        text21: '登录成功',
        text22: '登录',
        text23: '账号密码登录',
        text24: '手机号验证码登录',
        text25: '忘记密码',
        text26: '请输入邮箱',
        text27: '请输入密码',
        text28: '手机号',
        text29: '邮箱',
        text30: '设置登录密码',
        text31: '请输入8-15位数字字母组合密码',
        text32: '请再次确认密码',
        text33: '密码不匹配',
        text34: '跳过',
        text35: '确定',
        text36: '设置成功',
        text37: '请输入8-15位数字字母组合密码',
        text38: '登录/注册',
        text39: '两次输入的密码不一致，请重新输入',
        text40: '密码重置成功',
        text41: '请输入验证码',
        text42: '请输入电子邮件',
        text43: '邮件格式错误',
        text44: '验证码错误',
    },
    components: {
        text1: '修改交易密码',
        text2: '输入6位数字的密码',
        text3: '请输入6位数字的密码',
        text4: '交易密码不能为6位重复',
        text5: '交易密码为6位',
        text6: '交易密码不能为连续6位数字',
        text7: '修改成功',
        text8: '设置交易密码',
        text9: '交易密码不能重复或连续6位',
        text10: '请确认密码',
        text11: '解绑',
        text12: '选择银行卡',
        text13: '绑定邮箱',
        text14: '请输入手机验证码',
        text15: '下一步',
        text16: '请输入邮箱地址',
        text17: '请输入邮箱验证码',
        text18: '邮箱绑定成功',
    },
    myAccount: {
        text1: '账户中心',
        text2: '账户资产',
        text3: '历史交易',
        text4: '入金',
        text5: '出金',
        text6: '资金流水',
        text7: '资金记录',
        text8: '资金划拨',
        text9: '个人信息',
        text10: '头像',
        text11: '修改',
        text12: '昵称',
        text13: 'Advantrade账户',
        text14: '手机号码',
        text15: '实名认证',
        text16: '地址证明',
        text17: '未提交',
        text18: '审核中',
        text19: '地址认证错误',
        text20: 'MT5实盘账号',
        text21: '个人资料',
        text22: '提交',
        text23: '编辑',
        text24: '邮箱',
        text25: '解绑',
        text26: '绑定',
        text27: '安全中心',
        text28: '登录密码',
        text29: '设置',
        text30: 'MT5实盘账号密码',
        text31: '重置',
        text32: '交易密码',
        text33: '银行账户',
        text34: '添加',
        text35: '出金地址',
        text36: '删除',
        text37: '未实名',
        text38: '已实名',
        text39: '身份认证失败',
        text40: '上传',
        text41: '签名无效',
        text42: '地址认证失败',
        text43: '未上传证件',
        text44: '未提交签名',
        text45: '待上传手持证件照',
        text46: '手持证件照错误',
        text47: '确认解除绑定邮箱',
        text48: '邮箱解绑成功',
        text49: 'MT5 实盘密码每天只能重置一次',
        text50: '解除绑定您的',
        text51: '卡，尾号为',
        text52: '解绑成功',
        text53: '删除出金地址？',
        text54: '地址删除成功',
        text55: '根据Advantrade合规体系规定，您需要补充地址证明资料',
        text56: '补充资料',
        text57: '您的地址证明未通过验证，请重新提交',
        text58: '附上文件',
        text59: '* 个人资料的完善便于系统进行风险提醒，请认真填写',
        text60: '家庭成员数',
        text61: '人',
        text62: '人',
        text63: '人及以上',
        text64: '婚姻状况',
        text65: '已婚',
        text66: '未婚',
        text67: '离异',
        text68: '丧偶',
        text69: '职业状况',
        text70: '在职',
        text71: '无业',
        text72: '学生',
        text73: '退休',
        text74: '选择行业',
        text75: '金融/银行/保险',
        text76: '政府/事业机构',
        text77: '广告公关/媒体/艺术文化',
        text78: '销售/贸易',
        text79: '计算机/互联网/通信/电子技术',
        text80: '生产制造/物流运输',
        text81: '医药/化工',
        text82: '建筑/房地产',
        text83: '教育/法律',
        text84: '餐饮/娱乐/美容',
        text85: '其他',
        text86: '总资产净值',
        text87: '投资目标',
        text88: '投资经验',
        text89: '',
        text90: '资产增值',
        text91: '投机',
        text92: '套利',
        text93: '对冲',
        text94: '年以下',
        text95: '年以上',
        text96: '',
        text97: '成功',
        text98: '修改头像',
        text99: '头像',
        text100: '更新头像',
        text101: '修改成功',
        text102: '昵称长度不能超过15个字符',
        text103: '请输入',
        text104: '登录密码',
        text105: '修改登录密码',
        text106: '请输入原密码',
        text107: '登录密码重置成功',
        text108: '重置MT5实盘密码',
        text109: '* 新MT5密码将发送至您手机',
        text110: '添加银行账户信息',
        text111: '银行账户姓名',
        text112: '币种',
        text113: '银行名称',
        text114: '银行账户号',
        text115: '银行代码',
        text116: '开户银行地址',
        text117: '请输入',
        text118: '保存',
        text119: '请选择该通道可用的银行卡',
        text120: '银行卡添加成功',
        text121: '选择银行名称',
        text122: '添加出金地址',
        text123: '备注',
        text124: '不超过10个字符',
        text125: '出金地址添加成功',
        text126: '请选择',
        text127: '修改昵称',
        text128: '添加银行账户信息',
        text129: '银行代码',
        text130: '选择或输入银行名称',
        text131: '选择出金地址',
        text132: '查看',
        text133: '高于',
        text134: '10万美元以下',
        text135: '10万美元—20万美元',
        text136: '20万美元—30万美元',
        text137: '30万美元—50万美元',
        text138: '50万美元—100万美元',
        text139: '100万美元—500万美元',
        text140: '500万美元—1000万美元',
        text141: '1000万美元以上',
        text142: 'MT4实盘账号',
        text143: 'MT4实盘账号密码',
        text144: 'MT4 实盘密码每天只能重置一次',
        text145: '重置MT4实盘密码',
        text146: '* 新MT4密码将发送至您手机',
        text147: '请输入银行名称',
    },
    AccountAssets: {
        text1: '实盘账户',
        text2: '账户资产',
        text3: '净值',
        text4: '余额',
        text5: '浮动盈亏',
        text6: '信用赠金',
        text7: '可用预付款',
        text8: '已用预付款',
        text9: '预付款比例',
        text10: '总平仓盈亏',
        text11: '总返佣',
        text12: '总入金',
        text13: '总出金',
        text14: '交易品种',
        text15: '下单时间',
        text16: '全部',
        text17: '开始时间',
        text18: '结束时间',
        text19: '查询',
        text20: '数据统计',
        text21: '总隔夜利息',
        text22: '总平仓手数',
        text23: '总平仓订单数',
        text24: '已平仓',
        text25: '已撤单',
        text26: '国家',
        text27: '姓氏',
        text28: '名字',
        text29: '生日',
        text30: '详细地址',
        text31: '身份证号',
        text32: '认证成功后即可交易，坐享收益',
        text33: '确认退出验证?',
        text34: '继续验证',
        text35: '实名认证',
        text36: '基本资料',
        text37: '身份验证',
        text38: '协议确认',
        text39: '开户文件',
        text40: '身份证明',
        text41: '带有头像和有效期的身份证明文件，例如：身份证、护照、驾照以及政府颁发的其他有效证明等',
        text42: '必传',
        text43: '地址证明',
        text44: '过去六个月内的水、电、煤气、信用卡纸质账单等，建议提交银行账单的pdf文件',
        text45: '非必传',
        text46: '文件示例',
        text47: '正确示例',
        text48: '',
        text49: '信息模糊',
        text50: '',
        text51: '边角缺失',
        text52: '',
        text53: '请确认已阅读并同意以下协议',
        text54: '《用户协议》',
        text55: '《税务表格》',
        text56: '《附件I 利益冲突》',
        text57: '《附件II 风险提示书》',
        text58: '《附件III 最佳执行策略》',
        text59: '请确认以下声明',
        text60: '我是本账户的最终受益人',
        text61: '我不是美国公民或就税务而言的公民',
        text62: '我不是Advantrade代理人或与职员有亲属关系',
        text63: '本人提供的所有开户信息均为真实准确，并同意在内容有变化的情况下，以电话或者邮件的形式通知Advantrade，必要时本人授权Advantrade对资料的准确性进行确认，签名后表示您阅读并理解风险提示书中的内容。',
        text64: `开户审核中`,
        text65: `您的资料已提交审核，等待1-2个工作日进行审核，审核成功后，我们将通知联系您，请您保持联系。`,
        text66: `时间`,
        text67: `总赠金`,
        text68: `总划拨`,
        text69: `金额`,
        text70: `划拨类型`,
        text71: `划拨金额`,
        text72: `可划拨金额`,
        text73: `实名认证`,
        text74: `* 您的资料提交我们已收到，鉴于Advantrade合规体系的规定，为防止其他人恶意使用您的身份，您需要上传您的手持证件照`,
        text75: `请上传您的手持身份证`,
        text76: `请上传您的手持身份证正面照片`,
        text77: `头像和文字清晰，无明显反光`,
        text78: `整个头部和身份证的四个角和边框都在拍摄区域`,
        text79: `示例`,
        text80: `取消`,
        text81: `提交`,
        text82: `我已阅读全文，了解风险并愿意继续在Advantrade开户`,
        text83: `清空`,
        text84: `请输入手写签名`,
        text85: `超龄用户风险协议`,
        text86: `* 根据Advantrade合规体系规定，您需要补充地址证明资料`,
        text87: `* 您提交的地址证明资料未通过审核，请重新上传提交`,
        text88: `地址证明`,
        text89: `地址证明审核中`,
    },
    fundHistory: {
        text1: '入金',
        text2: '出金',
        text3: '赠金',
        text4: '返佣',
        text5: '划拨',
        text6: '入金类型',
        text7: '订单状态',
        text8: '时间',
        text9: '状态',
        text10: '入金金额',
        text11: '时间',
        text12: '操作',
        text13: '详情',
        text14: '状态',
        text15: '币种',
        text16: '入金金额',
        text17: '支付金额',
        text18: '上链时间',
        text19: '到账时间',
        text20: '点击钱包地址或TxHash可以在浏览器查看链上记录',
        text21: '地址',
        text22: '订单已确认付款，等待资金到账',
        text23: '',
        text24: ' 后订单失效，请尽快汇款并确认已付款操作',
        text25: '购买数量',
        text26: '支付金额',
        text27: '支付方式',
        text28: '收款账号',
        text29: '收款人',
        text30: '取消订单',
        text31: '确认已付款',
        text32: '确认取消订单？',
        text33: '订单取消成功',
        text34: '订单确认已完成付款?',
        text35: '订单确认成功',
        text36: '正在加载',
        text37: '出金类型',
        text38: '出金金额',
        text39: '预计到账',
        text40: '手续费',
        text41: '撤销',
        text42: '到账金额',
        text43: '手续费',
        text44: '收款银行',
        text45: '确定是否撤销该笔出金订单？',
        text46: '撤销成功',
        text47: '赠金类型',
        text48: '类型',
        text49: '佣金类型',
        text50: '总佣金',
        text51: '交易手数',
        text52: '交易时间',
        text53: '划拨金额',
        text54: '划拨方向',
        text55: '时间',
        text56: '最低划拨',
        text57: '可划拨金额不足',
        text58: '提交成功',
    },
    deposit: {
        text1: '选择支付币种',
        text2: '* 请选择您要支付的币种，我们将根据您选择的付款币种提供相应的支付通道。',
        text3: '操作汇款',
        text4: '上传凭证',
        text5: '完成',
        text6: '收款信息',
        text7: '展开',
        text8: '收款人姓名',
        text9: '收款人地区',
        text10: '汇款信息',
        text11: '汇款银行',
        text12: '汇款金额',
        text13: '汇款凭证',
        text14: '*上传图片大小不得大于4M',
        text15: '收款信息',
        text16: '收款人姓名',
        text17: '收款人地区',
        text18: '收款人地址',
        text19: '收款人银行',
        text20: '收款账号',
        text21: '收款行地址',
        text22: 'SWIFT代码:',
        text23: '复制全部信息',
        text24: '复制成功',
        text25: '入金金额不能少于',
        text26: '银行对账无误后金额会自动到账，一般需要1个工作日左右，请耐心等待。',
        text27: `选择币种`,
        text28: `链名称`,
        text29: `当前汇率`,
        text30: `入金金额`,
        text31: `预计支付金额`,
        text32: `请输入金额`,
        text33: `您的个人多重签名`,
        text34: `入金地址`,
        text35: `单笔限制 `,
        text36: `最多 `,
        text37: `最少 `,
        text38: `选择通道`,
        text39: `入金金额`,
        text40: `其他`,
        text41: `手续费`,
        text42: `支付金额`,
        text43: `汇率`,
        text44: `所属国家`,
        text45: `银行`,
        text46: `确定`,
        text47: `注意事项:`,
        text48: `综合汇率`,
        text49: `购买数量`,
        text50: `实名认证`,
        text51: `* 支付金额为预估金额，请按照实际生成订单中的金额支付`,
        text52: `未完成订单`,
        text53: ``,
        text54: `后订单失效，请尽快汇款并确认已付款操作`,
        text55: `订单已确认付款，等待资金到账`,
        text56: `状态`,
        text57: `支付方式`,
        text58: `入金金额`,
        text59: `收款账号`,
        text60: `复制`,
        text61: `收款人`,
        text62: `时间`,
        text63: `取消订单`,
        text64: `确认已付款`,
        text65: `存在未支付订单，发起新订单失败`,
        text66: `确认已付款`,
        text67: `取消`,
        text68: `确认取消订单？`,
        text69: `订单取消成功`,
        text70: `订单确认已付款？`,
        text71: `分`,
        text72: `秒`,
    },
    withdraw: {
        text1: `选择通道`,
        text2: `当前汇率`,
        text3: `可提金额`,
        text4: `出金金额`,
        text5: `预计收款`,
        text6: `全部`,
        text7: `实名认证`,
        text8: `收款银行卡`,
        text9: `添加银行卡`,
        text10: `切换其他已绑银行卡`,
        text11: `* 预计收款金额为预估金额，实际收款以到账金额为准`,
        text12: `确定`,
        text13: `最近一笔订单`,
        text14: `状态`,
        text15: `收款银行`,
        text16: `出金金额`,
        text17: `收款账号`,
        text18: `预计到账`,
        text19: `收款人`,
        text20: `时间`,
        text21: `撤销`,
        text22: `正在加载`,
        text23: `单笔限制`,
        text24: `最多 `,
        text25: `最少 `,
        text26: `风险提示`,
        text27: `出金`,
        text28: `取消`,
        text29: `提交成功`,
        text30: `复制成功`,
        text31: `确认是否撤销该笔出金订单`,
        text32: `好的`,
        text33: `可提金额 = 净值 - 信用赠金 - 余额赠金 - 待打款订单金额`,
        text34: `对于持仓客户:可提金额 = 净值 - 信用赠金 - 余额赠金-待打款订单金额- 已用保证金/杠杆比例 X2%(2%是我们对您的持仓订单最大亏损的一个估值，为保证提现后不影响您当前的持仓)`,
        text35: ``,
        text36: ``,
        text37: `可提金额说明`,
        text38: `选择收款币种`,
        text39: `*请选择您要接收的货币，我们将根据您选择的货币提供相应的出金通道。`,
        text40: `收款币种`,
        text41: `预计到账金额`,
        text42: `汇率`,
        text43: `银行账户信息`,
        text44: `可用银行`,
        text45: `开户姓名`,
        text46: `银行名称`,
        text47: `银行地址`,
        text48: `银行账户号`,
        text49: `SWIFT代码`,
        text50: `请填写补充信息`,
        text51: `使用该通道出金请使用以下银行收款`,
        text52: `该通道不支持使用此银行收款`,
        text53: `请选择`,
        text54: `添加`,
        text55: `银行代码`,
        text56: `选择币种`,
        text57: `链名称`,
        text58: `可提金额`,
        text59: `手续费`,
        text60: `出金地址`,
        text61: `选择或输入地址`,
        text62: `选择`,
        text63: `预计扣除`,
        text64: `*预计收款金额为预估金额，实际收款金额以到账金额为准`,
        text65: `可提金额说明`,
        text66: `*无持仓用户`,
        text67: `可提金额 = 净值-信用赠金-余额赠金-待打款订单金额`,
        text68: `*有持仓用户`,
        text69: `可提金额 = 净值-信用赠金-余额赠金-待打款订单金额-已用保证金/杠杆比例*2%`,
        text70: `(2%是我们对您的持仓订单最大亏损的一个估值，为保证提现后不影响您当前的持仓 )`,
        text71: '安全验证',
        text72: '*为保证您链上的资金安全，需要对您的账户进行安全验证',
        text73: '提币金额错误，不能大于当前可提币金额',
    }
}

export default messages