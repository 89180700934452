<template>
  <div
    class="header box_fixed"
    id="boxFixed"
    :class="{ is_fixed: isFixed, head_flag: headFlag }"
  >
    <div class="header_nav">
      <img
        class="logo"
        src="https://advantrade.oss-accelerate.aliyuncs.com/web/top_logo%402x.png"
        alt=""
      />
      <div v-if="userInfo?.nickname" class="sign_up cursor" @click="logout()">
        Sign out
      </div>
      <div v-else class="login_register cursor" @click="jump('signUp')">
        Login/Register
      </div>

      <div class="language">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ lang }}
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_more_gray%402x.png"
              alt=""
            />
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="ChangeLang('cn')"
                >中文</el-dropdown-item
              >
              <el-dropdown-item @click="ChangeLang('en')"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="header_user" v-if="userInfo?.nickname">
        <a class="ant-dropdown-link" @click="jump('myAccount')">
          <img :src="userInfo.headpicUrl" alt="" />
          <div class="header_username">
            {{ userInfo.nickname }}
          </div>
        </a>
      </div>
      <div class="set">
        <!-- <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/icon_set%402x.png" alt=""> -->
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <img
              class="icon_set"
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/icon_set%402x.png"
              alt=""
            />
          </span>
          <template #dropdown>
            <el-dropdown-menu style="width: 240px">
              <ul class="set_ul">
                <li>
                  <h2>Colour preferences</h2>
                  <el-radio-group
                    v-model="upColor"
                    class="ml-4"
                    @change="onChange"
                  >
                    <el-radio value="red">
                      <span class="text_red">Red rise </span>
                      <span class="text_green"> green fall</span></el-radio
                    >
                    <el-radio value="green">
                      <span class="text_green">Green rise </span>
                      <span class="text_red"> red fall</span></el-radio
                    >
                    <el-radio value="blue">
                      <span class="text_blue">Blue rise </span>
                      <span class="text_red">red fall</span></el-radio
                    >
                  </el-radio-group>
                </li>
                <div class="line"></div>
                <li>
                  <h2>Instant trading</h2>
                  <el-radio-group
                    v-model="instantTrading"
                    class="ml-4"
                    @change="instantTradingFunc"
                  >
                    <el-radio v-model="instantTrading" value="1"
                      ><span>Open</span></el-radio
                    >
                    <el-radio v-model="instantTrading" value="2"
                      ><span>Close</span></el-radio
                    >
                  </el-radio-group>
                </li>
              </ul>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <section class="color-6">
        <nav class="cl-effect-3">
          <router-link to="/">
            <div
              class="underscore"
              :class="
                this.$route.path == '/' || this.$route.path == 'home'
                  ? 'underscore_line'
                  : ''
              "
            >
              Home
            </div>
          </router-link>
          <a>
            <el-dropdown class="header_text">
              <div
                class="underscore el-dropdown-link"
                :class="
                  this.$route.path == '/transaction' ||
                  this.$route.path == '/transactionMn'
                    ? 'underscore_line'
                    : ''
                "
              >
                Trade
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :class="
                      this.$route.path == '/transaction'
                        ? 'underscore_line_2'
                        : ''
                    "
                    @click="jump('transaction')"
                    >Live account</el-dropdown-item
                  >
                  <el-dropdown-item
                    :class="
                      this.$route.path == '/transactionMn'
                        ? 'underscore_line_2'
                        : ''
                    "
                    @click="jump('transactionMn')"
                    >Demo account</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </a>
          <router-link to="/whyChooseUs">
            <div
              class="underscore"
              :class="
                this.$route.path == '/whyChooseUs' ? 'underscore_line' : ''
              "
            >
              Why choose us
            </div>
          </router-link>
          <!-- <router-link to="/promotion">
                        <div class="underscore" :class="this.$route.path == '/promotion'?'underscore_line':''" @mouseover="showDrop" @mouseleave="hideDrop">
                            Promotion <img :class="{'icon_up' : isShow}" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_more_white_down%402x.png" alt="">
                        </div>
                        <div class="drop_down"  @mouseover="showDrop" @mouseleave="hideDrop" :class="{'is_show' : isShow}">
                            <div class="drop_down_box">
                                <div class="drop_down_left">
                                    <h3>Regular</h3>
                                    <router-link :class="this.$route.path == '/membership'?'active':''" to="/membership">Membership</router-link>
                                    <router-link :class="this.$route.path == '/referralBonus'?'active':''" to="/referralBonus">Referral Bonus</router-link>
                                </div>
                                <div class="drop_down_right">
                                    <h3>Exclusive</h3>
                                    <router-link :class="this.$route.path == '/luckSpin'?'active':''" to="/luckSpin">Lucky-spin</router-link>
                                    <router-link :class="this.$route.path == '/switchingBonus'?'active':''" to="/switchingBonus">Switching Bonus</router-link>
                                </div>
                            </div>
                        </div>
                    </router-link> -->
          <router-link to="/latestNews">
            <div
              class="underscore"
              :class="
                this.$route.path == '/latestNews' ? 'underscore_line' : ''
              "
            >
              Latest news
            </div>
          </router-link>
          <!--  <router-link to="/contactUs">
                       <div class="underscore" :class="this.$route.path == '/contavyUs'?'underscore_line':''">
                            CONTACT US
                       </div>
                    </router-link> -->
          <router-link to="/download">
            <div
              class="underscore"
              :class="this.$route.path == '/download' ? 'underscore_line' : ''"
            >
              Download
            </div>
          </router-link>
        </nav>
      </section>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="Disclaimer"
      width="700"
      class="my_dialog"
      :show-close="false"
    >
      <div class="scroll">
        <p>
          You are about to activate Instant Trading mode. By clicking "I Accept
          these Terms and Conditions" below, you acknowledge that you have read
          and understood the following terms and conditions, and you agree to be
          bound hereby. Your current version of the terminal enables you to
          choose between the following modes for order submission. You agree
          that you will be bound by the procedures and conditions specified
          herein with respect to each such mode.
        </p>
        <p>
          1. The Default mode for order submission is a two-step process: you
          first open a new order window, then you select an appropriate order
          type, its parameters, and confirm your order submission by clicking
          either Buy, Sell, Place, Modify or Close buttons depending on the
          particular order type selected and your trading intentions. Your order
          will not be submitted until you have completed both of the
          aforementioned steps.
        </p>
        <p>
          2. The Instant Trading mode for order submission is a one-step
          process. Your order will be submitted when you:
        </p>
        <p>
          <span class="circle"></span>on a chart click either bid (SELL) or ask
          (BUY) rate button:
        </p>
        <p>
          <span class="circle"></span>delete orders in the Trade tab of the web
          window
        </p>
        <p>
          THERE WILL BE NO SUBSEQUENT CONFIRMATION PROMPT FOR YOU TO CLICK. YOU
          WILL NOT BE ABLE TO WITHDRAW OR CHANGE YOUR ORDER ONCE YOU CLICK.
          UNDER NORMAL MARKET CONDITIONS AND SYSTEM PERFORMANCE, A MARKET ORDER
          WILL BE PROMPTLY FILLED AFTER SUBMISSION AND YOU WILL HAVE ENTERED
          INTO A BINDING TRANSACTION.
        </p>
        <p>
          You can activate or deactivate Instant Trading mode on the Trade tab
          of Options window of the web browser.
        </p>
        <p>
          By selecting the Instant Trading mode, you understand that your orders
          will be submitted by clicking the bid or ask rate button or in any
          other way described above, without any further order confirmation. You
          agree to accept all risks associated with the use of the order
          submission mode you have chosen, including, without limitation, the
          risk of errors, omissions or mistakes made in submitting any order.
        </p>
        <p>
          You agree to fully indemnify and hold harmless AdvanTrade from any and
          all losses, costs and expenses that it may incur as a result of any
          such errors, omissions or mistakes by you, your trading manager or any
          other person trading on your behalf.
        </p>
      </div>

      <div>
        <span
          class="my_radio"
          :class="agreeFlag ? 'agree' : ''"
          @click="agreeFlag = !agreeFlag"
        ></span
        >I accept the above contract terms
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="openInstantTradingFunc"
            :disabled="!agreeFlag"
          >
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, onMounted, nextTick, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import apiService from "@/api/request";
import { ElMessage } from "element-plus";

export default {
  name: "header",
  props: {
    headFlag: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    let isFixed = ref(false);
    let isShow = ref(false);
    let offsetTop = ref(90);
    const store = useStore();
    const router = useRouter();
    let lang = ref("English");
    const { locale } = useI18n();
    const { t } = useI18n();
    const dialogVisible = ref(false);
    const agreeFlag = ref(false);

    function initHeight() {
      // 设置或获取位于对象最顶端和窗口中可见内容的最顶端之间的距离 (被卷曲的高度)
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      isFixed.value = scrollTop > offsetTop.value ? true : false;
    }
    function showDrop() {
      isShow.value = true;
    }
    function hideDrop() {
      isShow.value = false;
    }
    const userInfo = computed({ get: () => store.getters.userInfo });
    console.log("userInfo", userInfo);
    onMounted(() => {
      console.log("Component is mounted!");
      window.addEventListener("scroll", initHeight);
      nextTick(() => {
        //获取对象相对于版面或由 offsetTop 属性指定的父坐标的计算顶端位置
        // offsetTop.value = document.querySelector('#boxFixed').offsetTop;
      });
    });

    /**
     * 跳转方法
     */
    function jump(e) {
      if (e == "transactionMn") {
        apiService.SwitchQuoteInfo().then((res) => {
          console.log("SwitchQuoteInfo", res);
          let status = res.data.demo.status;
          if (status == 0) {
            router.push({
              name: "signUp",
            });
          } else if (status == 2 || status == 3) {
            ElMessage({
              message: t("trade.text148"),
              type: "error",
            });
            return;
          } else {
            router.push({
              name: e,
            });
          }
        });
      } else {
        router.push({
          name: e,
        });
      }
    }

    // 安全退出
    function logout(responseData) {
      store.commit("LOGINOUT");
      localStorage.removeItem("ehammer.state");
      localStorage.removeItem("userToken");
      localStorage.removeItem("tradeToken");
      localStorage.removeItem("userStatus");
      localStorage.removeItem("instantTrading");
      instantTrading.value = "2";
      localStorage.setItem("ehammer.state", JSON.stringify(store.state));
      jump("signUp");
      // window.location.reload();
    }
    //选择语言
    function ChangeLang(e) {
      if (e == "cn") {
        locale.value = "cn";
        jump("homeCN");
        store.commit("NOW_LANG", e);

        // window.location.reload();
      }
    }
    let upColor = ref("red");
    let instantTrading = ref("2");

    const onChange = (e, f) => {
      console.log("onChange", e);
      store.commit("NOW_UP_COLOR", e);
      store.commit("NOW_THEME_COLOR", "white");
      localStorage.removeItem(
        "tradingview.chartproperties.mainSeriesProperties"
      ); //移除用户设置颜色
      if (e === "red") {
        document.body.classList.remove("green-up");
        document.body.classList.remove("blue-up");
        document.body.classList.add("red-up");
      } else if (e === "green") {
        document.body.classList.remove("red-up");
        document.body.classList.remove("blue-up");
        document.body.classList.add("green-up");
      } else if (e === "blue") {
        document.body.classList.remove("green-up");
        document.body.classList.remove("red-up");
        document.body.classList.add("blue-up");
      }
      if (!f) {
        location.reload();
      }
    };

    function instantTradingFunc(e) {
      if (!localStorage.userToken) {
        router.push("/signUp");
        instantTrading.value = "2";
      } else {
        if (e == "1") {
          instantTrading.value = "2";
          dialogVisible.value = true;
        } else {
          localStorage.instantTrading = e;
          agreeFlag.value = false;
        }
      }
    }

    //确认快速交易
    function openInstantTradingFunc() {
      console.log("agreeFlag.value", agreeFlag.value);
      if (agreeFlag.value) {
        instantTrading.value = "1";
        localStorage.instantTrading = "1";
      }
      dialogVisible.value = false;
      ElMessage({
        message: t("trade.text150"),
        type: "success",
      });
    }

    onMounted(() => {
      upColor.value = store.state.home.contentColor;
      onChange(upColor.value, true);
      instantTrading.value = localStorage.instantTrading || "2";
    });
    return {
      jump,
      isFixed,
      offsetTop,
      initHeight,
      isShow,
      showDrop,
      hideDrop,
      userInfo,
      logout,
      lang,
      ChangeLang,
      instantTrading,
      upColor,
      onChange,
      instantTradingFunc,
      dialogVisible,
      agreeFlag,
      openInstantTradingFunc,
    };
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 50px;
  background: #0e0e0e;

  .header_nav {
    height: 50px;
    padding: 10px 15px 0 21px;

    .logo {
      display: block;
      width: 80px;
      float: left;
    }

    .language {
      float: right;
      color: #ffffff;
      margin-top: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        display: inline-block;
        width: 10px;
        height: 6px;
        margin-left: 8px;
      }
    }

    .set {
      display: flex;
      float: right;
      align-items: center;
      height: 30px;
      margin-right: 13px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .color-6 {
      float: left;
      margin-left: 50px;
    }

    .sign_up,
    .login_register {
      float: right;
    }

    .cl-effect-3 a {
      position: relative;
    }

    .cl-effect-3 {
      img {
        width: 12px;
        height: 8px;
      }
    }

    .cl-effect-3 .underscore {
      padding: 0 0;
    }

    .cl-effect-3 .underscore::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 4px;
      background: #fb741b;
      content: "";
      opacity: 0;
      -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
      -moz-transition: opacity 0.3s, -moz-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      transform: translateY(10px);
    }

    .cl-effect-3 .underscore_line::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 4px;
      background: #fb741b;
      content: "";
      opacity: 1;
      transform: translateY(0);
    }

    .cl-effect-3 .underscore:hover::after,
    .cl-effect-3 .underscore:focus::after {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      transform: translateY(0px);
    }

    .sign_up,
    .login_register {
      width: 80px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      background: #fb741b;
      font-size: 14px;
      border-radius: 2px;
      color: #ffffff;
      margin-left: 25px;
    }

    .login_register {
      width: 124px;
    }
  }

  .header_user {
    float: right;
    margin-right: 20px;
    width: 124px;
    height: 28px;
    border-radius: 2px;
    border: 1px solid #797979;
    padding-left: 8px;
    .header_username {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ant-dropdown-link {
      display: flex;
      justify-content: left;
      align-items: center;
      color: @white;
      margin-top: 2px;
      cursor: pointer;

      img {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 7px;
      }
    }
  }
  .my_dialog {
    .scroll {
      max-height: 450px;
      overflow-y: scroll;
    }
    p {
      margin-bottom: 16px;
      font-size: 14px;
    }
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #666d7f;
      border-radius: 50%;
      margin-right: 6px;
    }
    .my_radio {
      width: 12px;
      height: 12px;
      display: inline-block;
      border: 1px solid #bdbdbd;
      border-radius: 50%;
      margin-right: 8px;
      position: relative;
      top: 1px;
      cursor: pointer;
      margin-top: 10px;
    }
    .my_radio.agree {
      background: url(https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_sel_yes%402x.png)
        no-repeat;
      background-size: 100% 100%;
      border: 0;
    }
  }
}

.box_fixed {
}

.head_flag {
  position: fixed;
  background: none;
}

.is_fixed {
  animation: myfirst 1s;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #0e0e0e;
}

.drop_down {
  width: 320px;
  /*no*/
  background: white;
  position: absolute;
  left: -20px;
  display: none;
  z-index: 9;
  padding: 13px 16px;

  /*no*/
  .drop_down_box {
    display: flex;
    justify-content: space-between;
  }

  .drop_down_left,
  .drop_down_right {
    width: 130px;

    /*no*/
    h3 {
      font-size: 14px;
      /*no*/
      font-weight: bold;
      color: black;
      text-align: left;
      margin-bottom: 15px;
      text-transform: none;
    }

    a {
      width: 100%;
      color: #666666;
      font-size: 14px;
      /*no*/
      text-align: left;
      margin: 0;
      padding-bottom: 8px;
      /*no*/
      margin-bottom: 8px;
      /*no*/
      border-bottom: 1px solid #ebebeb;
      text-transform: none;
    }

    .active {
      color: #fb741b;
    }
  }
}
.icon_set {
  cursor: pointer;
  margin-right: 10px;
}
.is_show {
  display: block;
  animation: myfirst2 0.5s;
}

.icon_up {
  transform: rotate(180deg);
  animation: myfirst3 0.5s;
}

@keyframes myfirst {
  from {
    top: -90px;
  }

  to {
    top: 0;
  }
}

@keyframes myfirst2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes myfirst3 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

nav a {
  position: relative;
  display: inline-block;
  margin: 7px 25px;
  outline: none;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  font-size: 14px;
}

nav a:hover,
nav a:focus {
  outline: none;
}
</style>
<style lang="less">
.underscore_line_2 {
  background-color: var(--el-dropdown-menuItem-hover-fill);
  color: var(--el-dropdown-menuItem-hover-color) !important;
}
.header {
  .my_dialog {
    border-radius: 10px;
  }
}
</style>