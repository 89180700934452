<template>
    <div class="AddBankCard">
        <el-dialog :title="$t('myAccount.text128')" v-model="AddBankCardModelFlag" width='500px' custom-class="dialog-box"
            :close-on-click-modal="false" destroy-on-close @closed="beforeClose">
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text111') }}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        {{ name }}
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text112') }}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-checkbox v-for="item in bankCardCheckbox" v-model="item.checked" :label="item.name" size="large"
                            @change="currencyChangeFunc" />
                        <el-radio-group v-model="currency" class="ml-4" @change="currencyRadioChangeFunc">
                            <el-radio v-for="item in bankCardRadio" :value="item.name" size="large">{{ item.name }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text113') }}
                    </div>
                </div>
                <div class="dialog-right" v-if="currency == 'CNY' || currency == 'VND'">
                    <div class="value">
                        <el-input v-model="bankName" :placeholder="placeholder">

                        </el-input>
                    </div>
                </div>
                <div class="dialog-right" v-else>
                    <div class="value">
                        <el-select v-model="bankName" filterable allow-create :disabled='currency == ""' class="m-2"
                            :placeholder="placeholder" :no-data-text="$t('trade.text66')">
                            <el-option v-for="item in bankNameList" :key="item" :label="item" :value="item" />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text114') }}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input @input="specification" v-model="bankAccountNumber" :placeholder="$t('myAccount.text117')"
                            maxlength='30' />
                    </div>
                </div>
            </div>
            <div class="box-inline" v-if="currency != 'CNY'">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text129') }}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input v-model="bankCode" :placeholder="$t('myAccount.text117')" />
                    </div>
                </div>
            </div>
            <div class="box-inline textarea" v-if="currency != 'CNY'">
                <div class="dialog-left">
                    <div class="label">
                        {{ $t('myAccount.text116') }}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input type="textarea" resize="none" v-model="bankAddress"
                            :placeholder="$t('myAccount.text117')" />
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="AddBankCardModelFlag = false">{{ $t('trade.text72') }}</el-button>
                    <el-button type="primary" :loading='loading' :disabled="!submitFlag"
                        @click="Save">{{ $t('myAccount.text118') }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
    ref,
    reactive,
    computed,
    inject,
    onBeforeUnmount,
    nextTick
} from 'vue';
import { useStore } from 'vuex';
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useI18n } from "vue-i18n";


export default {
    name: 'AddBankCard',
    setup(props, ctx) {
        const emitt = inject('emitt');
        const { t } = useI18n()
        const AddBankCardModelFlag = ref(false);
        const store = useStore();
        const loading = ref(false);
        const placeholder = ref(t('myAccount.text119'));
        const bankAccountNumber = ref('');
        const bankCode = ref('');
        const bankAddress = ref('')

        /**
         * 打开弹框-开始
         */
        emitt.$sub('openAddBankCard', (val) => {
            BankCardCurrency();
            AddBankCardModelFlag.value = true;
            loading.value = false
        });
        onBeforeUnmount(() => {
            emitt.$unsub("openAddBankCard");
        });
        /**
         * 打开弹框-结束
         */

        /**
         * 获取展示信息-开始
         */
        // 实名认证需要的姓名
        const name = computed({
            get: () => store.state.user.userInfo.name,
        });

        /**
         * 获取展示信息-结束
         */

        /**
         * 按钮提交条件-开始
         */
        const submitFlag = computed(() => {
            if (currency.value == 'CNY') {
                return bankName.value != '' &&
                    bankAccountNumber.value != ''
            } else {
                return bankName.value != '' &&
                    bankAccountNumber.value != '' &&
                    bankCode.value != '' &&
                    bankAddress.value != '' &&
                    currency.value != ''
            }
        })
        /**
         * 按钮提交条件-结束
         */

        /**
         * 添加银行卡-开始
         */
        function Save() {
            let params = {
                banktypename: bankName.value,
                bankaccount: bankAccountNumber.value,
                swiftcode: bankCode.value,
                bankaddress: bankAddress.value,
                currency: currency.value,
                firstname: name.value
            }
            loading.value = true;
            console.log(params)
            apiService.AddBankWire(params).then(res => {
                loading.value = false;
                if (res.status == 1) {
                    ElMessage({
                        message: t('myAccount.text120'),
                        type: 'success',
                    });
                    setTimeout(() => {
                        // window.location.reload();
                    }, 2000);
                } else {
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            })
        }
        /**
         * 添加银行卡-结束
         */

        /**
         * 获取币种-开始
         */
        const bankCardCheckbox = ref([])
        const bankCardRadio = ref([])
        function BankCardCurrency() {
            apiService.BankCardCurrency().then(res => {
                if (res.status == 1) {
                    let arr = []
                    let arr2 = []
                    for (let item of res.data.list) {
                        if (item == 'VND' || item == 'CNY') {
                            arr.push({
                                name: item,
                                checked: false
                            })
                        } else {
                            arr2.push({
                                name: item,
                                checked: false
                            })
                        }

                    }
                    bankCardCheckbox.value = arr2;
                    bankCardRadio.value = arr;
                } else {
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            })
        }
        /**
         * 获取币种-结束
         */

        /**
         * 选择币种获取银行名称列表-开始
         */
        const bankName = ref('');
        const currency = ref('');
        const bankNameList = ref([])
        function currencyChangeFunc(e) {
            bankAccountNumber.value = '';
            bankName.value = '';
            bankNameList.value = [];
            currency.value = '';
            for (let item of bankCardCheckbox.value) {
                if (item.checked) {
                    currency.value += item.name + ','
                }
            }
            if (currency.value == '') return;
            let params = {
                currency: currency.value
            }
            apiService.CurrencyBank(params).then(res => {
                if (res.status == 1) {
                    bankNameList.value = res.data.list;
                    placeholder.value = currency.value == "CNY" || currency.value == "VND"?t('myAccount.text147'):t('myAccount.text130');
                } else {
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            })
        }
        /**
         * 选择币种获取银行名称列表-结束
         */

        //单选币种获取银行名称
        function currencyRadioChangeFunc(e) {
            bankAccountNumber.value = '';
            bankName.value = '';
            bankNameList.value = [];
            bankCardCheckbox.value.forEach(e => { e.checked = false });
            let params = {
                currency: currency.value
            }
            apiService.CurrencyBank(params).then(res => {
                if (res.status == 1) {
                    bankNameList.value = res.data.list;
                    placeholder.value = currency.value == "CNY" || currency.value == "VND"?t('myAccount.text147'):t('myAccount.text130');
                } else {
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            })
        }

        /**
        * 关闭弹框-开始
        */
        function beforeClose() {
            bankName.value = '';
            bankAccountNumber.value = '';
            bankCode.value = '';
            bankAddress.value = '';
            currency.value = '';
        }
        /**
         * 关闭弹框-结束
         */

        //规范银行卡号
        function specification(v) {
            // 输入的数据进行初始化，将非数字的替换为空
            let val = v;
            let isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
            while (!isMatch && val != '') {
                val = val.substring(0, val.length - 1)
                isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
            }
            nextTick(() => {
                bankAccountNumber.value = val;
            });
        }
        return {
            AddBankCardModelFlag,
            name,
            loading,
            submitFlag,
            Save,
            bankCardCheckbox,
            bankCardRadio,
            currencyChangeFunc,
            bankNameList,
            bankName,
            currency,
            placeholder,
            bankAccountNumber,
            bankCode,
            bankAddress,
            beforeClose,
            currencyRadioChangeFunc,
            specification
        }
    }
}
</script>
<style lang="less" scoped>
.AddBankCard {
    .box-inline {
        font-size: 14px;
        /*no*/
        display: flex;
        justify-content: space-between;
        line-height: 48px;

        /*no*/
        .dialog-left {
            width: 196px;
            /*no*/
        }

        .dialog-right {
            width: 240px;
            /*no*/
        }

        .el-select {
            width: 100%;
        }

        .el-checkbox {
            height: 48px;
            /*no*/
        }
    }

    .textarea {
        height: auto;

        .value {
            margin-top: 14px;
            /*no*/
        }
    }
}</style>
