<template>
    <div class="withdrawalCurrency">
        <div class="choose_currency">
            <div class="heading">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_currency%402x.png" alt="">
                <div class="title">{{$t('withdraw.text38')}}</div>
            </div>
            <div class="currency_list">
                <p>{{$t('withdraw.text39')}}</p>
                <ul>
                    <li v-for="item in currencyList" @click="gotoGetPayType(item.id)">
                        <div class="li_left">
                            <img :src="item.icon" alt="">
                            <div class="name">{{item.name}}</div>
                        </div>
                        <i class="iconfont icon-icon_jump_dack"></i>
                    </li>
                </ul>
                <div class="empty" v-if="currencyList.length == 0">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_no_data%402x.png" alt="">
                    <p>{{$t('trade.text66')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
 ref,computed
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default {
    name:'withdrawalCurrency',
    components:{
      
    },
    setup(){
        const store = useStore();
        const router = useRouter();

        const currencyList = ref([]);//币种列表
        //获取币种
        apiService.GetPayCurrencyCategoryList({type:2}).then(res => {
            console.log('GetPayCurrencyCategoryList',res);
            if(res.status == 1){
                currencyList.value = res.data.list;
            }
        })

        function gotoGetPayType(e){
            router.push({
                    name:'withdrawal',
                    query:{
                        id:e
                    }
                })
        }
        return {
           currencyList,
           gotoGetPayType
        }
    }
}
</script>
<style lang="less" scoped>
.withdrawalCurrency{
    .heading{
            padding: 12px 30px;
            font-size: 18px;
            display: flex;
            justify-content: left;
            border-bottom: 1px solid @border-color-base;/*no*/
        img{
            display: block;
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-right: 10px;
        }
        .title{
            font-weight: 500;
        }
    }
    .currency_list{
        padding: 20px 30px;
        p{
            color: #FB741B;
            font-size: 12px;
            text-align: left;
        }
        ul{
            margin-top: 30px;
            display: flex;
            justify-content: left;
            width: 840px;
            flex-wrap: wrap;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 400px;
                height: 56px;
                border: 2px solid #E9E9E9;
                padding: 10px 16px;
                border-radius: 10px;
                margin-bottom: 30px;
                &:nth-child(odd){
                    margin-right: 40px;
                }
                cursor: pointer;
                .li_left{
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    img{
                        width: 32px;
                        height: 32px;
                    }
                    .name{
                        font-size: 16px;
                        margin-left: 16px;
                    }
                }
                .iconfont{
                    font-size: 12px;
                    color:#BEC2CA;
                }
            }
        }
        .empty{
            padding-top: 80px;
            img{
            width: 70px;
            }
            p{
                text-align: center;
                font-size: 14px;
                color: #979DAB;
            }
        }
    }
}
</style>