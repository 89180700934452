<template>
    <div class="BlockChainWithdraw">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('withdraw.text56')}}</div>
                <div class="value">
                    <el-select v-model="widthdrawData.payChannel"
                     class="m-2" 
                     size="large" 
                     @change="selectwithDrawChannel"
                     >
                        <el-option
                        v-for="item in widthdrawShow.payChannels"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
            <div class="line" v-if="widthdrawData.payId == 5004">
            <div class="label">{{$t('withdraw.text57')}}</div>
            <div class="value">
                <div class="radio">
                    <div class="btn_radio cursor" :class="widthdrawData.chainValue == item.value?'active_radio':''" v-for="item in  widthdrawShow.protocol" @click="onChangeChain(item)">{{item.name}}</div>
                </div>
            </div>
        </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text58')}}
                    <img class="cursor" src="https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_instructions_gray.png" @click="alertExplain">
                </div>
                <div class="value">
                    <div class="value_text">{{widthdrawShow.canDrawAmount}} {{widthdrawShow.currentType}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text42')}}</div>
                <div class="value">
                    <div class="value_text">1{{widthdrawShow.currentType}}≈{{widthdrawShow.exchangeRate}}USD</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text59')}}</div>
                <div class="value">
                    <div class="value_text">{{widthdrawData.walletfee}} USD</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text16')}}({{widthdrawShow.currentType}})</div>
                <div class="value">
                    <el-input
                        v-model="widthdrawData.amount"
                        size="large"
                        :placeholder="placeholderText"
                        class="withdrawal_amount"
                        @input="countPayment"
                    >
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="allPrice">{{$t('withdraw.text6')}}</div>
                    </template>
                    </el-input>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text60')}}({{widthdrawData.currentType}})</div>
                <div class="value">
                    <el-input
                        v-model="widthdrawData.outCashAddress"
                        size="large"
                        :placeholder="$t('withdraw.text61')"
                        class="withdrawal_amount"
                    >
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="openSelectWithdrawalAddress">{{$t('withdraw.text62')}}</div>
                    </template>
                    </el-input>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text63')}}</div>
                <div class="value">
                    <div class="value_text">{{widthdrawShow.rmbAmount}} USD</div>
                </div>
            </div>
            <p class="wran">{{$t('withdraw.text64')}}</p>
            <el-button size="large" type="primary" class="confirm" :loading='btnLoading' :disabled="!disabled" @click="Confirm">{{$t('withdraw.text12')}}</el-button>
            <p class="arrayTips" v-html="widthdrawShow.arrayTips.tips"></p>
        </div>
        <SelectWithdrawalAddress 
            @changeAddrees="changeAddrees" 
            :address="widthdrawData.outCashAddress" 
            :currentType="widthdrawData.currentType"
        />
        <AddWithdrawalAddress
            @changeAddrees="changeAddrees" 
            :currentType="widthdrawData.currentType"
        />
        <SafetyVerification @walletRquestChain="walletRquestChain"/>
    </div>
</template>
<script>
import {
 ref, toRefs, computed, reactive, getCurrentInstance, inject, nextTick,
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import AddWithdrawalAddress from '../dialog/AddWithdrawalAddress'
import SelectWithdrawalAddress from '../dialog/SelectWithdrawalAddress'
import SafetyVerification from '../dialog/SafetyVerification'
import { useI18n } from "vue-i18n";

export default {
    name:'BlockChainWithdraw',
    props: {
        payType: {
            type: Number,
        },
    },
     components:{
         SelectWithdrawalAddress,
         AddWithdrawalAddress,
         SafetyVerification
    },
    setup(props){
        const store = useStore();
        const { t } = useI18n()
        let loading;
        const emitt = inject('emitt');
        const btnLoading = ref(false);
        const placeholderText = ref('');
        openFullScreen();
        // 获取 payId
        apiService.PayOutChannel({
            userToken: store.getters.userToken,
            payType: Number(props.payType),
        }).then(async (res) => {
            if (res.status === 1) {
                widthdrawData.payChannel = res.data?.list[0].name;
                widthdrawShow.payChannels.push(...res.data.list);

                // 默认选择第一个通道
                // 根据 payId，获取页面详情
                selectwithDrawChannel(res.data.list[0].payId);
                // 获取交易中的订单
                // await getOTCPayingOrder(res.data.list[0].payId);
            }
        });
        //打开选择银行卡弹框
        function openSelectWithdrawalAddress(){
            emitt.$pub('openSelectWithdrawalAddress');
        }

          //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('withdraw.text22'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

        /**
         * 按钮提交条件-开始
         */
            const disabled = computed(() => {
                return  widthdrawData.amount != '' &&
                        widthdrawData.outCashAddress != ''
            })
        /**
         * 按钮提交条件-结束
         */

         // 选择通道
        async function selectwithDrawChannel(payId) {
            widthdrawData.payId = payId;
            // 获取通道的详情数据
            const res = await apiService.PayOutChannelPage({
                userToken: store.getters.userToken,
                payId: widthdrawData.payId,
            });
            // 获取汇率展示需要的数据
            widthdrawShow.exchangeRate = res.data.exchangeRateInfo[0].exchangeRate;
            widthdrawShow.currentType = res.data.currentType;
            widthdrawData.currentType = res.data.currentType;
            if (res.data.currentType === 'USDT')widthdrawData.currentType = widthdrawData.currentType +'-'+ res.data.protocol[0].name;
            // 最大最小限制
            widthdrawShow.minWithdrawLimit = res.data.minWithdrawLimit;
            widthdrawShow.maxWithdrawLimit = res.data.maxWithdrawLimit;

            if(widthdrawShow.minWithdrawLimit != '' && widthdrawShow.maxWithdrawLimit){
                placeholderText.value = t('withdraw.text23') + widthdrawShow.minWithdrawLimit +'-'+ widthdrawShow.maxWithdrawLimit + widthdrawShow.currentType;
            }else if(minWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text24') + widthdrawShow.maxWithdrawLimit + widthdrawShow.currentType;
            }else if(maxWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text25') + widthdrawShow.minWithdrawLimit + widthdrawShow.currentType;
            }
            widthdrawShow.protocol = res.data.protocol;
            widthdrawData.chainValue = res.data.protocol[0].value;

            // 获取手续费
            if(widthdrawData.payId == 5004){
                widthdrawData.walletfee = res.data.protocol[0].fee;
                widthdrawShow.canDrawAmount = res.data.protocol[0].canDrawAmount;
            }else{
                widthdrawData.walletfee = res.data.walletfee;
                widthdrawShow.canDrawAmount = res.data.canDrawAmount;
            }
            // 整理提示数据
            Object.assign(widthdrawShow.arrayTips, res.data.arrayTips[0]);
            // 清空输入
            widthdrawData.amount = '';
            widthdrawData.outCashAddress = '';
            countPayment(widthdrawData.amount);
            loading.close();
        }


        //选择地址
        function changeAddrees(item){
            console.log(item)
            widthdrawData.outCashAddress = item;
        }
        const widthdrawShow = reactive({
            exchange: '',
            // 出金类型
            payType: '',
            // 当前选中label
            payChannel: '',
            // 出金通道列表
            payChannels: [],
            // 当前tips
            arrayTips: { tips: '' },
            // 最大值限制
            maxWithdrawLimit: '',
            // 最小值限制
            minWithdrawLimit: '',
            // 人民币汇率
            exchangeRate: '',
            // 可提金额
            canDrawAmount: '',
            // 当前币种
            currentType: '',
            // 当前选中银行卡显示
            walletBank: {},
            //
            walletBankStr: '',
            // 换算美元金额
            rmbAmount: '0.00',
            payOrder: {},
            // 出金地址列表
            addressList: [],
            // 选中的出金地址
            AddressItem: {},
            isShowSendCode: false,
        });
        const widthdrawData = reactive({
        // 当前通道id
            payId: '',
            // 手续费
            walletfee: '',
            // 选中银行卡
            walletbankId: '',
            // 出金金额
            amount: '',
            // 出金地址
            outCashAddress: '',
            // 当前币种
            currentType: '',
            chainValue: 0,
            // 验证码
            vcode: '',
        });

    // 选择所有可提余额
    function allPrice() {
      widthdrawData.amount = widthdrawShow.canDrawAmount;
      countPayment(widthdrawData.amount);
    }

    // 计算入金金额
    function countPayment(v) {
       // 输入的数据进行初始化，将非数字的替换为空
            const  value = v;
            let reg=new RegExp(`\\d+(\\.\\d{0,${8}})?`,'g')
            const val = value.match(reg)?value.match(reg)[0]:""
            nextTick(() => {
                widthdrawData.amount = val;
                // 计算支付金额
                if (widthdrawData.amount !== null ) {
                    widthdrawShow.rmbAmount = widthdrawData.amount * widthdrawShow.exchangeRate;
                    widthdrawShow.rmbAmount = widthdrawShow.rmbAmount < 0 ? 0 : widthdrawShow.rmbAmount.toFixed(2);
                }
            });
    }

    function alertExplain() {
      // 可提金额说明
      const explainText = `
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #111E36;
        margin-bottom: 16px;
        text-align: left;
      `;
      const smallTitleStyle = `
      font-size: 14px;
      font-family: MicrosoftYaHei - Bold,MicrosoftYaHei;
      font-weight: bold;
      color: #FB741B;
      text-align: left;
      margin-bottom: 8px;
      `;
      const grayText = `
        text-align: left;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #919CAD;
        line-height: 24px;
        margin-bottom: 24px;
      `;
      ElMessageBox.alert(
        `
          <p style="${smallTitleStyle}">${t('withdraw.text66')}</p>
          <p style="${explainText}">${t('withdraw.text67')}</p>
          <p style="${smallTitleStyle}">${t('withdraw.text68')}</p>
          <p style="${explainText}">${t('withdraw.text69')}</p>
          <p style="${grayText}">${t('withdraw.text70')}</p>
        `,
        t('withdraw.text65'),
        {
            dangerouslyUseHTMLString: true,
            confirmButtonText: t('withdraw.text32')
        }
      );
    }

     // 切换链名称
    function onChangeChain(e) {
      widthdrawData.currentType = 'USDT' +'-'+ e.name
      widthdrawData.chainValue = e.value;
      if(e.value == 1){
            widthdrawData.walletfee = widthdrawShow.protocol[0].fee;
            widthdrawShow.canDrawAmount = widthdrawShow.protocol[0].canDrawAmount;
      }else if(e.value == 2){
          widthdrawData.walletfee = widthdrawShow.protocol[1].fee;
        widthdrawShow.canDrawAmount = widthdrawShow.protocol[1].canDrawAmount;
      }
    }
    
    //提交
   async function Confirm(){

        if(Number(widthdrawShow.canDrawAmount) < Number(widthdrawData.amount)){
                    ElMessage.error(t('withdraw.text73'))
                    return
        }

        if(widthdrawShow.minWithdrawLimit != '' && widthdrawShow.maxWithdrawLimit != ''){
                if(Number(widthdrawData.amount) < Number(widthdrawShow.minWithdrawLimit) || Number(widthdrawData.amount) > Number(widthdrawShow.maxWithdrawLimit)){
                    ElMessage.error(t('withdraw.text23') + widthdrawShow.minWithdrawLimit +'-'+ widthdrawShow.maxWithdrawLimit + widthdrawShow.currentType)
                    return;
                }
            }else if(widthdrawShow.minWithdrawLimit == ''){
                if(Number(widthdrawData.amount) > Number(widthdrawShow.maxWithdrawLimit)){
                    ElMessage.error(t('withdraw.text24') + maxWithdrawLimit.value + widthdrawShow.currentType)
                    return;
                }
            }else if(widthdrawShow.maxWithdrawLimit == ''){
                    if(Number(widthdrawData.amount) < Number(widthdrawShow.minWithdrawLimit)){
                    ElMessage.error(t('withdraw.text25') + minWithdrawLimit.value + widthdrawShow.currentType)
                    return;
                }
            }

        const res = await apiService.JudgeHavePos({});
        if(res.status == 1){
                if(res.data.hasPos){
                    if(res.data.hasCredit){
                        ElMessageBox.alert(res.data.tips,t('withdraw.text26'),
                        {
                            confirmButtonText:t('trade.text84'),
                        })
                    }else{
                        ElMessageBox.confirm(
                            res.data.tips,
                            t('withdraw.text26'),
                            {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: t('withdraw.text27'),
                            cancelButtonText: t('withdraw.text28'),
                            }
                        ).then(() => {
                            openSafetyVerification();
                        }) .catch(() => {

                        })
                    }
                }else{
                    openSafetyVerification();
                }
            }
    }
        //出金
        function walletRquestChain(vcode){
            btnLoading.value = true;
            const params = {
                outCashAddress: widthdrawData.outCashAddress,
                digiccyAmount: widthdrawData.amount,
                currentType: widthdrawShow.currentType,
                fundTerminal: 1,
                payChannelId: widthdrawData.payId,
                vcode: vcode,
            };
            if (widthdrawShow.currentType === 'USDT') {
                Object.assign(params, { protocol: widthdrawData.chainValue });
            }
            apiService.walletRquestChain(params).then((res) => {
                if (res.status === 1) {
                    widthdrawData.amount = '';
                    widthdrawData.vcode = '';
                    widthdrawData.outCashAddress = '';
                    selectwithDrawChannel(widthdrawData.payId);
                    emitt.$pub('toggleSafetyVerification',{flag:false});
                    ElMessage({
                        message: t('withdraw.text29'),
                        type: 'success',
                    })
                }else {
                    ElMessage({
                        message: res.message,
                        type: 'error',
                        })
                    }
                    btnLoading.value = false;
            });
        }
      
        //打开验证码
        function openSafetyVerification(){
            emitt.$pub('toggleSafetyVerification',{flag:true,title:t('withdraw.text71'),warn:t('withdraw.text72')});
        }
        return {
            widthdrawShow,
            widthdrawData,
            selectwithDrawChannel,
            alertExplain,
            onChangeChain,
            allPrice,
            countPayment,
            openSelectWithdrawalAddress,
            changeAddrees,
            Confirm,
            btnLoading,
            disabled,
            walletRquestChain,
            placeholderText
        }
    }
}
</script>
<style lang="less" scoped>
.BlockChainWithdraw{
    padding: 24px 30px;
    .line_box{
        .line{
            overflow: hidden;
            margin-bottom: 16px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 251px;
                text-align: left;
                font-size: 14px;
                img{
                    position: relative;
                    top:2px;
                }
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                    img{
                        width: 12px;
                        height: 12px;
                        position: relative;
                        top: 2px;
                        left: 7px;
                    }
                }
                 .fast_btn_l{
                        border-right:  1px solid @border-color-base;/*no*/
                        width: 60px;
                        line-height: 40px;
                        color: @primary-color;
                    }
                    .fast_btn_r{
                        border-left:  1px solid @border-color-base;/*no*/
                        width: 60px;
                        line-height: 40px;
                        color: @primary-color;
                    }
                    .radio{
                        display: flex;
                        justify-content: left;
                        padding-top: 4px;
                        .btn_radio{
                            width: 80px;
                            height: 32px;
                            border: 1px solid @border-color-base;/*no*/
                            color: @text-color-secondary;
                            text-align: center;
                            line-height: 32px;
                            border-radius: 2px;
                            &:first-child{
                                margin-right: 22px;
                            }
                        }
                        .active_radio{
                            border-color: @primary-color;
                            color: @primary-color;
                        }
                     }
                .fast_btn_r{
                    width: 64px;
                    height: 40px;
                    background: @primary-color;
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    line-height: 40px;
                }
            }
        }
        .confirm{
            margin-top: 20px;
            width: 100%;
        }
        .wran{
            color: @red;
            font-size: 14px;
            text-align: left;
        }
        .arrayTips{
            margin-top: 28px;
            text-align: left;
        }
    }
}
</style>
<style lang="less">
.BlockChainWithdraw{
    .el-select{
        width:100%;
    }
     .withdrawal_amount{
        .el-input__suffix{
            right: 0;
        }
    }
}
</style>
