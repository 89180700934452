import { App } from 'vue';
import PhoneInputBox from "./PhoneInputBox/index.vue";
import CodeInputBox from "./CodeInputBox/index.vue";
import PasswordInputBox from "./PasswordInputBox/index.vue";
import ChangeTradingPassword from "./ChangeTradingPassword/index.vue";
import SetTradingPassword from "./SetTradingPassword/index.vue";

const components = [
    PhoneInputBox,
    CodeInputBox,
    PasswordInputBox,
    ChangeTradingPassword,
    SetTradingPassword
]
export default {
  install(app) {
    components.forEach(component => {
        app.component(component.name, component);
    });
  },
  PhoneInputBox,
  CodeInputBox,
  PasswordInputBox,
  ChangeTradingPassword,
  SetTradingPassword
}
