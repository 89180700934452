<template>
    <div class="IBProgram">
       <div class="bannar">
          <div class="w1200">
              <h2>经纪人 (IB) 计划</h2>
                <p>您还能怎么赚钱？</p>
                <p>成为IB代理人！</p>
                <div class="introduce">
                    <div class="introduce_title">什么是经纪人（IB）计划?</div>
                    <p>AdvanTrade爱跟投深耕于外汇和CFD差价合约交易多年，深受客户和合作伙伴信任，我们鼓励所有把新客户推荐给公司的个人和机构，为此特别成立了IB经纪人计划。您只需要通过自己的人脉或营销工作把客户介绍来，剩下的我们负责。</p>
                    <p>成为IB代理人很简单。AdvanTrade爱跟投经纪人（IB）计划是最直接的代理合作类型，无需前期投资、无需复杂手续即可快速加入，迄今已有数百位合作伙伴通过经纪人（IB）计划向我们推介客户，立刻联系我们吧。</p>
                </div>
          </div>
       </div>
       <div class="content_one">
            <div class="title">您将获得</div>
            <div class="w1200 content_flex">
                <div class="content_left">
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_1%402x.png" alt="">
                        <p>实时客户反馈和报告</p>
                    </div>
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_2%402x.png" alt="">
                        <p>有吸引力的IB薪酬回报</p>
                    </div>
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_3%402x.png" alt="">
                        <p>与值得信赖</p>
                        <p>受监管的经纪商合作</p>
                    </div>
                </div>
                <div class="content_center">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_pic_benefits%402x.png" alt="">
                </div>
                <div class="content_right">
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_4%402x.png" alt="">
                        <p>几天内轻松上手</p>
                    </div>
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_5%402x.png" alt="">
                        <p>详细的客户指引</p>
                    </div>
                    <div class="item">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_include_6%402x.png" alt="">
                        <p>无需前期投资</p>
                    </div>
                </div>
            </div>
       </div>
       <div class="content_two">
            <div class="title">
                IB福利
            </div>
            <div class="w1200 content_flex">
                <div class="item">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_compensations_1%402x.png" alt="">
                    <p>有竞争力的返佣回报</p>
                    <p>高达 15 美元/手</p>
                </div>
                <div class="item">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_compensations_2%402x.png" alt="">
                    <p>转账、提现快速通道</p>
                </div>
                <div class="item">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_icon_compensations_3%402x.png" alt="">
                    <p>高价值奖品</p>
                    <p>如：新车、MacBooks、VIP</p>
                    <p>晚宴、沙龙活动等</p>
                </div>
            </div>
       </div>
       <div class="content_three">
            <div class="content_box w1200">
                <div class="title">IB支持</div>
                <h6>自定义的工具和设置</h6>
                <p>AdvanTrade爱跟投基于自身强大的技术团队，可以根据IB要求开发个性化的功能和工具，帮助 IB 拓展客户。同时，我们的系统具备高度灵活性，支持IB为不同客户进行自定义设置，例如更低的点差、更高的杠杆及不同的跟单交易费用等。</p>
                <h6>营销支持</h6>
                <p>AdvanTrade爱跟投为尊贵的 IB 合作伙伴提供营销支持，包括但不限于设计宣传册、礼品等物料，便于IB 更好地向客户进行品牌展示和营销宣传。</p>
                <h6>活动支持</h6>
                <p>大力支持代理，配合开展各种新户活动。无论需要我们提供素材内容或是其他支持，我们都乐于帮助。</p>
            </div>
             <div class="btn cursor" @click="routerTo('/signUp')">
                <span>加入我们</span><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png" alt="">
            </div>
       </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from "vue-router"

export default {
    name:"iBProgram",
    components:{
    },
    setup(){
        const router = useRouter(); 
        function routerTo(e){
            router.push(e)
       }
       
        return {
            routerTo
        }
    }
}
</script>
<style lang="less" scoped>
.IBProgram{
    .bannar{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/ib_banner%402x.png) no-repeat;
        height: 500px;
        background-size: cover;
        background-position: center;
        color: white;
        text-align: left;
        padding-top: 132px;
        position: relative;
        h2{
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 44px;
        }
        p{
            margin-bottom: 21px;
        }
    }
    .introduce{
        padding: 27px 25px 20px 25px;
        position: absolute;
        bottom: -177px;
        left: 50%;
        transform: translateX(-50%);
        background: white;
        border-radius: 8px;
        color: #000000;
        width: 1200px;
        box-shadow: 0px 14px 20px 0px #F5F5F8;
        .introduce_title{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 17px;
            p{
                margin-bottom: 20px;
            }
        }
    }
    .w1200{
        width: 1200px;
        margin: 0 auto;
    }
    .content_one{
        padding-top: 300px;
        background: white;
        .title{
            font-size: 40px;
            font-weight: 600;
        }
        .content_flex{
            display: flex;
            justify-content: space-between;
            .content_left{
                text-align: right;
                
            }
            .item{
                margin-bottom: 55px;
                width: 240px;
                &>img{
                    width: 60px;
                    height: 60px;
                    margin-bottom: 10px;
                }
                &>p{
                    font-size: 18px;
                    font-weight: 600;
                }
            }
            .content_center{
                img{
                    width: 600px;
                }
            }
            .content_right{
                text-align: left;
            }
        }
    }
    .content_two{
        padding-top: 98px;
        background: white;
        padding-bottom: 140px;
        .title{
            font-size: 40px;
            font-weight: 600;
        }
        .content_flex{
            display: flex;
            justify-content: space-between;
            margin-top: 74px;
            .item{
                width: 360px;
                height: 300px;
                text-align: center;
                padding-top: 60px;
                border-radius: 50px;
                box-shadow: 0px 10px 30px 0px rgba(210,214,218,0.5);
                img{
                    width: 82px;
                    height: 82px;
                }
                p{
                    font-size: 20px;
                }
            }
        }
    }
    .content_three{
        height: 700px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/IBProgram/bg_support.png) no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 104px;
        text-align: left;
        .title{
            font-size: 40px;
            font-weight: normal;
            margin-bottom: 45px;                                                                    
        }
        h6{
            font-weight: 600;
            margin-bottom: 17px;
            font-size: 20px;
        }
        .content_box{
            &>p{
                margin-bottom: 37px;
                font-size: 16px;
            }
        }
    }
    .btn{
        width: 210px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FB741B;
        font-size: 16px;
        border-radius: 27px;
        margin-top: 130px;
        color: white;
        margin: 0 auto;
        margin-top: 80px;
        img{
            width: 22px;
            height: 14px;
            display: block;
            margin-left: 8px;
        }
        span{
            display: block;
            font-weight: 600;
        }
    }
}
</style>
