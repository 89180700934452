import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import "@/assets/styles/common.less";
import "@/assets/styles/element.less";
import '@/assets/js/rem'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "@/assets/styles/element-variables.scss";
import "@/assets/styles/global.less";
import store from '@/store';
import emitt from '@/until/emitt';
import component from "@/components/pc";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { getQueryVariable,isNotEmpty } from '@/until/common';
import i18n from "@/lang/index";

if(isNotEmpty(getQueryVariable('keywords'))){
    sessionStorage.keywords = getQueryVariable('keywords');
}
if(isNotEmpty(getQueryVariable('campaign'))){
    sessionStorage.campaign = getQueryVariable('campaign');
}
if(isNotEmpty(getQueryVariable('tag'))){
    sessionStorage.tag = getQueryVariable('tag');
}
if(isNotEmpty(getQueryVariable('tag1'))){
    sessionStorage.tag1 = getQueryVariable('tag1');
}
if(isNotEmpty(getQueryVariable('tag2'))){
    sessionStorage.tag2 = getQueryVariable('tag2');
}
if(isNotEmpty(getQueryVariable('tag3'))){
    sessionStorage.tag3 = getQueryVariable('tag3');
}
if(isNotEmpty(getQueryVariable('language'))){
    sessionStorage.language = getQueryVariable('language');
}


// 判断浏览器函数
if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    console.log("window.location.href = '/mobile'",location.pathname)
    if(location.pathname.indexOf('/mobile') === -1){
        sessionStorage.daihref = '/mobile'+location.pathname;
    }else{
        sessionStorage.daihref = location.pathname;
    }
    window.location.href = '/mobile'; // 移动端
}





if(isNotEmpty(getQueryVariable('systemlang'))){
    store.commit('NOW_LANG', getQueryVariable('systemlang'));
}

const app = createApp(App)


app.use(VueViewer)
app.use(component);
app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(i18n)
app.mount('#app')
app.provide('emitt', emitt);
