<template>
  <Header v-if='!$route.meta.headHideFlag && locale != "cn"'  :headFlag='$route.meta.transparent'></Header>
  <HeaderCN v-if='!$route.meta.headHideFlag && locale == "cn"'  :headFlag='$route.meta.transparent'></HeaderCN>
  <el-config-provider :locale="elementLocale">
  <router-view></router-view>
  </el-config-provider>
  <Footer v-if='!$route.meta.footHideFlag && locale != "cn"'></Footer>
  <FooterCN v-if='!$route.meta.footHideFlag && locale == "cn"'></FooterCN>
</template>

<script>

import { useStore } from 'vuex'
import {
 ref, reactive,computed,onMounted,inject
} from 'vue';
import Header from 'components/pc/Header.vue'
import HeaderCN from 'components/pc/HeaderCN.vue'
import Footer from 'components/pc/Footer.vue'
import FooterCN from 'components/pc/FooterCN.vue'
import { useI18n } from "vue-i18n";
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
export default {
   name: 'App',
   components:{
        Header,
        Footer,
        HeaderCN,
        FooterCN
    },
  setup(){
    const store = useStore();
    const upColor = ref('red');
    const { locale } = useI18n()
    const lang = computed({ get: () => store.getters.getNowTheme.lang });
      
    console.log("store.getters",store.getters.getNowTheme.lang)


    window.addEventListener('beforeunload', () => { // 刷新保存store里面的state
      localStorage.setItem('ehammer.state', JSON.stringify(store.state));
    });
    store.dispatch('getWsUrl');
    store.dispatch('getWalletBank');
    console.log(store.state.home.activeSymbolItem.contractId,"store.state.home.activeSymbolItem ")
    if(!store.state.home.activeSymbolItem.contractId){
      console.log(store.state.home.activeSymbolItem.contractId,"activeSymbolItem 2")
      store.dispatch('getDefaultSymbol');
    }

     const onChange = (e,f) => {
          store.commit('NOW_UP_COLOR', e.target.value);
          store.commit('NOW_THEME_COLOR', 'white');
          if (e.target.value === 'red') {
              document.body.classList.remove('green-up');
              document.body.classList.remove('blue-up');
              document.body.classList.add('red-up');
          } else if (e.target.value === 'green') {
              document.body.classList.remove('red-up');
              document.body.classList.remove('blue-up');
              document.body.classList.add('green-up');
          } else if (e.target.value === 'blue') {
              document.body.classList.remove('green-up');
              document.body.classList.remove('red-up');
              document.body.classList.add('blue-up');
          }
          if(!f){
              location.reload();
          }
      };
    
      onMounted(() => {
          upColor.value = store.state.home.contentColor;
          onChange({ target: { value: upColor.value } },true);
      });

      const elementLocale = computed({ get: () => store.getters.getNowTheme.lang == 'cn'?zhCn:'' });



      return {
        locale,
        elementLocale: elementLocale,

      }
  }
};


</script>

<style>
#app {
  font-family: PingFangSC-Regular, PingFang SC,poppins, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background: #EDEEF1;
  min-width: 910px;/*no*/
}
</style>
