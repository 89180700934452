<template>
    <div class="SafetyVerification">
        <el-dialog
            :title="title"
            v-model="SafetyVerificationFlag"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
        >
            <p class="warn">{{warn}}</p>
            <PhoneInputBox 
                :blurFunc="phoneBlur" 
                :value="phoneData.phone" 
                :code="phoneData.mobileAreaCode"
            />
            <CodeInputBox 
                :blurFunc="codeBlur" 
                :mobileAreaCode="phoneData.mobileAreaCode" 
                :phone="phoneData.phone" 
                :value="phoneData.code" 
                :smsType='phoneData.smsType'
                :placeholder="$t('signUp.text15')" 
            />
            <template #footer>
                  <span class="dialog-footer">
                  <el-button @click="SafetyVerificationFlag = false">{{$t('trade.text72')}}</el-button>
                  <el-button type="primary" :loading='loading' :disabled="!submitFlag" @click="Confirm"
                      >{{$t('trade.text73')}}</el-button>
                  </span>
              </template>
        </el-dialog>
    </div>
</template>
<script>
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import {
  ref,
  reactive,
  computed,
  inject,
  onBeforeUnmount
} from 'vue';
import { useStore } from 'vuex';

export default {
    name:'SafetyVerification',
    setup(props,ctx){
        const SafetyVerificationFlag = ref(false);
        const loading = ref(false);
        const emitt = inject('emitt');
        const store = useStore();
        const title = ref('');
        const warn = ref('');
        const userInfo = computed({
            get: () => {
                return store.state.user.userInfo;
            },
        });
        /**
         * 打开弹框-开始
         */ 
            emitt.$sub('toggleSafetyVerification', (val) => {
                console.log("val",val)
                phoneData.phone = userInfo.value.phone;
                phoneData.mobileAreaCode = userInfo.value.mobileAreaCode;
                SafetyVerificationFlag.value = val[0].flag;
                if(val[0].title){
                    title.value = val[0].title;
                }
                if(val[0].warn){
                    warn.value = val[0].warn
                }
            });
            onBeforeUnmount(() => {
                emitt.$unsub("toggleSafetyVerification");
            });
        /**
         * 打开弹框-开始
         */ 

        /**
         * 电话验证参数-开始
         */ 
            const phoneData = reactive({
                phone:"",//手机号
                code:"",//验证码
                smsType:'30',//验证类型
                mobileAreaCode:'',//国际区号
            })
         /**
         * 电话验证参数-结束
         */

         /**
         * 验证按钮提交条件-开始
         */
            const submitFlag = computed(() => {
                return  phoneData.phone != "" && 
                        phoneData.code != "" 
            })
        /**
         * 验证按钮提交条件-结束
         */

        /**
         * 组件赋值操作-开始
         */
            function codeBlur(val) {
                phoneData.code = val;
            }
            function phoneBlur(val) {
                phoneData.phone = val;
            }
        /**
         * 组件赋值操作-结束
         */

        /**
         * 提交-开始
         */
            function Confirm(){
                if (!submitFlag.value) {
                  return;
                }
                const params = {
                    code:phoneData.code
                }
                ctx.emit('walletRquestChain',phoneData.code);
            }
        /**
         * 提交-结束
         */
        return {
            SafetyVerificationFlag,
            phoneBlur,
            codeBlur,
            phoneData,
            submitFlag,
            loading,
            Confirm,
            title,
            warn
        }
    }
}
</script>
<style lang="less" scoped>
.SafetyVerification{
    .warn{
        color: @red;
        margin-bottom: 20px;
    }
}
</style>
