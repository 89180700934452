<template>
    <div class="AccountAssets">
         <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane :label="$t('AccountAssets.text1')" name="live">
                <AssetsLiveAccount />
            </el-tab-pane>
            <el-tab-pane label="" name="follow">
                Stay tuned
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
 ref
} from 'vue';
import AssetsLiveAccount from './components/AssetsLiveAccount'
export default {
    name:"AccountAssets",
    components:{
        AssetsLiveAccount
    },
    setup(){
        const activeName = ref('live');
        
        return {
            activeName
        }
    }
}
</script>
<style lang="less" scoped>
.AccountAssets{

}
</style>
<style lang="less">
.AccountAssets{
    .el-tabs__nav{
        margin-left: 0;
    }
    .el-tabs__item{
        margin-right:0;
        margin-left:240px;
    }
}
</style>
