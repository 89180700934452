<template>
  <div class="email-container public-tab-container">
    <div class="email-input input-row">
      <div class="value">
        <input type="text" v-model="emailData.emailVal" :placeholder="$t('signUp.text26')">
      </div>
    </div>
    <div class="pwd-input input-row">
      <div class="value">
        <input :type="pwdData.isPasswordType?'text':'password'" :placeholder="$t('signUp.text27')" v-model="pwdData.pwdVal">
      </div>
      <span class="password-icon">
        <img :src="pwdData.isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
        : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
        @click="pwdData.isPasswordType = !pwdData.isPasswordType;"
        class="pw-icon"
        >
      </span>
    </div>
    <div :class="pwdData.pwdVal != '' && emailData.emailVal != ''?'':'btn-disabled'" class="login-btn" @click="logins">
      {{$t('signUp.text22')}}
    </div>
    <div class="btn-text-con">
      <button  @click="jumpToForgetPwd" class="forget-pwd public-text-btn btn-text-left">{{$t('signUp.text24')}}</button>
      <button  @click="jumpToForgetPwd" class="forget-pwd public-text-btn btn-text-right">{{$t('signUp.text25')}}</button>
    </div>
  </div>
</template>
<script>
import {
 ref, reactive, inject, computed,
} from 'vue';
import apiService from '@/api/request';;
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";

export default {
  name: 'EmailLogin',
  components: {
  },
  setup(props, context) {
    console.log(props, context);
    const { t } = useI18n()
    const emitt = inject('emitt');
    const store = useStore();
    const router = useRouter();
    const ostatus = computed(() => store.getters.userStatus.ostatus || 0);

    // 邮箱登录
    const emailData = reactive({
      isEmailActive: false, // 是否激活
      emailVal: '', // 输入框的值
      isEmailErr: false, // 验证码的问题
      errText: '',
    });
    function emailFocus(activeSta) {
      emailData.isEmailActive = activeSta;
      emailData.isEmailErr = false;
    }
    function emailBlur(val) {
      emailData.emailVal = val;
    }

    // 密码登录
    const pwdData = reactive({
      isPwdActive: false,
      isPasswordType: false,
      isPwdErr: false,
      pwdVal: '',
      errText: '',
    });
    function pwdFocus(activeSta) {
      pwdData.isPwdActive = activeSta;
      pwdData.isPwdErr = false;
    }
    function pwdBlur(val) {
      pwdData.pwdVal = val;
    }

    // 点击了忘记密码，跳转到忘记密码界面，通知login组件切换
    const jumpToForgetPwd = () => {
      console.log('jumpToForgetPwd');
      const jumpParams = {
        status: false,
        type: 'email',
      };
      emitt.$pub('switchForget', jumpParams);
    };

    const isPassword = ref(false);

    function checkEmailPatern(value) {
      const cpVal = value.replace(/\s+/g, '');
      const myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,9}$/;
      return myreg.test(cpVal);
    }

    // 邮箱登录
    let loginNow = false;
    // 账号密码登录
    const logins = async () => {
      if(loginNow){
        return
      }
      if (!emailData.emailVal) {
        return;
      }
      // 登录的时候，验证表单是否都通过
      const isPass = checkEmailPatern(emailData.emailVal);
      if (!isPass) {
         ElMessage({
          message: t('signUp.text43'),
          type: 'error',
        })
        return;
      }

      if (!pwdData.pwdVal) {
        return;
      }
      const params = {
        account: window.btoa(emailData.emailVal),
        passWord: pwdData.pwdVal,
        userAgent: window.navigator.userAgent,
      };
      loginNow = true;
      const res = await apiService.Login(params);
      if (res.status === 1) {
         ElMessage({
          message: t('trade.text86'),
          type: 'success',
        })
        store.commit('USERTOKEN', res.data.userToken);
        await store.dispatch('getUserInfo');
        await store.dispatch('getUserStatus');
        if (ostatus.value !== 3) {
          router.push({
              name:'myAccount'
            });
        } else {
          router.push('transaction');

        }
      } else { // 密码错误
        ElMessage({
          message: res.message,
          type: 'error',
        })
        pwdData.isPwdErr = true;
      }
        loginNow = false;
    };

    // 暴露给模板
    return {
      jumpToForgetPwd,
      isPassword,
      emailData,
      emailFocus,
      emailBlur,
      pwdData,
      pwdFocus,
      pwdBlur,
      logins,
    };
  },
};
</script>
<style lang="less" scope>
.email-container {
  .input-row {
      margin: 0 auto;
      margin-top: 24px;/*no*/
      background: white;
      display: flex;
      justify-content: left;
      margin-bottom: 16px;/*no*/
      border-radius: 4px;/*no*/
      height: 48px;/*no*/
      position: relative;
      border: 1px solid @border-color-base;/*no*/
      width: 400px;/*no*/
      .value{
        width: 100%;
      }
      .password-icon{
        position: absolute;
        right: 16px;/*no*/
        top: 14px;/*no*/
        cursor: pointer;
      }
       .send{
         position: absolute;
         right: 10px;
         top: 8px;
        }
      .prefix{
        width: 85px;
        padding-left: 10px;
        border-right: 1px solid @border-color-base;/*no*/
        text-align: left;
        cursor: pointer;
        position: relative;
        line-height: 48px;
        .icon_search{
          position: relative;
          top: 8px;
        }
        .icon_choice{
          width: 8px;/*no*/
          position: absolute;
          right: 9px;
          top: 22px;
        }
      }
      input{
        height: 46px;/*no*/
        line-height: 46px;/*no*/
        border-radius: 4px;/*no*/
        outline: none;
        padding-left: 24px;/*no*/
        width: 100%;
        font-size: 14px;/*no*/
      }
      .value_code{
        padding-left: 10px;
      }
      .select_region{
        position: absolute;
        left: 0;
        padding:20px 0;
        background: white;
        width: 300px;
        height: 490px;
        z-index: 9;
        top: 58px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        display: none;
        h3{
          font-size: 16px;
          text-align: center;
          color: #111E36;
          position: relative;
          img{
            display: none;
          }
        }
        .seach{
          width: 284px;
          height: 28px;
          background: #F6F6F6;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          padding-left: 13px;
          margin-top: 19px;
          img{
            display: block;
            width: 12px;
            height: 12px;
            position: relative;
            top: 8px;
          }
          input{
            width: 243px;
            height: 28px;
            background: #F6F6F6;
            line-height: 28px;
          }
        }
        ul{
          width: 100%;
          height: 360px;
          margin-top: 12px;
          overflow-y: scroll;
          li{
            height: 45px;
            padding: 13px 16px;
            border-bottom: 1px solid #F8F9FB;
            cursor: pointer;
          }
          li:hover{
            background: #FEE3D1;
          }
        }
    }
  }
  .login-btn {
    width: 400px;/*no*/
    height: 48px;/*no*/
    margin: 0 auto;
    margin-top: 50px;/*no*/
    line-height: 48px;/*no*/
    background: #FB5B39;
    border-radius: 2px;/*no*/
    font-size: 14px;/*no*/
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    cursor: pointer;
  }
  .btn-text-con {
    width: 400px;/*no*/
    position: relative;
    height: 20px;/*no*/
    margin: 0 auto;
    margin-top: 16px;/*no*/
    .public-text-btn {
      position: absolute;
      font-size: 14px;/*no*/
      font-family: MicrosoftYaHei;
      color: @primary-color;
      background: transparent;
      cursor: pointer;
    }
    .btn-text-right {
      right: 0;
    }
    .btn-text-left {
      left: 0;
    }
  }
}
</style>
