<template>
    <div class="FundHistory">
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane :label="$t('fundHistory.text1')" name="deposit">
                <FundHistoryDeposit :itemFlowType="itemFlowType" :itemStatus="itemStatus" :startTime='startTime' :endTime='endTime'/>
            </el-tab-pane>
            <el-tab-pane :label="$t('fundHistory.text2')" name="withdrawal">
                <FundHistoryWithdrawal :itemFlowType="itemFlowType2" :itemStatus="itemStatus2" :startTime='startTime' :endTime='endTime'/>
            </el-tab-pane>
            <el-tab-pane :label="$t('fundHistory.text3')" name="bonus">
                <FundHistoryBonus :itemFlowType="itemFlowType3" :itemStatus="itemStatus3" :startTime='startTime' :endTime='endTime'/>
            </el-tab-pane>
            <el-tab-pane :label="$t('fundHistory.text4')" name="rebate">
                <FundHistoryRebate :itemFlowType="itemFlowType4" :startTime='startTime' :endTime='endTime'/>
            </el-tab-pane>
            <el-tab-pane :label="$t('fundHistory.text5')" name="transfer">
                <FundHistoryTransfer :itemFlowType="itemFlowType5" :startTime='startTime' :endTime='endTime'/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
 ref,reactive
} from 'vue';
import FundHistoryDeposit from './components/FundHistoryDeposit'
import FundHistoryWithdrawal from './components/FundHistoryWithdrawal'
import FundHistoryBonus from './components/FundHistoryBonus'
import FundHistoryRebate from './components/FundHistoryRebate'
import FundHistoryTransfer from './components/FundHistoryTransfer'
import apiService from '@/api/request';

export default {
    name:'FundHistory',
    components:{
        FundHistoryDeposit,
        FundHistoryWithdrawal,
        FundHistoryBonus,
        FundHistoryRebate,
        FundHistoryTransfer
    },
    setup(){
        const activeName = ref('deposit');
        const itemFlowType = ref([]);
        const itemStatus = ref([]);
        const itemFlowType2 = ref([]);
        const itemStatus2 = ref([]);
        const itemFlowType3 = ref([]);
        const itemStatus3 = ref([]);
        const itemFlowType4 = ref([]);
        const itemFlowType5 = ref([]);
        const itemStatus5 = ref([]);
        const endTime = ref('');
        const startTime = ref('');

         apiService.GetFlowFilteringRevision().then(res => {
            if(res.status == 1){
                console.log(res);
                itemFlowType.value = res.data.listType[0].itemFlowType;
                itemStatus.value = res.data.listType[0].itemStatus;
                itemFlowType2.value = res.data.listType[1].itemFlowType;
                itemStatus2.value = res.data.listType[1].itemStatus;
                itemFlowType3.value = res.data.listType[2].itemFlowType;
                itemStatus3.value = res.data.listType[2].itemStatus;
                itemFlowType4.value = res.data.listType[3].itemFlowType;
                itemFlowType5.value = res.data.listType[4].itemStatus;
                startTime.value = res.data.startTime;
                endTime.value = res.data.endTime;
            }
        });
        return {
            activeName,
            itemFlowType,
            itemFlowType2,
            itemFlowType3,
            itemFlowType4,
            itemFlowType5,
            itemStatus,
            itemStatus2,
            itemStatus3,
            endTime,
            startTime,
        }
    }
}
</script>
<style lang="less" scoped>
.FundHistory{

}
</style>
<style lang="less">
.FundHistory{
    .el-tabs__nav{
        margin-left: 0;
    }
    .el-tabs__item{
        margin-right:0;
        margin-left:93px;
    }
    thead{
        .el-table__header-wrapper,.el-table__cell{
            background:@bg-color !important;
        }
    }
}
</style>
