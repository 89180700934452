<template>
    <div class="Congratulation">
        <div class="banner">
            <h2>Congratulation</h2>
            <p>Thank you & welcome onboard!</p>
            <p>Please download Advantrade to enjoy an advanced trading journey and resources!</p>
            <router-link to="/download"><div class="btn">Click here to download app</div></router-link>
        </div>
         <div class="banner2">
             <div class="content_box">
                 <h2>In fact…</h2>
                    <ul>
                        <li>
                            <div class="percen">27%</div>
                            <p>of our users prefer our promotions to competitors</p>
                            <div class="learn_more"><router-link to="/promotion"> Learn more ></router-link></div>
                        </li>
                        <li>
                            <div class="percen">90%</div>
                            <p>of our users find our resources helpful for making trading decsisions</p>
                            <div class="learn_more"><router-link to="/promotion"> Get Our Resources ></router-link></div>
                        </li>
                        <li>
                            <div class="percen">86%</div>
                            <p>users remain with us after trying our services</p>
                            <div class="learn_more"><router-link to="/download"> Download  Advantrade ></router-link></div>
                        </li>
                    </ul>
             </div>
        </div>
        <div class="latest">
            <h2>Many promotions await you</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_003%402x.png" alt="">
                    <div class="name">Lucky spin</div>
                    <p>Win Bitcoin, Ethereum and other cryptocurrencies</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_002%402x.png" alt="">
                    <div class="name">Deposit bonus</div>
                    <p>Get upto $5588 Bonus to maximize your trading potential</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_001%402x.png" alt="">
                    <div class="name">Referral program</div>
                    <p>Refer your friends and start your journey together</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_005%402x.png" alt="">
                    <div class="name">Switching bonus</div>
                    <p>Enjoy even more rewards when you switch from others brokers</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_004%402x.png" alt="">
                    <div class="name">Exclusive account rankings</div>
                    <p>Become our VIP to enjoy unlimited trading discounts</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_pic_giftbox%402x.png" alt="">
                    <div class="name">coming soon!</div>
                    <p><router-link to="/promotion">Learn more ></router-link></p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>


export default {
name:'Congratulation',
    components:{
    },
     setup(){

     }
}
</script>
<style lang="less" scoped>
.Congratulation{
    .banner{
        height: 650px;
        color: white;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_pic1%402x.png) no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 153px;
        h2{
            font-size: 46px;
            margin-bottom: 24px;
        }
        p{
            font-size: 24px;
        }
        .btn{
            width: 400px;
            height: 52px;
            line-height: 52px;
            background: white;
            color: #FB741B;
            margin: 0 auto;
            margin-top: 48px;
        }
    }
    .banner2{
        height: 660px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_pic2%402x.png) no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 100px;
        .content_box{
            width: 1100px;
            margin: 0 auto;
            text-align: left;
        }
        h2{
            color: white;
        }
        ul{
            text-align: left;
            margin-top: 114px;
            display: flex;
            justify-content: space-between;
            li{
                width: 300px;
            }
            .percen{
                font-size: 60px;
                color: #FF8E43;
                font-weight: 900;
            }
            p{
                color: white;
                margin-top: 18px;
            }
            .learn_more{
                margin-top: 20px;
                color: #FF8E43;
            }
        }
        a{
            color: #FB741B;
        }
    }
    .latest{
        h2{
            font-size: 34px;
            margin-top: 100px;
        }
        ul{
            width: 1100px;
            margin: 0 auto;
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            li{
                width: 350px;
                margin-bottom: 60px;
                img{
                    width: 100%;
                }
                .name{
                    font-size: 20px;
                    margin-top: 16px;
                    font-weight: 600;
                }
                p{
                    margin-top: 12px;
                }
                a{
                    color: #FB741B;
                }
            }
        }
    }
}

</style>
