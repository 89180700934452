<template>
    <div class="LuckSpin">
        <div class="banner">
            
        </div>
        <div class="wealth">
            <div class="text">
                <h4>Max $40000 prizes await you</h4>
                <p>
                    New successfully registered users can now join our lucky spin and win the hottest cryptocurrency on the market as Bitcoin, Ethereum and more！
                </p>
                <div class="btn cursor" @click="routerTo('login')">
                    start lucky spin now
                </div>
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_pic_005%402x.png" alt="">
            </div>
        </div>
        <div class="membership">
            <h2> How to get your lucky prizes?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_001%402x.png" alt="">
                    <p>Sign up</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_005%402x.png" alt="">
                    <p>Deposit in app</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_009%402x.png" alt="">
                    <p>Contact us</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_008%402x.png" alt="">
                    <p>Get prizes</p>
                </li>
            </ul>
            <div class="btn join cursor" @click="routerTo('login')">JOIN NOW!</div>
        </div>
        <div class="referral_detail">
            <div class="content">
                <div class="title">
                    <div class="hr"></div>Campaign rules
                </div>
                <p>1.New successfully registered users can now join our lucky spin and win some of the hottest cryptocurrencies on the market.</p>
                <div class="content_tr text_color">
                    <div class="item">Cryptocurrencies(USD)</div>
                    <div class="item">Reward Amount</div>
                </div>
                <div class="content_tr">
                    <div class="item">Bitcoin</div>
                    <div class="item">0.01 lot (equal to 0.1 unit)</div>
                </div>
                <div class="content_tr">
                    <div class="item">Ethereum</div>
                    <div class="item">0.01 lot (equal to 0.1 unit)</div>
                </div>
                <div class="content_tr">
                    <div class="item">Litecoin </div>
                    <div class="item">0.1 lot  (equal to 1 unit)</div>
                </div>
                <div class="content_tr">
                    <div class="item">Bitcoin Cash </div>
                    <div class="item">0.1 lot (equal to 1 unit)</div>
                </div>
                <div class="content_tr">
                    <div class="item">Ripple</div>
                    <div class="item">1 lot (equal to 10 unit)</div>
                </div>
                <p>2.Successfully registered accounts must be verified; cryptocurrency rewards will be voided if accounts fail to complete verification within 7 days after reward redemptions.</p>
                <p>3.Stop loss and take profit are preset for rewarded cryptocurrencies. Stop loss price = opening price* 80%; take profit price = opening price*120%.</p>
                <p>4.The margin value of rewarded cryptocurrencies would be added to balance after the successful registration.</p>
                <ul>
                    <li>If no deposit is made, users are able to withdraw a maximum upto $158USD, once completing 5 trading lots (Exclude US & HK CFD) within 30 days after receiving the reward, any additional balance shall be converted into credit bonus. If the users not able to complete 5 trading lots within 30 days, all the bonuses will be removed from the account.</li>
                    <li>If deposit is made, bonuses will no longer have an expiry period and users will be eligible to withdraw $100USD per trading lot completed.</li>
                </ul>
                <p>5.Eligibility: New AdvanTrade registered users who complete account verifications.</p>
                <p>6.AdvanTrade reserves the rights to make changes to our Privacy Policy at any time, without notice, including, but not limited to, where such changes are required by applicable legislation.</p>
            </div>
        </div>
        <div style="background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);"> 
            <Advertise></Advertise>
        </div>
    </div>
</template>
<script>
import {
} from 'vue';
import Advertise from '../components/Advertise.vue'
import { useRouter } from "vue-router"

export default {
    name:"LuckSpin",
    props: {
    
    },
    components:{
        Advertise
    },
    setup() {
        const router = useRouter(); 
       function routerTo(e){
            router.push(e)
       }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.LuckSpin{
    h2{
        color: #FB741B;
        font-size: 30px;/*no*/
    
    }
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
    }
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_top_banner_003%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        height: 400px;/*no*/
        padding-top: 85px;/*no*/
        padding-left: 360px;
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 340px;
            img{
                width: 100%;
            }
        }
    }
    .membership{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        padding-top: 40px;
        padding-bottom: 50px;
        ul{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 300px;
                img{
                    width: 88px;
                    height: 88px;
                }
                p{
                    color: #4E4E4E;
                    margin-top: 16px;/*no*/
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
    .referral_detail{
        padding-top: 90px;
        padding-bottom:77px; 
        .content{
            width: 1200px;
            margin: 0 auto;
             ul{
                 padding-left: 20px;/*no*/
                li{
                    list-style-type: disc;
                    text-align: left;
                    color: #858585;
                    margin-top: 24px;/*no*/

                }
            }
        }
        .title{
            display: flex;
            justify-content: left;
            color: #FB741B;
            font-size: 30px;
            .hr{
                height: 42px;/*no*/
                width: 8px;/*no*/
                background: #FB741B;
                border: none;
                margin-right:37px; 
            }
        }
        p{
            text-align: left;
            margin-top: 24px;/*no*/
            color: #858585;
        }
        .content_tr{
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;
            margin-top: 24px;
           
            .item{
                width: 600px;
                border-bottom: 1px solid #F2F2F2;/*no*/
                height: 44px;/*no*/
                line-height: 44px;/*no*/
                color: #858585;
            }
        }
        .text_color{
            .item{
                color: #FB741B;
                font-weight: 500;
            }
        }
        .mar{
               margin-top: 48px;/*no*/
           }
    }
}
</style>
