<template>
    <div class="InformationRight">
        <div class="nav">
            <div class="title">CATEGORIES</div>
            <ul>
                <li><router-link to="/recommendations">Recommendations</router-link></li>
                <li><router-link to="/analysis">Analysis</router-link></li>
                <li><router-link to="/fastNews">7×24 News</router-link></li>
                <li><router-link to="/calender">Calender</router-link></li>
            </ul>
            <div class="title latest">LATEST POSTS</div>
            <div class="latest_posts" @click="gotoLatest" v-for="item in latestArr" :key="item.id">
                {{item.title}}
            </div>
        </div>
    </div>
</template>
<script>
import { assignParams } from '@/until/common';
import apiService from '@/api/request';
import { reactive } from "vue";
import { useRouter } from "vue-router"

export default {
    name:'InformationRight',
    setup(){
        const router = useRouter(); // 第一步

        const latestArr = reactive([]);//资讯列表
        ReadExternNewsList();

         //获取资讯列表
         function ReadExternNewsList(){
            let param = {
                pageSize:5,
                pageIndex:1
            }
            let params = assignParams(param);
            apiService.ReadExternNewsList(params).then((res) => {
                console.log("ReadExternNewsList",res);
                let list = res.data.list
                for(let item of list){
                    latestArr.push(item);
                }
            })
         }

        //跳转 最新资讯详情
        function gotoLatest(){
            router.push("recommendations")
        }
         return {
             ReadExternNewsList,
             latestArr,
             gotoLatest
         }
    }
}
</script>
<style lang="less">
.InformationRight{
    width: 300px;
    padding-left: 40px; 
    text-align: left;
    .nav{
        .title{
            font-weight: 600;
            font-size: 18px;
        }
        ul{
            text-align: left;
            margin-top: 8px;
            li{
                height: 54px;
                line-height: 54px;
                border-bottom: 1px solid #EBEBEB;
                font-size: 16px;
                a{
                    color: #954A19;
                }
            }
        }
        .latest{
            margin-top: 48px;
        }
        .latest_posts{
            font-size: 16px;
            color: #954A19;
            margin-top: 24px;
            cursor: pointer;
        }
    }
}
</style>


