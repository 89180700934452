<template>
    <div class="whyChooseUs" id="agentTitle">
        <div class="whyChooseUs_banner">
            <h2>Why choose Advantrade as your only trading app?</h2>
        </div>
        <div class="powerful_trading">
        <h4>Powerful trading functions</h4>
        <div class="powerful_trading_functions">
            <div class="functions_left">
                <div class="trading_functions trading_functions_1" >
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_real%402x.png" alt="">
                    <h5>Real-Time Quotation, Starting From 0 Spread</h5>
                    <p>Advantrade uses RTQs, an essential feature in modern trading apps, so traders can get real-time information and avoid missing out on the best opportunities.</p>
                </div>
                  <div class="trading_functions trading_functions_2">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_instant%402x.png" alt="">
                    <h5>50ms Fast Transaction</h5>
                    <p>Some trading apps take 2-3 days to complete the transfer of funds. Advantrade promises that all funds will be credited and withdrawn instantly, and the transaction will be completed within 50ms.</p>
                </div>
                  <div class="trading_functions">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_market%402x.png" alt="">
                    <h5>Market Volatility and Risk Alerts</h5>
                    <p>We understand the market and your needs, Advantrade's unique alerting system can help you minimizing your risk. You can trade with us with confidence!</p>
                </div>
            </div>
            <img class="functions_center" src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_powerful_phone.png" alt="">
            <div class="functions_right">
                  <div class="trading_functions">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_latest%402x.png" alt="">
                    <h5>Support Subscription, Copy Transactions, and Share Profits with Top Traders</h5>
                    <p>Advantrade provides independent trading and copy trading functions. By subscribing and following transactions, beginner can cross the threshold of investment knowledge and experience, and make profits with the selected traders.</p>
                </div>
                  <div class="trading_functions">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_expertise%402x.png" alt="">
                    <h5>First-Hand News, Professional Icons, Technical Indicators Analysis</h5>
                    <p>Advantrade always provides the latest market news, and uses AI to generate visual charts and indicators to assist traders in making their decision, ensuring that traders perform well in the fierce market competition.</p>

                </div>
                  <div class="trading_functions">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_able%402x.png" alt="">
                    <h5>10~400 Leverage</h5>
                    <p>Advantrade provides traders with leverage of 10 to 400. Users can adjust the leverage before creating the positions to maximize the profit. It is more flexible and convenient.</p>
                </div>
            </div>
        </div>
      </div>
         <div class="why_choose">
          <h4>Reliable trading journey</h4>
            <div class="why_choose_content">
            <div class="why_choose_line">
                <div class="why_choose_line_text">
                    <div class="title">Extraordinary trading experience</div>
                    <p>Advantrade provides proprietary trading and copy trade features, fulfilling the needs of the different investors, especially the investors who just started their investment journey. By subscribing to our featured copy traders, users can gain profit from the trader even if they lack knowledge and experience in investment.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_experience%402x_67.png" alt="">
            </div>
            <div class="why_choose_line">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_safe%402x_67.png" alt="">
                <div class="why_choose_line_text">
                    <div class="title">Strict safety protection for you</div>
                    <p>As a responsible operator, Advantrade systems use 128-bit SSL encryption for transmission and MD5 encryption for management to ensure the secure storage of users' personal privacy, transaction history and sensitive data.Only the owner can access and monitor all activities related to the account to prevent data leakage.</p>
                </div>
            </div>
            <div class="why_choose_line">
                <div class="why_choose_line_text">
                    <div class="title">Experienced financial professionals and tier-one technical teams</div>
                    <p>Advantrade is confident to offer all users a secured, stable, and responsive trading environment. In the future, our elite teams will keep reaching the new advances, optimizing trading system technology, and aiming to be the global pioneer of the innovating Fin-Tech industry.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_team%402x_67.png" alt="">
            </div>
          </div>
      </div>
      <div class="trading_journey">
          <h2>6 Cores Feature to enhance your trading journey</h2>
          <div class="trading_journey_list">
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_wallet%402x.png" alt="">
                  <p>User friendly interface</p>
                  <div class="learn_more" @click="scrollTop(1)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_stock-1%402x.png" alt="">
                  <p>Diversified investment portfolio</p>
                  <div class="learn_more" @click="scrollTop(2)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_cs%402x.png" alt="">
                  <p>Elite research and technical specialist</p>
                  <div class="learn_more" @click="scrollTop(3)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_market%402x.png" alt="">
                  <p>0 platform fee</p>
                  <div class="learn_more" @click="scrollTop(4)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_good%402x.png" alt="">
                  <p>Used by global brokers globally</p>
                  <div class="learn_more" @click="scrollTop(5)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
              <div class="list_item">
                  <img class="icon_wallet" src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_icon_clock%402x.png" alt="">
                  <p>Round the clock 24/5</p>
                  <div class="learn_more" @click="scrollTop(6)">LEARN MORE <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/icon_jump_yellow%402x.png" alt=""></div>
              </div> 
          </div>
      </div>
      <div class="why_choose_list">
          <div class="why_choose_list_content">
            <div class="why_choose_item">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_1%402x.png" alt="">
                <div class="why_choose_text">
                    <div class="title">User friendly interface</div>
                    <p>A modern, yet simple and easy-to-use app interface, complete with interactive user guides has been developed for users of all levels, even for beginners with minimal technical and trading knowledge can now be comfortable trading on the go. Providing a flawless user experience and ultimate convenience in the palm of your hands, all delivered through an all-in-one trading application, giving you real-time quotations, high-speed instant fund transfers, one-click trading, the latest market news updates and much more.</p>
                </div>
            </div>
            <div class="why_choose_item">
                <div class="why_choose_text">
                    <div class="title">Diversified investment portfolio</div>
                    <p>AdvanTrade covers more than 90% of most traded mainstream financial instruments, over 100 types of products, which empowers traders to customise and develop a diversified investment portfolio, optimised to their needs, decentralising risk and maximizing opportunities.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_2%402x.png" alt="">
            </div>
            <div class="why_choose_item">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_3%402x.png" alt="">
                <div class="why_choose_text">
                    <div class="title">Elite research and technical specialist</div>
                    <p>Over 300 elite members of ET FinTech, bringing experience from top technology companies and some of the best known digital marketing agencies. By bringing together world class programmers, research teams and industry leading financial professionals, AdvanTrade has been able to develop an innovative application, offering users cutting edge technology, which is able to keep up with the ever growing, fast paced financial markets</p>
                </div>
            </div>
            <div class="why_choose_item">
                <div class="why_choose_text">
                    <div class="title">Zero platform fees & no commission</div>
                    <p>At AdvanTrade we understand our users’ concerns on trading costs and platform fees, therefore we offer the lowest rates, yet high levels of support to our users ensuring cost savings are passed onto end users, meaning when users trade they can enjoy $0 commission and no platform fees.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_4%402x.png" alt="">
            </div>
            <div class="why_choose_item">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_5%402x.png" alt="">
                <div class="why_choose_text">
                    <div class="title">Approved by brokers globally</div>
                    <p>AdvanTrade has been adopted by different brokers globally, having more than 500,000+ users worldwide. Its operations reach across 4 continents, covering from South Asia, South America, Europe to Africa, including countries such as Singapore, Malaysia, Thailand, Argentina, Ecuador, Spain, Kenya and many more. Our vision is to keep advancing our fintech developments and optimizing trading system technology, providing a secure, stable and an efficient trading experience for all traders.</p>
                </div>
            </div>
            <div class="why_choose_item">
                <div class="why_choose_text">
                    <div class="title">Round the clock 24/5 support</div>
                    <p>With AdvanTrade, be empowered to choose from a variety of the most traded financial instruments, including forex, commodities, energies, indices, precious metals, and cryptocurrencies, with round-the-clock support wherever you are.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/why_choose_us/why_pic_features_6%402x.png" alt="">
            </div>
          </div>
      </div>
      <div class="good_news_for_you">
          <div class="good_news_for_you_text">
              <h4>Good news for you</h4>
              <p>In order to celebrate advantrade have reached 100000+ traders, we are going to push an incredible promotion to reward our traders. If you trade in advantrades, you can get a free chance to win bitcoin, ethereum and more!</p>
              <div class="btn_join_now cursor" @click="routerTo('login')">
                  Join Now
              </div>
          </div>
          <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_news%402x.png" alt="">
      </div>
    </div>
</template>
<script>

import { useRouter } from "vue-router"
export default {
    name:'whyChooseUs',
    setup(){
        const router = useRouter();  

        function scrollTop(e){
            let top = 0;
            let element = document.getElementsByClassName("why_choose_item")[e-1];
            top = element.offsetTop
            console.log(top,e)
            document.documentElement.scrollTop = top-100
        }
        function routerTo(e){
            router.push(e)
       }
        return {
            scrollTop,
            routerTo
        }
    }
}
</script>
<style lang="less" scoped>
.whyChooseUs{
    background: white;
    .whyChooseUs_banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_banner%402x.png) no-repeat;
        height: 340px;
        background-size: 100% 100%;
        font-size: 40px;
        color: white;
        font-weight: 600;
        padding: 146px 0 138px 0;
    }
    .powerful_trading{
        padding: 60px 0;
        background-size: cover;
        background-position: center;
        .powerful_trading_functions{
            display: flex;
            justify-content: center;
            margin-top: 80px;
        }
        h4{
            font-size: 36px;
            font-weight: 900;
        }
        .trading_functions{
            margin-bottom: 40px;
        }
        .trading_functions_1{
            margin-bottom: 61px;
        }
        .trading_functions_2{
            margin-bottom: 69px;
        }
        .functions_left,.functions_right{
            width:400px; 
            img{
                width: 80px;
            }
            h5{
                font-size: 20px;
                margin-top: 16px;
            }
            p{
                font-size: 14px;
                color: #666666;
                margin-top: 8px;
            }
        }
        .functions_left{
            text-align: right;
        }
        .functions_right{
            text-align: left;
        }
        .functions_center{
            width: 370px;
            margin: 0 50px;
            height: 735px;
        }
    }
   
    .why_choose{
        padding-top: 61px;
        padding-bottom: 64px;
        .why_choose_content{
            width: 1100px;
            margin: 0 auto;
            margin-top: 41px;
        }
        h4{
            font-size: 34px;
            font-weight: 900;
        }
        .why_choose_line{
            width: 1100px;
           display: flex;
           justify-content: space-between;
           margin-top: 40px;
           align-items: center;
            .title{
                font-size: 30px;
                color: #FB741B;
            }
            img{
                display: block;
                width: 400px;
                height: 400px;
            }
            p{
                font-size: 16px;
                margin-top: 24px;
                color: #666666;
            }
        }
        .why_choose_line_text{
            width: 500px;
            text-align: left;
        }
        .why_choose_right{
            margin-left: 100px;
        }
         .learn_more{
            width: 150px;
            height: 40px;
            line-height: 40px;
            background: #FB741B;
            color: white;
            text-align: center;
            font-size: 16px;
            margin-top: 24px;
        }
    }
    .trading_journey{
        h2{
            font-size: 34px;
            font-weight: 900;
        }
        .trading_journey_list{
            width: 1100px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
            .list_item{
                width: 300px;
                margin-top: 80px;
                .icon_wallet{
                    display: block;
                    width: 80px;
                    height: 80px;
                    margin: 0 auto;
                }
                p{
                    font-size: 16px;
                    margin-top: 30px;
                }
                .learn_more{
                    color: #FB741B;
                    font-size: 16px;
                    margin-top: 10px;
                    cursor: pointer;
                    img{
                        width: 8px;
                        height: 12px;
                    }
                }
            }
        }
    }
    .why_choose_list{
        width: 1100px;
        margin: 0 auto;
        margin-top: 100px;
        .why_choose_list_content{
            .why_choose_item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 90px;
                img{
                    display: block;
                    width: 490px;
                    height: 360px;
                }
                 .title{
                    font-size: 30px;
                    color: #FB741B;
                }
                p{
                    font-size: 16px;
                    margin-top: 24px;
                    color: #666666;
                }
                .why_choose_text{
                    width: 500px;
                    text-align: left;
                }
            }
        }
    }
    .good_news_for_you{
        height: 320px;
        background: #FB741B;
        color: white;
        padding-top: 9px;
        .good_news_for_you_text{
            width: 500px;
            text-align: left;
            float: left;
            margin-left: 410px;
            margin-top: 53px;
            h4{
                font-size: 30px;
            }
            p{
                margin-top: 24px;
            }
            .btn_join_now{
                margin-top: 24px;
                width: 150px;
                height: 40px;
                background: white;
                border-radius: 4px;
                color: #FB741B;
                line-height: 40px;
                text-align: center;
            }
        }
        img{
            display: block;
            height: 273px;
            width: 327px;
            float: left;
            margin-left: 237px;
        }
    }
}
</style>
