<template>
    <div class="CreditCard">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('deposit.text38')}}</div>
                <div class="value">
                    <el-select v-model="payId" class="m-2" size="large" @change="payIdChange">
                        <el-option
                        v-for="item in channelArr"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('deposit.text39')}}(USD)</div>
                <div class="value">
                    <el-input class="amount_input" size="large" v-model="amount" :placeholder="placeholder" @input="buyNumberAmountValue">
                    </el-input>
                    <div class="fast">
                        <div class="fast_choose" :class="amount == 1000?'active_choose':''" @click="quickSelect(1000)">1000</div>
                        <div class="fast_choose" :class="amount == 5000?'active_choose':''" @click="quickSelect(5000)">5000</div>
                        <div class="fast_choose" :class="amount == 7000?'active_choose':''" @click="quickSelect(7000)">7000</div>
                        <div class="fast_choose" :class="amount != 1000 && amount != 5000 && amount != 7000?'active_choose':''" @click="quickSelect('Other')">{{$t('deposit.text40')}}</div>
                    </div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text41')}} ({{feerate * 100}}%)</div>
                <div class="value">
                    <div class="value_text">{{fee}} {{payCurrency}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text42')}}</div>
                <div class="value">
                    <div class="value_text">{{paymentAmount}} {{payCurrency}}</div>
                </div>
            </div>
            <el-button :disabled="amount == '' && paymentAmount == ''" :loading="btnLoading" class="confirm" type="primary" size="large" @click="Confirm">{{$t('deposit.text46')}}</el-button>
            <p class="align-l">{{$t('deposit.text47')}}</p>
            <p class="arrayTips" v-html="arrayTips"></p>
        </div>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import { assignParams, urlEncode,xzLocal} from '@/until/common';
import axios from 'axios';
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name:'CreditCard',
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props){
        const { t } = useI18n()

        const store = useStore();
        const route = useRoute(); 

        const payId = ref('');//支付id
        const minDepositLimit = ref('');//最小入金
        const maxDepositLimit = ref('');//最大入金
        const channelArr = ref([]);
        const payCurrency = ref('');//单位
        const placeholder = ref('');
        const feerate = ref('');//费率
        const fee = ref('');//手续费
        const arrayTips = ref('');//提示文案
        const amount = ref(1000);//入金金额
        const paymentAmount = ref('');//支付金额
        const btnLoading = ref(false);
        let payUrl = ''//入金地址

        let loading;

        openFullScreen();

        // 获取 payId
        apiService.PayInChannel({
            userToken: store.getters.userToken,
            payType: Number(props.payType),
            currencyCategoryId: Number(route.query.id),
        }).then(async (res) => {
            if (res.status === 1) {
                channelArr.value = res.data.list
                payId.value = res.data.list[0].payId;
                getPayInChannelPage(res.data.list[0].payId);
            }
            loading.close();
        });

         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

        //切换通道
        function payIdChange(e){
            openFullScreen();
            getPayInChannelPage(e)
        }

        // 获取每个通道的详情数据
        async function getPayInChannelPage(payId) {
            const res = await apiService.PayInChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            if(res.status != 1) return;
            maxDepositLimit.value = res.data.maxDepositLimit;
            minDepositLimit.value = res.data.minDepositLimit;
            payCurrency.value = res.data.payCurrency;
            if(minDepositLimit.value != '' && maxDepositLimit.value != ''){
                placeholder.value = t('deposit.text35') + minDepositLimit.value +'-'+ maxDepositLimit.value + payCurrency.value;
            }else if(minDepositLimit.value == ''){
                placeholder.value = t('deposit.text36') + maxDepositLimit.value + payCurrency.value;
            }else if(maxDepositLimit.value == ''){
                placeholder.value = t('deposit.text37') + minDepositLimit.value + payCurrency.value;
            }
            fee.value = res.data.feerate;
            feerate.value = Number(res.data.feerate);
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            countAmount();
            loading.close();
            return res;
        }

        //计算手续费和支付金额
        function countAmount(){
            console.log("countAmount")
                fee.value = (amount.value * feerate.value).toFixed(2);
                paymentAmount.value = (Number(fee.value) + Number(amount.value)).toFixed(2);
        }
         // 支付金额
        function buyNumberAmountValue(v) {
            const  value = v;
            if(value == ''){
                    paymentAmount.value = 0
                    return
                }

            let precent = 2;
                
            let reg=new RegExp(`\\d+(\\.\\d{0,${precent}})?`,'g')
            const val = value.match(reg)?value.match(reg)[0]:""

            // 输入的数据进行初始化，将非数字的替换为空
            // /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g
        
            // 重新赋值
            nextTick(() => {
                amount.value = val;
                countAmount();
            });
        }

         function quickSelect(e){
            if(e == 'Other'){
                amount.value = '';
            }else{
                amount.value = e;
            }
            countAmount();
        }

        //确定
        function Confirm(){
            if(maxDepositLimit.value == ''){
                if(Number(amount.value) < Number(minDepositLimit.value)){
                    ElMessage.error(`${t('deposit.text37')}${minDepositLimit.value}USD`)
                    return;
                }
            }else{
                if(Number(amount.value) < Number(minDepositLimit.value) || Number(amount.value) > Number(maxDepositLimit.value)){
                    ElMessage.error(`${t('deposit.text35')}${minDepositLimit.value}~${maxDepositLimit.value}USD`)
                    return;
                }
            }
            
             apiService.payment(payUrl, {
                    returnurl: window.location.href,
                    fundterminal: 1,
                    factAmount: 1, // 不重要但必传的后台参数
                    payid: payId.value,
                    usertoken: store.getters.userToken,
                    payAmount: Number(amount.value),
                    realAmount:Number(paymentAmount.value)
                }).then((res) => {
                    if (res.status === 1) {
                        window.open(res.data.applyUrl, '_blank');
                    }else{
                         ElMessage({
                            message: res.message,
                            type: 'error',
                        });
                    }
                })
            }
        return {
            payId,
            channelArr,
            minDepositLimit,
            maxDepositLimit,
            payCurrency,
            placeholder,
            feerate,
            arrayTips,
            payIdChange,
            buyNumberAmountValue,
            amount,
            quickSelect,
            paymentAmount,
            fee,
            btnLoading,
            Confirm
            
        }
    }
}
</script>
<style lang="less" scoped>
.CreditCard{
    .line_box{
         padding: 24px 30px;
        .line{
            overflow: hidden;
            margin-bottom: 30px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 250px;
                text-align: left;
                font-size: 14px;
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                    width: 500px;
                }
            }
            .fast{
                margin-top: 10px;
                display: flex;
                justify-content: left;
                .fast_choose{
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: 1px solid @border-color-base;
                    text-align: center;
                    color: @text-color-secondary;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .active_choose{
                    border-color:@primary-color;
                    color: @primary-color;
                }
            }
        }
        .warning{
            color: #EF383C;
            font-size: 12px;
            text-align: left;
        }
        .please_note{
            font-size: 12px;
            color: #666666;
            text-align: left;
            margin-top: 20px;
        }
        .confirm{
            margin-top: 20px;
        }
        .arrayTips{
            text-align: left;
        }
    }
}
</style>
<style lang="less">
.CreditCard{
    .el-select{
        width:100%;
    }
    .el-button{
        width:100%;
    }
}
</style>
