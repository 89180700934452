<template>
    <div class="USDTWithdraw">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('withdraw.text1')}}</div>
                <div class="value">
                    <el-select v-model="payId" class="m-2"  size="large" @change="payIdChange">
                        <el-option
                        v-for="item in channelArr"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text2')}}</div>
                <div class="value">
                    <div class="value_text">1USD≈{{legalCurrencyExchangeRate}}{{legalCurrency}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text3')}} <i class="iconfont icon-icon_instructions" @click="AvailableAmountFun"></i></div>
                <div class="value">
                    <div class="value_text">{{canDrawAmount}}{{currentType}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text4')}}({{currentType}})</div>
                <div class="value">
                    <el-input class="amount_input" size="large" v-model="amount" :placeholder="placeholder" @input="withdrawNumberAmountValue">
                        <template #suffix>
                            <div class="fast_btn_r cursor" @click="withdrawalAmountAll">{{$t('withdraw.text6')}}</div>
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text5')}}</div>
                <div class="value">
                    <div class="value_text"> {{estimatedReceiptAmount}}{{legalCurrency}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text7')}}</div>
                <div class="value">
                    <div class="value_text">{{userInfo.name}}</div>
                </div>
            </div>
             <div class="line">
                 <div class="short_line">
                    <div class="label">{{$t('withdraw.text8')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfo.bankTypeName?bankCardInfo.bankTypeName+'('+bankCardInfo.lastNumber+')':'--'}}</div>
                    </div>
                 </div>
            </div>
             <div class="line line_btn">
                <div class="add_bankcard cursor" @click="addBankCard">+ {{$t('withdraw.text9')}}</div>
                <div class="use_another cursor" v-if="bankCardInfo.bankTypeName"  @click="openUseAnotherBoundCard">{{$t('withdraw.text10')}}</div>
            </div>
            <p class="warning">{{$t('withdraw.text11')}}</p>
            <el-button :disabled="amount == '' ||  !bankCardInfo.bankTypeName " :loading="btnLoading" class="confirm" type="primary" size="large" @click="Confirm">{{$t('withdraw.text12')}}</el-button>
            <p class="please_note" v-html="arrayTips"></p>
        </div>
        <div class="incompleteOrders">
             <div class="title">
                <div class="hr"></div>
                <div class="title_box">{{$t('withdraw.text13')}}</div>
            </div>
            <div class="content_box" v-if='RecentlyRoutineOutOrderObj.orderId'>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('withdraw.text14')}}</div>
                        <div :class="['value',RecentlyRoutineOutOrderObj.status == 9?'fail':'primary']">{{RecentlyRoutineOutOrderObj.statusStr}}</div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('withdraw.text15')}}</div>
                        <div class="value">
                            {{RecentlyRoutineOutOrderObj.bankName}}
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('withdraw.text16')}}</div>
                        <div class="value">{{RecentlyRoutineOutOrderObj.amount}} {{RecentlyRoutineOutOrderObj.amountCurrency}} </div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('withdraw.text17')}}</div>
                        <div class="value">
                           {{RecentlyRoutineOutOrderObj.bankAccount}}
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('withdraw.text18')}}</div>
                        <div class="value">{{RecentlyRoutineOutOrderObj.arrivalAmount}} {{RecentlyRoutineOutOrderObj.arrivalAmountCurrency}}</div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('withdraw.text19')}}</div>
                        <div class="value">
                            {{RecentlyRoutineOutOrderObj.realName}}
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('withdraw.text20')}}</div>
                        <div class="value">{{RecentlyRoutineOutOrderObj.withdrawtime}}</div>
                    </div>
                </div>
                <div class="btn_box" v-if="RecentlyRoutineOutOrderObj.status == 0 || RecentlyRoutineOutOrderObj.status == 3">
                    <div class="revoke" @click="Revoke">{{$t('withdraw.text21')}}</div>
                </div>
            </div>
            <div class="empty" v-else>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_no_data%402x.png" alt="">
                <p>{{$t('trade.text66')}}</p>
            </div>
        </div>
        <AddBankCard @addBankCardSuccsess="addBankCardSuccsess" />
        <UseAnotherBoundCard @changeBankCard="changeBankCard" :walletbankId='bankCardInfo.walletbankId' :legalCurrency='legalCurrency' :unbindHide="true"/>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick,computed,h,reactive,inject
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import { assignParams, urlEncode,xzLocal,isNotEmpty} from '@/until/common';
import axios from 'axios';
import { useRoute } from "vue-router"
import AddBankCard from '@/components/pc/AddBankCardModel'
import UseAnotherBoundCard from '@/components/pc/UseAnotherBoundCard'
import { useI18n } from "vue-i18n";


export default {
    name:'USDTWithdraw',
    props: {
        payType: {
            type: Number,
        },
    },
     components:{
            AddBankCard,
            UseAnotherBoundCard
        },
    setup(props){

        const emitt = inject('emitt');
        const store = useStore();
        const userInfo = computed({ get: () => store.getters.userInfo });
        const route = useRoute(); 
        const { t } = useI18n()
        
       
        const payId = ref('');//支付id
        const minWithdrawLimit = ref('');//最小入金
        const maxWithdrawLimit = ref('');//最大入金
        const currentType = ref('');//单位
        const feerate = ref('');//费率
        const placeholder = ref('');    
        const arrayTips = ref('');//提示文案
        const legalCurrencyExchangeRate = ref('');//支付币种汇率
        const payCurrencyExchangeRate = ref('');//出金币种汇率
        const channelArr = ref([]);
        const amount = ref('');//入金金额
        let payUrl = ''//入金地址
        const legalCurrency = ref('');//出金币种
        const btnLoading = ref(false);
        const RecentlyRoutineOutOrderObj = ref({orderId:0});
        const canDrawAmount = ref('');//可提金额
        let loading;
        const estimatedReceiptAmount = ref(0);
        const bankCardInfoList = reactive([]);
        const bankCardInfo = reactive({});
        //未完成订单
     
        openFullScreen();
        // 获取 payId
        apiService.PayOutChannel({
            userToken: store.getters.userToken,
            payType: Number(props.payType),
            currencyCategoryId: Number(route.query.id),
        }).then(async (res) => {
            if (res.status === 1) {
                channelArr.value = res.data.list
                payId.value = res.data.list[0].payId;
                getPayOutChannelPage(res.data.list[0].payId);
                RecentlyRoutineOutOrder();
            }
            loading.close();
        });

        //切换通道
        function payIdChange(e){
            openFullScreen();
            getPayOutChannelPage(e)
        }

         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('withdraw.text22'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

         //打开选择银行卡弹框
        function openUseAnotherBoundCard(){
            emitt.$pub('openUseAnotherBoundCard',{title:t('withdraw.text10')});
        }

        // 获取每个通道的详情数据
        async function getPayOutChannelPage(payId) {
            const res = await apiService.PayOutChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            if(res.status != 1) return;
            maxWithdrawLimit.value = res.data.maxWithdrawLimit;
            minWithdrawLimit.value = res.data.minWithdrawLimit;
            currentType.value = res.data.currentType;
            legalCurrency.value = res.data.legalCurrency;
            if(minWithdrawLimit.value != '' && maxWithdrawLimit.value != ''){
                placeholder.value = t('withdraw.text23') + minWithdrawLimit.value +'-'+ maxWithdrawLimit.value + currentType.value;
            }else if(minWithdrawLimit.value == ''){
                placeholder.value = t('withdraw.text24') + maxWithdrawLimit.value + currentType.value;
            }else if(maxWithdrawLimit.value == ''){
                placeholder.value = t('withdraw.text25') + minWithdrawLimit.value + currentType.value;
            }
            feerate.value = res.data.feerate;
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            canDrawAmount.value = res.data.canDrawAmount;
            for(let item of res.data.exchangeRateInfo){
                if('USD/'+currentType.value == item.currencyPair){
                        payCurrencyExchangeRate.value = item.exchangeRate;
                }else if('USD/'+(legalCurrency.value == 'CNY'?'CNH':legalCurrency.value) == item.currencyPair){
                    legalCurrencyExchangeRate.value = item.exchangeRate;
                }
                if(currentType.value == 'USD'){
                    payCurrencyExchangeRate.value = 1
                }
            }
             bankCardInfoList.value = store.getters.walletBank.filter(e => {
                if(e.currentType == legalCurrency.value){
                    return e;
                }
            });
            for(let item of bankCardInfoList.value){
                if(item.isDefault == 1){
                    Object.assign(bankCardInfo,item)
                }
            }

            if(!isNotEmpty(bankCardInfo)){
                Object.assign(bankCardInfo,bankCardInfoList.value[0])
            }

            loading.close();
            return res;
        }
        
         // 计算预计首款
        function countWithdraw(num) {
            estimatedReceiptAmount.value = Number((amount.value * legalCurrencyExchangeRate.value).toFixed(2));
        }
      

        // 出金金额
        function withdrawNumberAmountValue(v) {
            const  value = v;
            if(value == ''){
                    estimatedReceiptAmount.value = 0
                    return
                }

            let precent = 2;
                
            let reg=new RegExp(`\\d+(\\.\\d{0,${precent}})?`,'g')
            const val = value.match(reg)?value.match(reg)[0]:""

            // 输入的数据进行初始化，将非数字的替换为空
            // /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g
        
            // 重新赋值
            nextTick(() => {
                amount.value = val;
                countWithdraw();
            });
        }


        let validTimeVlaue = 0;
        function RecentlyRoutineOutOrder(){
            apiService.RecentlyRoutineOutOrder({payId:payId.value}).then(res => {
                RecentlyRoutineOutOrderObj.value = res.data;
            })
        }
         // 秒转换成分
        function formatTime(seconds) {
        return `${[
            parseInt(seconds / 60),
            parseInt(seconds % 60),
        ]
        .join('m')
        .replace(/\b(\d)\b/g, '0$1')
        }s`;
        }
       

         //确定
        async function Confirm(){
            if(Number(canDrawAmount.value) < Number(amount.value)){
                    ElMessage.error(t('withdraw.text73'))
                    return
            }


            if(maxWithdrawLimit.value == ''){
                if(Number(amount.value) < Number(minWithdrawLimit.value)){
                    ElMessage.error(`Min ${minWithdrawLimit.value}USD`)
                    return;
                }
            }else{
                if(Number(amount.value) < Number(minWithdrawLimit.value) || Number(amount.value) > Number(maxWithdrawLimit.value)){
                    ElMessage.error(`${t('withdraw.text23')} ${minWithdrawLimit.value}~${maxWithdrawLimit.value}USD`)
                    return;
                }
            }
            btnLoading.value = true;
            const res = await apiService.JudgeHavePos({});
            if(res.status == 1){
                if(res.data.hasPos){
                    if(res.data.hasCredit){
                        ElMessageBox.alert(res.data.tips,t('withdraw.text26'),
                        {
                            confirmButtonText:t('trade.text84'),
                        })
                    }else{
                        ElMessageBox.confirm(
                            res.data.tips,
                            t('withdraw.text26'),
                            {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: t('withdraw.text27'),
                            cancelButtonText: t('withdraw.text28'),
                            }
                        ).then(() => {
                            walletrquest();
                        }) .catch(() => {
                            btnLoading.value = false;
                        })
                    }
                }else{
                    walletrquest();
                }
            }
            }


        //出金
        function walletrquest(){
                 let params = {
                    walletbankId:bankCardInfo.walletbankId,
                    amount:amount.value,
                    currentType:currentType.value,
                    legalCurrency:legalCurrency.value,
                    fundTerminal:1,
                    payChannelId:payId.value,
                 }
            apiService.walletrquest(params).then(res => {
                console.log(res)
                if(res.status == 1){
                    amount.value = '';
                    getPayOutChannelPage(payId.value);
                    countWithdraw();
                    ElMessage({
                        message:t('withdraw.text29'),
                        type:'success'
                    })
                    window.location.reload();
                }else{
                    ElMessage.error(res.message)
                }
                btnLoading.value = false;
            })
        }
        // 复制
        function copyWith(text) {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', text);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            ElMessage({
                message: t('withdraw.text30'),
                type: 'success',
            });
        }

        
        //撤销
        function Revoke(){
             ElMessageBox.confirm( t('withdraw.text31'),{
                confirmButtonText: t('withdraw.text12'),
                cancelButtonText: t('trade.text72'),

            })
            .then(() => {
                openFullScreen();
                const params = {
                        orderId: RecentlyRoutineOutOrderObj.value.orderId,
                    };
                    console.log("params",params,RecentlyRoutineOutOrderObj)
                    apiService.CancelWithdrawOrder(params).then((res) => {
                        loading.close();
                        console.log(res);
                        if (res.status == 1) {
                            window.location.reload();
                        }else{
                            ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    });
                })
            .catch(() => {
            })
        }
         //添加银行卡
        function addBankCard(){
            emitt.$pub('openAddBankCard');
        }

        //可提金额
        function AvailableAmountFun(){
           ElMessageBox.alert(
            h('div',null,[
                h('p',t('withdraw.text33')),
                h('p',t('withdraw.text34')),
                h('p',t('withdraw.text35')),
                h('p',t('withdraw.text36'))
            ]), 
            t('withdraw.text37'), 
            {
            confirmButtonText: t('withdraw.text32')
            }
            )
        }

        //添加成功后更新银行卡信息
        function addBankCardSuccsess(e){
            if(e.currency == 'CNY'){
                Object.assign(bankCardInfo,e)
            }
        }

        //选择其他银行卡
        function changeBankCard(item){
            Object.assign(bankCardInfo,item)
        }

        //全部出金
        function withdrawalAmountAll(){
            amount.value = canDrawAmount.value;
            countWithdraw();
        }
        return {
            Revoke, 
            payId,
            minWithdrawLimit,
            maxWithdrawLimit,
            currentType,
            feerate,
            arrayTips,
            payCurrencyExchangeRate,
            channelArr,
            payIdChange,
            amount,
            placeholder,
            withdrawNumberAmountValue,
            userInfo,
            btnLoading,
            Confirm,
            legalCurrency,
            RecentlyRoutineOutOrderObj,
            copyWith,
            legalCurrencyExchangeRate,
            AvailableAmountFun,
            canDrawAmount,
            estimatedReceiptAmount,
            bankCardInfo,
            bankCardInfoList,
            addBankCardSuccsess,
            addBankCard,
            changeBankCard,
            openUseAnotherBoundCard,
            withdrawalAmountAll
        }
    }
}
</script>
<style lang="less" scoped>
.USDTWithdraw{
    .line_box{
         padding: 24px 30px;
        .line{
            overflow: hidden;
            margin-bottom: 30px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 250px;
                text-align: left;
                font-size: 14px;
                i{
                    color: #999999;
                    cursor: pointer;
                }
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                    width: 500px;
                }
                .fast_btn_l{
                    border-right:  1px solid @border-color-base;/*no*/
                    width: 60px;
                    line-height: 40px;
                    color: @primary-color;
                }
                .fast_btn_r{
                    border-left:  1px solid @border-color-base;/*no*/
                    width: 60px;
                    line-height: 40px;
                    color: @white;
                    background: @primary-color;
                }
            }
            .add_bankcard{
                width: 240px;
                color: @primary-color;
                background: @heading-bg-color;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                float: left;
            }
            .use_another{
                width: 240px;
                color: @primary-color;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                border: 1px solid @primary-color;
                float: left;
                margin-left: 30px;
            }
        }
        .line_btn{
            padding-left: 248px;
        }
        .warning{
            color: #EF383C;
            font-size: 12px;
            text-align: left;
        }
        .please_note{
            font-size: 12px;
            color: #666666;
            text-align: left;
            margin-top: 20px;
        }
        .confirm{
            margin-top: 20px;
        }
    }
    .incompleteOrders{
         padding: 0 20px;
        .title{
            font-size: 14px;
            display: flex;
            justify-content: left;
            height: 30px;
            .hr{
                width: 4px;
                height: 30px;
                background: @primary-color;
            }
            div{
                font-weight: 500;
            }
            .title_box{
                background: #F7F8FA;
                line-height: 30px;
                width: 100%;
                text-align: left;
                padding-left: 14px;
            }
        }
        .content_box{
            padding: 0 14px;
            padding-bottom: 60px;
            padding-top: 20px;
            .time_hint{
                font-size: 14px;
                text-align: left;
                margin-top: 16px;
                margin-bottom: 20px;
                span{
                    color: #EF383C;
                }
            }
            .line{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                text-align: left;
                margin-bottom: 16px;
                .line_left{
                    width: 50%;
                    display: flex;
                    justify-content: left;
                }
                .line_right{
                    width: 50%;
                    display: flex;
                    justify-content: left;
                }
                .lable{
                    color: #666666;
                    width: 167px;
                }
                .value{
                    width: 253px;
                    display: flex;
                    justify-content: space-between;
                }
                .primary{
                    color:#FB741B; 
                }
                .fail{
                    color: @blue-up-color;
                }
                .copy{
                    color: #FB741B;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
           
            .btn_box{
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                div{
                    width: 420px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    border-radius: 4px;
                    font-weight: 500;
                    cursor: pointer;
                }
                .cancel{
                    background: #EBEBEB;
                }
                .revoke{
                    background: #FB741B;
                    color: white;
                }
            }
        }
        .empty{
            padding-top: 40px;
            padding-bottom: 40px;
            img{
                width: 48px;
                height: 50px;
            }
            p{
                font-size: 12px;
                color:#999999; 
            }
        }
    }
}
</style>
<style lang="less">
.USDTWithdraw{
    .el-select{
        width:100%;
    }
    .line_box{
        .el-button{
            width:100%;
        }
    }
    .amount_input{
        .el-input__inner{
            // padding:0 60px !important;
            // text-align: center;
        }
        .el-input__prefix{
            left: 0;
        }
        .el-input__suffix{
            right: 0;
        }
    }
    .el-radio.el-radio--large{
        height: auto !important;
    }
}
</style>
