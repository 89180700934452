<template>
    <div class="Membership">
        <div class="banner">
        </div>
        <div class="wealth">
            <div class="text">
                <h4>Become our member to optimize your wealth</h4>
                <p>
                    Become our star member to enjoy more return and trading rebates. The more trading rebates and return, the more chance for you to succeed in the forex market.
                </p>
                <p>
                    We all know trading is like a snowball, you currently own more capital to trade in the market, your future capital will be overwhelmingly grew. Ready to invest your beautiful life from today
                </p>
                <div class="btn cursor" @click="routerTo('login')">
                    Start now
                </div>
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_pic_001%402x.png" alt="">
            </div>
        </div>
        <div class="programs_detail">
            <h2>Programs detail</h2>
            <div class="programs_detail_table">
                <div class="table_head">
                    <div class="table_td">
                        <p>Standard</p>
                        <p>$0.00</p>
                        <p>Basic trading account, no deposit required</p>
                    </div>
                     <div class="table_td">
                        <p>Silver account</p>
                        <p>$500.00</p>
                        <p>2nd tier trading account,small amount of deposit is required</p>
                    </div>
                     <div class="table_td">
                        <p>Gold account</p>
                        <p>$2500.00</p>
                        <p>1st tier trading account, medium amount of deposit is required</p>
                    </div>
                     <div class="table_td">
                        <p>Platinum account</p>
                        <p>$5000.00</p>
                        <p>1st tier trading acoount, medium amount of deposit is required</p>
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        Free learning resources
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        1 on 1 trading consultation
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        Leverage to 400X
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        Trading cost rebates
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        Permanent transaction cost reduction
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
                <div class="table_tr">
                    <div class="table_td2">
                        Account manager tips and exclusive news
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark_no">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_002%402x.png" alt="">
                    </div>
                    <div class="mark">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/icon_001%402x.png" alt="">
                    </div>
                </div>
            </div>
        </div>
         <div class="wealth how">
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_pic_002%402x.png" alt="">
            </div>
            <div class="text">
                <h4>How can membership optimize your trading strategies?</h4>
                <p>
                    Transaction fee is required for all trading platform, these fee may reduce your opportunities to snowball you capital. Therefore, our traders can not only enjoy low transaction cost, but also enjoy trading rebate to maximise the snowball advantage from the trading market. Also, our VIP members can enjoy the exclusive trading news and advises from our account manager to be the first-mover getting the greatest benefits via the market.
                </p>
                <div class="btn cursor" @click="routerTo('login')">
                    Sign up now
                </div>
            </div>
        </div>
        <div class="membership">
            <h2>How to upgrade your membership?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_icon_001%402x.png" alt="">
                    <p>Sign up & Verified</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_icon_002%402x.png" alt="">
                    <p>Deposit in-app</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_icon_003%402x.png" alt="">
                    <p>Auto-upgrade status</p>
                </li>
            </ul>
            <div class="btn join cursor"  @click="routerTo('login')">JOIN NOW!</div>
        </div>
        <Advertise></Advertise>
    
    </div>
</template>
<script>
import {
} from 'vue';
import Advertise from '../components/Advertise.vue'
import { useRouter } from "vue-router"

export default {
    name:"Membership",
    props: {
    
    },
    components:{
        Advertise
    },
    setup() {
        const router = useRouter(); 

       function routerTo(e){
            router.push(e)
       }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.Membership{
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
    }
    .primary{
          width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        color: white;/*no*/
        background: #FA6400;
    }
    .flex{
        display: flex;
        justify-content: left;
        margin-top: 60px;
    }
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_top_banner_001%402x.png) no-repeat;
        background-size: cover;
        height: 400px;/*no*/
        line-height: 340px;/*no*/
        background-position:center;
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 340px;
            img{
                width: 100%;
            }
        }
    }
     h2{
        color: #FB741B;
        font-size: 30px;/*no*/
    
    }
    .programs_detail{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        height: 718px;
        padding-top: 48px;
       
        .programs_detail_table{
            width: 1200px;/*no*/
            margin: 0 auto;
            margin-top: 44px;/*no*/
        }
        .table_head{
            display: flex;
            justify-content: space-between;
            padding-left: 304px;/*no*/
            .table_td{
                width: 224px;/*no*/
                text-align: left;
                padding-right: 24px;/*no*/
            }
            .table_td p:nth-child(1){
                font-weight: 600;
                color: #1D1D1D;
            }
            .table_td p:nth-child(2){
                font-size: 24px;
                font-weight: 600;
                color: #1D1D1D;
                margin: 8px 0;
            }
            .table_td p:nth-child(3){
                color: #858585;
            }
        }
        .table_tr{
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .table_td2{
                width: 304px;/*no*/
                font-weight: 500;
                text-align: left;
                padding-right: 104px;/*no*/
            }
            .mark,.mark_no{
                width: 224px;/*no*/
                text-align: left;
                height: 44px;/*no*/
                line-height: 44px;/*no*/
                border-bottom: 1px solid #FFCAA7;/*no*/
                img{
                    width: 16px;/*no*/
                    height: 16px;/*no*/
                }
            }
            .mark_no{
                border-bottom: none;
            }
        }
    }
    .how{
        height: auto;
    }
    .membership{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        padding-top: 40px;
        padding-bottom: 50px;
        ul{
            width: 900px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 300px;
                img{
                    width: 88px;
                    height: 88px;
                }
                p{
                    color: #4E4E4E;
                    margin-top: 16px;/*no*/
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
}
</style>
