<template>
    <div class="home">
        <div class="home_banner">
            <div class="home_slog">Your One-stop Trading Platform</div>
            <p>Secure · Hi-Tech · Innovative</p>
            <p class="p_piece">A trusted trading platform,we are committed to providing the best service to our users.
            </p>
            <div class="btn">
                <!-- <img @click="linkToGoogle" src="https://advantrade.oss-accelerate.aliyuncs.com/web/btn_google%402x.png" alt=""> -->
                <img @click="linkToApple" src="https://advantrade.oss-accelerate.aliyuncs.com/web/btn_app%402x.png"
                    alt="">
                <img @click="linkToAndroid"
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/btn_android%402x.png" alt="">
            </div>
        </div>
        <div class="home_introduction">
            <div class="home_introduction_text">
                <h4>Understand me in 1 minute</h4>
                <p>Advantrade is a one-stop trading app under Topical Wealth International Ltd(Top Wealth). It was
                    co-developed by Top Wealth and an Australian Fin-Tech company ET FinTech Pty Ltd.</p>
                <div class="learn_more cursor" @click="routerTo('about')">Learn more</div>
            </div>
            <div class="home_introduction_video">
                <iframe loading="lazy" title="advantrade video v2" width="1020" height="574"
                    src="https://www.youtube.com/embed/fp259HafN_c?feature=oembed" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""></iframe>
                <p class="eurusd">You can explore <a href="https://www.tradingview.com/symbols/EURUSD/">Eurusd Chart</a>
                    and more about forex here/on
                    TradinqView
                </p>
            </div>
        </div>
        <div class="guaranteed_protection">
            <div class="title">
                Guaranteed protection
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home-1.png" alt="">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home-2.png" alt="">
                <!-- <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home-3.png" alt=""> -->

            </div>
        </div>
        <div class="our_products">
            <h2 class="title">Our products</h2>
            <ul>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_forex_1%402x.png" alt=""></li>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_precious%402x.png" alt=""></li>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_energy%402x.png" alt=""></li>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_crypt%402x.png" alt=""></li>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_indices%402x.png" alt=""></li>
                <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_commod%402x.png" alt=""></li>
            </ul>
        </div>
        <div class="our_user">
            <h2 class="title">Friendly interfaces</h2>
            <p>The cutting-edge application has been subscribed by more than 500,000 users worldwide. Our business is
                across 4 continents, covering Southeast Asia, South America, Europe, and Africa.</p>
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_user%402x.png" alt="">
        </div>
        <div class="good_news_for_you">
            <div class="good_news_for_you_text">
                <h4>Good news for you</h4>
                <p>Advantrade teams are going to push an incredible promotion to reward our traders. If you trade in
                    advantrades, you can win bitcoin, ethereum and more for FREE！</p>
                <div @click="routerTo('login')" class="btn_join_now cursor">
                    Join Now
                </div>
            </div>
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_news%402x.png" alt="">
        </div>
        <div class="why_choose">
            <h4>Why choose Advantrade？</h4>
            <div class="why_choose_content">
                <div class="why_choose_line">
                    <div class="why_choose_line_text">
                        <div class="title">Extraordinary trading experience</div>
                        <p>Advantrade provides proprietary trading and copy trade features, fulfilling the needs of the
                            different investors, especially the investors who just started their investment journey. By
                            subscribing to our featured copy traders, users can gain profit from the trader even if they
                            lack knowledge and experience in investment.</p>
                        <div class="learn_more cursor" @click="routerTo('whyChooseUs')">Learn more</div>
                    </div>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_experience%402x_67.png"
                        alt="">
                </div>
                <div class="why_choose_line">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_safe%402x_67.png" alt="">
                    <div class="why_choose_line_text">
                        <div class="title">Strict safety protection for you</div>
                        <p>As a responsible operator, Advantrade systems use 128-bit SSL encryption for transmission and
                            MD5 encryption for management to ensure the secure storage of users' personal privacy,
                            transaction history and sensitive data.Only the owner can access and monitor all activities
                            related to the account to prevent data leakage.</p>
                        <div class="learn_more cursor" @click="routerTo('whyChooseUs')">Learn more</div>
                    </div>
                </div>
                <div class="why_choose_line">
                    <div class="why_choose_line_text">
                        <div class="title">Experienced financial professionals and tier-one technical teams</div>
                        <p>Advantrade is confident to offer all users a secured, stable, and responsive trading
                            environment. In the future, our elite teams will keep reaching the new advances, optimizing
                            trading system technology, and aiming to be the global pioneer of the innovating Fin-Tech
                            industry.</p>
                        <div class="learn_more cursor" @click="routerTo('whyChooseUs')">Learn more</div>
                    </div>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_team%402x_67.png" alt="">
                </div>
            </div>
        </div>
        <div class="powerful_trading">
            <h4>Powerful trading functions</h4>
            <div class="powerful_trading_functions">
                <div class="functions_left">
                    <div class="trading_functions trading_functions_1">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_real%402x.png" alt="">
                        <h5>Real-Time Quotation, Starting From 0 Spread</h5>
                        <p>Advantrade uses RTQs, an essential feature in modern trading apps, so traders can get
                            real-time information and avoid missing out on the best opportunities.</p>
                    </div>
                    <div class="trading_functions trading_functions_2">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_instant%402x.png" alt="">
                        <h5>50ms Fast Transaction</h5>
                        <p>Some trading apps take 2-3 days to complete the transfer of funds. Advantrade promises that
                            all funds will be credited and withdrawn instantly, and the transaction will be completed
                            within 50ms.</p>
                    </div>
                    <div class="trading_functions">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_market%402x.png" alt="">
                        <h5>Market Volatility and Risk Alerts</h5>
                        <p>We understand the market and your needs, Advantrade's unique alerting system can help you
                            minimizing your risk. You can trade with us with confidence!</p>
                    </div>
                </div>
                <img class="functions_center"
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_powerful_phone.png" alt="">
                <div class="functions_right">
                    <div class="trading_functions">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_latest%402x.png" alt="">
                        <h5>Support Subscription, Copy Transactions, and Share Profits with Top Traders</h5>
                        <p>Advantrade provides independent trading and copy trading functions. By subscribing and
                            following transactions, beginner can cross the threshold of investment knowledge and
                            experience, and make profits with the selected traders.</p>
                    </div>
                    <div class="trading_functions">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_expertise%402x.png"
                            alt="">
                        <h5>First-Hand News, Professional Icons, Technical Indicators Analysis</h5>
                        <p>Advantrade always provides the latest market news, and uses AI to generate visual charts and
                            indicators to assist traders in making their decision, ensuring that traders perform well in
                            the fierce market competition.</p>

                    </div>
                    <div class="trading_functions">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_icon_able%402x.png" alt="">
                        <h5>10~400 Leverage</h5>
                        <p>Advantrade provides traders with leverage of 10 to 400. Users can adjust the leverage before
                            creating the positions to maximize the profit. It is more flexible and convenient.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="Our_Multi-Brand">
            <h4>Our Multi-Brand</h4>
            <div class="content">
                <div class="pro">
                    私たちは2023年にマルチブランド戦略を展開し、オンラインFXサービス『FiXi（フィクシーFX）』を立ち上げました。<br />
                    マルチブランド戦略の特徴として、『AdvanTrade』はMetaQuotes社のMT5取引プラットフォームおよび自社開発のプラットフォーム『AdvanTrade』に特化したオンラインFXプラットフォームを提供しています。一方で、『FiXi（フィクシーFX）』はcTrader取引プラットフォームに特化したオンラインFXプラットフォームとして機能しています。
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/mobile/logo_asset%203%404x-8.png" alt="">
            </div>
        </div>
        <div class="our_clients">
            <div class="our_clients_content">
                <div class="swiper">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <p>“I trusted this broker because it has no dishonest problem in deposit or withdrawal.
                                    I get too much from this platform. Good job!“</p>
                                <div class="author">
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/hone_head_portrait1%402x.png"
                                        alt="">
                                    <div>
                                        <p class="name">Vivian.W</p>
                                        <p>3+ years traders</p>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <p>“I have to say..the lowest spreads forex provider isn’t this broker, but their
                                    reliable customer service and integrity are the very unique points that made me to
                                    trade in this platform”</p>
                                <div class="author">
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/hone_head_portrait2%402x.png"
                                        alt="">
                                    <div>
                                        <p class="name">Kirstin.Wu</p>
                                        <p>3+ years trading exp</p>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <p>“I signed up and get the free learning resources from Advantrade, it helps me too
                                    much in forex trading. Now I can trade safety here. Thanks you Advantrade!”</p>
                                <div class="author">
                                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/hone_head_portrait3%402x.png"
                                        alt="">
                                    <div>
                                        <p class="name">Elisa.L</p>
                                        <p>0+ experience traders</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 如果需要分页器 -->
                        <div class="swiper-pagination"></div>
                        <!-- 如果需要导航按钮 -->
                        <!-- <div class="swiper-button-prev"></div> -->
                        <!-- <div class="swiper-button-next"></div> -->
                        <!-- 如果需要滚动条 -->
                        <!-- <div class="swiper-scrollbar"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="join_us">
            <h4>Join us to get unlimited benefits</h4>
            <p>Enjoy our unlimited resources and support to make your asset explodingly for FREE！</p>
            <div class="btn">
                <div class="sign_up cursor" @click="routerTo('login')">Sign up now</div>
                <!-- <div class="learn_more cursor" @click="routerTo('promotion')">Learn more promotion </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, onBeforeMount, computed } from 'vue'
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import { useRouter } from "vue-router"
import { linkToGoogle, linkToApple, linkToAndroid } from '@/until/common';
import apiService from '@/api/request';
import { useStore } from 'vuex'


export default {
    name: 'home',
    components: {
    },
    setup() {
        const store = useStore();
        const lang = computed({ get: () => store.getters.getNowTheme.lang });


        onBeforeMount(() => {
            if (lang.value == 'cn') {
                routerTo('/homeCN');
            }
        })
        onMounted(() => {
            console.log('Component is mounted! home')
            new Swiper('.swiper-container', {
                autoplay: 3000,
                pagination: '.swiper-pagination',
            })
        })
        const router = useRouter();


        function routerTo(e) {
            router.push(e)
        }
        return {
            routerTo,
            linkToGoogle,
            linkToApple,
            linkToAndroid
        };
    }
}
</script>

<style lang="less" scoped>
.home {
    .home_banner {
        height: 43.75rem;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/home_banner%402x.png) no-repeat;
        background-size: 100%;
        padding: 177px 0 0 410px;
        text-align: left;
        color: #FFFFFF;

        .home_slog {
            font-size: 46px;
            font-weight: 900;
            margin-bottom: 32px;
        }

        p {
            font-size: 24px;
        }

        .p_piece {
            margin-top: 16px;
            width: 593px;
        }

        .btn {
            margin-top: 70px;
            width: 460px;
            display: flex;
            justify-content: space-between;

            img {
                display: block;
                width: 220px;
                height: 80px;
                cursor: pointer;
            }
        }
    }

    .guaranteed_protection {
        padding-top: 70px;
        padding-bottom: 100px;

        .title {
            font-size: 34px;
            font-weight: 900;
        }

        .pic {
            width: 1010px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 90px;

            img {
                display: block;
                width: 450px;
                height: 430px;
            }
        }

    }

    .learn_more {
        width: 150px;
        height: 40px;
        line-height: 40px;
        background: #FB741B;
        color: white;
        text-align: center;
        font-size: 16px;
        margin-top: 24px;
    }

    .home_introduction {
        height: 560px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .home_introduction_text {
            width: 500px;
            text-align: left;

            h4 {
                font-size: 30px;
                color: #FB741B;
                margin-bottom: 24px;
            }
        }

        .home_introduction_video {
            margin-left: 60px;

            iframe {
                width: 100%;
                height: 100%;
            }

            width: 540px;
            height: 360px;

            .eurusd {
                text-align: left;
            }
            a {
                color: red;
                font-weight: 600;
                text-decoration: underline;
            } 
        }
    }

    .our_products {
        height: 962px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_products%402x.png) no-repeat;
        background-size: 100%;
        padding-top: 61px;

        h2 {
            font-size: 34px;
            color: #FFFFFF;
            font-weight: 900;
            margin-bottom: 50px;
        }

        ul {
            width: 1100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;

            li {
                width: 530px;
                margin-bottom: 30px;

                img {
                    width: 100%
                }
            }
        }
    }

    .our_user {
        padding-top: 60px;
        padding-bottom: 70px;
        background: white;

        h2 {
            font-size: 34px;
            font-weight: 900;
        }

        img {
            width: 1100px;
            margin-top: 50px;
        }

        p {
            width: 1100px;
            font-size: 16px;
            color: #666666;
            margin: 0 auto;
            margin-top: 30px;
        }
    }

    .good_news_for_you {
        height: 320px;
        background: #FB741B;
        color: white;
        padding-top: 9px;

        .good_news_for_you_text {
            width: 500px;
            text-align: left;
            float: left;
            margin-left: 410px;
            margin-top: 53px;

            h4 {
                font-size: 30px;
            }

            p {
                margin-top: 24px;
            }

            .btn_join_now {
                margin-top: 24px;
                width: 150px;
                height: 40px;
                background: white;
                border-radius: 4px;
                color: #FB741B;
                line-height: 40px;
                text-align: center;
            }
        }

        img {
            display: block;
            height: 273px;
            width: 327px;
            float: left;
            margin-left: 237px;
        }
    }

    .why_choose {
        padding-top: 61px;
        padding-bottom: 64px;
        background: white;

        .why_choose_content {
            width: 1100px;
            margin: 0 auto;
            margin-top: 41px;
        }

        h4 {
            font-size: 34px;
            font-weight: 900;
        }

        .why_choose_line {
            width: 1100px;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            align-items: center;

            .title {
                font-size: 30px;
                color: #FB741B;
            }

            img {
                display: block;
                width: 400px;
                height: 400px;
            }

            p {
                font-size: 16px;
                margin-top: 24px;
                color: #666666;
            }
        }

        .why_choose_line_text {
            width: 500px;
            text-align: left;
        }

        .why_choose_right {
            margin-left: 100px;
        }
    }

    .Our_Multi-Brand {
        padding-top: 84px;
        padding-bottom: 114px;

        .pro {
            width: 726px;
            text-align: left;
            font-size: 16px;
        }

        h4 {
            font-size: 36px;
            color: black;
            font-weight: 900;
        }

        .content {
            width: 1080px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 70px;

            img {
                width: 200px;
                height: 98px;
                display: block;
            }
        }
    }

    .powerful_trading {
        padding: 60px 0;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_powerful%402x.png) no-repeat;
        background-size: cover;
        background-position: center;

        .powerful_trading_functions {
            display: flex;
            justify-content: center;
            margin-top: 80px;
        }

        h4 {
            font-size: 36px;
            color: #FFFFFF;
            font-weight: 900;
        }

        .trading_functions {
            margin-bottom: 40px;
        }

        .trading_functions_1 {
            margin-bottom: 61px;
        }

        .trading_functions_2 {
            margin-bottom: 69px;
        }

        .functions_left,
        .functions_right {
            width: 400px;

            img {
                width: 80px;
            }

            h5 {
                font-size: 20px;
                color: white;
                margin-top: 16px;
            }

            p {
                font-size: 14px;
                color: #FFFFFF;
                margin-top: 8px;
            }
        }

        .functions_left {
            text-align: right;
        }

        .functions_right {
            text-align: left;
        }

        .functions_center {
            width: 370px;
            margin: 0 50px;
            height: 735px;
        }
    }

    .our_clients {
        padding: 47px 0;
        background: white;

        .our_clients_content {
            background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_clients%402x.png) no-repeat;
            background-size: 100%;
            width: 1186px;
            height: 386px;
            margin: 0 auto;
            padding-left: 648px;
            padding-top: 93px;
            text-align: left;
            font-size: 16px;

            .swiper-container {
                width: 435px;
                height: 228px;
            }

            .swiper {
                width: 435px;
                height: 228px;
            }

            .author {
                display: flex;
                justify-content: left;
                margin-top: 30px;
                font-size: 12px;

                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                }

                .name {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    margin-top: 3px;
                }
            }

            .swiper-pagination-bullet-active {
                background: #FB741B;
            }
        }
    }

    .join_us {
        height: 290px;
        background: #FB741B;
        padding: 61px 0;

        h4 {
            font-size: 34px;
            color: white;
        }

        p {
            margin-top: 21px;
            font-size: 16px;
            color: white;
        }

        .btn {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            div {
                width: 200px;
                height: 44px;
                text-align: center;
                line-height: 44px;
                margin: 0 12px;
                background: white;
                color: #FB741B;
            }
        }
    }
}
</style>
