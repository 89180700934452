<template>
    <div class="ContactUsCN">
        <div class="bannar">
           <div class="bannar_box w1200">
                 <div class="box_left">
                    <img class="logo" src="https://advantrade.oss-accelerate.aliyuncs.com/web/top_logo3%402x.png" alt="">
                    <h2>下载AdvanTrade APP</h2>
                    <div class="downQR">
                        <a href="https://apps.apple.com/cn/app/advantrade/id1586086393"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/down_qr_ios%402x.png" alt=""></a>
                        <a href="https://apk.topwealthgroup.com/advantrade/download/Advantrade_Official_release.apk"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/down_qr_android%402x.png" alt=""></a>
                        <!-- <a href="https://play.google.com/store/apps/details?id=com.amicable.advance"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/down_qr_google%402x.png" alt=""></a> -->
                    </div>
                </div>
                <div class="box_right">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/pic_software%202%402x.png" alt="">
                </div>
           </div>
        </div>
        <!-- <div class="content_one">
            <div class="content_one_box w1200">
                <div class="box_left">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/pic_software%402x.png" alt="">
                </div>
                <div class="box_right">
                    <div class="box_text">
                    <h2>下载MT5交易软件</h2>
                    <h3>点击可下载</h3></div>
                    <div class="downQR">
                        <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=TopicalWealthIntl-Demo,TopicalWealthIntl-Live"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_ios%402x.png" alt=""></a>
                        <a href="https://twi.oss-cn-hangzhou.aliyuncs.com/app/metatrader5.apk"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_android%402x.png" alt=""></a>
                        <a href="https://download.mql5.com/cdn/web/18873/mt5/topicalwealthintl5setup.exe"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/mt5_down_qr_pc%402x.png" alt=""></a>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="content_two">
            <div class="content_two_box w1200">
                <div class="box_left">
                    <h2>下载MT4交易软件</h2>
                    <h3>点击可下载</h3>
                    <div class="downQR">
                        <a href="https://download.mql5.com/cdn/mobile/mt4/ios?server=TopWealthIntl-Demo,TopWealthIntl-Live"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_ios%40MT4.png" alt=""></a>
                        <a href="https://regainsite.oss-ap-southeast-1.aliyuncs.com/platform/metatrader4_400.1179.apk"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_android%40MT42.png" alt=""></a>
                        <a href="https://download.mql5.com/cdn/web/16704/mt4/topwealthintl4setup.exe"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/mt4_down_qr_pc%40MT4.png" alt=""></a>
                    </div>
                </div>
                <div class="box_right">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/download/home%EF%BC%8Fpic_mt4%402x%20(2).png" alt="">
                </div>
            </div>
        </div>
        <div class="content_three">
            <div class="content_three_box w1200">
                <div class="box_left">
                    <h3>我们期待与您沟通</h3>
                    <h3>请通过以下邮件联系AdvanTrade爱跟投运营团队</h3>
                    <div class="address">
                        <div class="lable">地址:</div>
                        <p>塞舌尔马埃岛普罗维登斯工业区 Zippora 街 Dekk House 1 楼 7 室</p>
                    </div>
                    <div class="email">
                        <div class="lable">邮箱:   </div>
                        <a href="partners@advantrade.net">partners@advantrade.net</a>
                    </div>
                </div>
                <div class="box_right">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/contact_pic%402x.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
const axios = require('axios');
import Qs from 'qs'

export default {
name:'ContactUsCN',
    components:{
    },
     setup(){
    

      return {
        
      };
    }
}
</script>
<style lang="less">
.ContactUsCN{
   .bannar{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/ContactUsCN/contact_banner1%402x.png) no-repeat;
        background-size: cover;
        background-position: center;
        padding: 175px 0;
        .bannar_box{
            display: flex;
            justify-content: space-between;
            .box_left{
                text-align: left;
                .logo{
                    width: 130px;
                    height: 50px;
                }
                h2{
                    font-size: 40px;
                    font-weight: 600;
                    margin-top: 14px;
                }
                .downQR{
                    margin-top: 40px;
                    img{
                        width: 158px;
                        height: 190px;
                    }
                }
            }
            .box_right{
                width: 584px;
                height: 336px;
                padding-top: 7px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
   }
   .content_one{
            padding-top: 118px;
            padding-bottom: 125px;
            background: white;
           
            .content_one_box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                
            }
            .box_left{
                width: 635px;
                height: 282px;
                img{
                    // width: 100%;
                    // height: 100%;
                    width: 635px;
                height: 282px;
                }
            }
            .box_right{
                text-align: left;
               .box_text{
                margin-bottom: 50px;
               }
                h2{
                    font-size: 40px;
                    font-weight: 600;
                    height: 56px;
                    line-height: 56px;
                }
                h3{
                    font-size: 24px;
                    font-weight: 600;
                    margin-top: 12px;
                }
                .downQR{
                  margin-bottom: 20px;
                    img{
                        width: 158px;
                        height: 190px;
                    }
                }
            }
        }
    
     .content_two{
           padding-top: 118px;
            padding-bottom: 125px;
            // background: white;
            
            background: linear-gradient(180deg, #FFFFFF 0%, #EDEDED 100%);
            .content_two_box{
                display: flex;
                justify-content: space-between;
                position: relative;
            }
            .box_left{
                text-align: left;
                width: 500px;
               
                h2{
                    font-size: 40px;
                    font-weight: 600;
                    // margin-top: 14px;
                    line-height: 56px;
                }
                h3{
                    font-size: 24px;
                    font-weight: 600;
                    margin-top: 12px;
                }
                .downQR{
                    margin-top: 10px;
                    img{
                        width: 158px;
                        height: 190px;
                    }
                }
            }
            .box_right{
                width: 634px;
                height: 346px;
                padding-top: 7px;
                position: absolute;
                left: 550px;
                top: 83px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content_three{
            padding-top: 118px;
            background: white;
            .content_three_box{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .box_left{
                    text-align: left;
                    padding-bottom: 104px;
                    h3{
                        font-size: 32px;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }
                    .address{
                        margin-top: 54px;
                        .lable{
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                    }
                    .email{
                        margin-top: 40px;
                         .lable{
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                        a{
                            color: #FB741B;
                        }
                    }
                }
                .box_right{
                     width: 360px;
                    height: 318px;
                    img{
                       width: 100%;
                       height: 100%;
                    }
                }
            }
        }
}

</style>
