<template>
  <div class="footer">
    <div class="footer_box">
      <div class="logo">
        <img
          src="https://advantrade.oss-accelerate.aliyuncs.com/web/top_logo%402x.png"
          alt=""
        />
        <p>一站式智能跟单交易app，Top Wealth集团旗下外汇交易品牌。</p>
      </div>
      <div class="navigation">
        <h5>导航</h5>
        <ul>
          <li>
            <router-link to="/homeCN"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />首页</router-link
            >
          </li>
          <li>
            <router-link to="/transaction"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />交易</router-link
            >
          </li>
          <li>
            <router-link to="/followOrder"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />智能跟单</router-link
            >
          </li>
          <li>
            <router-link to="/iBProgram"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />经纪人（IB）计划</router-link
            >
          </li>
          <li>
            <router-link to="/aboutCN"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />关于我们</router-link
            >
          </li>
          <li>
            <router-link to="/downloadCN"
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png"
                alt=""
              />软件下载</router-link
            >
          </li>
        </ul>
      </div>
      <!-- <div class="promotion">
                <h5>Promotion</h5>
                <ul>
                    <li><router-link to="/luckSpin"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png" alt="">Lucky Spin</router-link></li>
                    <li><router-link to="/referralBonus"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png" alt="">Referral Bonus</router-link></li>
                    <li><router-link to="/membership"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png" alt="">Membership</router-link></li>
                    <li><router-link to="/switchingBonus"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_jump_white%402x.png" alt="">Switching Bonus</router-link></li>
                </ul>
            </div> -->
      <div class="trade_with_us">
        <h5>Advantrade</h5>
        <p>
          拥有海量的学习资源，独创智能跟单系统，专属客服5*24
          h在线服务，为您交易成功提供助力！
        </p>
        <router-link to="/signUp" v-if="!userInfo?.nickname"
          ><div class="sign_up">登录</div></router-link
        >
        <div class="link">
          <a href="https://www.facebook.com/AdvanTrade-109126638142147"
            ><img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_facebook%402x.png"
              alt=""
          /></a>
          <a href="https://www.youtube.com/channel/UCQ7Y1rfu8SOAa06lphRcZbQ"
            ><img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/bottom_icon_youtobe%402x.png"
              alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "footer",
  setup() {
    const store = useStore();
    const userInfo = computed({ get: () => store.getters.userInfo });
    return {
      userInfo,
    };
  },
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  background: #14151a;
  color: white;
  padding-bottom: 54px;
  .footer_box {
    width: 1005px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 54px;
    text-align: left;
    font-size: 12px;
    .logo {
      width: 237px;
      img {
        width: 200px;
      }
      p {
        margin-top: 15px;
      }
    }
    .navigation {
    }
    h5 {
      font-size: 16px;
    }
    .trade_with_us {
      width: 306px;
      p {
        margin-top: 16px;
      }
      .sign_up {
        width: 150px;
        height: 40px;
        background: #fb741b;
        text-align: center;
        line-height: 40px;
        color: white;
        margin-top: 20px;
        font-size: 16px;
      }
      .link {
        width: 150px;
        display: flex;
        justify-content: left;
        margin-top: 24px;
        img {
          display: block;
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
      }
    }
    ul {
      li {
        margin-top: 20px;
        a {
          color: @white;
        }
        img {
          width: 8px;
          margin-right: 14px;
        }
      }
    }
  }
}
</style>
