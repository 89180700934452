<template>
    <div class="AddWithdrawalAddress">
         <el-dialog
            v-model="dialogVisible"
            :title="$t('myAccount.text122')"
            width="460px"
            destroy-on-close
            @closed="beforeClose"
        >
        <div class="line">
            <div class="label">
                {{$t('myAccount.text112')}}
            </div>
            <div class="value">
                <div class="value_text weight500">{{currentType}}</div>
            </div>
        </div>
        <div class="line">
            <div class="label">
                {{$t('myAccount.text35')}}
            </div>
            <div class="value">
                <el-input
                    v-model="address"
                    :placeholder="$t('myAccount.text117')"
                    class="withdrawal_amount"
                />
            </div>
        </div>
        <div class="line">
            <div class="label">
                {{$t('myAccount.text123')}}
            </div>
            <div class="value">
                <el-input
                    v-model="remarks"
                    @input="remarksInput"
                    :placeholder="$t('myAccount.text124')"
                    class="withdrawal_amount"
                />
            </div>
        </div>
         <template #footer>
                <span class="dialog-footer">
                <el-button @click="Cancel">{{$t('trade.text72')}}</el-button>
                <el-button type="primary"  @click="Save" :disabled="!disabled">{{$t('myAccount.text118')}}</el-button>
                </span>
            </template>
         </el-dialog>
    </div>
</template>
<script>
import {
 ref,inject,computed,onBeforeUnmount,nextTick
} from 'vue'
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useI18n } from "vue-i18n";

export default {
    name:'AddWithdrawalAddress',
      props:{
        currentType: { 
            type: String,
            default: '',
        }
    },
    setup(props,ctx){
        const dialogVisible = ref(false);
        const emitt = inject('emitt');
        const remarks = ref('');
        const address = ref('');
        const { t } = useI18n()

        /**
         * 打开弹框-开始
         */ 
          emitt.$sub('openAddWithdrawalAddress2', (val) => {
            dialogVisible.value = true;
          });
        onBeforeUnmount(() => {
            emitt.$unsub("openAddWithdrawalAddress2");
        });
        /**
         * 打开弹框-结束
         */ 

        /**
         * 按钮提交条件-开始
         */
            const disabled = computed(() => {
                return  address.value != '' &&
                        remarks.value != ''
            })
        /**
         * 按钮提交条件-结束
         */

         //关闭弹窗
        function beforeClose(){
            
        }

        //保存
        function Save(){
            const params = {
                remark: remarks.value,
                address: address.value,
                currency: props.currentType,
            };
            apiService.WalletAddress(params, 'post').then((res) => {
                if(res.status == 1){
                        dialogVisible.value = false;
                        Cancel();
                        ctx.emit('changeAddrees',address.value)
                        ElMessage({
                        message: t('myAccount.text125'),
                        type: 'success',
                    });
                }else{
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            })
        }

         function Cancel(){
            address.value = '' 
            remarks.value = ''
            dialogVisible.value = false;
        }

          /**
         * 限制输入-开始
         */
            function remarksInput(v){
                let val = v;
                if (val.length > 10) {
                   val = val.substring(0, val.length - 1);
                }
                nextTick(() => {
                    remarks.value = val;
                });
            }
        /**
         * 限制输入-结束
         */

        return {
            dialogVisible,
            beforeClose,
            Save,
            remarks,
            address,
            disabled,
            Cancel,
            remarksInput
        }
    }
}
</script>
<style lang="less" scoped>
.AddWithdrawalAddress{
     .line{
        overflow: hidden;
        margin-bottom: 16px;
        .label{
            float: left;
            height: 40px;
            line-height: 40px;
            width: 156px;
            text-align: left;
            font-size: 14px;
            img{
                position: relative;
                top:2px;
            }
        }
        .value{
            float: left;
            width: 240px;
            text-align: left;
            font-size: 14px;
            .value_text{
                line-height: 40px;
                img{
                    width: 12px;
                    height: 12px;
                    position: relative;
                    top: 2px;
                    left: 7px;
                }
            }
        }
     }
}
</style>
