<template>
    <div class="SelectWithdrawalAddress">
        <el-dialog
            v-model="dialogVisible"
            :title="$t('myAccount.text131')"
            width="540px"
            destroy-on-close
            @closed="beforeClose"
        >
        <ul>
            <li v-for="item in addressList" @click="chooseWithdrawalAddress(item)">
                <div class="logo">
                    <img 
                        :src="currentType == 'BTC'?'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_btc_yellow%402x.png':currentType == 'ETH'?'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_eth_blue%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_usdt_green%402x.png'"
                     >
                </div>
                <div class="address">
                    <div class="name">{{item.remark}}</div>
                    <div class="value">{{item.address}}</div>
                </div>
                <div class="choose">
                    <div class="radio" :class="item.address == nowAddress?'checked':''">

                    </div>
                </div>
            </li>
            <div class="empty" v-if="empty">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_no_data%402x.png" alt="">
                <div>{{$t('trade.text66')}}</div>
            </div>
        </ul>
            <template #footer>
            <span class="dialog-footer">
                <div class="empty_btn" v-if="empty">
                    <el-button class="cancel" @click="dialogVisible = false">{{$t('trade.text72')}}</el-button>
                    <el-button class="add_addres" type="primary" @click="AddAddress"
                    >{{$t('myAccount.text122')}}</el-button>
                </div>

                <div class="no_empty_btn" v-else>
                    <el-button class="add_addres" @click="AddAddress">{{$t('myAccount.text122')}}</el-button>
                    <el-button class="confirm" type="primary" @click="Confirm">{{$t('trade.text73')}}</el-button>
                </div>
            </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
 ref,inject,onBeforeUnmount
} from 'vue';
import apiService from '@/api/request';

export default {
    name:'SelectWithdrawalAddress',
       props:{
        currentType: { 
            type: String,
            default: '',
        },
        address: { 
            type: String,
            default: '',
        }
    },
    setup(props,ctx){
        const dialogVisible = ref(false);
        const addressList = ref([])
        const emitt = inject('emitt');
        const nowAddress = ref('');
        const empty = ref(false);
        
        /**
         * 打开弹框-开始
         */ 
          emitt.$sub('openSelectWithdrawalAddress', (val) => {
            getWalletAddress();
            nowAddress.value = props.address;
            dialogVisible.value = true;
          });
           onBeforeUnmount(() => {
            emitt.$unsub("openSelectWithdrawalAddress");
        });
        /**
         * 打开弹框-结束
         */ 
        // 获取提币地址
        function getWalletAddress() {
            const params = {
                currency: props.currentType,
            };
            apiService.WalletAddress(params).then((res) => {
                if (res.status === 1) {
                    if(res.data.listWalletAddress.length == 0){
                        empty.value = true;
                    }else{
                        empty.value = false;
                    }
                    addressList.value = res.data.listWalletAddress;
                }
            });
        }
        
        //选择提币地址
        function chooseWithdrawalAddress(e){
            nowAddress.value = e.address;
        }

        //关闭弹窗
        function beforeClose(){
            addressList.value = [];
            nowAddress.value = '';
        }

        //确认
        function Confirm(){
            ctx.emit('changeAddrees',nowAddress.value);
            dialogVisible.value = false;
        }

        //添加地址
        function AddAddress(){
            dialogVisible.value = false;
            emitt.$pub('openAddWithdrawalAddress2');
        }
        return {
            dialogVisible,
            addressList,
            chooseWithdrawalAddress,
            nowAddress,
            empty,
            beforeClose,
            Confirm,
            AddAddress
        }
    }
}
</script>
<style lang="less" scoped>
.SelectWithdrawalAddress{
    ul{
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            cursor: pointer;
        }
    }
    .logo{
        img{
            width: 32px;
            height: 32px;
        }
    }
    .address{
        width: 380px;
        .name{
            color: @text-color-secondary;
            font-size: 12px;
        }
        .value{
            font-size: 14px;
            margin-top: 4px;
        }
    }
    .choose{
        float: left;
    }
    .empty{
        width: 60px;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        color: @description-color;
        img{
            display: block;
            width: 48px;
            margin: 0 auto;
        }
    }
    .radio{
        width: 20px;
        height: 20px;
        border: 1px solid @disabled-color;
        border-radius: 50%;
    }
    .checked{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/icon_sel_yes%402x.png) no-repeat;
        background-size: 100% 100%;
        border: none;
    }
    .empty_btn{
        .cancel{
            width: 180px;
        }
        .add_addres{
            width: 280px;
        }
    }
    .no_empty_btn{
        .add_addres{
            width: 280px;
        }
        .confirm{
            width: 180px;
        }
    }
}
</style>
<style lang="less">
.SelectWithdrawalAddress{
    .el-dialog__footer{
        text-align: center !important;
    }
}
</style>
