<template>
  <div>
    <div class="forget-phone-pwd public-forget-pwd public-tab-container">
      <div class="phone-con input-row">
          <div class="value"><input type="email" v-model="emailData.emailVal" :placeholder="$t('signUp.text26')" /></div>
    </div>
    <div class="code-input input-row">
      <div class="value">
        <input class="value_code" v-model="codeData.codeVal" type="text" :placeholder="$t('signUp.text41')" />
      </div>
        <el-button class="send" link @click="sendCode">{{codeData.sendMessage}}</el-button>
    </div>
      <div class="pwd-input input-row" >
        <div class="value">
          <input :type="pwdData.isPasswordType?'text':'password'" v-model="pwdData.pwdVal"  :placeholder="$t('signUp.text37')"/>
        </div>
        <span class="password-icon">
          <img :src="pwdData.isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
          : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
          @click="pwdData.isPasswordType = !pwdData.isPasswordType;"
          class="pw-icon"
          >
        </span>
      </div>
      <div class="pwd-input input-row">
        <div class="value">
          <input :type="newPwdData.isPasswordType?'text':'password'" v-model="newPwdData.newPwdVal"  :placeholder="$t('signUp.text32')"/>
        </div>
        <span class="password-icon">
          <img :src="newPwdData.isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
          : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
          @click="newPwdData.isPasswordType = !newPwdData.isPasswordType;"
          class="pw-icon"
          >
        </span>
      </div>
    <div :class="ConfirmFlag?'':'btn-disabled'" class="confirm-btn" @click="Confirm">
      {{$t('signUp.text35')}}
    </div>
      <div class="btn-text-con">
        <button class="forget-pwd public-text-btn btn-text-right" @click="switchForget">{{$t('signUp.text38')}}</button>
      </div>
    </div>
    <div class="verify" v-show="verifyFlag">
      <div id="slide-verify2" ref="slideVer"></div>
    </div>
  </div>
</template>
<script>
import {
 reactive, ref, getCurrentInstance, inject,computed
} from 'vue';
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { assignParams } from '@/until/common';

import Verify from '@/until/verify';
import { useI18n } from "vue-i18n";

export default {
  name: 'ForgetPwd',
  components: {
    // SpecialInput,
  },
  setup() {
    const { ctx } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const emitt = inject('emitt');
    const { t } = useI18n()

    /**
     * 监听按钮可否点击状态
     * 开始
     */
     const ConfirmFlag = computed(() => {
          return emailData.emailVal!='' && codeData.codeVal !='' && pwdData.pwdVal !='' && newPwdData.newPwdVal !='';
    });
    /**
     * 监听按钮可否点击状态
     * 结束
     */

    function switchForget(){
      const jumpParams = {
        type: 'back',
      };
       emitt.$pub('switchForget', jumpParams);
    }

    function routeTo(name) {
      router.push(name);
    }

    // 邮箱
    const emailData = reactive({
      isEmailActive: false, // 是否激活
      emailVal: '', // 输入框的值
      isEmailErr: false, // 验证码的问题
    });
    


    // 检查邮箱
    function checkEmailPatern(value) {
      const cpVal = value.replace(/\s+/g, '');
      const myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,9}$/;
      return myreg.test(cpVal);
    }

    // 密码登录
    const pwdData = reactive({
      isPwdActive: false,
      isPasswordType: false,
      isPwdErr: false,
      pwdVal: '',
      errText: '',
    });

    // 验证码
    const codeData = reactive({
      sendMessage: t('signUp.text16'), // 验证码的文本
      isCodeActive: false, // 是否激活
      codeVal: '', // 输入框的值
      isVerticodeErr: false, // 验证码的问题
      errText: '',
    });
    
    let timer = null;
    const time = ref(60);
    const isSending = ref(false);
    const verifyFlag = ref(true);
    const slideVer = ref('');
    const sendCode = async () => {
      console.log('sendCode');

      
      if (isSending.value) {
        return;
      }
      
       if (emailData.emailVal == '') {
         ElMessage({
            message: t('signUp.text42'),
            type: 'error',
          })
          return
      }
      if(checkEmailPatern(emailData.emailVal)){
      }else{
         ElMessage({
            message: t('signUp.text43'),
            type: 'error',
          })
        return
      }
      await sendCodeFunc();
    };

    // 检查邮箱
    function checkEmailPatern(value) {
      const cpVal = value.replace(/\s+/g, '');
      const myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,9}$/;
      return myreg.test(cpVal);
    }
    // 发送验证码动作的逻辑
    async function sendCodeFunc() {
      // 发起请求
      if (timer) {
        return;
      }
      isSending.value = true;
      codeData.sendMessage = t('signUp.text18');
      let res;
        const params = {
          smsType: 28,
          email: window.btoa(emailData.emailVal),
        };
        res = await apiService.GetByEmail(params);
      if (res.status === 1) {
        codeData.sendMessage = `${time.value}S`;
        timer = setInterval(() => {
          codeData.sendMessage = `${time.value}S`;
          time.value -= 1;
          if (time.value <= 0) {
            isSending.value = false;
            clearInterval(timer);
            codeData.sendMessage = t('signUp.text17');
            time.value = 60;
            timer = null;
          }
        }, 1000);
      } else {
        ElMessage({
            message: res.message,
            type: 'error',
          })
        codeData.sendMessage = t('signUp.text16');
        isSending.value = false;
      }
    }

     // 再次确认密码
    const newPwdData = reactive({
      isNewPwdActive: false,
      isNewPasswordType: false,
      newPwdVal: '',
      isNewPwdErr: false,
    });
   
    let loginNow = false;
    function setPhonePwd() {
      if (!emailData.emailVal) {
        return;
      }
      if(checkEmailPatern(emailData.emailVal)){
      }else{
         ElMessage({
            message: t('signUp.text43'),
            type: 'error',
          })
        return
      }
      // 验证码是否填写
      if (!codeData.codeVal) {
        return;
      }
      // 密码框是否输入
      if (!pwdData.pwdVal) {
        return;
      }
      // 密码是否一致
      if (pwdData.pwdVal !== newPwdData.newPwdVal) {
        ElMessage({
            message: t('signUp.text39'),
            type: 'error',
          })
        return;
      }

       let mask = /^[\da-z]+$/i
      if(!mask.test(pwdData.pwdVal)){
        ElMessage({
              message: t('signUp.text37'),
              type: 'error',
            })
          return
      }


      const params = {
        account: window.btoa(emailData.emailVal),
        newPwd: window.btoa(newPwdData.newPwdVal),
        code: codeData.codeVal,
      };
      console.log("ResetPassword",params,emailData.emailVal,newPwdData.newPwdVal)
      loginNow = true
      apiService.ResetPassword(params).then(async (res) => {
        if (res.status === 1) {
          ElMessage({
            message: t('signUp.text40'),
            type: 'success',
          })
          store.commit('USERTOKEN', res.data.userToken);
          await store.dispatch('getUserInfo');
          await store.dispatch('getUserStatus');
          router.push('transaction');
        } else {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        }
        loginNow = false
      });
    }


    // 发布事件
    // 点击登录
    const jumpToLogin = () => {
      const jumpParams = {
        status: true,
      };
      emitt.$pub('switchLogin', jumpParams);
    };

    function Confirm() {
        setPhonePwd();
    }

    return {
      switchForget,
      jumpToLogin,
      Confirm,
      emailData,
      routeTo,
      ConfirmFlag,
      codeData,
      pwdData,
      newPwdData,
      sendCode,
      verifyFlag,
      slideVer,
    };
  },
};
</script>
<style lang="less" scoped>
.public-tab-container {
}
.input-row {
      margin: 0 auto;
      margin-top: 24px;/*no*/
      background: white;
      display: flex;
      justify-content: left;
      margin-bottom: 16px;/*no*/
      border-radius: 4px;/*no*/
      height: 48px;/*no*/
      position: relative;
      border: 1px solid #EBEBEB;/*no*/
      width: 400px;/*no*/
      .value{
        width: 100%;
      }
      .password-icon{
        position: absolute; 
        right: 16px;/*no*/
        top: 16px;/*no*/
        cursor: pointer;
      }
       .send{
         position: absolute;
         right: 10px;/*no*/
         top: 13px;/*no*/
         font-size: 14px;/*no*/
        }
      input{
        height: 46px;/*no*/
        line-height: 46px;/*no*/
        outline: none;
        padding-left: 10px;/*no*/
        border-radius: 4px;/*no*/
        width: 100%;
        font-size: 14px;/*no*/
      }
      .value_code{
        padding-left: 10px;/*no*/
      }
  }
.confirm-btn {
  width: 400px;/*no*/
    height: 48px;/*no*/
    margin: 0 auto;
    margin-top: 50px;/*no*/
    line-height: 48px;/*no*/
    background: #FB5B39;
    border-radius: 2px;/*no*/
    font-size: 14px;/*no*/
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    cursor: pointer;
}
.btn-con {
  padding-top: 16px;
  .cancel-btn {
    width: 192px;
    height: 48px;
    margin-right: 16px;
    background: @hover-color;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #FB5B39;
    line-height: 19px;
    cursor: pointer;
  }
  .confirm-btn {
    width: 192px;
    height: 48px;
    background: #FB5B39;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 19px;
    cursor: pointer;
  }
}
.btn-text-con {
    width: 400px;/*no*/
    position: relative;
    height: 20px;/*no*/
    margin: 0 auto;
    margin-top: 16px;/*no*/
    .public-text-btn {
      position: absolute;
      font-size: 14px;/*no*/
      font-family: MicrosoftYaHei;
      color: @primary-color;
      background: transparent;
      cursor: pointer;
    }
    .btn-text-right {
      right: 0;
    }
    .btn-text-left {
      left: 0;
    }
  }

.verify{
  width: 100%;
  position: fixed;
  // top: 1.8rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  #slide-verify2{
      width: 280px;
      margin: 0 auto;
      background: white;
      font-size: 0.14rem;
      .slider-container {
        background-color: #E7EAF3;
        .sliderMask {
          height: 38px;
          border-width: 1px;
        }
      }
  }
}
.slider-container {
  position: relative;
  text-align: center;
  width: 280px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
  background: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
}

.sliderContainer_active {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #1991FA;
  }
}

.sliderContainer_success {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #52CCBA;
    background-color: #52CCBA !important;
  }
}

.sliderContainer_success {
  .sliderMask {
    height: 38px;
    border: 1px solid #52CCBA;
    background-color: #D2F4EF;
  }
}

.sliderContainer_success {
  .sliderIcon {
    background-position: 0 0 !important;
  }
}

.sliderContainer_fail {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #f57a7a;
    background-color: #f57a7a !important;
  }
}

.sliderContainer_fail {
  .sliderMask {
    height: 38px;
    border: 1px solid #f57a7a;
    background-color: #fce1e1;
  }
}

.sliderContainer_fail {
  .sliderIcon {
    top: 14px;
    background-position: 0 -82px !important;
  }
}
.sliderContainer_active .sliderText,
.sliderContainer_success .sliderText,
.sliderContainer_fail .sliderText {
  display: none;
}

.sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991FA;
  background: #D1E9FE;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background .2s linear;
}

.slider:hover {
  background: #1991FA;
}

.slider:hover {
  .sliderIcon {
    background-position: 0 -13px;
  }
}

.sliderIcon {
  position: absolute;
  top: 15px;
  left: 13px;
  width: 14px;
  height: 12px;
  background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -26px;
  background-size: 34px 471px;
}

.refreshIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -437px;
  background-size: 34px 471px;
}
.bottom{
  padding-top: 0.12rem;
  padding-bottom: 0.22rem;
  background: linear-gradient(180deg, #FB5B39 0%, #FB5B39 100%);
  margin-top: 0.12rem;
  img{
    display: block;
    width: 2.78rem;
    margin:0 auto;
  }
}
.block {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
