<template>
    <div class="onlineBankTransfer">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('deposit.text38')}}</div>
                <div class="value">
                    <el-select v-model="payId" class="m-2" size="large" @change="payIdChange">
                        <el-option
                        v-for="item in channelArr"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('deposit.text39')}} (USD)</div>
                <div class="value">
                    <div><el-input v-model="amount" :placeholder="placeholder" size="large" @input="amountInput"/></div>
                    <div class="fast">
                            <div class="fast_choose" :class="amount == 1000?'active_choose':''" @click="quickSelect(1000)">1000</div>
                            <div class="fast_choose" :class="amount == 5000?'active_choose':''" @click="quickSelect(5000)">5000</div>
                            <div class="fast_choose" :class="amount == 7000?'active_choose':''" @click="quickSelect(7000)">7000</div>
                            <div class="fast_choose" :class="amount != 1000 && amount != 5000 && amount != 7000?'active_choose':''" @click="quickSelect('Other')">{{$t('deposit.text40')}}</div>
                    </div>
                </div>
            </div>
            <div v-show="payId == 6018">
                <div class="line">
                    <div class="label">{{$t('deposit.text41')}} (2%)</div>
                    <div class="value">
                        <div class="value_text">{{fee}} {{payCurrency}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="label">{{$t('deposit.text42')}}</div>
                    <div class="value">
                        <div class="value_text">{{paymentAmount}} {{payCurrency}}</div>
                    </div>
                </div>
            </div>
            <div  v-if="payId != 6024">
                <div class="line">
                    <div class="label">{{$t('deposit.text43')}}</div>
                    <div class="value">
                        <div class="value_text">{{exchangerate}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="label">{{$t('deposit.text42')}}</div>
                    <div class="value">
                        <div class="value_text">{{paymentAmount}} {{payCurrency}}</div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="line">
                    <div class="label">{{$t('deposit.text44')}}</div>
                    <div class="value">
                        <el-select v-model="countryId" class="m-2" size="large" @change="countryIdChange">
                            <el-option
                            v-for="item in countryArr"
                            :key="item.isocode"
                            :label="item.country"
                            :value="item.isocode"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="line">
                    <div class="label">{{$t('deposit.text45')}}</div>
                    <div class="value">
                        <el-select v-model="bankId" class="m-2" size="large">
                            <el-option
                            v-for="item in bankArr"
                            :key="item.uid"
                            :label="item.name"
                            :value="item.uid"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <el-button :disabled="amount == ''" :loading="btnLoading" class="confirm" type="primary" size="large" @click="Confirm">{{$t('deposit.text46')}}</el-button>
            <p class="arrayTips" v-html="arrayTips"></p>
        </div>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading } from 'element-plus'
import { assignParams, urlEncode,xzLocal} from '@/until/common';
import axios from 'axios';
import { ElMessage } from 'element-plus'
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name:'onlineBankTransfer',
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props){
        const route = useRoute(); 
        const { t } = useI18n()

        const channelArr = ref([]);
        const { payType } = toRefs(props);
        const store = useStore();
        const payId = ref('');
        const amount = ref('');
        const countryArr = ref('');
        const bankArr = ref('');
        const bankId = ref('');
        const countryId = ref('');
        const minDepositLimit = ref('');//最小入金
        const maxDepositLimit = ref('');//最大入金
        const payCurrency = ref('');//单位
        const feerate = ref('');//费率
        const fee = ref('');//手续费
        const paymentAmount = ref('');//支付金额
        const placeholder = ref('');
        const arrayTips = ref('');//提示文案
        const exchangerate = ref('');//汇率
        let payUrl = ''//入金地址
        const btnLoading = ref(false);
        let loading;

        console.log("payType",payType.value)

        openFullScreen();
       // 获取 payId

        apiService.PayInChannel({
            userToken: store.getters.userToken,
            payType: Number(payType.value),
            currencyCategoryId: Number(route.query.id),
        }).then((res) => {
            if (res.status === 1) {
                channelArr.value = res.data.list
                payId.value = res.data.list[0].payId;
                getPayInChannelPage(res.data.list[0].payId);

            }
            loading.close();
        });
        // 获取每个通道的详情数据
        async function getPayInChannelPage(payId) {
            const res = await apiService.PayInChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            maxDepositLimit.value = res.data.maxDepositLimit;
            minDepositLimit.value = res.data.minDepositLimit;
            payCurrency.value = res.data.payCurrency;
            if(minDepositLimit.value != '' && maxDepositLimit.value != ''){
                placeholder.value = t('deposit.text35') + minDepositLimit.value +'-'+ maxDepositLimit.value + 'USD';
            }else if(minDepositLimit.value == ''){
                placeholder.value = t('deposit.text36') + maxDepositLimit.value + 'USD';
            }else if(maxDepositLimit.value == ''){
                placeholder.value = t('deposit.text37') + minDepositLimit.value + 'USD';
            }
            amount.value = 1000;
            feerate.value = res.data.feerate;
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            countAmount();
            GetExchangeRate();
            if(payId == 6024){
                getFairPayCountry();
            }
            return res;
        }

        //计算手续费和支付金额
        function countAmount(){
            if(payId.value == 6018){
                fee.value = (amount.value * feerate.value).toFixed(2);
                paymentAmount.value = (Number(fee.value) + Number(amount.value)).toFixed(2);
            }else if(payId.value == 6028 || payId.value == 6030 || payId.value == 6031 || payId.value == 6032 || payId.value == 7028 || payId.value == 6033){
                paymentAmount.value = (exchangerate.value * amount.value).toFixed(2);
            }
            console.log("Payment amount",paymentAmount.value)
        }

        //切换通道
        function payIdChange(e){
            console.log(e)
            openFullScreen();
            getPayInChannelPage(e)
        }


        //获取汇率
        function GetExchangeRate(){
            if(payId.value == 6028 || payId.value == 6030 || payId.value == 6031 || payId.value == 6032 || payId.value == 7028 || payId.value == 6033){
                apiService.GetExchangeRate({
                    bsType:1,
                    symbol:'USD/'+payCurrency.value
                }).then(res => {
                    exchangerate.value = res.data.exchangeRates[0].exchangerate;
                    paymentAmount.value = (exchangerate.value * amount.value).toFixed(2);
                    loading.close();
                }).catch(err => {
                    loading.close();
                })
            }else{
                loading.close();
            }
        }

        //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

        
        async function getFairPayCountry(){
            console.log("getFairPayCountry")
            const countryRes = await apiService.FairPayCountry({});
            countryArr.value = countryRes.data.country;
            countryId.value = countryRes.data.country[0].isocode
            const bankRes =  await apiService.FairPayMethod({isocode:countryRes.data.country[0].isocode});
            bankArr.value = bankRes.data.paymethod;
            bankId.value = bankRes.data.paymethod[0].uid;
        }

        //切换国家
        function countryIdChange(e){
            openFullScreen();
            apiService.FairPayMethod({isocode:e}).then(res => {
                bankArr.value = res.data.paymethod;
                bankId.value = res.data.paymethod[0].uid;
                loading.close();
            });
        }

        //格式化入金金额
        function amountInput(v){
            // 输入的数据进行初始化，将非数字的替换为空
            const  value = v
            if(value == ''){
                    amount.value = ""
                    countAmount();
                    return
                }
            let reg=new RegExp(/\d+(\.\d{0,2})?/,'g')

            let val = value.match(reg)?value.match(reg)[0]:""
            
            if (maxDepositLimit.value != '' &&  Number(val) > Number(maxDepositLimit.value)) {
                val = maxDepositLimit.value;
            }
                // 重新赋值
                nextTick(() => {
                    amount.value = val;
                    countAmount();
                });
        }


        function quickSelect(e){
            if(e == 'Other'){
                amount.value = '';
            }else{
                amount.value = e;
            }
            countAmount();
        }

        //确定
        function Confirm(){
            if(Number(amount.value) < Number(minDepositLimit.value)){
                ElMessage.error(`${t('deposit.text25')}${minDepositLimit.value}USD`)
                return;
            }
            let returnurl =  window.location.href
            const userToken = xzLocal.get('userToken') ? xzLocal.get('userToken') : null;
            let params;

            if(payId.value == 6018 || payId.value == 6032){
               params = assignParams({
                    userToken,
                    payAmount:amount.value,
                    paymentAmount:paymentAmount.value,
                    payid:payId.value,
                    fundterminal:1,
                    returnurl,
                 })
            }else if(payId.value == 6028 || payId.value == 7028){
                let failurl = returnurl;
                 params = assignParams({
                    userToken,
                    amount:amount.value,
                    payid:payId.value,
                    fundterminal:1,
                    successurl:returnurl,
                    failurl:failurl,
                    currency:payCurrency.value,
                 })
                
            }else if(payId.value == 6030 || payId.value == 6033){
                 params = assignParams({
                    userToken,
                    amount:amount.value,
                    payid:payId.value,
                    fundterminal:1,
                    currency:payCurrency.value,
                    returnurl
                 })
            }else if(payId.value == 6031){
                 params = assignParams({
                    userToken,
                    payAmount:amount.value,
                    payid:payId.value,
                    fundterminal:1,
                    returnUrl:returnurl,
                    currency:payCurrency.value,
                 })
            }else if(payId.value == 6032){
                 params = assignParams({
                    userToken,
                    payAmount:amount.value,
                    payid:payId.value,
                    fundterminal:1,
                    returnUrl:returnurl,
                    currency:payCurrency.value,
                 })
            }
            else if(payId.value == 6024){
                 params = assignParams({
                    userToken,
                    amount:amount.value,
                    payid:payId.value,
                    fundterminal:1,
                    returnurl:returnurl,
                    cancelurl:returnurl,
                    currency:payCurrency.value,
                    uid:bankId.value,
                    isocode:countryId.value
                 })
            }
            btnLoading.value = true;
            axios({
                method:"GET",
                url:payUrl,
                params:params
            }).then(response=>{
                    console.log(response)

                if(response.data.status == 1){
                    btnLoading.value = false;
                    window.open(response.data.data.applyUrl)
                }else{
                    ElMessage.error(response.data.message)
                }
                btnLoading.value = false;
                
            }).catch(err => {
                btnLoading.value = false;
            })
        }
        return {
            channelArr,
            payId,
            payIdChange,
            amount,
            countryArr,
            bankArr,
            countryId,
            bankId,
            countryIdChange,
            amountInput,
            minDepositLimit,
            maxDepositLimit,
            payCurrency,
            feerate,
            fee,
            paymentAmount,
            quickSelect,
            placeholder,
            arrayTips,
            Confirm,
            btnLoading,
            exchangerate
        }
    }
}
</script>
<style lang="less" scoped>
.onlineBankTransfer{
    .line_box{
        padding: 24px 30px;
        .line{
            overflow: hidden;
            margin-bottom: 16px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 212px;
                text-align: left;
                font-size: 14px;
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                }
            }
            .fast{
                margin-top: 10px;
                display: flex;
                justify-content: left;
                .fast_choose{
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: 1px solid @border-color-base;
                    text-align: center;
                    color: @text-color-secondary;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .active_choose{
                    border-color:@primary-color;
                    color: @primary-color;
                }
            }
        }
        .confirm{
            margin-top: 20px;
        }
    }
    .arrayTips{
        margin-top: 28px;
        text-align: left;
    }
}
</style>
<style lang="less">
.onlineBankTransfer{
    .el-select{
        width:100%;
    }
    .el-button{
        width:100%;
    }
}
</style>
