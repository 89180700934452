<template>
    <div class="USDTDeposit">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('deposit.text38')}}</div>
                <div class="value">
                    <el-select v-model="payId" class="m-2"  size="large" @change="payIdChange">
                        <el-option
                        v-for="item in channelArr"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text48')}}</div>
                <div class="value">
                    <div class="value_text">1USD≈{{payCurrencyExchangeRate}}{{payCurrency}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text39')}}(USD)</div>
                <div class="value">
                    <el-input class="amount_input" size="large" v-model="amount" :placeholder="placeholder" @input="buyNumberAmountValue">
                    <template #prefix>
                        <div class="fast_btn_l cursor" @click="addAmount(-10)">-10</div>
                        </template>
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="addAmount(+10)">+10</div>
                        </template>
                    </el-input>
                    <div class="fast" v-if="ostatus != 2">
                        <div class="fast_choose" :class="amount == 1000?'active_choose':''" @click="quickSelect(1000)">1000</div>
                        <div class="fast_choose" :class="amount == 5000?'active_choose':''" @click="quickSelect(5000)">5000</div>
                        <div class="fast_choose" :class="amount == 7000?'active_choose':''" @click="quickSelect(7000)">7000</div>
                        <div class="fast_choose" :class="amount != 1000 && amount != 5000 && amount != 7000?'active_choose':''" @click="quickSelect('Other')">{{$t('deposit.text40')}}</div>
                    </div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text31')}} ({{legalCurrency}})</div>
                <div class="value">
                    <el-input class="amount_input" size="large" v-model="payMentAmount" :placeholder ="$t('deposit.text32')" @input="buyNumberPaymentValue">
                    <template #prefix>
                        <div class="fast_btn_l cursor" @click="addPayment(-100)">-100</div>
                        </template>
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="addPayment(+100)">+100</div>
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text49')}}</div>
                <div class="value">
                    <div class="value_text">{{payMentAmount == '' || amount=='' ? 0:quantity}} {{payCurrency}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('deposit.text50')}}</div>
                <div class="value">
                    <div class="value_text">{{userInfo.name}}</div>
                </div>
            </div>
            <p class="warning">{{$t('deposit.text51')}}</p>
            <el-button :disabled="amount == '' && payMentAmount == ''" :loading="btnLoading" class="confirm" type="primary" size="large" @click="Confirm">{{$t('deposit.text46')}}</el-button>
            <p class="please_note" v-html="arrayTips"></p>
        </div>
        <div class="incompleteOrders">
             <div class="title">
                <div class="hr"></div>
                <div class="title_box">{{$t('deposit.text52')}}</div>
            </div>
            <div class="content_box" v-if='OTCPayingOrderObj.orderId != 0'>
                <p class="time_hint" v-show="OTCPayingOrderObj.status === 0"><span>*</span>{{$t('deposit.text53')}} <span>{{validTime}}</span>{{$t('deposit.text54')}}</p>
                <p class="time_hint" v-show="OTCPayingOrderObj.status === 2"><span>{{$t('deposit.text55')}}</span></p>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('deposit.text56')}}</div>
                        <div class="value primary">{{OTCPayingOrderObj.statusDesc}}</div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('deposit.text57')}}</div>
                        <div class="value">
                             <el-radio-group v-model="payWay" class="ml-4" @change="payingWayChange">
                                <el-radio v-for="item of OTCPayingOrderObj.receivables" :key="item.num" :value="item.mode" size="large">{{item.mode}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('deposit.text58')}}</div>
                        <div class="value">{{OTCPayingOrderObj.usdAmount}}</div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('deposit.text59')}}</div>
                        <div class="value">
                             <div class="num">{{receiveNum}}</div>
                             <div class="copy" @click="copyWith(receiveNum)">{{$t('deposit.text60')}}</div>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('deposit.text49')}}</div>
                        <div class="value">{{OTCPayingOrderObj.coinAmount}}</div>
                    </div>
                    <div class="line_right">
                        <div class="lable">{{$t('deposit.text61')}}</div>
                        <div class="value">
                             <div class="num">{{receiveName}}</div>
                             <div class="copy" @click="copyWith(receiveName)">{{$t('deposit.text60')}}</div>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('deposit.text42')}}</div>
                        <div class="value">{{OTCPayingOrderObj.cnyAmount}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="line_left">
                        <div class="lable">{{$t('deposit.text62')}}</div>
                        <div class="value">{{OTCPayingOrderObj.orderTime}}</div>
                    </div>
                </div>
                <div class="btn_box" v-if="OTCPayingOrderObj.status == 0 && OTCPayingOrderObj.orderId != 0">
                    <div class="cancel" @click="Cancel">{{$t('deposit.text63')}}</div>
                    <div class="confirm" @click="Confirme">{{$t('deposit.text64')}}</div>
                </div>
            </div>
            <div class="empty" v-else>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_no_data%402x.png" alt="">
                <p>{{$t('trade.text66')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick,computed
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import { assignParams, urlEncode,xzLocal} from '@/until/common';
import axios from 'axios';
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";


export default {
    name:'USDTDeposit',
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props){
        const { t } = useI18n()
        const store = useStore();
        const userInfo = computed({ get: () => store.getters.userInfo });
        const route = useRoute(); 
        const ostatus = computed({
            get: () => store.state.user.userInfo.ostatus,
        });


        const payId = ref('');//支付id
        const minDepositLimit = ref('');//最小入金
        const maxDepositLimit = ref('');//最大入金
        const payCurrency = ref('');//单位
        const feerate = ref('');//费率
        const placeholder = ref('');
        const arrayTips = ref('');//提示文案
        const legalCurrencyExchangeRate = ref('');//支付币种汇率
        const payCurrencyExchangeRate = ref('');//入金币种汇率
        const channelArr = ref([]);
        const amount = ref(null);//入金金额
        if(store.state.user.userInfo.ostatus != 2){
            amount.value = 1000
        }
        let payUrl = ''//入金地址
        const legalCurrency = ref('');//预计支付金额币种
        const payMentAmount = ref('');//支付金额
        const quantity = ref();//购买数量
        const btnLoading = ref(false);
        const OTCPayingOrderObj = ref({orderId:0});
        let loading;

        //未完成订单
        const payWay = ref('');//支付方式
        const receiveNum = ref('');//收款账号
        const receiveName = ref('');//收款人
        const validTime = ref('');

        openFullScreen();
        // 获取 payId
        apiService.PayInChannel({
            userToken: store.getters.userToken,
            payType: Number(props.payType),
            currencyCategoryId: Number(route.query.id),
        }).then(async (res) => {
            if (res.status === 1) {
                channelArr.value = res.data.list
                payId.value = res.data.list[0].payId;
                getPayInChannelPage(res.data.list[0].payId);
                OTCPayingOrder();
            }
            loading.close();
        });

        //切换通道
        function payIdChange(e){
            openFullScreen();
            getPayInChannelPage(e)
            OTCPayingOrder();
            
        }

         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

        // 获取每个通道的详情数据
        async function getPayInChannelPage(payId) {
            const res = await apiService.PayInChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            if(res.status != 1) return;
            maxDepositLimit.value = res.data.maxDepositLimit;
            minDepositLimit.value = res.data.minDepositLimit;
            payCurrency.value = res.data.payCurrency;
            legalCurrency.value = res.data.legalCurrency;

             if(minDepositLimit.value != '' && maxDepositLimit.value != ''){
                placeholder.value = t('deposit.text35') + minDepositLimit.value +'-'+ maxDepositLimit.value + "USD";
            }else if(minDepositLimit.value == ''){
                placeholder.value = t('deposit.text36') + maxDepositLimit.value + "USD";
            }else if(maxDepositLimit.value == ''){
                placeholder.value = t('deposit.text37') + minDepositLimit.value + "USD";
            }
            feerate.value = res.data.feerate;
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            
            for(let item of res.data.exchangeRateInfo){
                if('USD/'+payCurrency.value == item.currencyPair){
                        payCurrencyExchangeRate.value = item.exchangeRate;
                }else if('USD/'+(legalCurrency.value == 'CNY'?'CNH':legalCurrency.value) == item.currencyPair){
                    legalCurrencyExchangeRate.value = item.exchangeRate;
                }
                if(payCurrency.value == 'USD'){
                    payCurrencyExchangeRate.value = 1
                }
            }
             if(store.state.user.userInfo.ostatus != 2){
                countPayment();
                countAmount();
            }
            
            loading.close();
            console.log(payCurrencyExchangeRate.value,legalCurrencyExchangeRate.value)
            return res;
        }

        
        // 限制属于框属于非法字符
        // 入金金额 
        function buyNumberPaymentValue(v) {
            // 输入的数据进行初始化，将非数字的替换为空
            const  value = v;
            if(value == ''){
                amount.value = ""
                return
            }
            let reg=new RegExp(/\d+(\.\d{0,2})?/,'g')

            const val = value.match(reg)?value.match(reg)[0]:""
            
            // 重新赋值
            nextTick(() => {
                payMentAmount.value = val;
                countAmount();
            });
        }

        function addAmount(num){
            amount.value = Number(amount.value) + num;
            countPayment();
        }

        function addPayment(num){
            payMentAmount.value = Number(payMentAmount.value) + num;
            countAmount();
        }

         // 计算支付金额和购买数量
        function countPayment(num) {
            quantity.value = Number((amount.value * payCurrencyExchangeRate.value).toFixed(2));
            payMentAmount.value = Number((amount.value * legalCurrencyExchangeRate.value).toFixed(2));
        }
        //计算入金金额
        function countAmount(num){
            amount.value = Number((payMentAmount.value / legalCurrencyExchangeRate.value).toFixed(2))
            quantity.value = Number((amount.value * payCurrencyExchangeRate.value).toFixed(2));
        }

        function quickSelect(e){
            if(e == 'Other'){
                amount.value = '';
            }else{
                amount.value = e;
            }
            countPayment();
        }

        // 支付金额
        function buyNumberAmountValue(v) {
            const  value = v;
            if(value == ''){
                    payMentAmount.value = ""
                    return
                }

            let precent = 2;
                
            let reg=new RegExp(`\\d+(\\.\\d{0,${precent}})?`,'g')
            const val = value.match(reg)?value.match(reg)[0]:""

            // 输入的数据进行初始化，将非数字的替换为空
            // /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g
        
            // 重新赋值
            nextTick(() => {
                amount.value = val;
                countPayment();
            });
        }


        let validTimeVlaue = 0;
        let timer;
        function OTCPayingOrder(){
            apiService.OTCPayingOrder({payId:payId.value}).then(res => {
                OTCPayingOrderObj.value = res.data;
                if(res.data.orderId == 0 || res.data.orderId == null){
                    
                }else{
                    receiveNum.value = res.data.receivables[0].num;
                    receiveName.value = res.data.receivables[0].name;
                    payWay.value = res.data.receivables[0].mode;
                    if(res.data.status === 0 && res.data.orderId != 0){
                        validTimeVlaue = res.data.validTime;
                            timer = setInterval(() => {
                            validTimeVlaue -= 1;
                            validTime.value = formatTime(validTimeVlaue);
                            if (validTimeVlaue <= 0) {
                                clearInterval(timer);
                                window.location.reload();
                            }
                        }, 1000);
                    }
                }
            })
        }
         // 秒转换成分
        function formatTime(seconds) {
        return `${[
            parseInt(seconds / 60),
            parseInt(seconds % 60),
        ]
        .join(t('deposit.text71'))
        .replace(/\b(\d)\b/g, '0$1')
        }${t('deposit.text72')}`;
        }
        // 切换支付方式
        function payingWayChange(mode) {
            OTCPayingOrderObj.value.receivables.forEach((item) => {
                if (item.mode === mode) {
                    receiveNum.value = item.num;
                    receiveName.value = item.name;
                }
            });
        }

         //确定
        function Confirm(){
            if(maxDepositLimit.value == ''){
                if(Number(amount.value) < Number(minDepositLimit.value)){
                    ElMessage.error(`${t('deposit.text37')}${minDepositLimit.value}USD`)
                    return;
                }
            }else{
                if(Number(amount.value) < Number(minDepositLimit.value) || Number(amount.value) > Number(maxDepositLimit.value)){
                    ElMessage.error(`${t('deposit.text35')}${minDepositLimit.value}~${maxDepositLimit.value}USD`)
                    return;
                }
            }
            
             apiService.payment(payUrl, {
                    successUrl: window.location.href,
                    failUrl: window.location.href,
                    fundTerminal: 1,
                    factAmount: 1, // 不重要但必传的后台参数
                    payId: payId.value,
                    usertoken: store.getters.userToken,
                    payAmount: amount.value,
                }).then((res) => {
                    if (res.status === 1) {
                        OTCPayingOrder();
                        window.open(res.data.applyUrl, '_blank');
                    }else{
                        if(res.status == 600){
                            ElMessageBox.confirm( t('deposit.text65'),{
                                confirmButtonText: t('deposit.text66'),
                                cancelButtonText: t('deposit.text67'),
                            })
                            .then(() => {
                                window.open(res.data.applyUrl, '_blank');
                            })
                            .catch(() => {
                            })
                        }else{
                             ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    }
                })
            }

        // 复制
        function copyWith(text) {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', text);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            ElMessage({
                message: t('deposit.text24'),
                type: 'success',
            });
        }

        //取消订单
        function Cancel(){
            ElMessageBox.confirm( t('deposit.text68'),{
                confirmButtonText: t('deposit.text46'),
                cancelButtonText: t('deposit.text67'),
            })
            .then(() => {
                    openFullScreen();
                    console.log("OTCPayingOrderObj",OTCPayingOrderObj)
                    const params = {
                        payId:OTCPayingOrderObj.value.payId,
                        orderId: OTCPayingOrderObj.value.orderId,
                        logId: OTCPayingOrderObj.value.logId,
                    };
                    apiService.OTCOrderCancel(params).then((res) => {
                        loading.close();
                        if (res.status == 1) {
                            OTCPayingOrder();
                            OTCPayingOrderObj.orderId = 0;
                            ElMessage({
                                message: t('deposit.text69'),
                                type: 'success',
                            });
                        }else{
                             ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    });
                })
            .catch(() => {
            })
            
        }

        //确认订单
        function Confirme(){
             ElMessageBox.confirm( t('deposit.text70'),{
                confirmButtonText: t('deposit.text46'),
                cancelButtonText: t('deposit.text67'),
            })
            .then(() => {
                openFullScreen();
                    console.log("OTCPayingOrderObj",OTCPayingOrderObj)
                const params = {
                        payId:OTCPayingOrderObj.value.payId,
                        orderId: OTCPayingOrderObj.value.orderId,
                        logId: OTCPayingOrderObj.value.logId,
                    };
                    apiService.OTCOrderConfirm(params).then((res) => {
                        loading.close();
                        console.log(res);
                        if (res.status == 1) {
                            OTCPayingOrder();
                        }else{
                            ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    });
                })
            .catch(() => {
            })
        }
        return {
            Cancel,
            Confirme,
            payId,
            minDepositLimit,
            maxDepositLimit,
            payCurrency,
            feerate,
            arrayTips,
            payCurrencyExchangeRate,
            channelArr,
            payIdChange,
            amount,
            placeholder,
            payMentAmount,
            buyNumberPaymentValue,
            buyNumberAmountValue,
            quickSelect,
            countAmount,
            quantity,
            addAmount,
            addPayment,
            userInfo,
            btnLoading,
            Confirm,
            legalCurrency,
            OTCPayingOrderObj,
            payWay,
            receiveNum,
            receiveName,
            copyWith,
            payingWayChange,
            validTime,
            ostatus
        }
    }
}
</script>
<style lang="less" scoped>
.USDTDeposit{
    .line_box{
         padding: 24px 30px;
        .line{
            overflow: hidden;
            margin-bottom: 30px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 250px;
                text-align: left;
                font-size: 14px;
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                    width: 500px;
                }
                .fast_btn_l{
                    border-right:  1px solid @border-color-base;/*no*/
                    width: 60px;
                    line-height: 40px;
                    color: @primary-color;
                }
                .fast_btn_r{
                    border-left:  1px solid @border-color-base;/*no*/
                    width: 60px;
                    line-height: 40px;
                    color: @primary-color;
                }
            }
            .fast{
                margin-top: 10px;
                display: flex;
                justify-content: left;
                .fast_choose{
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: 1px solid @border-color-base;
                    text-align: center;
                    color: @text-color-secondary;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .active_choose{
                    border-color:@primary-color;
                    color: @primary-color;
                }
            }
        }
        .warning{
            color: #EF383C;
            font-size: 12px;
            text-align: left;
        }
        .please_note{
            font-size: 12px;
            color: #666666;
            text-align: left;
            margin-top: 20px;
        }
        .confirm{
            margin-top: 20px;
        }
    }
    .incompleteOrders{
         padding: 0 20px;
        .title{
            font-size: 14px;
            display: flex;
            justify-content: left;
            height: 30px;
            .hr{
                width: 4px;
                height: 30px;
                background: @primary-color;
            }
            div{
                font-weight: 500;
            }
            .title_box{
                background: #F7F8FA;
                line-height: 30px;
                width: 100%;
                text-align: left;
                padding-left: 14px;
            }
        }
        .content_box{
            padding: 0 14px;
            padding-bottom: 60px;
            .time_hint{
                font-size: 14px;
                text-align: left;
                margin-top: 16px;
                margin-bottom: 20px;
                span{
                    color: #EF383C;
                }
            }
            .line{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                text-align: left;
                margin-bottom: 16px;
                .line_left{
                    width: 50%;
                    display: flex;
                    justify-content: left;
                }
                .line_right{
                    width: 50%;
                    display: flex;
                    justify-content: left;
                }
                .lable{
                    color: #666666;
                    width: 167px;
                }
                .value{
                    width: 253px;
                    display: flex;
                    justify-content: space-between;
                }
                .primary{
                    color:#FB741B; 
                }
                .copy{
                    color: #FB741B;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .btn_box{
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                div{
                    width: 420px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    border-radius: 4px;
                    font-weight: 500;
                    cursor: pointer;
                }
                .cancel{
                    background: #EBEBEB;
                }
                .confirm{
                    background: #FB741B;
                    color: white;
                }
            }
        }
        .empty{
            padding-top: 40px;
            img{
                width: 48px;
                height: 50px;
            }
            p{
                font-size: 12px;
                color:#999999; 
            }
        }
    }
}
</style>
<style lang="less">
.USDTDeposit{
    .el-select{
        width:100%;
    }
    .el-button{
        width:100%;
    }
    .amount_input{
        .el-input__inner{
            padding:0 60px !important;
            text-align: center;
        }
        .el-input__prefix{
            left: 0;
        }
        .el-input__suffix{
            right: 0;
        }
    }
    .el-radio.el-radio--large{
        height: auto !important;
    }
}
</style>
