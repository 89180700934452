import { createI18n } from 'vue-i18n';
import en from './en.js';
import cn from './cn.js';

import store from '@/store';
const lang = store.getters.getNowTheme.lang;


// 准备翻译的语言环境信息
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: lang.indexOf('zh') != -1 || lang.indexOf('cn') != -1?'cn':'en' ,//这个就代表了你当前的国际化语言
    messages: {
        en,
        cn
    },
  });
  

export default i18n