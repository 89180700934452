<template>
    <div class="Deposit">
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane v-for="item in payTypeList" :label="item.name" :name="item.name">
                <component :is="item.payType==2?OnlineBankTransfer:item.payType==3?BankWireTransfer:item.payType==4?BlockChain:item.payType==1?USDTDeposit:item.payType==5?ScanningCode:item.payType==7?CreditCard:''" :payType="item.payType" v-if="item.name == activeName"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
 ref,computed
} from 'vue';
import apiService from '@/api/request';
import OnlineBankTransfer from './components/OnlineBankTransfer'
import BankWireTransfer from './components/BankWireTransfer'
import BlockChain from './components/BlockChain'
import USDTDeposit from './components/USDTDeposit'
import ScanningCode from './components/ScanningCode'
import CreditCard from './components/CreditCard'
import { useStore } from 'vuex';
import { useRoute } from "vue-router"

export default {
    name:'Deposit',
    components:{
        OnlineBankTransfer,
        BankWireTransfer,
        BlockChain,
        USDTDeposit,
        ScanningCode,
        CreditCard
    },
    setup(){
        const store = useStore();
        const route = useRoute(); 

        const payTypeList = ref([]);//通道列表
        const onlineFlag = ref(false);//是否有网银入金
        const activeName = ref('');

        GetPayType();
        // 获取入金通道大分类
        function GetPayType(){
            apiService.GetPayType({currencyCategoryId:route.query.id}).then(res => {
                payTypeList.value = res.data.list
                activeName.value = res.data.list[0].name;
                console.log("GetPayType",res)
            })
        }

        
        return {
            activeName,
            GetPayType,
            payTypeList,
            OnlineBankTransfer,
            BankWireTransfer,
            BlockChain,
            USDTDeposit,
            ScanningCode,
            CreditCard
        }
    }
}
</script>
<style lang="less" scoped>
.Deposit{
    .el-tabs__nav{
        margin: 0 auto;
    }
    .heading{
            padding: 12px 30px;
            font-size: 18px;
            display: flex;
            justify-content: left;
            border-bottom: 1px solid @border-color-base;/*no*/
        img{
            display: block;
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-right: 10px;
        }
        .title{
            font-weight: 500;
        }
    }
    .currency_list{
        padding: 20px 30px;
        p{
            color: #FB741B;
            font-size: 12px;
            text-align: left;
        }
        ul{
            margin-top: 30px;
            display: flex;
            justify-content: left;
            width: 840px;
            flex-wrap: wrap;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 400px;
                height: 56px;
                border: 2px solid #E9E9E9;
                padding: 10px 16px;
                border-radius: 10px;
                margin-bottom: 30px;
                &:nth-child(odd){
                    margin-right: 40px;
                }
                cursor: pointer;
                .li_left{
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    img{
                        width: 32px;
                        height: 32px;
                    }
                    .name{
                        font-size: 16px;
                        margin-left: 16px;
                    }
                }
                .iconfont{
                    font-size: 12px;
                    color:#BEC2CA;
                }
            }
        }
    }
}
</style>
<style lang="less">
.Deposit{
    .el-tabs__nav{
        float: none;
    }
    .el-tabs__item{
        padding-right:0 !important;
        padding-left:0 !important;
    }
}
</style>
