<template>
    <div class="Promotion">
        <div class="banner">
            <h2>All promotions</h2>
        </div>
        <ul>
            <li @click="gotoPage('Membership')"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/promotion_all%20banner_001%402x.png" alt=""></li>
            <li @click="gotoPage('ReferralBonus')"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/promotion_all%20banner_002%402x.png" alt=""></li>
            <li @click="gotoPage('LuckSpin')"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/promotion_all%20banner_003%402x.png" alt=""></li>
            <li @click="gotoPage('DepositBonus')"><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/promotion_all%20banner_004%402x.png" alt=""></li>
        </ul>
    </div>
</template>
<script>
import {
} from 'vue';
import { useRouter } from "vue-router"
export default {
    name:"Promotion",
    props: {
    
    },
    components:{
    },
    setup() {
        const router = useRouter(); 


        function gotoPage(e){
            router.push(e)
        }
        return {
            gotoPage
        }
    },
    
}
</script>
<style lang="less" scoped>
.Promotion{
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_banner%402x.png) no-repeat;
        background-size: 100% 100%;
        height: 340px;/*no*/
        line-height: 340px;/*no*/
    }
    h2{
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: white;
    }
    ul{
        width: 1200px;
        margin: 0 auto;
        padding: 1px 0 80px 0;
        li{
            width: 100%;
            height: 400px;
            margin-top: 46px;/*no*/
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
