<template>
    <div class="FundsDetailsLiveAccount">
        <div class="search_date">
                <span class="search_title">
                    {{$t('AccountAssets.text66')}}
                </span>
                <div class="screening_picker">
                      <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                            :start-placeholder="$t('AccountAssets.text17')"
                            :end-placeholder="$t('AccountAssets.text18')"
                            size='large'
                            format="MM/DD/YYYY"
                            value-format="YYYY-MM-DD"
                        />
                </div>
            <el-button type="primary" @click="search" class="search_btn" size="large">{{$t('AccountAssets.text19')}}</el-button>
        </div>
        <div class="statistics_title">
            <div class="hr"></div>
            <div>{{$t('AccountAssets.text20')}}</div>
        </div>
        <div class="statistics_data">
            <div class="statistics_data_box">
                <div class="value">{{sumData.deposit}}</div>
                <div class="title">{{$t('AccountAssets.text12')}}</div>
            </div>
            <div class="statistics_data_box">
                <div class="value">{{sumData.withdrawl}}</div>
                <div class="title">{{$t('AccountAssets.text13')}}</div>
            </div>
            <div class="statistics_data_box">
                <div class="value">{{sumData.bonus}}</div>
                <div class="title">{{$t('AccountAssets.text67')}}</div>
            </div>
            <div class="statistics_data_box" :class="Number(sumData.transfer)>0?'up':'down'">
                <div class="value">{{sumData.transfer}}</div>
                <div class="title">{{$t('AccountAssets.text68')}}</div>
            </div>
        </div>
        <div class="statistics_title">
            <div class="hr"></div>
            <div>{{$t('myAccount.text6')}}</div>
        </div>
        <div class="table_box">
            <el-table v-loading="tloading" :data="tableData" style="width: 100%" :empty-text="$t('trade.text66')">
                <el-table-column prop="bizTime" :label="$t('AccountAssets.text66')" width="240" />
                <el-table-column prop="amount" :label="$t('AccountAssets.text69')" width="164" />
                <el-table-column prop="canUsedAmount" width="190" :label="$t('trade.text109')" />
                <el-table-column prop="bizName" :label="$t('trade.text116')" width="320"/>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination :page-size="10" layout="prev, pager, next"  :total="totalPage" @current-change="handleCurrentChange"/>
        </div>
    </div>
</template>
<script>
import {
 ref,reactive
} from 'vue';
import apiService from '@/api/request';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
export default {
    name:'FundsDetailsLiveAccount',
    setup(){
        const searchDate = ref('');
        const tableData = ref([]);
        const sumData = reactive({});
        const pageSize = ref(10);
        const tloading = ref(false);
        const totalPage = ref(1);
        const nowPage = ref(1);//当前页

        search();
        //获取流水
       

        function search(){
             let params = {
                usertoken:localStorage.userToken,
                startTime:searchDate.value?searchDate.value[0]:'',
                endTime:searchDate.value?searchDate.value[1]:'',
                pageIndex:nowPage.value,
                pageSize:pageSize.value
            }
            tloading.value = true;
             apiService.GetFundFlow(params).then(res => {
                if(res.status == 1){
                    tableData.value = res.data.list;
                    Object.assign(sumData,res.data.sum)
                    totalPage.value = res.data.count
                }
                tloading.value = false;
            })
        }


        //切换页面
        function handleCurrentChange(e){
            console.log(e)
            nowPage.value = e;
            search();
        }
            return {
                searchDate,
                search,
                tableData,
                sumData,
                pageSize,
                tloading,
                totalPage,
                handleCurrentChange,
            }
        }
}
</script>
<style lang="less" scoped>
.FundsDetailsLiveAccount{
    padding: 0 20px;
    padding-top: 9px;
     .search_title{
            margin-right: 20px;
            font-size: 14px;
            color: @text-color-secondary;
            line-height: 40px;
        }
        .search_date{
            display: flex;
            justify-content: left;
        }
        .screening_picker{
            width: 390px;
        }
        .hr{
            color:@text-color-secondary;
            line-height: 40px;
            margin: 0 8px;
            font-size: 14px;
        }
        .search_btn{
            width: 80px;
            margin-left: 20px;
        }
        .statistics_title{
            font-size: 18px;
            display: flex;
            justify-content: left;
            margin-top: 30px;
            .hr{
                width: 4px;
                height: 20px;
                background: @primary-color;
                margin-right: 10px;
            }
            div{
                font-weight: 500;
            }
        }
        .statistics_data{
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            .statistics_data_box{
                width: 220px;
                height: 80px;
                padding: 16px 0 12px 0;
                background: @bg-color;
                border-radius: 4px;
                .value{
                    font-weight: 500;
                    font-size: 20px;
                }
                .title{
                    font-size: 14px;
                    color: @text-color-secondary;
                }
            }
        }
        .pagination{
            margin-top: 24px;
            float: right;
        }
        .table_box{
            margin-top: 16px;
        }
}
</style>

