<template>
    <div class="PhoneInputBox">
         <div class="phone-con input-row">
                <div class="prefix" >
                    <span class="selected" >{{mobileAreaCode}}</span><img class="icon_choice" src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/icon_an%402x.png" alt="">
                </div>
                <div class="value">
                  <input 
                    disabled
                    type="text" 
                    @input="blurFunc(phone)"
                    v-model="phone" 
                    :placeholder="$t('signUp.text13')" 
                /></div>
        </div>
    </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  onMounted
} from 'vue';
import { assignParams } from '@/until/common';
import apiService from '@/api/request';

export default {
    name:'PhoneInputBox',
    props: {
        value: { // 输入框的值
            type: String,
            default: '',
        },
        laceholder: { // 输入框的提示
            type: String,
            default: '',
        },
        blurFunc: { // 失去焦点的回调事件
            type: Function,
        },
        code:{
          type:String,
          default:''
        },
    },
    setup(props,ctx){

        const phone = ref('');
        const mobileAreaCode = ref('');
        const { value,code} = toRefs(props);
        phone.value = value.value;
        mobileAreaCode.value = code.value;



        return {
            mobileAreaCode,
            phone,
        }
    }
}
</script>
<style lang="less" scoped>
.PhoneInputBox{
    .input-row {
      margin: 0 auto;
      background: white;
      display: flex;
      justify-content: left;
      margin-bottom: 20px;/*no*/
      border-radius: 4px;
      height: 40px;/*no*/
      position: relative;
      border: 1px solid #EBEBEB;/*no*/
      .value{
        width: 100%;/*no*/
      }
      .password-icon{
        position: absolute; 
        right: 16px;/*no*/
        top: 10px;/*no*/
        cursor: pointer;
      }
       .send{
         position: absolute;
         right: 10px;/*no*/
         top: 4px;/*no*/
        }
      .prefix{
        width: 80px;/*no*/
        padding-left: 10px;/*no*/
        border-right: 1px solid #EBEBEB;/*no*/
        text-align: left;
        cursor: pointer;
        position: relative;
        line-height: 40px;/*no*/
        .icon_search{
          position: relative;
          top: 8px;/*no*/
        }
        .icon_choice{
          width: 8px;/*no*/
          position: absolute;
          right: 9px;/*no*/
          top: 18px;/*no*/
        }
      }
      input{
        height: 20px;/*no*/
        outline: none;
        padding-left: 16px;/*no*/
        width: 100%;
        height: 100%;
      }
      .value_code{
        padding-left: 10px;/*no*/
      }
      .select_region{
        position: absolute;
        left: 0;
        padding:20px 0;/*no*/
        background: white;
        width: 300px;/*no*/
        height: 490px;/*no*/
        z-index: 9;
        top: 50px;/*no*/
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        h3{
          font-size: 16px;
          text-align: center;
          color: #111E36;
          position: relative;
          img{
            display: none;
          }
        }
        .seach{
          width: 284px;/*no*/
          height: 32px;/*no*/
          background: #FFFFFF;
          border-radius: 2px;
          margin: 0 auto;
          border: 1px solid #DADADA;/*no*/
          display: flex;
          justify-content: space-between;
          padding-left: 13px;/*no*/
          margin-top: 19px;/*no*/
          img{
            display: block;
            width: 12px;/*no*/
            height: 12px;/*no*/
            position: relative;
            top: 8px;/*no*/
          }
          input{
            width: 243px;/*no*/
            height: 28px;/*no*/
            line-height: 28px;/*no*/
            padding-left: 0;
          }
        }
        ul{
          width: 100%;
          height: 360px;/*no*/
          margin-top: 12px;/*no*/
          overflow-y: scroll;
          text-align: left;
          li{
            height: 45px;/*no*/
            padding: 13px 16px;/*no*/
            border-bottom: 1px solid #F8F9FB;/*no*/
            cursor: pointer;
          }
          li:hover{
            background: #F7F8FA;
            color: #FB741B;
          }
          .sort_list_active{
            background: #F7F8FA;
            color: #FB741B;
          }
        }
    }
  }
}
</style>
