<template>
  <div class="home">
    <div class="home_banner">
      <div class="home_slog animate__animated animate__fadeInUp">
        <p>AdvanTrade爱跟投</p>
        <p>您的一站式交易平台</p>
        <p>可自主交易，可复制跟单</p>
      </div>
      <p class="p_piece animate__animated animate__fadeInUp">
        一个安全、智能、创新的交易平台
      </p>
      <p class="p_piece animate__animated animate__fadeInUp">
        我们致力于为用户提供最好的服务
      </p>
      <div
        class="btn cursor animate__animated animate__fadeInUp"
        @click="openAccountFun"
      >
        <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
        ><img
          src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
          alt=""
        />
      </div>
    </div>
    <div class="our_user">
      <div class="our_user_box">
        <h2 class="title animate__animated">为什么选择AdvanTrade爱跟投？</h2>
        <div class="content_box">
          <div class="content_box_l animate__animated">
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/homePic1%402x.png"
              alt=""
            />
          </div>
          <div class="content_box_r animate__animated">
            <div class="content_box_r_line">
              <span>安全</span>
              <span>透明</span>
              <span>共赢</span>
            </div>
            <p>AdvanTrade平台采用纯STP的经营模式，与投资者的利益保持一致。</p>
            <p>
              AdvanTrade希望能给投资者提供更多技术和工具支持，帮助投资者实现长期盈利。其中平台自主研发的跟单交易功能，就是为投资者提供优质的策略交易团队，帮助投资者在缺乏经验的情况下，能够更安全的参与到外汇市场的投资。这些策略交易团队都是平台经过严格的评估和实盘交易测试后筛选出来的，AdvanTrade在这些策略交易团队提供跟单信号的过程中，也会实时监控风险，尽量将投资的风险最小化，保障投资者的资金安全。
            </p>
            <div
              class="btn cursor openAccount animate__animated"
              @click="openAccountFun"
            >
              <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
              ><img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="the_point the_first_point">
          <div class="point animate__animated">ONE</div>
          <div class="point_title animate__animated">交易更透明</div>
          <p class="animate__animated">
            AdvanTrade致力于为投资者提供安全、公平的交易环境，平台采用纯STP模式，通过与全球最透明的流动性提供商Advanced
            Markets合作，100%执行Direct Market
            Access(DMA)直接市场准入机制，保证投资者的订单直接抛给价格最优的流动性提供者，包括高盛(GS)、瑞士银行(UBS)、摩根大通(JP
            Morgan)、花旗银行(Citibank)、德意志银行(DB)、巴克莱银行(Barclays)、法国巴黎银行(BNP
            Paribas)、渣打银行(SC)等银行机构以及华尔街对冲基金HCT、高频交易公司Tower
            Research Capital、Jump Trading以及其他大型外汇经纪商等金融机构。
          </p>
          <img
            class="animate__animated"
            src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_market.png"
            alt=""
          />
          <p class="animate__animated">
            投资者可以在订单成交后查看每笔订单的成交对手方，AdvanTrade愿意为投资者实时查询该笔订单的情况，为投资者提供公开、透明的信息。
          </p>
          <img
            class="animate__animated"
            src="https://advantrade.oss-accelerate.aliyuncs.com/web/pic_account.png"
            alt=""
          />
        </div>
        <div class="the_point the_first_point">
          <div class="point animate__animated">TWO</div>
          <div class="point_title animate__animated">交易更省心</div>
          <ul>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages1%402x.png"
                alt=""
              />
              <div class="point_sub_title">人性化的交易设计</div>
              <p>
                我们设计的用户操作界面适用于各类投资者，即使是没有专业投资经验的新手也可以轻松掌握。
              </p>
            </li>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages2%402x.png"
                alt=""
              />
              <div class="point_sub_title">0佣金，低成本</div>
              <p>
                我们不收取任何佣金和手续费，竭力为客户降低交易成本，提升收益。
              </p>
            </li>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages3%402x.png"
                alt=""
              />
              <div class="point_sub_title">优秀的交易环境</div>
              <p>
                拥有自主研发的交易引擎，可实现50ms急速成交，同时提供一键平仓、一键反手等特色交易功能，支持API程序化交易，还可以定制开发专属交易工具。
              </p>
            </li>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages4%402x.png"
                alt=""
              />
              <div class="point_sub_title">提供跟单交易服务</div>
              <p>
                为投资者提供自主研发的跟单交易功能，通过技术手段精选出优秀的交易员为投资者提供跟单服务，特别适合没有经验的新手。
              </p>
            </li>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages5%402x.png"
                alt=""
              />
              <div class="point_sub_title">专业的策略研究团队</div>
              <p>
                市场分析师团队为您提供专业的分析研究报告以及各类教学培训资源，包括宏观研究、技术分析、市场情绪分析等，为您决策分析提供助力。
              </p>
            </li>
            <li class="animate__animated">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_advantages6%402x.png"
                alt=""
              />
              <div class="point_sub_title">专属客户经理</div>
              <p>
                除了5天*24小时的常规客户服务外，我们还提供1对1专属客户经理，及时解决您在交易过程中遇到的各类问题。
              </p>
            </li>
          </ul>
          <div
            class="btn cursor openAccount animate__animated"
            @click="openAccountFun"
          >
            <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
            ><img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
              alt=""
            />
          </div>
        </div>
        <div class="the_point the_second_point">
          <div class="point animate__animated">THREE</div>
          <div class="point_title animate__animated">交易更安全</div>
          <div class="the_point_content_box animate__animated">
            <div class="content_box_line">
              <div class="content_box_line_left">账户安全</div>
              <div class="content_box_line_right">
                作为负责任的经纪商，AdvanTrade爱跟投系统通过 256 位 SSL
                加密传输，MD5加密技术进行管理，确保用户个人隐私、交易历史和敏感数据的安全存储，只有所有者才能访问和监控与帐户相关的所有活动，从而避免信息和数据泄露。
              </div>
            </div>
            <div class="content_box_line animate__animated">
              <div class="content_box_line_left">独立账户</div>
              <div class="content_box_line_right">
                根据国际监管标准，AdvanTrade爱跟投使用隔离账户，这使受保护的客户资金与公司的资产负债表分离，确保您的资金安全无优。
              </div>
            </div>
            <div class="content_box_line animate__animated">
              <div class="content_box_line_left">合规监管</div>
              <div class="content_box_line_right">
                AdvanTrade爱跟投受到全球顶级的金融机构监管，为全球投资者提供安全合规的服务。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_introduction">
      <div class="home_introduction_title animate__animated">
        AdvanTrade，让投资更容易
      </div>
      <div class="home_introduction_content">
        <div class="home_introduction_text animate__animated">
          <p>
            AdvanTrade爱跟投是Top Wealth集团旗下的外汇交易品牌，作为知名的金融科技集团，Top Wealth成立的初衷就是希望打破MetaQuotes公司在外汇市场的垄断，为客户提供更加人性化的投资服务。2017年，我们上线了首款自主研发交易软件，随后几年里，我们不断优化产品，增加了数据分析工具、市场分析资讯、特色交易功能以及各种优惠活动，为投资者提供了更加高效、便捷的服务。
          </p>
          <p>
            近期，AdvanTrade爱跟投再次升级，推出自主研发的跟单功能。区别于传统跟单平台，AdvanTrade设置了严格的交易员准入机制，利用大数据、人工智能等技术手段从成千上万的投资者中筛选出优秀的交易员，为客户提供跟单交易服务。
          </p>
          <p>
            目前，AdvanTrade爱跟投已经发展成为一个以交易为核心，集成各种功能为一体的投资服务平台，受到来自全球各地不同类型用户的广泛好评。
          </p>
        </div>
        <div class="home_introduction_video animate__animated">
          <video
            controls="controls"
            poster="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/poster.png"
            src="https://advantrade.oss-accelerate.aliyuncs.com/Video/AdvanTrade.mp4"
          ></video>
        </div>
      </div>
    </div>
    <div class="guaranteed_protection">
      <div class="title animate__animated animate__bounceInUp">
        多元化的交易产品
      </div>
      <p class="text animate__animated">
        作为亚太地区具有领先地位的经纪交易商，AdvanTrade爱跟投为客户提供了100多种主要资产
      </p>
      <p class="text animate__animated">
        包括：外汇、贵金属、能源、指数、数字货币等各类衍生产品等的全面投资服务
      </p>
      <div class="protection">
        <div class="protection_left animate__animated">
          <ul>
            <li
              @mouseenter="hoverFunc(7, 'enter')"
              @mouseleave="hoverFunc(7, 'leave')"
              @click="switchBreed(7)"
            >
              <img :src="img7" alt="" />
              <p>热门</p>
            </li>
            <li
              @mouseenter="hoverFunc(1, 'enter')"
              @mouseleave="hoverFunc(1, 'leave')"
              @click="switchBreed(1)"
            >
              <img :src="img1" alt="" />
              <p>外汇</p>
            </li>
            <li
              @mouseenter="hoverFunc(2, 'enter')"
              @mouseleave="hoverFunc(2, 'leave')"
              @click="switchBreed(2)"
            >
              <img :src="img2" alt="" />
              <p>贵金属</p>
            </li>
            <li
              @mouseenter="hoverFunc(3, 'enter')"
              @mouseleave="hoverFunc(3, 'leave')"
              @click="switchBreed(3)"
            >
              <img :src="img3" alt="" />
              <p>能源</p>
            </li>
            <li
              @mouseenter="hoverFunc(4, 'enter')"
              @mouseleave="hoverFunc(4, 'leave')"
              @click="switchBreed(4)"
            >
              <img :src="img4" alt="" />
              <p>指数</p>
            </li>
            <!-- <li
              @mouseenter="hoverFunc(5, 'enter')"
              @mouseleave="hoverFunc(5, 'leave')"
              @click="switchBreed(5)"
            >
              <img :src="img5" alt="" />
              <p>股票</p>
            </li> -->
            <li
              @mouseenter="hoverFunc(6, 'enter')"
              @mouseleave="hoverFunc(6, 'leave')"
              @click="switchBreed(6)"
            >
              <img :src="img6" alt="" />
              <p>数字货币</p>
            </li>
            <!-- <li
              @mouseenter="hoverFunc(8, 'enter')"
              @mouseleave="hoverFunc(8, 'leave')"
              @click="switchBreed(8)"
            >
              <img :src="img8" alt="" />
              <p>有色金属</p>
            </li> -->
          </ul>
        </div>
        <div class="protection_right animate__animated">
          <el-tabs
            v-model="activeProtection"
            @tab-click="activeProtectionChange"
            class="demo-tabs"
          >
            <el-tab-pane label="热门交易" name="popular"></el-tab-pane>
            <el-tab-pane label="外汇" name="foreignExchange"></el-tab-pane>
            <el-tab-pane label="贵金属" name="preciousMetal"></el-tab-pane>
            <el-tab-pane label="能源" name="energy"></el-tab-pane>
            <el-tab-pane label="指数" name="index"></el-tab-pane>
            <!-- <el-tab-pane label="股票" name="stock"></el-tab-pane> -->
            <el-tab-pane label="数字货币" name="digitalCurrency"></el-tab-pane>
            <!-- <el-tab-pane
              label="有色金属"
              name="non-ferrousMetals"
            ></el-tab-pane> -->
          </el-tabs>
          <ul class="protection_list">
            <li class="protection_list_th">
              <div class="protection_list_head_td td_1">品种</div>
              <div class="protection_list_head_td td_2">涨跌幅</div>
              <div class="protection_list_head_td td_3">买价</div>
              <div class="protection_list_head_td td_4">卖价</div>
              <div class="protection_list_head_td td_5">交易方向</div>
              <div class="protection_list_head_td td_6">交易方向</div>
            </li>
            <li
              class="protection_list_td cursor"
              v-for="item in productList"
              @click="checkSymbol(item)"
              :key="item.symbol"
            >
              <div class="protection_list_head_td td_1">{{ item.symbol }}</div>
              <div
                class="protection_list_head_td td_2"
                :class="item.upDownRate.indexOf('-') == -1 ? 'up' : 'down'"
              >
                {{ item.upDownRate }}
              </div>
              <div class="protection_list_head_td td_3">
                {{
                  (Number(item.bidPrice) + Number(item.bidForDiffer)).toFixed(
                    item.decimalPlace
                  )
                }}
              </div>
              <div class="protection_list_head_td td_4">
                {{
                  (Number(item.askPrice) + Number(item.askForDiffer)).toFixed(
                    item.decimalPlace
                  )
                }}
              </div>
              <div class="protection_list_head_td td_5"><span>买</span></div>
              <div class="protection_list_head_td td_6"><span>卖</span></div>
            </li>
          </ul>
          <p class="eurusd"
            >您可以在TradingView探索<a  href="https://www.tradingview.com/symbols/EURUSD/">Eurusd Chart</a> 和更多外汇相关内容。
          </p>
        </div>
      </div>
      <div
        class="btn cursor openAccount animate__animated"
        @click="openAccountFun"
      >
        <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
        ><img
          src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
          alt=""
        />
      </div>
    </div>
    <div class="homepage-scroll">
      <div class="homepage-scroll-title">强大的交易软件</div>
      <img
        class="home_pic_advance"
        src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic_advance.png"
        alt=""
      />
      <div class="container row justify-content-end">
        <div class="hp-scroll-illu">
          <div class="hp-illu-item">
            <div class="hp-illu-bg hp-illu-bg-1"></div>
            <img
              class="hp-illu-img-1"
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic_app%402x.png"
              alt=""
            />
          </div>
          <div class="hp-illu-item">
            <div class="hp-illu-bg hp-illu-bg-2"></div>
            <img
              class="hp-illu-img-2"
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic_pc%402x.png"
              alt=""
            />
          </div>
          <!-- <div class="hp-illu-item">
            <img
              class="hp-illu-img-3"
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/pic_software%402x.png"
              alt=""
            />
          </div> -->
        </div>
      </div>
      <div class="container homepage-scroll-wrap">
        <div class="hp-scroll-wrap">
          <div class="hp-scroll-list">
            <div class="hp-scroll-list-item active">
              <div class="piece">自主研发交易软件</div>
              <div class="hp-scroll-list-item-title">
                移动端APP — 随时随地，掌握先“机”
              </div>
              <ul>
                <li>
                  <span></span>
                  <div>智能跟单系统</div>
                </li>
                <li>
                  <span></span>
                  <div>特色交易功能</div>
                </li>
                <li>
                  <span></span>
                  <div>多种分析工具</div>
                </li>
                <li>
                  <span></span>
                  <div>支持多通道出入金</div>
                </li>
                <li>
                  <span></span>
                  <div>海量优惠活动</div>
                </li>
              </ul>
              <div class="btn cursor" @click="routerTo('downloadCN')">
                <span>下载AdvanTrade </span
                ><img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
                  alt=""
                />
              </div>
            </div>
            <div class="hp-scroll-list-item">
              <div class="piece">自主研发交易软件</div>
              <div class="hp-scroll-list-item-title">
                PC端网页版 — 便于使用，无需安装
              </div>
              <ul>
                <li>
                  <span></span>
                  <div>采用Tradingview图表工具</div>
                </li>
                <li>
                  <span></span>
                  <div>人性化交互设计</div>
                </li>
                <li>
                  <span></span>
                  <div>集成分析工具</div>
                </li>
                <li>
                  <span></span>
                  <div>适配各种电脑</div>
                </li>
              </ul>
              <div class="btn cursor" @click="openAccountFun">
                <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
                ><img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
                  alt=""
                />
              </div>
            </div>
            <!-- <div class="hp-scroll-list-item hp-scroll-list-item-3">
              <div class="hp-scroll-list-item-title">
                MT5 — MetaTrader5 交易软件
              </div>
              <p>
                MT5是由MetaQuotes平台(MetaQuotes)编写的一款金融交易软件，包括各类交易执行功能、无限的图表、大量技术指标和曲线、自定义指标和脚本。不仅如此，MT5平台还提供内置的自动交易市场，为自由职业者提供的策略开发人员数据库，复制交易和虚拟主机(Forex
                VPS)服务等。
              </p>
              <div class="go-down-box">
                <div class="go-down" @click="downLoad(1)">
                  <img
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_down_ios.png"
                    alt=""
                  />
                  <p>苹果<i class="iconfont icon-icon_jump_dack"></i></p>
                </div>
                <div class="go-down" @click="downLoad(2)">
                  <img
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_down_android.png"
                    alt=""
                  />
                  <p>安卓<i class="iconfont icon-icon_jump_dack"></i></p>
                </div>
                <div class="go-down" @click="downLoad(3)">
                  <img
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_down_pc.png"
                    alt=""
                  />
                  <p>PC客户端<i class="iconfont icon-icon_jump_dack"></i></p>
                </div>
                <div class="go-down" @click="downLoad(4)">
                  <img
                    src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_down_web.png"
                    alt=""
                  />
                  <p>网页交易<i class="iconfont icon-icon_jump_dack"></i></p>
                </div>
              </div>
              <div class="btn cursor" @click="downLoad(3)">
                <span>下载MT5 </span
                ><img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
                  alt=""
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="our_user">
      <div class="w1200">
        <img
          class="home_font_regulatory animate__animated"
          src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_font_regulatory%402x.png"
          alt=""
        />
        <div style="clear: both"></div>
        <div class="regulatory animate__animated">
          <div class="regulatory_item">
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_fsa%402x.png"
              alt=""
            />
            <div class="regulatory_title">塞舌尔金融服务管理局(FSA）</div>
            <p>
              Top Wealth集团旗下的Topical Wealth International
              Ltd持有证券经纪业务牌照，受塞舌尔金融服务管理局（FSA）监管，牌照编号：SD077。FSA是根据2013《金融服务管理局法》成立的，主要职责包括通过稳固的监管制度，对塞舌尔的非银行金融服务进行发牌、监督和发展。
            </p>
          </div>
          <div class="regulatory_item">
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_msb%402x.png"
              alt=""
            />
            <div class="regulatory_title">加拿大MSB注册机构</div>
            <p>
              Top Wealth集团旗下的ADVANTRADE CAPITAL
              LIMITED是加拿大FINTRAC监管的MSB注册金融机构，受监管的经营范围包括Foreign
              exchange dealing和Money transferring。注册编号：M20027180。
            </p>
          </div>
          <!-- <div class="regulatory_item">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_asic%402x.png" alt="">
                <div class="regulatory_title">澳大利亚证券及投资委员会ASIC</div>
                <p>Top Wealth集团的技术合作伙伴ET Fintech Pty Ltd持有澳大利亚证券投资委员会ASIC的金融服务牌照并受其监管，牌照编号：470050。ASIC是世界顶级和最为严格的三大金融监管机构之一，其监管范围涵盖各银行金融机构、证券、期货、外汇、金融衍生品等金融交易机构。</p>
            </div> -->
        </div>
      </div>
    </div>
    <div class="open_an_account_now">
      <div class="open_an_account_now_box">
        <div class="box_left">
          <div class="open_account_title">立即开设账户</div>
          <div class="open_account_line">
            <div class="open_account_item">
              <div class="number">
                <img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_progress_bar1%402x.png"
                  alt=""
                />
                <div class="hr"></div>
              </div>
              <div class="open_account_item_title">注册</div>
              <p>注册AdvanTrade爱跟投</p>
              <p>开设专属账户</p>
            </div>
            <div class="open_account_item">
              <div class="number">
                <img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_progress_bar2%402x.png"
                  alt=""
                />
                <div class="hr"></div>
              </div>
              <div class="open_account_item_title">入金</div>
              <p>您的资金将被</p>
              <p>单独隔离存管</p>
            </div>
            <div class="open_account_item">
              <div class="number">
                <img
                  src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_progress_bar3%402x.png"
                  alt=""
                />
                <div class="hr"></div>
              </div>
              <div class="open_account_item_title">交易</div>
              <p>选择交易品种</p>
              <p>开始您的交易之旅</p>
            </div>
          </div>
          <div
            class="btn cursor openAccount animate__animated"
            @click="openAccountFun"
          >
            <span>{{ userInfo?.nickname ? "立即交易" : "立即开户" }} </span
            ><img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png"
              alt=""
            />
          </div>
        </div>
        <div class="box_right">
          <img
            src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic2%402x.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed, onUnmounted, onBeforeMount } from "vue";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import { useRouter } from "vue-router";
import {
  linkToGoogle,
  linkToApple,
  linkToAndroid,
  isNotEmpty,
} from "@/until/common";
import apiService from "@/api/request";
import { setInterval, clearInterval } from "timers";
import "animate.css";
import $ from "jquery";
import { useStore } from "vuex";

export default {
  name: "home",
  components: {},
  setup() {
    const store = useStore();
    const lang = computed({ get: () => store.getters.getNowTheme.lang });
    onBeforeMount(() => {
      if (lang.value == "en") {
        routerTo("/");
      }
    });
    onMounted(() => {
      new Swiper(".swiper-container", {
        autoplay: 3000,
        pagination: ".swiper-pagination",
      });
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", animateFun);
      if (document.getElementsByClassName("widget-visible").length != 0)
        document.getElementsByClassName("widget-visible")[0].style.display =
          "none";
      clearInterval(myTimer);
      myTimer = null;
    });
    const router = useRouter();
    const userInfo = computed({ get: () => store.getters.userInfo });
    const userToken = computed({ get: () => store.getters.userToken });

    function routerTo(e) {
      router.push(e);
    }

    let productList = ref([]);

    let img1 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_forex%402x.png"
    );
    let img2 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metals%402x.png"
    );
    let img3 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_commodities%402x.png"
    );
    let img4 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_indices%402x.png"
    );
    let img5 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_stocks%402x.png"
    );
    let img6 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_cryptocurrency%402x.png"
    );
    let img7 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_hot%402x.png"
    );
    let img8 = ref(
      "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metalcolor%402x.png"
    );
    let activeProtection = ref("popular");
    function hoverFunc(e, type) {
      if (type == "enter") {
        switch (e) {
          case 1:
            img1.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_forex2%402x.png";
            break;
          case 2:
            img2.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metals2%402x.png";
            break;
          case 3:
            img3.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_commodities2%402x.png";
            break;
          case 4:
            img4.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_indices2%402x.png";
            break;
          case 5:
            img5.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_stocks2%402x.png";
            break;
          case 6:
            img6.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_cryptocurrency2%402x.png";
            break;
          case 7:
            img7.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_hot2%402x.png";
            break;
          case 8:
            img8.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metalcolor2%402x.png";
            break;
        }
      } else if (type == "leave") {
        switch (e) {
          case 1:
            img1.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_forex%402x.png";
            break;
          case 2:
            img2.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metals%402x.png";
            break;
          case 3:
            img3.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_commodities%402x.png";
            break;
          case 4:
            img4.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_indices%402x.png";
            break;
          case 5:
            img5.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_stocks%402x.png";
            break;
          case 6:
            img6.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_cryptocurrency%402x.png";
            break;
          case 7:
            img7.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_hot%402x.png";
            break;
          case 8:
            img8.value =
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_icon_metalcolor%402x.png";
            break;
        }
      }
    }

    function switchBreed(e) {
      switch (e) {
        case 1:
          activeProtection.value = "foreignExchange";
          getProtection(activeProtection.value);
          break;
        case 2:
          activeProtection.value = "preciousMetal";
          getProtection(activeProtection.value);
          break;
        case 3:
          activeProtection.value = "energy";
          getProtection(activeProtection.value);
          break;
        case 4:
          activeProtection.value = "index";
          getProtection(activeProtection.value);
          break;
        case 5:
          activeProtection.value = "stock";
          getProtection(activeProtection.value);
          break;
        case 6:
          activeProtection.value = "digitalCurrency";
          getProtection(activeProtection.value);
          break;
        case 7:
          activeProtection.value = "popular";
          getProtection(activeProtection.value);
          break;
        case 8:
          activeProtection.value = "non-ferrousMetals";
          getProtection(activeProtection.value);
          break;
      }
    }

    //买卖操作
    function checkSymbol(e) {
      // 选择合约
      store.commit("ACTIVESYMBOL", e);
      routerTo("transaction");
    }

    //下载
    function downLoad(e) {
      switch (e) {
        case 1:
          location.href =
            "https://download.mql5.com/cdn/mobile/mt5/ios?server=TopicalWealthIntl-Demo,TopicalWealthIntl-Live";
          break;
        case 2:
          location.href =
            "https://download.mql5.com/cdn/mobile/mt5/android?server=TopicalWealthIntl-Demo,TopicalWealthIntl-Live";
          break;
        case 3:
          location.href =
            "https://download.mql5.com/cdn/web/18873/mt5/topicalwealthintl5setup.exe";
          break;
        case 4:
          location.href = "https://trade.metatrader5.com/terminal";
          break;
      }
    }

    function getProtectionCycle(e) {
      let params = { contracttypeid: e };
      apiService.product(params).then((res) => {
        productList.value = res.data.productList;
      });
    }

    let myTimer;
    getProtection("popular");

    //获取指定品种
    function getProtection(e) {
      clearInterval(myTimer);
      myTimer = null;
      let contracttypeid;

      switch (e) {
        case "popular":
          contracttypeid = 0;
          break;
        case "foreignExchange":
          contracttypeid = 1;
          break;
        case "preciousMetal":
          contracttypeid = 3;
          break;
        case "energy":
          contracttypeid = 4;
          break;
        case "index":
          contracttypeid = 5;
          break;
        case "stock":
          contracttypeid = 6;
          break;
        case "digitalCurrency":
          contracttypeid = 7;
          break;
        case "non-ferrousMetals":
          contracttypeid = 8;
          break;
      }

      if (0 == contracttypeid) {
        getETradeDefaultSymbol(contracttypeid);
        myTimer = setInterval(() => {
          getETradeDefaultSymbol(contracttypeid);
        }, 3000);
      } else {
        getProtectionCycle(contracttypeid);
        myTimer = setInterval(() => {
          getProtectionCycle(contracttypeid);
        }, 3000);
      }
    }

    //获取热门品种
    function getETradeDefaultSymbol() {
      apiService.getETradeDefaultSymbol().then((res) => {
        productList.value = res.data.quote;
      });
    }

    //选择品种
    function activeProtectionChange(e) {
      getProtection(e.props.name);
    }

    //立即开户
    function openAccountFun() {
      if (isNotEmpty(userToken.value)) {
        routerTo("transaction");
      } else {
        routerTo("signUp");
      }
    }

    function animateFun() {
      let element = $(".animate__animated");
      let Wheight = Number($(window).height()); //文档窗口高度：
      let sTop = Number($(document).scrollTop()); //是滚动条高度

      element.each((i, e) => {
        let oTop = Number($(e).offset().top); //标签距离顶部高度
        let Eheight = Number($(e).height()); //元素高度
        if (Wheight - (oTop - sTop) > 0) {
          $(e).addClass("animate__fadeInUp");
        }
      });

      let homeTop = Number($(".homepage-scroll").offset().top);
      let hoemHeight = Number($(".homepage-scroll").innerHeight()); //元素高度
      let rem = parseFloat($("html").css("font-size"));
      let innerScrollHeight = -(homeTop - sTop);
      if (
        homeTop - sTop <= -115 &&
        sTop - (homeTop + hoemHeight - Wheight) <= 0
      ) {
        $(".homepage-scroll-wrap").css({ position: "fixed", top: "60px" });
        $(".home_pic_advance").css({ position: "fixed", top: "251px" });
        $(".hp-illu-bg").css({
          transform: `translateX(-50%) translateY(${
            -50 - ((homeTop - sTop) * (homeTop - sTop)) / hoemHeight / 8
          }%) rotateZ(${
            ((homeTop - sTop) * (homeTop - sTop)) / hoemHeight / 3
          }deg)`,
        });
        if (0 < innerScrollHeight && innerScrollHeight < hoemHeight / 6) {
          if ($(".hp-scroll-list-item").eq(0).hasClass("active")) {
          } else {
            $(".hp-scroll-list-item").removeClass("active");
            $(".hp-scroll-list-item").eq(0).addClass("active");
          }
        } else if (
          innerScrollHeight > hoemHeight / 6 &&
          innerScrollHeight < hoemHeight / 2
        ) {
          if ($(".hp-scroll-list-item").eq(1).hasClass("active")) {
            $(".homepage-scroll").css({ background: `rgb(255, 132, 51)` });
            $(".home_pic_advance").attr(
              "src",
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic_advance.png"
            );
          } else {
            $(".hp-scroll-list-item").removeClass("active");
            $(".hp-scroll-list-item").eq(1).addClass("active");
          }
        } else if (
          innerScrollHeight > hoemHeight / 2 &&
          innerScrollHeight < hoemHeight
        ) {
          if ($(".hp-scroll-list-item").eq(2).hasClass("active")) {
            $(".homepage-scroll").css({ background: `#FFF7F2` });
          } else {
            $(".hp-scroll-list-item").removeClass("active");
            $(".hp-scroll-list-item").eq(2).addClass("active");
            $(".home_pic_advance").attr(
              "src",
              "https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/home_pic_advance%402x.png"
            );
          }
        }
      } else {
        if (homeTop - sTop > -115) {
          $(".homepage-scroll-wrap").css({
            position: "absolute",
            top: "168px",
            bottom: "auto",
          });
          $(".home_pic_advance").css({
            position: "absolute",
            top: "355px",
            bottom: "auto",
          });
        } else {
          $(".homepage-scroll-wrap").css({
            position: "absolute",
            bottom: "-100px",
            top: "auto",
          });
          $(".home_pic_advance").css({
            position: "absolute",
            top: "auto",
            bottom: "400px",
          });
        }
      }
    }
    window.addEventListener("scroll", animateFun);

    return {
      routerTo,
      linkToGoogle,
      linkToApple,
      linkToAndroid,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      hoverFunc,
      activeProtection,
      switchBreed,
      activeProtectionChange,
      productList,
      openAccountFun,
      userInfo,
      checkSymbol,
      downLoad,
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  .home_banner {
    height: 1000px;
    background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/banner%402x.png)
      no-repeat;
    background-size: cover;
    background-position: center;
    padding: 177px 0 0 410px;
    text-align: left;
    color: #ffffff;
    .home_slog {
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 60px;
    }
    .p_piece {
      margin-top: 16px;
      width: 593px;
    }
    .btn {
      width: 210px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fb741b;
      font-size: 16px;
      border-radius: 27px;
      margin-top: 130px;
      img {
        width: 22px;
        height: 14px;
        display: block;
        margin-left: 8px;
      }
      span {
        display: block;
        font-weight: 600;
      }
    }
  }
  .guaranteed_protection {
    padding-top: 140px;
    padding-bottom: 72px;
    background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/homebg1%402x.png)
      no-repeat;
    background-size: cover;
    background-position: center;
    .title {
      font-size: 34px;
      font-weight: 900;
      margin-bottom: 30px;
      color: white;
    }
    .text {
      width: 1200px;
      margin: 0 auto;
      color: white;
    }
    .protection {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 118px;
      .protection_left {
        width: 360px;
        color: white;
        ul {
          overflow: hidden;
          li {
            width: 80px;
            float: left;
            margin-left: 43px;
            cursor: pointer;
            &:nth-child(3n + 1) {
              margin-left: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 80px;
            }
            img {
              display: block;
              height: 80px;
              width: 80px;
            }
            p {
              font-size: 16px;
              margin-top: 16px;
              text-align: center;
            }
          }
        }
      }
      .protection_right {
        width: 680px;
        height: 410px;
        background: white;
        margin-left: 100px;
        border-radius: 8px;
        text-align: left;
        .eurusd{
          color: red;
          text-align: left;
        }
        a {
          color: white;
          font-weight: 600;
          text-decoration: underline;
        }
        .protection_list {
          height: 358px;
          overflow: scroll;
          margin-bottom: 10px;
          li {
            overflow: hidden;
            &:nth-child(even) {
              background: #f7f8f9;
            }
          }
          .protection_list_th {
            height: 50px;
            font-size: 14px;
            color: #78838c;
            padding: 0 16px;
            line-height: 50px;
          }
          .protection_list_td {
            height: 50px;
            font-size: 16px;
            color: #000000;
            padding: 0 16px;
            line-height: 50px;
          }
          .td_1 {
            width: 110px;
            float: left;
            text-align: left;
          }
          .td_2 {
            width: 90px;
            float: left;
            text-align: right;
          }
          .td_3 {
            width: 124px;
            float: left;
            text-align: right;
          }
          .td_4 {
            width: 124px;
            float: left;
            text-align: right;
          }
          .td_5 {
            width: 120px;
            float: left;
            text-align: center;
            span {
              display: block;
              margin: 0 auto;
              width: 30px;
              height: 20px;
              background: @red;
              line-height: 20px;
              margin-top: 15px;
              border-radius: 3px;
              color: white;
              cursor: pointer;
              font-size: 14px;
            }
          }
          .td_6 {
            width: 75px;
            float: left;
            text-align: center;
            span {
              display: block;
              margin: 0 auto;
              width: 30px;
              height: 20px;
              background: @green;
              line-height: 20px;
              margin-top: 15px;
              border-radius: 3px;
              color: white;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .openAccount {
      margin: 0 auto;
      margin-top: 64px;
      color: white;
    }
  }
  .learn_more {
    width: 150px;
    height: 40px;
    line-height: 40px;
    background: #fb741b;
    color: white;
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
  }
  .home_introduction {
    background: #fbfbfb;
    padding: 200px 0 264px 0;
    .home_introduction_title {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 140px;
    }
    .home_introduction_content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .home_introduction_text {
      width: 500px;
      text-align: left;
      p {
        margin-bottom: 24px;
      }
    }
    .home_introduction_video {
      margin-left: 60px;
      video {
        width: 100%;
        height: 100%;
      }
      width: 540px;
    }
  }
  .homepage-scroll {
    padding-top: 120px;
    padding-bottom: 147px;
    background: #ff8433;
    overflow: hidden;
    min-height: calc(200vh - 180px);
    position: relative;
    .homepage-scroll-title {
      font-size: 40px;
      font-weight: 600;
      color: white;
    }
    .home_pic_advance {
      width: 100%;
      position: absolute;
      top: 366px;
      left: 0;
    }
    h2 {
      font-size: 40px;
      color: #ffffff;
      font-weight: 600;
    }
    .container {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 1170px;
      margin-top: 116px;
      .hp-scroll-wrap {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
        align-items: center;
        display: flex;
        height: calc(100vh - 60px);
        .btn {
          background: #000000;
          color: white;
        }
        .piece {
          width: 150px;
          height: 32px;
          line-height: 32px;
          border-radius: 16px;
          background: white;
          color: #fb741b;
        }
        .hp-scroll-list-item-title {
          font-size: 32px;
          font-weight: 600;
          margin-top: 30px;
          color: white;
          text-align: left;
          white-space: nowrap;
        }
        .hp-scroll-list {
          position: relative;
          width: 100%;
          height: 100%;
        }
        .hp-scroll-list-item {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(100%);
          transition: transform 0.3s ease-in-out;
        }
        .hp-scroll-list-item-3 {
          text-align: left;
          .hp-scroll-list-item-title {
            color: #000000;
          }
          & > p {
            margin-top: 24px;
            font-size: 16px;
          }
          .go-down-box {
            display: flex;
            justify-content: space-between;
            margin-top: 71px;
            .go-down {
              cursor: pointer;
              img {
                display: block;
                width: 40px;
                height: 40px;
                margin: 0 auto;
              }
              p {
                margin-top: 32px;
                text-align: center;
                i {
                  font-size: 12px;
                  margin-left: 6px;
                }
              }
            }
          }
        }
        .active {
          transform: translateY(0);
        }
        .hp-scroll-list-item > ul {
          li {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 30px;
            span {
              display: block;
              height: 10px;
              width: 10px;
              background: white;
              border-radius: 50%;
              margin-right: 12px;
            }
            color: white;
            font-size: 20px;
            &:first-child {
              margin-top: 50px;
            }
          }
        }
      }

      .hp-scroll-illu {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
        .hp-illu-item {
          position: relative;
          height: calc(100vh - 60px);
        }
        .hp-illu-bg {
          position: absolute;
          top: 30%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
        }
        .hp-illu-bg-1 {
          width: 400px;
          height: 400px;
          border-radius: 50%;
          background: white;
        }
        .hp-illu-img-1 {
          width: 430px;
          height: 610px;
          position: relative;
          z-index: 2;
        }
        .hp-illu-bg-2 {
          width: 400px;
          height: 400px;
          background: white;
          top: 19%;
        }
        .hp-illu-img-2 {
          width: 600px;
          height: 362px;
          position: relative;
          z-index: 2;
        }
        .hp-illu-bg-3 {
          width: 400px;
          height: 400px;
          background: white;
          top: 19%;
        }
        .hp-illu-img-3 {
          width: 963px;
          height: 450px;
          position: absolute;
          right: -500px;
          top: -200px;
          z-index: 2;
        }
      }
    }
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    .justify-content-end {
      justify-content: flex-end;
    }
  }
  .our_user {
    background: white;
    padding: 140px 0 70px 0;
    .our_user_box {
      width: 1240px;
      margin: 0 auto;
    }
    .title {
      font-size: 40px;
      font-weight: 600;
    }
    .content_box {
      margin: 0 auto;
      margin-top: 94px;
      display: flex;
      justify-content: space-between;
      .content_box_l {
        img {
          display: block;
          width: 710px;
          height: 440px;
        }
      }
      .content_box_r {
        width: 500px;
        .content_box_r_line {
          display: flex;
          justify-content: left;
          span {
            display: block;
            font-size: 24px;
            margin-right: 40px;
            font-weight: 600;
          }
        }
        & > p {
          font-size: 18px;
          margin-top: 11px;
          text-align: left;
          line-height: 29px;
        }
        & > p:last-of-type {
          font-size: 16px;
        }
        .btn {
          color: white;
          float: left;
          margin-top: 72px;
        }
      }
    }
    .the_point {
      text-align: left;
      .point {
        font-size: 24px;
        color: #fb741bff;
      }
      .point_title {
        font-size: 32px;
        font-weight: 600;
        margin-top: 15px;
      }
      & > p {
        margin-top: 24px;
        line-height: 28px;
        &:last-of-type {
          margin-top: 70px;
        }
      }
      & > img {
        margin-top: 32px;
        width: 100%;
      }
    }
    .the_first_point {
      margin-top: 120px;
      .point {
        font-size: 24px;
        color: #fb741bff;
      }
      .point_title {
        font-size: 32px;
        font-weight: 600;
        margin-top: 15px;
      }
      & > ul {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 380px;
          height: 420px;
          border: 2px solid #dee2e6;
          border-radius: 30px;
          padding: 40px 30px 0;
          text-align: center;
          img {
            width: 180px;
            height: 180px;
            display: block;
            margin: 0 auto;
          }
          .point_sub_title {
            margin-top: 28px;
            font-size: 22px;
            font-weight: 600;
          }
          p {
            margin-top: 16px;
            font-size: 16px;
            color: #78838c;
          }
          &:nth-child(n + 4) {
            margin-top: 30px;
          }
        }
      }
    }
    .the_second_point {
      margin-top: 85px;
      .the_point_content_box {
        margin-top: 46px;
        .content_box_line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 60px;
          .content_box_line_left {
            width: 240px;
            height: 120px;
            line-height: 120px;
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            border-radius: 10px;
            font-family: SimSong-Bold, SimSong;
          }
          .content_box_line_right {
            width: 910px;
            font-size: 18px;
          }
          &:nth-child(1) {
            .content_box_line_left {
              background: #fbf7ec;
              color: #876300;
            }
          }
          &:nth-child(2) {
            .content_box_line_left {
              background: #f1fcfa;
              color: #1b5e41;
            }
          }
          &:nth-child(3) {
            .content_box_line_left {
              background: #eef2ff;
              color: #132c8b;
            }
          }
        }
      }
    }
    .btn {
      color: white;
      margin-left: auto;
      margin-right: auto;
    }
    .home_font_regulatory {
      width: 700px;
      height: 140px;
      float: right;
      margin-top: 35px;
    }
    .regulatory {
      display: flex;
      justify-content: center;
      margin-top: 78px;
      .regulatory_item {
        border-radius: 30px;
        width: 550px;
        height: 500px;
        background: #f7f8f9;
        padding: 0 30px;
        padding-top: 50px;
        text-align: left;
        & > img {
          width: 260px;
          height: 140px;
          display: block;
          margin: 0 auto;
        }
        .regulatory_title {
          font-size: 22px;
          font-weight: 600;
          margin-top: 48px;
          text-align: center;
        }
        & > p {
          font-size: 16px;
          margin-top: 24px;
        }
        &:first-child {
          margin-right: 100px;
        }
      }
    }
  }
  .good_news_for_you {
    height: 320px;
    background: #fb741b;
    color: white;
    padding-top: 9px;
    .good_news_for_you_text {
      width: 500px;
      text-align: left;
      float: left;
      margin-left: 410px;
      margin-top: 53px;
      h4 {
        font-size: 30px;
      }
      p {
        margin-top: 24px;
      }
      .btn_join_now {
        margin-top: 24px;
        width: 150px;
        height: 40px;
        background: white;
        border-radius: 4px;
        color: #fb741b;
        line-height: 40px;
        text-align: center;
      }
    }
    img {
      display: block;
      height: 273px;
      width: 327px;
      float: left;
      margin-left: 237px;
    }
  }
  .open_an_account_now {
    background: #fdfaf4;
    padding-top: 120px;
    padding-bottom: 90px;
    .open_an_account_now_box {
      width: 1200px;
      margin: 0 auto;
      padding-right: 40px;
      display: flex;
      justify-content: space-between;
      .box_left {
        text-align: left;
        .open_account_title {
          font-size: 40px;
          font-weight: 600;
        }
        .open_account_line {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;
        }
        .btn {
          color: white;
        }
        .open_account_item {
          margin-right: 16px;
        }
        .number {
          display: flex;
          justify-content: left;
          align-items: center;
          img {
            display: block;
            width: 28px;
            height: 24px;
          }
          .hr {
            height: 2px;
            width: 160px;
            background: #ffc097;
            margin-left: 16px;
          }
        }
        .open_account_item_title {
          font-size: 22px;
          font-weight: 600;
          margin-top: 12px;
          margin-bottom: 10px;
        }
        p {
          color: #78838c;
        }
      }
      .box_right {
        width: 420px;
        height: 350px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btn {
    width: 210px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fb741b;
    font-size: 16px;
    border-radius: 27px;
    margin-top: 130px;
    img {
      width: 22px;
      height: 14px;
      display: block;
      margin-left: 8px;
    }
    span {
      display: block;
      font-weight: 600;
    }
  }
  .animate__animated {
  }
}
</style>
<style lang="less">
.home {
  .el-tabs__item {
    padding: 16px 30px !important;
    margin: 0;
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs__nav {
    width: 100%;
    margin: 0;
  }
}
:root {
  --animate-duration: 1500ms;
  --animate-delay: 0.9s;
}
</style>
