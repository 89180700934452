<template>
    <div class="contactUs">
        <div class="contact_banner">
            <h2>We are ready to help you</h2>
            <p>We are happy to support you here</p>
            <p>Customer service available in 24/5</p>
            <p>100% response rate in details</p>
            <p>Professional customer support experience</p>
        </div>
        <div class="contact_banner2">
            <div class="looking_for_support">
                <div class="enter">
                    <h4>Looking for support？</h4>
                    <div class="lable">Your Name (required)</div>
                    <div class="enter_input">
                        <input type="text" v-model="name">
                    </div>
                    <div class="lable">Your Phone (required)</div>
                     <div class="enter_input">
                        <input type="phone" v-model="phone">
                    </div>
                    <div class="lable">Your Email (required)</div>
                    <div class="enter_input">
                        <input type="email" v-model="email">
                    </div>
                    <div class="lable">What are you looking for ? (required)</div>
                    <div class="enter_select">
                        <select v-model="lookingFor">
                            <option value ="Promotion">Promotion</option>
                            <option value ="Technical">Technical Support</option>
                            <option value="Information">Information</option>
                        </select>
                    </div>
                    <div class="lable">Information in details (optional)</div>
                    <div class="enter_textarea">
                        <textarea v-model="details" placeholder="text your inquires here"></textarea>
                    </div>
                    <div class="submit" @click="submit">
                        SUBMIT
                    </div>  
                </div>
                <div class="our_working">
                    <h4>Our Working Hours</h4>
                    <div class="our_working_left">
                        <div class="item">
                            <img @click="cutover(1)" :src="itemFlag1?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                            <div class="text">
                                <div class="title">Chinese</div>
                                <div v-show="itemFlag1">
                                    <p>Working Hour:</p>
                                    <p>GMT(+8) 9:00 AM – 6:00 PM</p>
                                </div>
                            </div>
                        </div>
                      <div class="item">
                            <img @click="cutover(2)" :src="itemFlag2?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                            <div class="text">
                                <div class="title">English</div>
                                <div v-show="itemFlag2">
                                    <p>Working Hour:</p>
                                    <p>GMT(+8) 9:00 AM – 6:00 PM</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                                <img @click="cutover(3)" :src="itemFlag3?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                                <div class="text">
                                    <div class="title">Bahasa</div>
                                    <div v-show="itemFlag3">
                                        <p>Working Hour:</p>
                                        <p>GMT(+8) 9:00 AM – 6:00 PM</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="our_working_right">
                        <div class="item">
                            <img @click="cutover(4)" :src="itemFlag4?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                            <div class="text">
                                <div class="title">Tagalog</div>
                                <div v-show="itemFlag4">
                                    <p>Working Hour:</p>
                                    <p>GMT(+8) 9:00 AM – 6:00 PM</p>
                                </div>
                            </div>
                        </div>
                       <div class="item">
                            <img @click="cutover(5)" :src="itemFlag5?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                            <div class="text">
                                <div class="title">Spanish</div>
                                <div v-show="itemFlag5">
                                    <p>Working Hour:</p>
                                    <p>GMT(+1) 9:00 AM – 6:00 PM</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <img @click="cutover(6)" :src="itemFlag6?'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_add2%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/icon_jian%402x.png'" alt="">
                            <div class="text">
                                <div class="title">Thailand</div>
                                <div v-show="itemFlag6">
                                    <p>Working Hour:</p>
                                    <p>GMT(+7) 9:00 AM – 6:00 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="your_comments">
            <h2>YOUR COMMENTS ARE IMPORTANT</h2>
            <p>Wont to say something for us ? We are willing to listen from you !</p>
            <div class="important">
                <input type="text">
            </div>
            <div class="submit">
                SUBMIT
            </div>
        </div> -->
    </div>
</template>
<script>
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
const axios = require('axios');
import Qs from 'qs'

export default {
name:'contactUs',
    components:{
    },
     setup(){
    const itemFlag1 = ref(true);
    const itemFlag2 = ref(true);
    const itemFlag3 = ref(true);
    const itemFlag4 = ref(true);
    const itemFlag5 = ref(true);
    const itemFlag6 = ref(true);
   

    const name = ref("");
    const email = ref("");
    const lookingFor = ref("Promotion");
    const details = ref("");
    const phone = ref("");
       function cutover(el){
           switch(el){
               case 1:itemFlag1.value = !itemFlag1.value;break;
               case 2:itemFlag2.value = !itemFlag2.value;break;
               case 3:itemFlag3.value = !itemFlag3.value;break;
               case 4:itemFlag4.value = !itemFlag4.value;break;
               case 5:itemFlag5.value = !itemFlag5.value;break;
               case 6:itemFlag6.value = !itemFlag6.value;break;
           }
       }
       function submit(){
           if(name.value == '') {
                ElMessage.error("Please enter your name")
                return
            }
            if(email.value == '') {
                ElMessage.error("Please enter your email")
                return
            }
            if(phone.value == '') {
                ElMessage.error("Please enter your phone")
                return
            }
           
           let data = {
                token:"2502d72e2aad531e3aeab5df669a9dbf",
                status:'官网',
                operation:'consult',
                assignment:'Website',
                clientType:'New',
                phone:phone.value,
                email: email.value,
                lastName: name.value,
                reg_intention:lookingFor.value +"_"+ details.value,
            }
           axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'post',
                url: 'https://hw.dq810.com/Rong/Inlet/Marketing/entrance',
                data: Qs.stringify(data),
            }).then((res) => {
                console.log(res)
                if(res.data.errCode == 1){
                    ElMessage({
                        message: res.data.errMsg,
                        type: 'success',
                    })
                    phone.value = '';
                    email.value = '';
                    name.value = '';
                    details.value = '';
                    lookingFor.value = '';
                }else{
                    ElMessage.error(res.data.errMsg)
                }
            })
       }

      return {
        itemFlag1,
        itemFlag2,
        itemFlag3,
        itemFlag4,
        itemFlag5,
        itemFlag6,
        cutover,
        name,
        email,
        lookingFor,
        details,
        submit,
        phone
      };
    }
}
</script>
<style lang="less">
.contactUs{
    .contact_banner{
        text-align: left;
        padding:260px 0 0  410px;
        height: 750px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/contact_banner%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        color: white;
        h2{
            font-size: 46px;
            font-weight: 900;
            margin-bottom: 30px;
        }
        p{
            font-size: 24px;
            margin-bottom: 16px;
        }
    }
    .contact_banner2{
        height: 887px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/contact_banner2%402x.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 107px;
        .looking_for_support{
            width: 1100px;
            background: white;
            margin: 0 auto;
            border-radius: 10px;
            padding: 32px 50px;
            overflow: hidden;
        }
        .enter{
            width: 400px;
            text-align: left;
            float: left;
            h4{
                padding-bottom: 6px;
                font-size: 20px;
                font-weight: 600;
            }
            .lable{
                margin-top: 24px;
                font-size: 16px;
                color: #666666;
                margin-bottom:16px; 
            }
            .enter_input{
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 10px;
            }
            .enter_select{
                border: 1px solid #D9D9D9;
                padding: 10px;
                select{
                    width: 100%;
                    cursor:pointer;
                    
                }
            }
            textarea{
                width: 400px;
                border:1px solid #D9D9D9;
                resize: none;
                height: 100px;
                padding: 5px 10px;
            }
            .submit{
                width: 120px;
                height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                background: #FB741B;
                line-height: 40px;
                margin-top: 24px;
                cursor: pointer;
            }
        }
        .our_working{
            float: right;
            text-align: left;
            width: 560px;
            h4{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 30px;
            }
            .our_working_left,.our_working_right{
                float: left;
                width: 220px;
                .item{
                    overflow: hidden;
                    margin-bottom: 40px;
                }
                .title{
                    font-size: 16px;
                    font-weight: 500;
                }
                img{
                    display: block;
                    width: 16px;
                    float: left;
                    cursor: pointer;
                }
                p{
                    margin-top: 10px;
                    font-size: 14px;
                }
                .text{
                    float: left;
                    margin-left: 10px;
                }
            }
            .our_working_right{
                margin-left: 66px;
            }
        }
    }
    .your_comments{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/contacy_us/contact_banner3%402x.png) no-repeat;
        background-size: 100% 100%;
        height: 330px;
        padding: 60px 0;
        h2{
            font-weight: 900;
            font-size: 34px;
            color: white;
        }
        p{
            font-size: 16px;
            color: white;
        }
        .important{
            width: 800px;
            height: 50px;
            margin: 0 auto;
            margin-top: 20px;
            input{
                width: 100%;
                height: 100%;
                padding: 5px;
                font-size: 24px;
            }
        }
        .submit{
            width: 200px;
            height: 44px;
            background: #FB741B;
            text-align: center;
            line-height: 44px;
            color: white;
            margin: 0 auto;
            margin-top: 24px;
            cursor: pointer;
        }
    }
}

</style>
