<template>
    <div class="PasswordInputBox">
         <div class="input-row" :class="errorFlag?'error':''">
            <div class="value">
                <input 
                    :type="isPasswordType?'text':'password'" 
                    v-model="password"  
                    :placeholder="placeholder"
                    @input="blurFunc(password)"
                    :maxlength="maxlength"
                />
            </div>
            <span class="password-icon">
            <img :src="isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
            : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
            @click="isPasswordType = !isPasswordType;"
            class="pw-icon"
            >
            </span>
        </div>
         <p class="error-text" v-if="errorFlag">{{errText}}</p>
    </div>
</template>

<script>
import {
 ref,toRefs,watch
} from 'vue';
export default {
    name:'PasswordInputBox',
    props: {
        value: { // 输入框的值
            type: String,
            default: '',
        },
        placeholder: { // 输入框的提示
            type: String,
            default: '',
        },
        blurFunc: { // 失去焦点的回调事件
            type: Function,
        },
        placeholder: { 
            type: String,
            default: '',
        },
        errorFlag:{
            type: Boolean,
            default: false,
        },
        errText:{
           type: String,
            default: '', 
        },
        maxlength:{
          type:Number,
        }
    },
    setup(){
        const isPasswordType = ref(false)
        const password = ref('')
        
        


        return {
            isPasswordType,
            password
        }
    }
}
</script>
<style lang="less" scoped>
.PasswordInputBox{
    position: relative;
    .input-row {
      margin: 0 auto;
      background: white;
      display: flex;
      justify-content: left;
      margin-bottom: 20px;/*no*/
      border-radius: 4px;/*no*/
      height: 40px;/*no*/
      position: relative;
      border: 1px solid #EBEBEB;/*no*/
      .value{
        padding-top: 10px;/*no*/
        width: 100%;/*no*/
      }
      .password-icon{
        position: absolute; 
        right: 16px;/*no*/
        top: 10px;/*no*/
        cursor: pointer;
      }
       .send{
         position: absolute;
         right: 10px;/*no*/
         top: 4px;/*no*/
        }
      .prefix{
        width: 80px;/*no*/
        padding-left: 10px;/*no*/
        border-right: 1px solid #EBEBEB;/*no*/
        text-align: left;
        cursor: pointer;
        position: relative;
        line-height: 40px;/*no*/
        .icon_search{
          position: relative;
          top: 8px;/*no*/
        }
        .icon_choice{
          width: 8px;/*no*/
          position: absolute;
          right: 9px;/*no*/
          top: 18px;/*no*/
        }
      }
      input{
        height: 20px;/*no*/
        outline: none;
        padding-left: 16px;/*no*/
        width: 100%;
      }
      .value_code{
        padding-left: 10px;/*no*/
      }
 }
 .error{
     border: 1px solid @red;/*no*/
 }
 .error-text{
     color: @red;
     position: absolute;
     bottom: -19px;/*no*/
 }
}
</style>
