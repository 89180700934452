<template>
    <div class="Withdrawal">
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane v-for="item in payTypeList" :label="item.name" :name="item.name">
                <component :is="item.payType==53?BankTransfer:item.payType==50?USDTWithdraw:item.payType==51?NormalWithdraw:item.payType==52?BlockChainWithdraw:''" :payType="item.payType" v-if="item.name == activeName"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
 ref,computed
} from 'vue';
import apiService from '@/api/request';
import BankTransfer from './components/BankTransfer'
import BlockChainWithdraw from './components/BlockChainWithdraw'
import NormalWithdraw from './components/NormalWithdraw'
import USDTWithdraw from './components/USDTWithdraw'
import { useStore } from 'vuex';
import { useRoute } from "vue-router"

export default {
    name:'Withdrawal',
    components:{
        BankTransfer,
        BlockChainWithdraw,
        NormalWithdraw
    },
    setup(){
        const store = useStore();
        const route = useRoute(); 

       
        const payTypeList = ref([]);//通道列表
        const onlineFlag = ref(false);//是否有网银入金
        const activeName = ref('');

        GetWithdrawType();
        // 获取入金通道大分类
        function GetWithdrawType(){
            apiService.GetWithdrawType({currencyCategoryId:route.query.id}).then(res => {
                payTypeList.value = res.data.list
                activeName.value = res.data.list[0].name;
            })
        }

        return {
            payTypeList,
            onlineFlag,
            activeName,
            BankTransfer,
            BlockChainWithdraw,
            NormalWithdraw,
            USDTWithdraw
        }
    }
}
</script>
<style lang="less" scoped>
.Withdrawal{

}
</style>
<style lang="less">
.Withdrawal{
     .el-tabs__nav{
        float: none;
    }
    .el-tabs__item{
        padding-right:0 !important;
        padding-left:0 !important;
    }
}
</style>
