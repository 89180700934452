import mitt from 'mitt';

const emitter = mitt();
const mitter = {
  $pub: (...args) => {
    emitter.emit(args.shift(), [...args]);
  },
  $sub: (...args) => {
    Reflect.apply(emitter.on, emitter, [...args]);
  },
  $unsub: (...args) => {
    Reflect.apply(emitter.off, emitter, [...args]);
  },
};
export default mitter;
