<template>
    <div class="SetTradingPassword">
        <el-dialog
            :title="$t('components.text8')"
            v-model="SetTradingPasswordModelFlag"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
            @closed="beforeClose"
        >
            <p class="warn">* {{$t('components.text9')}}</p>
            <PasswordInputBox 
                :blurFunc="PwdBlur" 
                :value="password"
                :placeholder="$t('components.text2')"
                :maxlength="6"
            />
            <PasswordInputBox 
                :blurFunc="Pwd2Blur" 
                :value="password2"
                :placeholder="$t('components.text10')"
                :errorFlag="errorFlag"
                :errText="errText"
                :maxlength="6"
            />
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="SetTradingPasswordModelFlag = false">{{$t('trade.text72')}}</el-button>
                <el-button type="primary" :loading='loading' :disabled="!submitFlag" @click="Confirm"
                    >{{$t('trade.text73')}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  inject,
  onBeforeUnmount
} from 'vue';
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";


export default {
    name:'SetTradingPassword',
    setup(){
        const emitt = inject('emitt');
        const SetTradingPasswordModelFlag = ref(false);
        const errorFlag = ref(false);//密码错误提示
        const loading = ref(false);
        const store = useStore();
        const { t } = useI18n()

        const password = ref('');
        const password2 = ref('');
        const errText = ref('');
        /**
         * 打开弹框-开始
         */ 
          emitt.$sub('openSetTradingPassword', (val) => {
            SetTradingPasswordModelFlag.value = true;
          });
          onBeforeUnmount(() => {
            emitt.$unsub("openSetTradingPassword");
          });
        /**
         * 打开弹框-开始
         */ 

        /**
         * 按钮提交条件-开始
         */
            const submitFlag = computed(() => {
                return  password.value != "" && 
                        password2.value != "" && password.value.length == 6 && password2.value.length == 6
            })
        /**
         * 按钮提交条件-结束
         */

        /**
         * 组件赋值操作-开始
         */
            function PwdBlur(val) {
                errorFlag.value = false;
                password.value = val;
            }
            function Pwd2Blur(val) {
                errorFlag.value = false;
                password2.value = val;
            }
        
        /**
         * 组件赋值操作-结束
         */

        /**
         * 提交修改-开始
         */
            function Confirm(){

                if(password.value !== password2.value){
                    errorFlag.value = true;
                    errText.value = t('signUp.text33');
                    return
                }
                if(Number(password.value)+'' !==NaN+'' ){
                }else{
                    errText.value = t('components.text3')
                    errorFlag.value = true;
                    return
                }
                //6位密码不能相同
                if (/(\w)*(\w)*(\w)*(\w)*(\w)\5{5}(\w)*/g.test(password.value)) {
                  errText.value = t('components.text4')
                  errorFlag.value = true;
                  return
                }
                if(password.value.length != 6){
                  errText.value = t('components.text5')
                  errorFlag.value = true;
                  return
                }

                let arr = password.value.split('');
                let index1 = arr[0]
                let index2 = arr[1]
                let index3 = arr[2]
                let index4 = arr[3]
                let index5 = arr[4]
                let index6 = arr[5]
                if (
                    index1 - index2 == 1 &&
                    index2 - index3 == 1 &&
                    index3 - index4 == 1 &&
                    index4 - index5 == 1 &&
                    index5 - index6 == 1
                ) {
                    errText.value = t('components.text6')
                    errorFlag.value = true;
                    return
                }
                if (
                    index6 - index5 == 1 &&
                    index5 - index4 == 1 &&
                    index4 - index3 == 1 &&
                    index3 - index2 == 1 &&
                    index2 - index1 == 1
                ){
                    errText.value = t('components.text6')
                    errorFlag.value = true;
                    return
                }

                
                let params = {
                    tradePwd:password.value
                }
                loading.value = true;
                apiService.TradePasswordSet(params).then(res => {
                    console.log(res)
                    if(res.status == 1){
                        store.dispatch('getUserStatus');
                        SetTradingPasswordModelFlag.value = false;
                        ElMessage({
                            message: t('signUp.text36'),
                            type: 'success',
                        });
                    }else{
                         ElMessage({
                            message: res.message,
                            type: 'error',
                        });
                    }
                    loading.value = false;
                })
            }
        /**
         * 提交修改-开始
         */

        /**
         * 关闭弹框-开始
         */
            function beforeClose(){
                password.value = '';
                password2.value = '';
                errorFlag.value = false;
            }
        /**
         * 关闭弹框-结束
         */
        return {
            SetTradingPasswordModelFlag,
            PwdBlur,
            Pwd2Blur,
            password,
            password2,
            loading,
            errorFlag,
            submitFlag,
            Confirm,
            errText,
            beforeClose
        }
    }
}
</script>
<style lang="less" scoped>
.SetTradingPassword{
    .warn{
        color: @red;
        font-size: 12px;/*no*/
        margin-bottom: 24px;/*no*/
    }
}
</style>
