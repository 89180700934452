import { createRouter, createWebHistory } from 'vue-router';
import { xzLocal } from '../until/common';
import  routes  from './routes';
import { ElMessage,ElMessageBox} from 'element-plus'
import { h } from 'vue';
import { useI18n } from "vue-i18n";
import store from '@/store';

const lang = store.getters.getNowTheme.lang;
const router = createRouter({
  history: createWebHistory(),
  routes,
});





router.beforeEach((to,from,next)=>{
  console.log("to,from",to,from)
  
  let ostatus = xzLocal.get('userStatus')?xzLocal.get('userStatus').ostatus:1
  let addressStatus = xzLocal.get('userStatus')?xzLocal.get('userStatus').addressStatus:1
  if(to.path == '/withdrawalCurrency'){
    if(ostatus!=3){
      ElMessageBox.confirm(lang=='cn'?'您尚未完成实名认证，根据Advantrade合规要求，您不能申请出金，请完成实名认证后再尝试出金':'According to Advantrade compliance requirements, you must complete the ID and address verification before applying for withdrawal. Please complete the verification and apply for withdrawal again.',
        '',
        {
          confirmButtonText: lang=='cn'?'实名认证':'Verify',
          cancelButtonText: lang=='cn'?'暂不实名':'Cancel',
        }
      ).then(() => {
          if(from.path == '/accountInformation/myAccount'){
            goVerfy(ostatus,addressStatus)
          }else{
            next({name:'myAccount',params:{needVerification:true}})
          }
        }) .catch(() => {
        })
      return
    }
  }
  if(to.path == '/depositCurrency'){
    if(ostatus!=3 && ostatus!=2){
      ElMessageBox.confirm(
        h('div',null,lang=='cn'?h('p','您尚未完成实名认证，根据Advantrade合规要求，在完成实名认证前您只能使用数字货币入金，入金后可正常交易，但不可出金；完成实名认证后可获得使用网银入金的权限和出金权限'):[
          h('p','1. You can only fund your account with digital currency before your account is verified. '),
          h('p','2. You can trade normally but not able to withdraw before your account is verified. Please verify your account before you make withdrawal.           '),
      ]),
      lang=='cn'?'':'Your account is partially functional before verification, the details as below:      ',
      {
        confirmButtonText: lang=='cn'?'继续入金':'Deposit',
        cancelButtonText: lang=='cn'?'实名认证':'Verify',
      }
      ).then(() => {
          next()
      }) .catch(() => {
        if(from.path == '/accountInformation/myAccount'){
          goVerfy(ostatus,addressStatus)
        }else{
          next({name:'myAccount',params:{needVerification:true}})
        }
      })
      return
    }
  }
  if(to.meta.requireAuth&&!xzLocal.get('userToken')){//需要登录的页面
    next({name:'signUp'})
    return
  }else if(to.meta.requireRealName&&ostatus!=2&&ostatus!=3){//需要实名验证的页面
    next({name:'myAccount'})
    setTimeout(()=>{
      ElMessage({
        type:'error',
        title:'为保障账户资金安全,请先通过实名认证'
      })
    })
    return
  }
  // firefox
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  next()
})


function goVerfy(ostatus,addressStatus){
  console.log("ostatusostatusostatus22222",ostatus)
    switch(ostatus){
      /** 展示第一步页面 */
      case 0:
      case 1://未认证
      case 6://审核未通过-存在于黑名单中
      case 4://身份证错误
          router.push({
              name:"certification",
                  query:{
                      step:1
                  }
              })
          break;
      /** 展示证件收集页面-第二步*/
      case 11://已上传信息，未提交证件
      case 16://重填证件信息
      case 17://审核未通过-身份证翻译失败
      router.push({
              name:"certification",
                  query:{
                      step:2
                  }
              })
          break;
      case 7://审核未通过-地址认证失败
      case 13://已提交证件，未提交地址证件
      router.push("addressFailed");
      break;
      case 2://等待审核
      router.push({
              name:"certification",
              query:{
                  step:4
              }
          })
      break;
      /** 展示已认证页面-第三步*/
      case 3://已认证
          if(addressStatus)
      break;

      case 5://签名错误
          router.push({
              name:"certification",
              query:{
                  step:3
              }
          })
          break;
      case 12://已提交证件，未提交签名
          router.push({
              name:"certification",
              query:{
                  step:3
              }
          })
      break;
      /** 展示手持身份证页面*/
      case 14://已提交证件，未提交手持身份证件
      case 15://审核未通过，手持身份证认证失败
      router.push("selfieHolding");
      break;
  }
}
export default router;
