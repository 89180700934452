const messages = {
    trade:{
        text1: 'Product name',
        text2: 'Favourites',
        text3: 'Products',
        text4: 'Ask',
        text5: 'Spread',
        text6: 'Last price',
        text7: 'Bid',
        text8: '%Change',
        text9: 'Removed from favourites',
        text10: 'Added to favourites',
        text11: 'High',
        text12: 'Low',
        text13: 'Quotes',
        text14: 'Rules',
        text15: 'Analysis',
        text16: 'Trading period(UTC+8)',
        text17: 'Day',
        text18: 'Quote',
        text19: 'Trade',
        text20: 'Market Analysis',
        text21: 'Short',
        text22: 'Long',
        text23: 'Interpretation of indicators',
        text24: 'm',
        text25: 'h',
        text26: 'd',
        text27: 'w',
        text28: 'MN',
        text29: 'Summary',
        text30: 'Buy',
        text31: 'StrongBuy',
        text32: 'Sell',
        text33: 'StrongSell', 
        text34: 'Neutral',
        text35: 'Oscillator',
        text36: 'Strong',
        text37: 'Indicators',
        text38: 'Numerical value',
        text39: 'Signal',
        text40: 'Moving average',
        text41: 'Name',
        text42: 'more',
        text43: 'Pivot point',
        text44: 'Classic',
        text45: 'Fibonacci',
        text46: 'Live account',
        text46_1: 'Demo account',
        text47: 'Positions',
        text48: 'Pending order',
        text49: 'Close all positions',
        text50: 'Product',
        text51: 'Direction',
        text52: 'Lot',
        text53: 'Open price',
        text54: 'Last price',
        text55: 'Floating PnL',
        text56: 'Margin',
        text57: 'Swap',
        text58: 'Take profit price',
        text59: 'Stop loss price',
        text60: 'Opening time',
        text61: 'Transaction number',
        text62: 'Operate',
        text63: 'Close position',
        text64: 'Close and reverse',
        text65: 'Take profit and Stop loss',
        text66: 'no data',
        text67: 'Login',
        text68: 'Closing product',
        text69: 'Holding lots',
        text70: 'Lots to close',
        text71: 'Floating PnL',
        text72: 'Cancel',
        text73: 'Confirm',
        text74: ' Promotion shares have been confirmed and can be unlocked after verification. ',
        text75: 'Order details',
        text76: 'Closing PnL',
        text77: 'Closing price',
        text78: 'Rebate',
        text79: 'Swap',
        text80: 'Minimum ',
        text81: ' lots can be closed',
        text82: 'Maximum ',
        text83: 'Are you sure to close all positions?',
        text84: 'OK',
        text85: 'Confirm to close current position and open a new reverse order?',
        text86: 'Success',
        text87: 'Pending order price',
        text88: 'Estimated margin',
        text89: 'Order time',
        text90: 'End date',
        text91: 'Modify order',
        text92: 'Cancel order',
        text93: 'Modify order',
        text94: 'https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_buy_red%402x.png',
        text94_green: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_buy_en.png',
        text94_blue: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_blue_up_buy_en.png',
        text95: 'https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_sell_green%402x.png',
        text95_green: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_sell_en.png',
        text95_blue: 'https://advantrade.oss-accelerate.aliyuncs.com/web/icon_green_up_sell_en.png',
        text96: 'Price',
        text97: ' or ',
        text98: 'Estimated profit',
        text99: 'Estimated loss',
        text100: 'Order expiry time',
        text101: 'Please enter the pending order price',
        text102: 'Stop loss setting',
        text103: 'Stop profit setting',
        text104: 'Not set',
        text105: 'Invalid pending order price',
        text106: 'The pending order deadline should be greater than the current latest time',
        text107: 'Confirm cancellation？',
        text108: 'Live account',
        text109: 'Balance',
        text110: 'Equity',
        text111: 'Floating PnL',
        text112: 'Free margin',
        text113: 'Margin',
        text114: 'Credit bonus',
        text115: 'Margin level',
        text116: 'Type',
        text117: 'Market trading',
        text118: 'Pending order',
        text119: 'Lots',
        text120: 'Pending order price',
        text121: 'closed',
        text122: 'Please enter trading password',
        text123: 'Trading product',
        text124: 'Forgotten password',
        text125: 'Insufficient margin',
        text126: 'The trading password has been locked',
        text127: 'please reset trading password',
        text128: 'Retrieve trading password',
        text129: `The lot size can't be less than`,
        text130: `The lot size can't be higher than`,
        text131: `Please enter the pending order price`,
        text132: `You need to make a deposit before you can use the bonus`,
        text133: `Take profit and Stop loss`,
        text134: `Closing time`,
        text135: `1m`,
        text136: `5m`,
        text137: `15m`,
        text138: `30m`,
        text139: `1h`,
        text140: `4h`,
        text141: `1d`,
        text142: `1w`,
        text143: `1m`,
        text144: `Order confirmation`,
        text145: `Buy`,
        text146: `Sell`,
        text147: `Trade`,
        text148: `The demo account has expired`,
        text149: 'Partial close',
        text150: 'Instant trading opened',
        text151: 'Click to expand',
        text152: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_zk_right_en.png',
        text153: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_sq_right_en.png',
        text154: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_zk_left_en.png',
        text155: 'https://advantrade.oss-ap-southeast-1.aliyuncs.com/web/icon_sq_left_en.png',
    },
    signUp:{
        text1:'Register now to start',
        text2:'your investment journey',
        text3:'Support Subscription, Copy Transactions, and Share Profits with Top Traders.',
        text4:'Fund safety - Rapid deposit and withdrawal.',
        text5:'User friendly - fulfilling the needs of the different investors.',
        text6:'Excellent Resource - free trading analysis and 1 on 1 trading guideline.',
        text7:'Mobile Verification Login',
        text8:'New users can register and log in directly',
        text9:'Phone login',
        text10:'Email login',
        text11:'By clicking the button,you understand and agree to the',
        text12:'《CLIENT AGREEMENT》',
        text13:'Enter phone number',
        text14:'Select Region',
        text15:'Enter phone verification code',
        text16:'Get Code',
        text17:'Resend',
        text18:'Sending',
        text19:'Send completed',
        text20:'please enter phone number',
        text21:'Successful landing',
        text22:'Login',
        text23:'Use password to login',
        text24:'Mobile Verification login',
        text25:'Forgotten password',
        text26:'Email address',
        text27:'Enter password',
        text28:'Phone',
        text29:'Email',
        text30:'Set login password',
        text31:'Enter a 8-15 digits and letters combination',
        text32:'Please confirm password',
        text33:'Passwords do not match',
        text34:'Skip',
        text35:'Confirm',
        text36:'Set successfully',
        text37:'Please enter a 8-15 digits and letters combination',
        text38:'Login/Register',
        text39:'Password does not match, please re-enter',
        text40:'Password reset successful',
        text41:'Email verification code',
        text42:'please enter email',
        text43:'Email format error',
        text44:'Verification code error',
    },
    components:{
        text1:'Change trading password',
        text2:'Enter a 6-digit password',
        text3:'Please enter a 6-digit password',
        text4:'Transaction password cannot be a repeat of six digits',
        text5:'Transaction password 6 digits',
        text6:'Transaction password cannot be consecutive six digits',
        text7:'Change successfully',
        text8:'Set trading password',
        text9:'Transaction password cannot be repeated or consecutive 6 digits',
        text10:'Please confirm passwords',
        text11:'Unbind',
        text12:'Select a bank card',
        text13:'Bind email',
        text14:'Enter phone verification code',
        text15:'Next',
        text16:'Enter email address',
        text17:'Enter email verification code',
        text18:'Email binding successfully',
    },
    myAccount:{
        text1:'My account',
        text2:'Account assets',
        text3:'Historical orders',
        text4:'Deposit',
        text5:'Withdrawal',
        text6:'Funds Details',
        text7:'Fund history',
        text8:'Fund transfer',
        text9:'Basic infomation',
        text10:'Avatar',
        text11:'Change',
        text12:'Nickname',
        text13:'Advantrade account',
        text14:'Mobile phone number',
        text15:'Verification',
        text16:'Proof of address',
        text17:'Unsubmitted',
        text18:'Reviewing',
        text19:'Address proof error',
        text20:'MT5 live account',
        text21:'Personal information',
        text22:'Submit',
        text23:'Edit',
        text24:'Email',
        text25:'Unbind',
        text26:'Bind',
        text27:'Security center',
        text28:'Login password',
        text29:'Set',
        text30:'MT5 live account password',
        text31:'Reset',
        text32:'Trading password',
        text33:'Bank card',
        text34:'Add',
        text35:'Withdrawal address',
        text36:'Delete',
        text37:'Unverified',
        text38:'Verified',
        text39:'Identity Verification failed',
        text40:'Upload',
        text41:'Invalid Signature',
        text42:'Failure of address authentication',
        text43:'No ID uploaded',
        text44:'Signature not yet submitted',
        text45:'waiting ID selfie photo to be uploaded',
        text46:'Selfie photo invalid',
        text47:'Confirm to unbind email',
        text48:'Email unbind successfully',
        text49:'The MT5 Live password can only be reset once a day',
        text50:'Unbind your ',
        text51:' card ending in ',
        text52:'Unbind successfully',
        text53:'Delete the withdrawal address?',
        text54:'Address deleted successfully?',
        text55:'According to compliance requirements, you have to submit the address proof documents.',
        text56:'Resubmit',
        text57:'Your proof of address did not pass the verification, please resubmit',
        text58:'Attach Document',
        text59:'* The answers to the following questions will be used to monitor risks and optimise risk reminders,please answer accurately',
        text60:'Family member in household',
        text61:' person',
        text62:' persons',
        text63:' persons and above',
        text64:'Marital status',
        text65:'Married',
        text66:'Single',
        text67:'Divorced',
        text68:'Widowed',
        text69:'Career status',
        text70:'Employed',
        text71:'Unemployed',
        text72:'Student',
        text73:'Retired',
        text74:'Select industry',
        text75:'Finance/Banking/Insurance',
        text76:'Government/Institution',
        text77:'Advertising PR/Media/Art and Culture',
        text78:'Sales/Trade',
        text79:'Computer/Internet/Communication/Electronic Technology',
        text80:'Manufacturing/logistics transportation',
        text81:'Pharmaceutical/Chemical',
        text82:'Construction/Real Estate',
        text83:'Education/Legal',
        text84:'Catering/Entertainment/Beauty',
        text85:'Other',
        text86:'Total net asset value',
        text87:'Investment objective',
        text88:'Investment experience',
        text89:'Less than ',
        text90:'Asset appreciation',
        text91:'Speculation',
        text92:'Arbitrage',
        text93:'Hedge',
        text94:' year',
        text95:' years',
        text96:'More than',
        text97:'success',
        text98:'Modify avatar',
        text99:'Avatar',
        text100:'Update',
        text101:'Modify completed',
        text102:'The length of the nickname cannot exceed 15 characters',
        text103:'Please input',
        text104:' login password',
        text105:'Change login password',
        text106:'Enter original password',
        text107:'Login password reset complete',
        text108:'Reset MT5 live password',
        text109:'* The new MT5 password will be sent to your mobile phone',
        text110:'Add available bank card',
        text111:'Beneficiary name',
        text112:'Currency',
        text113:'Bank name',
        text114:'Bank account number',
        text115:'Swift code',
        text116:'Bank address',
        text117:'Please enter',
        text118:'Save',
        text119:'Select available bank',
        text120:'Bank card added successfully',
        text121:'Choose bank name',
        text122:'Add withdrawal address',
        text123:'Remarks',
        text124:'No more than 10 characters',
        text125:'Address added successfully',
        text126:'Please select',
        text127:'Modify nickname',
        text128:'Add bank card',
        text129:'Bank code',
        text130:'Choose or enter bank name',
        text131:'Select withdrawal address',
        text132:'View',
        text133:'More than ',
        text134:'Less than 100,000 USD',
        text135:'100,000 - 200,000 USD',
        text136:'200,000 - 300,000 USD',
        text137:'300,000 - 500,000 USD',
        text138:'500,000 - 1,000,000 USD',
        text139:'1,000,000 - 5,000,000 USD',
        text140:'5,000,000 - 10,000,000 USD',
        text141:'More than 10,000,000 USD',
        text142:'MT4 live account',
        text143:'MT4 live account password',
        text144:'The MT4 Live password can only be reset once a day',
        text145:'Reset MT4 live password',
        text146:'* The new MT4 password will be sent to your mobile phone',
        text147:'Please enter bank name',
        
    },
    AccountAssets:{
        text1:'Live account',
        text2:'Account assets',
        text3:'Equity',
        text4:'Balance',
        text5:'Floating PNL',
        text6:'Credit bonus',
        text7:'Free Margin',
        text8:'Margin',
        text9:'Margin level',
        text10:'Total PNL',
        text11:'Total rebate',
        text12:'Total deposit',
        text13:'Total withdrawal',
        text14:'Trading products',
        text15:'Opening time',
        text16:'All',
        text17:'Start date',
        text18:'End date',
        text19:'Search',
        text20:'Statistics',
        text21:'Total swap',
        text22:'Total lots',
        text23:'Total orders',
        text24:'Closed',
        text25:'Cancelled',
        text26:'Country',
        text27:'Last name',
        text28:'First name',
        text29:'Date of birth(MM/DD/Year)',
        text30:'Full address',
        text31:'ID number',
        text32:'Verification must be completed in order to trade and enjoy further services',
        text33:'Confirm to quit verification?',
        text34:'Continue verification',
        text35:'Verification',
        text36:'Informations',
        text37:'Upload documents',
        text38:'Confirm agreement',
        text39:'Supporting Documents:',
        text40:'Photo ID',
        text41:'Please provide a colour photo copy of the main page of your current valid passport, or both sides of your photo ID or driving licence.',
        text42:'Required',
        text43:'Address Proof',
        text44:'Please provide a scanned copy or photo of utility bills, bank statement or other kind of documents with address issued within last 6 months,suggest to provide bank statement in a pdf format.',
        text45:'Optional',
        text46:'File example',
        text47:'Correct',
        text48:'example',
        text49:'Information',
        text50:'ambiguity',
        text51:'Missing',
        text52:'corners',
        text53:'Please confirm that you have read and agree to the following agreemen：',
        text54:'《Client Agreement》',
        text55:'《Form W8-BEN》',
        text56:'《ANNEX I CONFLICT OF INTEREST》',
        text57:'《ANNEX II Risk Disclosure》',
        text58:'《ANNEX III Best Execution Policy》',
        text59:'Please confirm the following statement：',
        text60:'I am the ultimate beneficiary of this account',
        text61:'I am not a U.S. citizen or a citizen for tax purposes',
        text62:'I am not an Advantrade agent or related to any of their staff',
        text63:`All information provided by me is true and accurate, and I agree to notify Advantrade by phone or email if any information changes. If necessary, I authorize Advantrade to confirm the accuracy of the information, and sign it to indicate that I've read and understand the content of the risk warning policy.`,
        text64:`Account opening under review`,
        text65:`Your information has been submitted, please allow 1-2 working days for the reviewal process, once successful you will be notified, do not hesitate to contact us if you have any questions.`,
        text66:`Time`,
        text67:`Total bonus`,
        text68:`Total transfer`,
        text69:`Amount`,
        text70:`Transfer type`,
        text71:`Amount to transfer`,
        text72:`Transferable amount`,
        text73:`Verification`,
        text74:`* We have received your application. In order to fulfil Advantrade regulation compliance system and to prevent others from maliciously using your identity, you need to upload a selfie holding your ID document.`,
        text75:`Please upload selfie holding your ID document`,
        text76:`Please upload selfie displaying the front of your ID document.`,
        text77:`The profile picture and text must be clear,  without reflections.`,
        text78:`The entire face and the four corners and borders of the ID card must be included.`,
        text79:`Selfie example`,
        text80:`Cancel`,
        text81:`Submit`,
        text82:`I have read the full agreement. I understand the risks and would like to continue to open an account with Advantrade`,
        text83:`Clear signature`,
        text84:`Please sign here`,
        text85:`High-risk aged category investors agreement:`,
        text86:`* According to the Advantrade compliance system, you have to submit the proof of address documents.`,
        text87:`* Your proof of address did not pass the verification, please resubmit.`,
        text88:`Address Proof`,
        text89:`Proof of address under review`,
    },
    fundHistory:{
        text1:'Deposit',
        text2:'Withdrawal',
        text3:'Bonus',
        text4:'Rebate',
        text5:'Transfer',
        text6:'Deposit type',
        text7:'Order status',
        text8:'Time',
        text9:'Status',
        text10:'Deposit amount',
        text11:'Time',
        text12:'Operate',
        text13:'Details',
        text14:'Status',
        text15:'Currency',
        text16:'Deposit amount',
        text17:'Payment amount',
        text18:'Submission time',
        text19:'Arrival time',
        text20:'Click on the wallet address or TxHash to view blockchain details',
        text21:'Address',
        text22:'The order has been confirmed for payment, waiting for the funds to arrive',
        text23:'The order will expire after',
        text24:'(please send money as soon as possible and confirm the payment complete)',
        text25:'Purchase quantity',
        text26:'Payment amount',
        text27:'Payment method',
        text28:'Account number',
        text29:'Payee',
        text30:'Cancel order',
        text31:'Payment confirmed',
        text32:'Confirm to cancel the order?',
        text33:'Order canceled successfully',
        text34:'Order confirmation has completed payment?',
        text35:'Order confirmation successfully',
        text36:'Loading',
        text37:'Withdrawal type',
        text38:'Withdrawal amount',
        text39:'Estimated credited',
        text40:'Handling fee',
        text41:'Revoke',
        text42:'Receipt amount',
        text43:'Handling fee',
        text44:'Recipient bank',
        text45:'Confirm whether to cancel the withdrawal order?',
        text46:'Revocation successful',
        text47:'Bonus type',
        text48:'Type',
        text49:'Commission type',
        text50:'Total Commission',
        text51:'Trading lots',
        text52:'Transaction time',
        text53:'Transfer amount',
        text54:'Transfer to',
        text55:'Transaction time',
        text56:'Minimum transfer amount ',
        text57:'Exceeded transferable amount',
        text58:'Submitted successfully',
    },
    deposit:{
        text1:'Choose the currency',
        text2:'*Please select the currency you want to payment, we will provide the appropriate deposit channel according to the currency you choose.',
        text3:'Process transfer',
        text4:'Upload documentation',
        text5:'Completed',
        text6:'Bank information',
        text7:'Reveal',
        text8:'Payee name',
        text9:'Payee local area',
        text10:'Please fill your remittance information',
        text11:'Remittance bank',
        text12:'Remittance amount',
        text13:'Remittance documentation',
        text14:'*Uploaded image size must not be exceed 4MB',
        text15:'Bank information',
        text16:'Payee name',
        text17:'Payee local area',
        text18:'Payee address',
        text19:'Payee bank',
        text20:'Payee account',
        text21:'Payee bank address',
        text22:'swift code',
        text23:'Copy all information',
        text24:'Copy completed',
        text25:`The deposit amount can't be less than `,
        text26:`Submitted successfully, after the bank confirms details, the amount will be credited usually upto 1 working day, please wait patiently. `,
        text27:`Choose currency`,
        text28:`Blockchain type`,
        text29:`Exchange rate`,
        text30:`Deposit amount`,
        text31:`Estimated payment amount`,
        text32:`Please enter amount`,
        text33:`Your personal `,
        text34:` deposit address`,
        text35:'Single limit ',
        text36:`Max `,
        text37:`Min `,
        text38:`Channel`,
        text39:`Deposit amount`,
        text40:`Other`,
        text41:`Fee`,
        text42:`Payment amount`,
        text43:`Exchange rate`,
        text44:`Country`,
        text45:`Bank`,
        text46:`Confirm`,
        text47:`Please note:`,
        text48:`Exchange rate`,
        text49:`Purchase quantity`,
        text50:`Verification`,
        text51:`*The initial payment amount is an estimation, please pay according to the real-time generated order amount.`,
        text52:`Incomplete Orders`,
        text53:`The order will expire after `,
        text54:`(please send money as soon as possible and confirm the payment complete)`,
        text55:`*The order has been confirmed for payment, waiting for the funds to arrive`,
        text56:`Status`,
        text57:`Payment method`,
        text58:`Deposit amount`,
        text59:`Account number`,
        text60:`Copy`,
        text61:`Payee`,
        text62:`Time`,
        text63:`Cancel order`,
        text64:`Payment confirmed`,
        text65:`You have an unpaid order, unable to initiate a new order`,
        text66:`Proceed to payment`,
        text67:`Cancel`,
        text68:`Confirm to cancel the order?`,
        text69:`Order canceled successfully`,
        text70:`Order confirmation has completed payment?`,
        text71:`m`,
        text72:`s`,
    },
    withdraw:{
        text1:`Channel`,
        text2:`Exchange rate`,
        text3:`Available amount`,
        text4:`Withdrawal amount`,
        text5:`Estimated receipt amount`,
        text6:`All`,
        text7:`Verification`,
        text8:`Recipient account`,
        text9:`Add available bank card`,
        text10:`Use another bound card`,
        text11:`*The initial payment amount is an estimation, please pay according to the real-time generated order amount.`,
        text12:`Confirm`,
        text13:`Latest Order`,
        text14:`Status`,
        text15:`Recipient  bank`,
        text16:`Withdrawal amount`,
        text17:`Account number`,
        text18:`Estimated credited`,
        text19:`Payee`,
        text20:`Time`,
        text21:`Revoke`,
        text22:`Loading`,
        text23:`Single limit `,
        text24:`Max `,
        text25:`Min `,
        text26:`Risk warning`,
        text27:`Withdraw`,
        text28:`Cancel`,
        text29:`Submitted successfully`,
        text30:`Copy completed`,
        text31:`Confirm whether to cancel the withdrawal order`,
        text32:`OK`,
        text33:`Withdrawable amount = Equity - Credit bonus - Cash bonus - Pending orders amount`,
        text34:`For clients with open positions：`,
        text35:`Withdrawable Amount = Equity - Credit Bonus - Cash bonus - Pending Orders amount - Used Margin / Leverage X 2%`,
        text36:`(2% is a valuation of the maximum loss of your position, to ensure that the withdrawal does not affect your current position)`,
        text37:`Description of available amount`,
        text38:`Choose the currency`,
        text39:`*Please select the currency you want to receive, we will provide the appropriate withdrawal channel according to the currency you choose.`,
        text40:`Receipt currency`,
        text41:`Expected receipt amount`,
        text42:`Exchange rate`,
        text43:`Bank card information`,
        text44:`Available banks`,
        text45:`Beneficiary name`,
        text46:`Bank name`,
        text47:`Bank address`,
        text48:`Bank account number`,
        text49:`Swift code`,
        text50:`Please fill in additional information`,
        text51:`To withdraw funds using this channel,please use the following banks to receive payments.`,
        text52:`This bank is not supported`,
        text53:`Choose a bank card`,
        text54:`Add bank card`,
        text55:`Bank code`,
        text56:`Choose currency`,
        text57:`Blockchain type`,
        text58:`Available amount`,
        text59:`Handling fee`,
        text60:`Withdrawal address`,
        text61:`Select or enter address`,
        text62:`Select`,
        text63:`Expected deduction`,
        text64:`*The initial payment amount is an estimation,amount received may differ slightly due to market conditions and exchange rates`,
        text65:`Description of available amount`,
        text66:`*For users without positions：`,
        text67:`Available amount=Equity-Credit bonus-Cash bonus-Pending orders amount`,
        text68:`*For users with open positions:`,
        text69:`Available amount=Equity-Credit bonus-Cash bonus-Pending orders amount-Used margin/Leverage*2%`,
        text70:`(2% is a valuation of the maximum loss of your position, to ensure that the withdrawal does not affect your current position )`,
        text71:'Safety verification',
        text72:'*To ensure the safety of your blockchain funds,you need to verify your account',
        text73:'Exceeded available amount',
    }
  }

export default messages
