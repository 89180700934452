<template>
    <div class="NormalWithdraw">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('withdraw.text43')}}</div>
                <div class="value">
                    <div class="value_text btn_text cursor" @click="AddbankCardOrChoose">{{bankCardInfoShow.bankTypeName != ''?$t('withdraw.text53'):$t('withdraw.text54')}}</div>
                </div>
            </div>
        </div>
        <div class="line_box" v-if="bankCardInfoShow.bankTypeName != ''">
             <div class="line">
                <div class="label">{{$t('withdraw.text45')}}</div>
                <div class="value">
                    <div class="value_text">{{bankCardInfoShow.firstName}} {{bankCardInfoShow.lastName}}</div>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text46')}}</div>
                <div class="value">
                    <div class="value_text">{{bankCardInfoShow.bankTypeName}}</div>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text48')}}</div>
                <div class="value">
                    <div class="value_text">{{bankCardInfoShow.bankAccount}}</div>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text47')}}</div>
                <div class="value">
                    <div class="value_text">{{bankCardInfoShow.bankaddress}}</div>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text55')}}</div>
                <div class="value">
                    <div class="value_text">{{bankCardInfoShow.swiftCode}}</div>
                </div>
            </div>
        </div>
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('withdraw.text3')}}</div>
                <div class="value">
                    <div class="value_text">{{canDrawAmount}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text4')}}(USD)</div>
                <div class="value">
                    <el-input
                        v-model="withdrawalAmount"
                        size="large"
                        :placeholder="placeholderText"
                        class="withdrawal_amount"
                        @input="specification"
                    >
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="withdrawalAmountAll">{{$t('withdraw.text6')}}</div>
                    </template>
                    </el-input>
                </div>
            </div>
        </div>
        <el-button size="large" type="primary" class="confirm" :loading='btnLoading' :disabled="!disabled" @click="Confirm">{{$t('withdraw.text12')}}</el-button>
        <p class="arrayTips" v-html="arrayTips"></p>
        <AddBankCard :currentType="currentType" @addBankCardSuccsess="addBankCardSuccsess"/>
        <UseAnotherBoundCard @changeBankCard="changeBankCard" :walletbankId='bankCardInfoShow.walletbankId'/>

    </div>
</template>
<script>
import {
 ref,reactive,computed,inject,nextTick
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import AddBankCard from '@/components/pc/AddBankCardModel'
import UseAnotherBoundCard from '@/components/pc/UseAnotherBoundCard'
import { useI18n } from "vue-i18n";

export default {
    name:'NormalWithdraw',
    props: {
        payType: {
            type: Number,
        },
    },
    components:{
        AddBankCard,
        UseAnotherBoundCard
    },
    setup(props){
        const emitt = inject('emitt');
        const { t } = useI18n()

        const bankCardInfoShow = reactive({
            firstName:'',
            lastName:'',
            accountAddress:'',
            bankAccount:'',
            bankTypeName:'',
            swiftCode:'',
            walletbankId:0
        });
        const minWithdrawLimit = ref('');//最小入金
        const maxWithdrawLimit = ref('');//最大入金
        const placeholderText = ref('');
        const currentType = ref('');
        const arrayTips = ref('');
        const canDrawAmount = ref('');
        const payId = ref('');
        const store = useStore();
        const bankCardInfo = computed(() => store.getters.walletBank);
        if(store.getters.walletBank.length != 0){
            let obj = store.getters.walletBank.filter(e => {return e.isDefault == 1})
            if(obj.length == 0){
                obj =  store.getters.walletBank[0];
            }
            Object.assign(bankCardInfoShow,obj[0])
        }
        const withdrawalAmount = ref("");
        const btnLoading = ref(false);
        const disabled = computed(() => {
                return  withdrawalAmount.value != '' && bankCardInfoShow.bankTypeName != ''
            })

        let loading;


        openFullScreen();
         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('withdraw.text22'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }


       //规范
        function specification(v){
            // 输入的数据进行初始化，将非数字的替换为空
            let val = v;
            let isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
             while(!isMatch && val != ''){
                val = val.substring(0,val.length-1)
                isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
               }
             nextTick(() => {
                withdrawalAmount.value = val;
            });
        }
         // 获取 payId
        apiService.PayOutChannel({
            userToken: store.getters.userToken,
            payType: Number(props.payType),
            }).then(async (res) => {
            if (res.status === 1) {
                payId.value = res.data.list[0].payId;
                getPayOutChannelPage(res.data.list[0].payId);
                // LatestOnlineBankInfo();
            }
        });

          // 选择通道
        async function getPayOutChannelPage(payId) {
            // 获取通道的详情数据
            const res = await apiService.PayOutChannelPage({
                userToken: store.getters.userToken,
                payId:payId
            });

            currentType.value = res.data.currentType;
            // 最大最小限制
            minWithdrawLimit.value = res.data.minWithdrawLimit;
            maxWithdrawLimit.value = res.data.maxWithdrawLimit;

            if(minWithdrawLimit.value != '' && maxWithdrawLimit.value != ''){
                placeholderText.value = t('withdraw.text23') + minWithdrawLimit.value +'-'+ maxWithdrawLimit.value + 'USD';
            }else if(minWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text24') + maxWithdrawLimit.value + 'USD';
            }else if(maxWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text25') + minWithdrawLimit.value + 'USD';
            }
            // 整理提示数据
            arrayTips.value = res.data.arrayTips[0].tips
            canDrawAmount.value = res.data.canDrawAmount;
            loading.close();
        }
         //获取网银出金银行卡信息
        // function LatestOnlineBankInfo(){
        //     apiService.LatestOnlineBankInfo({
        //         payId:payId.value,
        //     }).then(res => {
        //         if(res.data.walletbankid != 0){
        //             Object.assign(bankCardInfoShow,res.data)
        //         }else{
        //             if(bankCardInfo.value.length !== 0){
        //                 let obj = bankCardInfo.value.filter(e => {return e.isDefault == 1})
        //                 if(obj.length == 0){
        //                     obj ==  bankCardInfo.value[0];
        //                 }
        //                 Object.assign(bankCardInfoShow,obj[0])
        //             }
        //         }
        //     }).catch(err => {

        //     })
        // }
         //全部出金
        function withdrawalAmountAll(){
            withdrawalAmount.value = canDrawAmount.value;
        }

        //添加银行卡或选择银行卡
        function AddbankCardOrChoose(){
            if(bankCardInfoShow.bankTypeName == ''){
                emitt.$pub('openAddBankCard');
            }else{
                emitt.$pub('openUseAnotherBoundCard');
            }
        }

        
        //添加成功后更新银行卡信息
        function addBankCardSuccsess(e){
            Object.assign(bankCardInfoShow,e)
        }

         //选择其他银行卡
        function changeBankCard(item){
            Object.assign(bankCardInfoShow,item)
        }

        //提交
        async function Confirm(){
            if(Number(canDrawAmount.value) < Number(withdrawalAmount.value)){
                    ElMessage.error(t('withdraw.text73'))
                    return
            }
             if(minWithdrawLimit.value != '' && maxWithdrawLimit.value != ''){
                if(Number(withdrawalAmount.value) < Number(minWithdrawLimit.value) || Number(withdrawalAmount.value) > Number(maxWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text23') + minWithdrawLimit.value +'-'+ maxWithdrawLimit.value + 'USD')
                    return;
                }
            }else if(minWithdrawLimit.value == ''){
                if(Number(withdrawalAmount.value) > Number(maxWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text24') + maxWithdrawLimit.value + 'USD')
                    return;
                }
            }else if(maxWithdrawLimit.value == ''){
                    if(Number(withdrawalAmount.value) < Number(minWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text25') + minWithdrawLimit.value + 'USD')
                    return;
                }
            }
             btnLoading.value = true;
            const res = await apiService.JudgeHavePos({});
             btnLoading.value = false;
            if(res.status == 1){
                if(res.data.hasPos){
                    if(res.data.hasCredit){
                        ElMessageBox.alert(res.data.tips,t('withdraw.text26'),
                        {
                            confirmButtonText:t('trade.text84'),
                        })
                    }else{
                        ElMessageBox.confirm(
                            res.data.tips,
                            t('withdraw.text26'),
                            {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: t('withdraw.text27'),
                            cancelButtonText: t('withdraw.text28'),
                            }
                        ).then(() => {
                            wireWalletRquest();
                            btnLoading.value = false;
                        }) .catch(() => {
                            btnLoading.value = false;
                        })
                    }
                }else{
                    wireWalletRquest();
                }
            }
        }

        //出金
        function wireWalletRquest(){
            let params = {
               walletbankId:bankCardInfoShow.walletbankId,
               amount:withdrawalAmount.value,
               currentType:currentType.value,
               fundTerminal:1,
               payChannelId:payId.value
            }
            apiService.wireWalletRquest(params).then(res => {
                if(res.status == 1){
                    withdrawalAmount.value = '';
                    getPayOutChannelPage(payId.value);
                     ElMessage({
                        message:t('withdraw.text29'),
                        type:'success'
                    })
                }else{
                     ElMessage.error(res.message)
                }
            })
        }

        return {
            bankCardInfoShow,
            minWithdrawLimit,
            maxWithdrawLimit,
            currentType,
            arrayTips,
            canDrawAmount,
            withdrawalAmountAll,
            withdrawalAmount,
            AddbankCardOrChoose,
            addBankCardSuccsess,
            changeBankCard,
            btnLoading,
            Confirm,
            disabled,
            placeholderText,
            specification
        }
    }
}
</script>
<style lang="less" scoped>
.NormalWithdraw{
    padding: 24px 30px;
 .line_box{
    .line{
        overflow: hidden;
        margin-bottom: 16px;
        .label{
            float: left;
            height: 40px;
            line-height: 40px;
            width: 212px;
            text-align: left;
            font-size: 14px;
        }
        .value{
            float: left;
            width: 500px;
            text-align: left;
            font-size: 14px;
            .value_text{
                line-height: 40px;
            }
            .btn_text{
                color: @primary-color;
                text-decoration:underline;
            }
        }
        .fast_btn_r{
            width: 64px;
            height: 40px;
            background: @primary-color;
            color: white;
            text-align: center;
            font-size: 14px;
            line-height: 40px;
        }
    }
 }
    .confirm{
        margin-top: 20px;
        width: 100%;
    }
    .arrayTips{
        margin-top: 28px;
        text-align: left;
    }
}
</style>
<style lang="less">
.NormalWithdraw{
     .withdrawal_amount{
        .el-input__suffix{
            right: 0;
        }
    }
}
</style>
