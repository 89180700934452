<template>
    <div class="BlockChain">
        <div class="line">
            <div class="label">{{$t('deposit.text27')}}</div>
            <div class="value">
                 <el-select v-model="payId" size="large" @change="payIdChange">
                        <el-option
                        v-for="item in channelArr"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
            </div>
        </div>
        <div class="line" v-show="payId == 6011">
            <div class="label">{{$t('deposit.text28')}}</div>
            <div class="value">
                <div class="radio">
                    <div class="btn_radio cursor" :class="chainValue == item.value?'active_radio':''" v-for="item in protocolAll" @click="onChange(item)">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="label">{{$t('deposit.text29')}}</div>
            <div class="value">
                <div class="value_text">{{exchangeRateShow}}</div>
            </div>
        </div>
        <div class="line">
            <div class="label">{{$t('deposit.text30')}}(USD)</div>
            <div class="value">
                <el-input class="amount_input" size="large" v-model="payMentAmount" :placeholder="placeholder" @input="buyNumberPaymentValue">
                   <template #prefix>
                       <div class="fast_btn_l cursor" @click="countPayment(-10)">-10</div>
                    </template>
                   <template #suffix>
                       <div class="fast_btn_r cursor" @click="countPayment(+10)">+10</div>
                    </template>
                </el-input>
            </div>
        </div>
        <div class="line">
            <div class="label">{{$t('deposit.text31')}}({{payCurrency}})</div>
            <div class="value">
                <el-input class="amount_input" size="large" v-model="amount" :placeholder ="$t('deposit.text32')" @input="buyNumberAmountValue">
                   <template #prefix>
                       <div class="fast_btn_l cursor" @click="countAmount(-chainPrice[payCurrency])">-{{chainPrice[payCurrency]}}</div>
                    </template>
                   <template #suffix>
                       <div class="fast_btn_r cursor" @click="countAmount(+chainPrice[payCurrency])">+{{chainPrice[payCurrency]}}</div>
                    </template>
                </el-input>
            </div>
        </div>
        <div class="title">
            {{$t('deposit.text33')}}<span>{{showCurrency}}</span>{{$t('deposit.text34')}}
        </div>
        <div class="sign_p">{{address}} <img class="cursor" src='https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png' @click="copyWith(address)" /></div>
        <div class="qrcode">
          <img :src="imageStr" alt="">
          <img :src='ico' v-show="imageStr" class="ico-qrcode">
        </div>
        <p class="arrayTips" v-html="arrayTips"></p>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick,reactive
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name:"BlockChain",
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props) {
        const route = useRoute(); 
        const { t } = useI18n()
        const store = useStore();
        const { payType } = toRefs(props);
        const protocolAll = ref([]);
        const amount = ref('');
        const channelArr = ref([]);
        const payId = ref('');
        const address = ref('13rtUHkxlswuiKJn8YncxiP9813YHn215');
        const exchangeRate = ref('');
        const exchangeRateShow = ref('');
        const placeholder = ref('');
        const minDepositLimit = ref('');//最小入金
        const maxDepositLimit = ref('');//最大入金
        const payCurrency = ref('');//单位
        const chainValue = ref(null);// 链值
        const chainName = ref('');// 链名称
        const showCurrency = ref('');
        const imageStr = ref('');
        const arrayTips = ref('');
        const ico = ref('');
        const payMentAmount = ref('');
        // 支付币种金额变化数值
        const chainPrice = reactive({
            USDT: 10.00000000,
            ETH: 1.00,
            BTC: 0.001,
        })
        let loading;
        let payUrl = ''//入金地址

        openFullScreen();
        apiService.PayInChannel({
            userToken: store.getters.userToken,
            payType: Number(payType.value),
            currencyCategoryId: Number(route.query.id),
        }).then(async (res) => {
            if (res.status === 1) {
                channelArr.value = res.data.list
                payId.value = res.data.list[0].payId;
                getPayInChannelPage(res.data.list[0].payId);
            }
            loading.close();
        });

         // 获取每个通道的详情数据
        async function getPayInChannelPage(payId) {
            const res = await apiService.PayInChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            showExchangeRate(res.data.exchangeRateInfo);
            maxDepositLimit.value = res.data.maxDepositLimit;
            minDepositLimit.value = res.data.minDepositLimit;
            ico.value = res.data.ico;
            payCurrency.value = res.data.payCurrency;

            if(minDepositLimit.value != '' && maxDepositLimit.value != ''){
                placeholder.value = t('deposit.text35') + minDepositLimit.value +'-'+ maxDepositLimit.value + "USD";
            }else if(minDepositLimit.value == ''){
                placeholder.value = t('deposit.text36') + maxDepositLimit.value + payCurrency.value;
            }else if(maxDepositLimit.value == ''){
                placeholder.value = t('deposit.text37') + minDepositLimit.value + payCurrency.value;
            }
            if(payId == 6011){
                protocolAll.value = res.data.protocol;
                chainValue.value = res.data.protocol[0].value;
                chainName.value = res.data.protocol[0].name;
            }
            // if(amount.value == '' || Number(amount.value) < Number(minDepositLimit.value) || Number(amount.value) > Number(maxDepositLimit.value)){
            //     amount.value = res.data.minDepositLimit;
            // }
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            getQrcode();

        }

        // 获取链上入金二维码
            async function getQrcode() {
                const params = {
                    fundTerminal: 1,
                    payId: payId.value,
                };
                if (payCurrency.value === 'USDT') {
                    Object.assign(params, { protocol: chainValue.value });
                }
                console.log("params:",params,chainValue.value,payCurrency.value)
                showCurrency.value = payCurrency.value === 'USDT' ? `${payCurrency.value}-${chainName.value}` : payCurrency.value;
                const res = await apiService.blockchain(params);
                if (res.status == 1) {
                    imageStr.value = res.data.imageStr;
                    address.value = res.data.address;
                }
            }
        // 展示汇率
        function showExchangeRate(exchangeRateInfo) {
            const arr = exchangeRateInfo[0].currencyPair.split('/');
            exchangeRate.value = Number(exchangeRateInfo[0].exchangeRate).toFixed(2);
            exchangeRateShow.value = `1${arr[0]} ≈ $${exchangeRateInfo[0].exchangeRate.toFixed(2)}`;
        }
        //切换通道
        function payIdChange(e){
            payMentAmount.value = '';
            amount.value = '';
            getPayInChannelPage(e);
        }

        function onChange(item) {
            chainValue.value = item.value;
            chainName.value = item.name;
            getQrcode();
        }

        //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

         // 计算入金金额
            function countPayment(num) {
            // 显示placeholder的时候，对应的按钮其它
            // 对应-10 、+10按钮
            if (num) {
                payMentAmount.value = Number(payMentAmount.value) + num > 0 ? Number(payMentAmount.value) + num : 0;
                payMentAmount.value = payMentAmount.value.toFixed(2);
            }

            // 计算支付金额
            console.log("chainData.exchangeRate:",exchangeRate.value)
                const precent = 8;
            if (payMentAmount.value !== null) {
                amount.value = payMentAmount.value / exchangeRate.value;
                amount.value = amount.value < 0 ? 0 : amount.value.toFixed(precent);
            }
            if(num && Number(payMentAmount.value) < Number(minDepositLimit.value)){
                payMentAmount.value = Number(minDepositLimit.value).toFixed(2);
                countPayment();
            }
            }
        // 计算支付金额
        function countAmount(num) {
            console.log(num,"num")
            // 对应-100 、+100按钮
                let precent = 8;
            if (num) {
                amount.value = Number(amount.value) + num > 0 ? Number(amount.value) + num : 0;
                amount.value = amount.value.toFixed(precent);
            }
            // 计算入金金额
            if (exchangeRate) {
                payMentAmount.value = amount.value * exchangeRate.value;
                payMentAmount.value = payMentAmount.value.toFixed(2);
            }

                if(num && Number(payMentAmount.value) < Number(minDepositLimit.value)){
                payMentAmount.value = Number(minDepositLimit.value).toFixed(2);
                countPayment();
            }
        }
        // 限制属于框属于非法字符
        // 入金金额 
        function buyNumberPaymentValue(v) {
            // 输入的数据进行初始化，将非数字的替换为空
            const  value = v;
            if(value == ''){
                    amount.value = ""
                    return
                }
            let reg=new RegExp(/\d+(\.\d{0,2})?/,'g')

            const val = value.match(reg)?value.match(reg)[0]:""
            
            // 重新赋值
            nextTick(() => {
            payMentAmount.value = val;
            countPayment();
            });
        }
        // 支付金额
        function buyNumberAmountValue(v) {
            const  value = v;
            if(value == ''){
                    payMentAmount.value = ""
                    return
                }

            let precent = 8;
                
            let reg=new RegExp(`\\d+(\\.\\d{0,${precent}})?`,'g')
            const val = value.match(reg)?value.match(reg)[0]:""

            // 输入的数据进行初始化，将非数字的替换为空
            // /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g
        
            // 重新赋值
            nextTick(() => {
                amount.value = val;
                countAmount();
            });
        }
        // 复制
        function copyWith(text) {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', text);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            ElMessage({
                message: t('deposit.text24'),
                type: 'success',
            })
        }
        return {
            amount,
            channelArr,
            payId,
            payIdChange,
            address,
            exchangeRate,
            exchangeRateShow,
            minDepositLimit,
            maxDepositLimit,
            payCurrency,
            placeholder,
            chainValue,
            showCurrency,
            imageStr,
            protocolAll,
            arrayTips,
            chainName,
            onChange,
            payMentAmount,
            chainPrice,
            countAmount,
            countPayment,
            buyNumberPaymentValue,
            buyNumberAmountValue,
            copyWith,
            ico
        }
    }
}
</script>
<style lang="less" scoped>
.BlockChain{
    padding: 24px 30px;
    .line{
        overflow: hidden;
        margin-bottom: 16px;
        .label{
            float: left;
            height: 40px;
            line-height: 40px;
            width: 270px;
            text-align: left;
            font-size: 14px;
        }
        .value{
            float: left;
            width: 440px;
            text-align: left;
            font-size: 14px;
            .value_text{
                line-height: 40px;
                font-weight: 500;
            }
            .fast_btn_l{
                border-right:  1px solid @border-color-base;/*no*/
                width: 60px;
                line-height: 40px;
                color: @primary-color;
            }
            .fast_btn_r{
                border-left:  1px solid @border-color-base;/*no*/
                width: 60px;
                line-height: 40px;
                color: @primary-color;
            }
            .radio{
                display: flex;
                justify-content: left;
                padding-top: 4px;
                .btn_radio{
                    width: 80px;
                    height: 32px;
                    border: 1px solid @border-color-base;/*no*/
                    color: @text-color-secondary;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 2px;
                    &:first-child{
                        margin-right: 22px;
                    }
                }
                .active_radio{
                    border-color: @primary-color;
                    color: @primary-color;
                }

            }
        }
        .el-select{
            width:100%;
        }
    }
    .title{
        height: 40px;
        background: @bg-color;
        line-height: 40px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        padding: 0 10px;
        margin-top: 40px;
        span{
            color: @primary-color;
        }
    }
    .sign_p{
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-top: 24px;
        img{
            width: 16px;
            height: 16px;
            margin-left: 16px;
            position: relative;
            top: 2px;
        }
    }
    .qrcode{
        width: 160px;
        position: relative;
        margin-top: 30px;
        img{
            width: 100%
        }
        .ico-qrcode{
            width: 32px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background: #FFF;
        }
    }
    .arrayTips{
        margin-top: 28px;
        text-align: left;
    }
}
</style>
<style lang="less">
.BlockChain{
    .amount_input{
        .el-input__inner{
            padding:0 60px !important;
            text-align: center;
        }
        .el-input__prefix{
            left: 0;
        }
        .el-input__suffix{
            right: 0;
        }
    }
}
</style>
