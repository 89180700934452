<template>
    <div class="About">
        <div class="about_banner">
            <h2>About us</h2>
        </div>
        <div class="about_advantrade">
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_phone.png" alt="">
            <div class="text">
                <h4>What is Advantrade?</h4>
                <p>Advantrade is a one-stop trading app under Topical Wealth International Ltd(Top Wealth). It was co-developed by Top Wealth and an Australian Fin-Tech company ET FinTech.</p>
                <p>What set AdvanTrade apart from the other Trading app is its user-friendly and intuitive interface, it also supports innovative functions such as Subscription and Copy trade.</p>
            </div>
        </div>
        <div class="company_introduction">
            <h2>Company introduction</h2>
            <div class="conntent">
                 <div class="line">
                    <div class="text">
                        <div class="title">About Topical Wealth International Ltd</div>
                        <p>Topical Wealth International Ltd holds an FSA (Seychelles Financial Services Authority)license. </p>
                        <p>The cutting-edge application has been subscribed by more than 500,000 users worldwide. Its business is across 4 continents, covering Southeast Asia, South America, Europe, and Africa.</p>
                    </div>
                    <img class="img_r" src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_right%20%402x_672.png" alt="">
                </div>
                 <div class="line">
                    <img class="img_l" src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_etft.png" alt="">
                    <div class="text">
                        <div class="title">About ADVANTRADE CAPITAL LIMITED</div>
                        <p>As affiliate company, ADVANTRADE CAPITAL LIMITED is registered under the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC) as the Money Services Business (MSB). MSB covers business operations such as Foreign Exchange dealing and money transfers.</p>
                    </div>
                </div>
                 <!-- <div class="line">
                    <div class="text">
                        <div class="title">About our partner ET FinTech</div>
                        <p>EF FinTech Pty Ltd is a Fin-Tech company registered in Australia and holds an ASIC license(Australian Securities & Investments Commission). With years of experience in developing Fin-Tech products, ET FinTech always applies the most advanced technology to establish an excellent and well-trusted trading system.</p>
                    </div>
                    <img class="img_r small_img" src="https://advantrade.oss-accelerate.aliyuncs.com/web/home_pic_etft%402x.png" alt="">
                </div> -->
            </div>
        </div>
        <div class="advantrade_story">
            <h2>Advantrade story</h2>
            <div class="text">
                <p class="m_t_30">In the past 20 years, traders have always changed brokers frequently,high investment threshold, limited product variety, uneven brokerage services...As well as the resulting high transaction costs, traders have to be forced to "migrate".</p>
                <p class="m_t_30">Is there a platform that integrates the strengths of hundreds of brokers to achieve all-in-one,Can it really bring down costs and return profits to traders?</p>
                <p class="m_t_30">The Advantrade team began to think and challenge to develop the "Advantrade" that appeared today.
Different from traditional brokers, Advantrade has the advantages of a minimalist interface, diversified products, customized services and extremely low spreads.</p>
                <p class="m_t_30">Even beginners who are just getting started can quickly find a product that suits them and get started quickly.</p>
                <p class="m_t_30">Just like our slogan and original intention, "Trade Easy·Trade Smart".</p>

            </div>
        </div>
        <div class="team_and_history">  
            <h2>Team and History</h2>
            <div class="content">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/about_pic_team%402x23231.png" alt="">
                <p>We were founded in 2017,currently consisting of over 300 members,of which over 150 are technical.professionals focusing on innovative technology development. Many of our core members have come from well-known financial organizations, tier one technology companies and top digital marketing agencies. With the support of such innovating members, experienced financial and technical professionals, AdvanTrade is empowered to continue reaching new advances and optimizing trading system technology, providing clients the most efficient and effective services.</p>
            </div>
        </div>
        <div class="our_core_values">
            <h2>Our Core values</h2>
            <img class="about_pic_core" src="https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_core%402x.png" alt="">
            <div class="our_missions">
                <div class="our_missions_text">
                    <h4>Our missions</h4>
                    <p>Dedicated to deliver flawless user experiences under a stable,secure, reliable and borderless trading environment and providing trading opportunities & convenience to all users in one application.</p>
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_missions%402x.png" alt="">
            </div>
            <div class="our_missions">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_visions%402x.png" alt="">
                <div class="our_missions_text">
                    <h4>our visions</h4>
                    <p>1、To eliminate trading barriers and empower more users to become advanced Traders.</p>
                    <p>2、To be a global pioneer of the innovating Fin-Tech industry.</p>
                </div>
            </div>
            <div class="our_missions our_missions_problem">
                <div class="our_missions_text">
                    <h4>More information? See our commons question now</h4>
                    <div class="problem" :style="{color:problem1?'#FB741B':''}" @click="cutover(1)"><img :src="problem1?'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_up_yellow%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_down%402x.png'" alt="">is Advantrade safe?</div>
                    <div class="answer" v-show="problem1">As responsible operators Advantrade ensure all personal and sensitive data is stored and managed securely by using 128-bit SSL encryption transmission and MD5 encryption on all our systems. Your asset is absolutely safe to store in our system."</div>
                    <div class="problem" :style="{color:problem2?'#FB741B':''}" @click="cutover(2)"><img :src="problem2?'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_up_yellow%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_down%402x.png'" alt="">is Advantrade charged by commission ?</div>
                    <div class="answer" v-show="problem2">Unlike other platforms, Advantrade is only charged by spreads.</div>
                    <div class="problem" :style="{color:problem3?'#FB741B':''}" @click="cutover(3)"><img :src="problem3?'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_up_yellow%402x.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/about/icon_more_down%402x.png'" alt="">How to deposit and withdraw?</div>
                    <div class="answer" v-show="problem3">You can follow our tutorial in our app, the app has been provided the full step for deposit and withdrawal. You can also learn our promotion for deposit.</div>
                    <hr />
                </div>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_question%402x.png" alt="">
            </div>
        </div>
        <div class="join_us">
          <h4>Join us to get unlimited benefits</h4>
          <p>Enjoy our unlimited resources and support to make your asset explodingly for FREE！</p>
          <div class="btn">
              <div class="sign_up cursor" @click="routerTo('login')">Sign up now</div>
              <!-- <div class="learn_more cursor"  @click="routerTo('promotion')">Learn more promotion </div> -->
          </div>
      </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from "vue-router"

export default {
    name:"about",
    components:{
    },
    setup(){
        const problem1 = ref(false);
        const problem2 = ref(false);
        const problem3 = ref(false);

        const router = useRouter(); 

        function cutover(el){
            switch(el){
                case 1:problem1.value = !problem1.value;break;
                case 2:problem2.value = !problem2.value;break;
                case 3:problem3.value = !problem3.value;break;
            }
        }
        function routerTo(e){
            router.push(e)
       }
        return {
            cutover,
            problem1,
            problem2,
            problem3,
            routerTo
        }
    }
}
</script>
<style lang="less">
.About{
    background: white;
    .about_banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_banner%402x.png) no-repeat;
        height: 340px;
        background-size: 100%;
        font-size: 40px;
        color: white;
        font-weight: 600;
        padding: 146px 0 138px 0;
    }
    .about_advantrade{
        width: 1020px;
        margin: 0 auto;
        padding: 100px 0;
        display: flex;
        justify-content: center;
        img{
            display: block;
            width: 384px;
            height: 500px;
        }
        .text{
            width: 500px;
            margin-left: 132px;
            text-align: left;
            padding-top: 50px;
            h4{
                color: #FB741B;
                font-size: 30px;

            }
            p{
                color:#666666;
                font-size: 16px;
                margin-top: 24px;
            }
        }
    }
    .company_introduction{
        padding: 100px 0;
        background: #F8F8F8;
        .conntent{
            width: 1100px;
            margin: 0 auto;
            margin-top: 41px;
        }
        .line{
            width: 1100px;
           display: flex;
           justify-content: space-between;
           margin-top: 128px;
           align-items: center;
        }
         .title{
                font-size: 30px;
                color: #FB741B;
        }
        .text{
            width: 500px;
            text-align: left;
            p{
                font-size: 16px;
                margin-top: 24px;
                color: #666666;
            }
        }
         .img_r{
                display: block;
                width: 460px;
                height: 400px;
            }
         .img_l{
                display: block;
                width: 400px;
                height: 200px;
            }
            .small_img{
                width: 400px;
                height: 200px;
            }
             
        h2{
            font-size: 34px;
            font-weight: 900;
        }
    }
    .advantrade_story{
        height: 580px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/about/about_pic_story%402x.png) no-repeat;
        background-size: 100% 100%;
        padding-top: 61px;
        h2{
            font-size: 34px;
            color: white;
            font-weight: 900;
        }
        .text{
            width: 1100px;
            margin: 0 auto;
            font-size: 20px;
            .m_41{
                margin-top: 41px;
            }
            .m_t_12{
                margin-top: 12px;
            }
            .m_t_30{
                margin-top: 30px;
            }
        }
        p{
            text-align: left;
            color: white;
            line-height: 22px;
            font-weight: 600;
        }
    }
    .team_and_history{    
        padding-top: 80px;
        padding-bottom: 100px;
        h2{
            font-size: 34px;
            font-weight: 900;
        }  
        .content{
            width: 1100px;
            margin: 0 auto;
            margin-top: 70px
        }
        img{
            width: 100%;
        }
        p{
            margin-top: 40px;
            text-align: left;
            font-size: 20px;
            color: #666666
        }
    }
    .our_core_values{
        padding-top: 61px;
        padding-bottom: 90px;
        h2{
            font-size: 34px;
            font-weight: 900;
        }
        .about_pic_core{
            display: block;
            width: 1014px;
            height: 925px;
            margin: 0 auto;
            margin-top: 174px;
            margin-bottom: 125px;
        }
        .our_missions{
            width: 1100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .our_missions_text{
                text-align: left;
                width: 421px;
                padding-top: 158px;
                h4{
                    color: #FB741B;
                    font-size: 30px;
                }
                p{
                    font-size: 16px;
                    margin-top: 24px;
                }
                .problem{

                }
            }
            img{
                display: block;
                width: 471px;
                height: 470px;
            }
        }
        .our_missions_problem{
            margin-top: 89px;
            h4{
                margin-bottom: 20px;
            }
            .our_missions_text{
                width: 550px;
                padding: 0;
                hr{
                    border-top: 1px solid #EBEBEB;
                    margin-top: 16px;
                }
            }
            .problem{
                padding-top: 12px;
                border-top: 1px solid #EBEBEB;
                margin-top: 16px;
                cursor: pointer;
                font-weight: 600;
                img{
                    width: 12px;
                    height: 8px;
                    display: inline-block;
                    position: relative;
                    top: -1px;
                    margin-right: 8px;
                }
            }
            .answer{
                margin-top: 16px;
                padding-left: 20px;
            }
            
        }
    }
    .join_us{
        height: 290px;
        background: #FB741B;
        padding: 61px 0;
        h4{
            font-size: 34px;
            color: white;
        }
        p{
            margin-top: 21px;
            font-size: 16px;
            color: white;
        }
        .btn{
            display: flex;
            justify-content: center;
            margin-top: 40px;
            div{
                width: 200px;
                height: 44px;
                text-align: center;
                line-height: 44px;
                margin: 0 12px;
                background: white;
                color: #FB741B;
            }
        }
    }
}
</style>
