<template>
    <div class="ScanningCode">
                    
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading } from 'element-plus'
import { assignParams, urlEncode,xzLocal} from '@/until/common';
import axios from 'axios';
import { ElMessage } from 'element-plus'

export default {
    name:'ScanningCode',
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props){
        

        return {
            
        }
    }
}
</script>
<style lang="less" scoped>
.ScanningCode{
    
}
</style>
<style lang="less">
.ScanningCode{
    .el-select{
        width:100%;
    }
    .el-button{
        width:100%;
    }
}
</style>
