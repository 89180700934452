<template>
    <div class="DepositBonus">
        <div class="banner">
            
        </div>
        <div class="wealth">
            <div class="text">
                <h4>Get up to $5588 rewards</h4>
                <p>
                    You can enjoy various credit bonuses as your first time deposited in Advantrade. According to the amount during first deposits on newly registered accounts and switching broker accounts.
                </p>
                <div class="btn cursor" @click="routerTo('download')">
                   Download Now
                </div>
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/depositBonus/promotion_pic_006%402x.png" alt="">
            </div>
        </div>
        <div class="membership">
            <h2>How to get your $5588 bonus?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/depositBonus/promotion_icon_001%402x.png" alt="">
                    <p>Sign up & Verified</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/depositBonus/promotion_icon_005%402x.png" alt="">
                    <p>Deposit in app</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/depositBonus/promotion_icon_009%402x.png" alt="">
                    <p>Notice us in-app</p>
                </li>
            </ul>
            <div class="btn join cursor"  @click="routerTo('login')">Get your credit bonus</div>
        </div>
        <div class="referral_detail">
            <div class="content">
                <div class="title">
                    <div class="hr"></div>Deposit bonus in detail
                </div>
                <p>1.Various credit bonuses can be redeemed according to the amount during first deposits on newly registered accounts and switching broker accounts. For details, please refer to the tables below:</p>
                <p class="text_color">First Deposit on newly registered accounts:</p>
                <div class="content_tr text_color">
                    <div class="item">1st Deposit Amount (USD) </div>
                    <div class="item">Credit Bonus</div>
                    <div class="item">Withdrawal requirement*</div>
                </div>
                <div class="content_tr">
                    <div class="item">100-299</div>
                    <div class="item">200</div>
                    <div class="item">20 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">300-499</div>
                    <div class="item">400</div>
                    <div class="item">40 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">500-999</div>
                    <div class="item">900</div>
                    <div class="item">90 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">1000-4999</div>
                    <div class="item">1000</div>
                    <div class="item">100 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">＞5000</div>
                    <div class="item">5000</div>
                    <div class="item">500 lots</div>
                </div>
                <p class="text_color">First Deposit of Switching broker accounts:</p>
                <div class="content_tr text_color">
                    <div class="item">1st Deposit Amount (USD) </div>
                    <div class="item">Credit Bonus</div>
                    <div class="item">Withdrawal requirement*</div>
                </div>
                <div class="content_tr">
                    <div class="item">500-999</div>
                    <div class="item">88</div>
                    <div class="item">6.16 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">1000-2999</div>
                    <div class="item">188</div>
                    <div class="item">13.16 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">3000-4999</div>
                    <div class="item">388</div>
                    <div class="item">27.16 lots</div>
                </div>
                <div class="content_tr">
                    <div class="item">5000+</div>
                    <div class="item">588</div>
                    <div class="item">41.16 lots</div>
                </div>
                <p>2.Switching broker accounts must be able provide deposit and trading proofs from another trading platform/s of prior year. (e.g. trading records, statements, documents or screencaptures showing the full name of users)</p>
                <p>3.Rewarded credit bonuses for newly registered accounts can act as margin during trades.</p>
                <p>E.g If a USD 400 deposit (first deposit) is made, you will receive an additional USD 400 Credit Bonus. Rewarded cash bonuses for switching broker account can be used to open orders.</p>
                <p>4.Credit bonuses and cash bonuses can be withdrawn once withdrawal requirements have been met, please refer to table above.*</p>
                <p>5.Bonuses are credited automatically.</p>
                <p>6.If any customer does not fulfil requirements for opening an account or conditions of any promotion/s, or abuses any activity offers, AdvanTrade reserves the right to cancel the customer’s application at any time.</p>
                <p>7.AdvanTrade reserves the right to make changes to these terms and condition at any time without prior notice.</p>
            </div>
        </div>
        <div style="background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);"> 
            <Advertise></Advertise>
        </div>
    </div>
</template>
<script>
import {
} from 'vue';
import Header from 'components/pc/Header.vue'
import Footer from 'components/pc/Footer.vue'
import Advertise from '../components/Advertise.vue'
import { useRouter } from "vue-router"

export default {
    name:"DepositBonus",
    props: {
    
    },
    components:{
        Header,
        Footer,
        Advertise
    },
    setup() {
        const router = useRouter(); 
        function routerTo(e){
            router.push(e)
       }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.DepositBonus{
    h2{
        color: #FB741B;
        font-size: 30px;/*no*/
    
    }
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
    }
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/depositBonus/promotion_top_banner_004%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        height: 400px;/*no*/
        padding-top: 85px;/*no*/
        padding-left: 360px;
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 340px;
            img{
                width: 100%;
            }
        }
    }
    .membership{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        padding-top: 40px;
        padding-bottom: 50px;
        ul{
            width: 900px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 300px;
                img{
                    width: 88px;
                    height: 88px;
                }
                p{
                    color: #4E4E4E;
                    margin-top: 16px;/*no*/
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
    .referral_detail{
        padding-top: 90px;
        padding-bottom:77px; 
        .content{
            width: 1200px;
            margin: 0 auto;
        }
        .title{
            display: flex;
            justify-content: left;
            color: #FB741B;
            font-size: 30px;
            .hr{
                height: 42px;/*no*/
                width: 8px;/*no*/
                background: #FB741B;
                border: none;
                margin-right:37px; 
            }
        }
        p{
            text-align: left;
            margin-top: 24px;/*no*/
            color: #858585;
        }
        .content_tr{
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;
            
            .item{
                width: 400px;
                border-bottom: 1px solid #F2F2F2;/*no*/
                height: 44px;/*no*/
                line-height: 44px;/*no*/
                color: #858585;
            }
        }
        .text_color{
            color: #FB741B;
            .item{
                color: #FB741B;
                font-weight: 500;
            }
        }
        .mar{
               margin-top: 48px;/*no*/
           }
    }
}
</style>
