<template>
    <div class="UseAnotherBoundCard">
        <el-dialog
            :title="title"
            v-model="dialogVisible"
            width='380px'
            custom-class="dialog-box" 
            :close-on-click-modal="false"
            destroy-on-close
            @close="beforeClose"
        >
        <div class="scroll">
            <div class="line cursor" v-for="item in bankCardInfo" @click="chooseBankCard(item);">
                <div class="choose">
                    <div class="radio" :class="item.walletbankId == nowCard.walletbankId?'checked':''">

                    </div>
                </div>
                <div class="bankcard">
                     <div class="bank-card-list-item" :class="item.currentType.indexOf(',') != -1?'':item.currentType.indexOf('MYR') != -1?'maybank':item.currentType.indexOf('USD') != -1?'jpmorgran':item.currentType.indexOf('THB') != -1?'bangkok':item.currentType.indexOf('CNY') != -1?'cny':item.currentType.indexOf('IDR') != -1?'idr':item.currentType.indexOf('VND') != -1?'vnd':''">
                        <div class="card-head" :class="item.currentType.indexOf(',') != -1 ? 'oneCurr':'oreCurr'">
                            <div class="icon_box">
                                <img v-if="item.currentType.indexOf('MYR') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_myr%402x.png" alt="">
                                <img v-if="item.currentType.indexOf('USD') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_usd%402x.png" alt="">
                                <img v-if="item.currentType.indexOf('THB') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_thb%402x.png" alt="">
                                <img v-if="item.currentType.indexOf('CNY') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_cny%402x.png" alt="">
                                <img v-if="item.currentType.indexOf('IDR') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_idr%402x.png" alt="">
                                <img v-if="item.currentType.indexOf('VND') != -1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_vnd%402x.png" alt="">
                            </div>
                            <div class="card-name">
                                {{item.bankTypeName}}
                            </div>
                            <div class="unbind cursor" @click="Unbind(item)" v-if="!unbindHide">
                                {{$t('components.text11')}}
                            </div>
                        </div>
                        <div class="bank-account">
                            ···· ···· ····  {{item.bankAccount.substring(item.bankAccount.length-4)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('trade.text72')}}</el-button>
                <el-button type="primary"  @click="Save"
                    >{{$t('trade.text73')}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  watchEffect,
  inject,
  onBeforeUnmount
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";

export default {
    name:'UseAnotherBoundCard',
    props:{
        walletbankId:{
            default:''
        },
        legalCurrency:{
            default:''
        },
        unbindHide:{
            type:Boolean,
            default:''
        }
    },
    setup(props,ctx){
        const store = useStore();
        const { t } = useI18n()
        const dialogVisible = ref(false);
        const nowCard = reactive({walletbankId:''});
        const emitt = inject('emitt');
        const title = ref(t('components.text12'))
        watchEffect(() => nowCard.walletbankId = props.walletbankId)
        /**
         * 打开弹框-开始
         */ 
          emitt.$sub('openUseAnotherBoundCard', (val) => {
              if(val.length != 0){
                  title.value = val[0].title;
              }
             dialogVisible.value = true;
          });
          onBeforeUnmount(() => {
            emitt.$unsub("openUseAnotherBoundCard");
          });
        /**
         * 打开弹框-结束
         */ 

        /**
         * 获取银行卡-开始
         */
        console.log("props.legalCurrency1",props.legalCurrency)
             const bankCardInfo = computed(() => {
                if(props.legalCurrency){
                    return store.getters.walletBank.filter(e => {
                        console.log('props.legalCurrency',props.legalCurrency)
                        if(e.currentType == props.legalCurrency){
                            return e;
                        }
                    })
                }else{
                    return  store.getters.walletBank;
                }        
                
             });
        /**
         * 获取银行卡-结束
         */
    
        /**
         * 关闭弹框-开始
         */
            function beforeClose(){
              
            }
        /**
         * 关闭弹框-结束
         */


        //保存
        function Save(){
            ctx.emit('changeBankCard',nowCard);
            dialogVisible.value = false;
        }

        //选择银行卡
        function chooseBankCard(item){
            Object.assign(nowCard,item)
            console.log('nowCard',nowCard)
        }

        return {
            dialogVisible,
            bankCardInfo,
            beforeClose,
            Save,
            chooseBankCard,
            nowCard,
            title
        }
    }
}
</script>
<style lang="less" scoped>
.UseAnotherBoundCard{
    .scroll{
        max-height: 500px;
        overflow-y: scroll;
    }
    .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .radio{
            width: 20px;
            height: 20px;
            border: 1px solid @disabled-color;
            border-radius: 50%;
        }
        .checked{
            background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/icon_sel_yes%402x.png) no-repeat;
            background-size: 100% 100%;
            border: none;
        }
    }
    .bank-card-list-item{
            background: linear-gradient(315deg, #A336D0 0%, #D248ED 100%);
            width: 250px;
            height: 80px;
            margin-right: 16px;
            border-radius: 6px;/*no*/
            padding: 10px;  
            &:nth-child(3n){
                margin-right: 0;
            }
            .card-head{
                overflow: hidden;
                height: 28px;
                .icon_box{
                    float: left;
                    margin-right: 8px;
                    img{
                        width: 28px;
                    }
                }
                .card-name{
                    float: left;
                    font-size: 14px;
                    height: 28px;
                    line-height: 28px;
                    color: @white;
                }
                .unbind{
                    padding: 2px 4px;
                    line-height: 18px;
                    font-size: 12px;
                    float: right;
                    margin-top: 3px;
                    color: @white;
                    border: 1px solid @white;/*no*/
                }
            }
            .oneCurr{
                .icon_box{
                    img{
                        width: 20px;
                    }
                }
                .card-name{
                    height: 20px;
                    line-height: 20px;
                }
            }
            .bank-account{
                font-size: 14px;
                color: @white;
                margin-top: 10px;
            }
                
        }
        .maybank{
            background: linear-gradient(135deg, #FA9C3C 0%, #ED8526 100%);
        }
        .jpmorgran{
            background: linear-gradient(315deg, #129A9E 0%, #07BDC1 100%);
        }
        .bangkok{
            background: linear-gradient(315deg, #2E62D0 0%, #0087E7 100%);
        }
        .cny{
            background: linear-gradient(135deg, #FF7531 0%, #EC5A19 100%);
        }
        .idr{
            background: linear-gradient(135deg, #F22E8A 0%, #CD214E 100%);
        }
        .vnd{
            background: linear-gradient(135deg, #FF4040 0%, #E72424 100%);
        }
}
</style>
