<template>
    <div class="FundHistoryTransfer">
        <div class="line">
            <div class="line_td">
                <div class="lable">{{$t('fundHistory.text7')}}</div>
                <div class="value">
                      <el-select v-model="type" class="m-2" size="large">
                        <el-option
                            v-for="item in itemFlowType"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
             <div class="line_td">
                <div class="lable">
                    {{$t('fundHistory.text8')}}
                </div>
                <div class="screening_picker">
                        <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                            :start-placeholder="$t('AccountAssets.text17')"
                            :end-placeholder="$t('AccountAssets.text18')"
                            size='large'
                            format="MM/DD/YYYY"
                            value-format="YYYY-MM-DD"
                        />
                </div>
                <div class="search">
                    <el-button type="primary" @click="search" class="search_btn" size="large">{{$t('AccountAssets.text19')}}</el-button>
                </div>
            </div>
        </div>
        <div class="line">
           
        </div>
        <div class="statistics_title">
            <div class="hr"></div>
            <div>{{$t('AccountAssets.text68')}}: {{total}}</div>
        </div>
         <div class="table_box">
            <el-table v-loading="tloading" :data="tableData" style="width: 100%" :empty-text="$t('trade.text66')">
                <el-table-column prop="statusDesc" :label="$t('fundHistory.text9')" width="180"/>
                <el-table-column prop="payAmount" :label="$t('fundHistory.text53')" width="160" />
                <el-table-column prop="transferType" :label="$t('fundHistory.text54')"  />
                <el-table-column prop="bizTime"  :label="$t('fundHistory.text55')"  width="180"/>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination :page-size="10" layout="prev, pager, next"  :total="totalPage" @current-change="handleCurrentChange"/>
        </div>
    </div>
</template>
<script>
import {
 ref,watchEffect,reactive
} from 'vue';
import apiService from '@/api/request';
import { ElMessageBox, ElMessage } from 'element-plus'

export default {
    name:'FundHistoryTransfer',
    props: {
        itemFlowType: {
            type: Object,
        },
        endTime:{
            type:String,
        },
        startTime:{
            type:String,
        }
    },
    setup(props){
        const type = ref(0);
        const typeList = ref([]);
        const searchDate = ref([]);
        const nowPage = ref(1);
        const pageSize = ref(10);
        const tloading = ref(false);
        const tableData = ref([]);
        const total = ref('');
        let firstTime = false;
        const DetailsModelFlag = ref(false);
        const totalPage = ref(0);

        watchEffect(() => {
            if(props.startTime != '' && props.endTime != '' && !firstTime){
                searchDate.value = [props.startTime,props.endTime];
                search();
                firstTime = true
            }
        })
         /*
         * 查询-开始
         */
            function search(){
                let params = {
                    startTime:searchDate.value?searchDate.value[0]:'',
                    endTime:searchDate.value?searchDate.value[1]:'',
                    pageIndex:nowPage.value,
                    pageSize:pageSize.value,
                    flowTypes:type.value,
                    type:'5',
                    status:type.value
                }
                tloading.value = true;
                apiService.GetFlowLogRevision(params).then(res => {
                    if(res.status == 1){
                        tableData.value = res.data.list;
                        total.value = res.data.total;
                        totalPage.value = Number(res.data.count);
                    }
                    tloading.value = false;
                })
            }
        /*
         * 查询-结束
         */
      

      
         //切换页面
        function handleCurrentChange(e){
            nowPage.value = e;
            search();
        }
        return {
            typeList,
            type,
            searchDate,
            search,
            status,
            nowPage,
            pageSize,
            tloading,
            tableData,
            total,
            DetailsModelFlag,
            handleCurrentChange,
            totalPage
        }
    }
}
</script>
<style lang="less" scoped>
.FundHistoryTransfer{
    padding: 24px 20px;
    .line{
        overflow: hidden;
        margin-bottom: 16px;
    }
    .line_td{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-right: 40px;
        float: left;
        .lable{
            margin-right: 20px;
            color: @text-color-secondary;
            font-size: 14px;
        }
        .value{
        }
        .search_btn{
            width: 80px;
            margin-left: 20px;
        }
    }
    .statistics_title{
        font-size: 18px;
        display: flex;
        justify-content: left;
        margin-top: 30px;
        .hr{
            width: 4px;
            height: 20px;
            background: @primary-color;
            margin-right: 10px;
        }
        div{
            font-weight: 500;
        }
    }
    .table_box{
        margin-top: 16px;
        span{
            color: @primary-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .pagination{
        float: right;
        margin-top: 20px;
    }
    .dialog-box{
        .row{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            .lable{
                width: 195px;
                float: left;
            }
            .value{
                float: left;
            }
        }
        .pro{
            margin-bottom: 16px;
            color: #666666;
        }
        .row-2{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            .lable{
                width: 96px;
                float: left;
            }
            .value{
                float: left;
                width: 332px;
                cursor: pointer;
                a{
                    color: #FB741B;
                }
            }
        }
    }
}
</style>
<style lang="less">
.FundHistoryTransfer{
    .el-select{
        width:100%;
    }
}
</style>
