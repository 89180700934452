<template>
    <div class="FundsDetails">
        <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane :label="$t('trade.text46')" name="live">
                <FundsDetailsLiveAccount />
            </el-tab-pane>
            <el-tab-pane label="" name="follow">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import FundsDetailsLiveAccount from './components/FundsDetailsLiveAccount'
import {
 ref
} from 'vue';
export default {
    name:'FundsDetails',
    components:{
        FundsDetailsLiveAccount
    },
    setup(){
        const activeName = ref('live');

        return {
            activeName
        }
    }
}
</script>
<style lang="less" scoped>
.FundsDetails{

}
</style>
<style lang="less">
.FundsDetails{
    .el-tabs__nav{
        margin-left: 0;
    }
    .el-tabs__item{
        margin-right:0;
        margin-left:240px;
    }
    thead{
        .el-table__header-wrapper,.el-table__cell{
            background:@bg-color !important;
        }
    }
}
</style>