<template>
  <div class="CodeInputBox">
    <div class="input-row">
      <div class="value">
        <input
          class="value_code"
          @input="blurFunc(code)"
          v-model="code"
          type="text"
          :placeholder="placeholder"
        />
      </div>
      <el-button class="send" link @click="sendCode">{{
        sendMessage
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { inject, ref, reactive, toRefs, onBeforeUnmount, computed } from "vue";
import { ElMessage } from "element-plus";
import apiService from "@/api/request";
import { useI18n } from "vue-i18n";
import { loadErrorCallback } from "@/until/common";
import { useStore } from "vuex";

export default {
  name: "CodeInputBox",
  props: {
    value: {
      // 输入框的值
      type: String,
      default: "",
    },
    placeholder: {
      // 输入框的提示
      type: String,
      default: "",
    },
    blurFunc: {
      // 失去焦点的回调事件
      type: Function,
    },
    smsType: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    mobileAreaCode: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const code = ref("");
    const { t } = useI18n();
    //发送手机验证码按钮文本
    const sendMessage = ref(t("signUp.text16"));
    const { phone, smsType, mobileAreaCode } = toRefs(props);
    const store = useStore();
    const lang = computed({ get: () => store.getters.getNowTheme.lang });

    /**
     * 发送手机验证码-开始
     */
    const isSending = ref(false);
    const sendCode = async () => {
      if (isSending.value) {
        return;
      }
      // 没填电话号码
      if (phone.value == "") {
        ElMessage({
          message: t("signUp.text20"),
          type: "error",
        });
        return;
      }
      try {
        var captcha = new TencentCaptcha("190327966", sendphoneCodeFunc, {
          userLanguage: lang.value == "cn" ? "zh" : "en",
          needFeedBack: false,
        });
        captcha.show();
        const el = document.getElementsByClassName("trade-view1")[0];
        console.log("el", el);
        if (isFullScreen(el)) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            // 改变平面图在google浏览器上面的样式问题
            el.style.position = "static";
            el.style.width = "100%";
            document.webkitExitFullscreen();
          } else if (!document.msRequestFullscreen) {
            document.msExitFullscreen();
          }
        }
      } catch (error) {
        loadErrorCallback();
      }
    };

    function isFullScreen(element) {
      // 获取元素的宽度和高度
      const elementWidth = element.offsetWidth;
      const elementHeight = element.offsetHeight;

      // 获取视口的宽度和高度
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // 判断元素是否与视口相等
      return elementWidth === viewportWidth && elementHeight === viewportHeight;
    }

    // 发起请求
    let timer = null;
    const time = ref(60);
    async function sendphoneCodeFunc(result) {
      console.log("result", result);
      if (result.ret != 0) return;

      if (timer) {
        return;
      }
      isSending.value = true;
      sendMessage.value = t("signUp.text18");
      console.log("phone", phone);
      const params = {
        smsType: smsType.value,
        phone: window.btoa(phone.value),
        mobileAreaCode: mobileAreaCode.value,
        ticket: result.ticket,
        randstr: result.randstr,
      };
      const res = await apiService.GetByMobile(params);
      if (res.status === 1) {
        ElMessage({
          message: t("signUp.text19"),
          type: "success",
        });
        sendMessage.value = `${time.value}S`;
        timer = setInterval(() => {
          sendMessage.value = `${time.value}S`;
          time.value -= 1;
          if (time.value <= 0) {
            isSending.value = false;
            clearInterval(timer);
            sendMessage.value = t("signUp.text17");
            time.value = 60;
            timer = null;
          }
        }, 1000);
      } else {
        ElMessage({
          message: res.message,
          type: "error",
        });
        sendMessage.value = t("signUp.text16");
        isSending.value = false;
      }
    }
    /**
     * 发送手机验证码-结束
     */
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    return {
      code,
      sendMessage,
      sendCode,
    };
  },
};
</script>
<style lang="less" scoped>
.CodeInputBox {
  .input-row {
    margin: 0 auto;
    background: white;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    border-radius: 4px;
    height: 40px; /*no*/
    position: relative;
    border: 1px solid #ebebeb; /*no*/
    .value {
      padding-top: 10px; /*no*/
      width: 100%; /*no*/
    }
    .password-icon {
      position: absolute;
      right: 16px; /*no*/
      top: 10px; /*no*/
      cursor: pointer;
    }
    .send {
      position: absolute;
      right: 10px; /*no*/
      top: 10px; /*no*/
    }
    .prefix {
      width: 80px; /*no*/
      padding-left: 10px; /*no*/
      border-right: 1px solid #ebebeb; /*no*/
      text-align: left;
      cursor: pointer;
      position: relative;
      line-height: 40px; /*no*/
      .icon_search {
        position: relative;
        top: 8px; /*no*/
      }
      .icon_choice {
        width: 8px; /*no*/
        position: absolute;
        right: 9px; /*no*/
        top: 18px; /*no*/
      }
    }
    input {
      height: 20px; /*no*/
      outline: none;
      padding-left: 16px; /*no*/
      width: 100%;
    }
    .value_code {
      padding-left: 10px; /*no*/
    }
  }
}
</style>
