<template>
    <div class="FundHistoryDeposit">
        <div class="line">
            <div class="line_td">
                <div class="lable">{{$t('fundHistory.text6')}}</div>
                <div class="value">
                      <el-select v-model="type" class="m-2" size="large">
                        <el-option
                            v-for="item in itemFlowType"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="line_td">
                <div class="lable">{{$t('fundHistory.text7')}}</div>
                <div class="value">
                      <el-select v-model="status" class="m-2" size="large">
                        <el-option
                            v-for="item in itemStatus"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                
            </div>
        </div>
        <div class="line">
            <div class="line_td">
                <div class="lable">
                    {{$t('fundHistory.text8')}}
                </div>
                <div class="screening_picker">
                        <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                            :start-placeholder="$t('AccountAssets.text17')"
                            :end-placeholder="$t('AccountAssets.text18')"
                            size='large'
                            format="MM/DD/YYYY"
                            value-format="YYYY-MM-DD"
                        />
                </div>
                <div class="search">
                    <el-button type="primary" @click="search" class="search_btn" size="large">{{$t('AccountAssets.text19')}}</el-button>
                </div>
            </div>
        </div>
        <div class="statistics_title">
            <div class="hr"></div>
            <div>{{$t('AccountAssets.text12')}}: {{total}}</div>
        </div>
         <div class="table_box">
            <el-table v-loading="tloading" :data="tableData" style="width: 100%" :empty-text="$t('trade.text66')">
                <el-table-column prop="statusDesc" :label="$t('fundHistory.text9')" width="160" />
                <el-table-column prop="amount" :label="$t('fundHistory.text10')" width="200" />
                <el-table-column prop="bizTime" width="230" :label="$t('fundHistory.text11')" />
                <el-table-column  :label="$t('fundHistory.text12')" width="320" >
                     <template #default="scope">
                            <span v-if="scope.row.payType == 1 || scope.row.payType == 4" @click="details(scope.row)">{{$t('fundHistory.text13')}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
         <div class="pagination">
            <el-pagination :page-size="10" layout="prev, pager, next"  :total="totalPage" @current-change="handleCurrentChange"/>
        </div>
          <el-dialog
            :title="$t('fundHistory.text13')"
            v-model="DetailsModelFlag"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
        >
            <div class="row">
                <div class="lable">{{$t('fundHistory.text14')}}：</div>
                <div :class="['value',detailsObj.status == 1 ?'green':detailsObj.status == 2 ?'blue':detailsObj.status == 3 ?'red':'primary']">{{detailsObj.statusStr}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text15')}}:</div>
                <div class="value">{{detailsObj.digiccyAmountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text16')}}:</div>
                <div class="value">{{detailsObj.amount}}{{detailsObj.amountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text17')}}:</div>
                <div class="value">{{detailsObj.digiccyAmount}}{{detailsObj.digiccyAmountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text18')}}:</div>
                <div class="value">{{detailsObj.factTime}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text19')}}:</div>
                <div class="value">{{detailsObj.orderTime}}</div>
            </div>
            <div class="pro">{{$t('fundHistory.text20')}}</div>
            <div class="row-2">
                <div class="lable">{{$t('fundHistory.text21')}}:</div>
                <div class="value"><a target="__blank" :href="detailsObj.outCashAddressLink">{{detailsObj.outCashAddress}}</a></div>
            </div>
            <div class="row-2">
                <div class="lable">TxHash：</div>
                <div class="value"><a target="__blank" :href="detailsObj.txIdLink">{{detailsObj.txId}}</a></div>
            </div>
          </el-dialog>
          <el-dialog
            :title="$t('fundHistory.text13')"
            v-model="DetailsModelFlagUSDT"
            width='550px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
        >
            <p class="warn" v-if="detailsObj.status == 2">{{$t('fundHistory.text22')}}</p>
            <p class="time_hint" v-if="detailsObj.status === 0 && detailsObj.orderId  != 0"><span>*</span>{{$t('fundHistory.text23')}} <span>{{validTime}}</span>{{$t('fundHistory.text24')}}</p>

            <div class="row">
                <div class="lable">{{$t('fundHistory.text9')}}:</div>
                <div :class="['value',detailsObj.status == 1 ?'green':detailsObj.status == 2 ?'blue':detailsObj.status == 3 ?'red':'primary']">{{detailsObj.statusDesc}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text16')}}:</div>
                <div class="value">{{detailsObj.usdAmount}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text25')}}:</div>
                <div class="value">{{detailsObj.coinAmount}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text26')}}:</div>
                <div class="value">{{detailsObj.cnyAmount}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text11')}}:</div>
                <div class="value">{{detailsObj.orderTime}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text27')}}:</div>
                <div class="value">
                    <el-radio v-if="detailsObj.receivables.length != 0"  size="large">{{detailsObj.receivables.length != 0?detailsObj.receivables[0].mode:''}}</el-radio>
                </div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text28')}}:</div>
                <div class="value">{{detailsObj.receivables.length != 0?detailsObj.receivables[0].num:'--'}} <span @click="copyWith(detailsObj.receivables.length != 0?detailsObj.receivables[0].num:'')">{{$t('deposit.text60')}}</span></div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text29')}}:</div>
                <div class="value">{{detailsObj.receivables.length != 0?detailsObj.receivables[0].name:'--'}} <span @click="copyWith(detailsObj.receivables.length != 0?detailsObj.receivables[0].name:'')">{{$t('deposit.text60')}}</span></div>
            </div>
             <div class="btn_box" v-if="detailsObj.status === 0 && detailsObj.orderId  != 0">
                    <div class="cancel" @click="Cancel">{{$t('fundHistory.text30')}}</div>
                    <div class="confirm" @click="Confirme">{{$t('fundHistory.text31')}}</div>
                </div>
          </el-dialog>

    </div>
</template>
<script>
import {
 ref,watchEffect,reactive
} from 'vue';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import apiService from '@/api/request';
import { useI18n } from "vue-i18n";

export default {
    name:'FundHistoryDeposit',
    props: {
        itemFlowType: {
            type: Object,
        },
        itemStatus: {
            type: Object,
        },
        endTime:{
            type:String,
        },
        startTime:{
            type:String,
        }
    },
    setup(props){
        const { t } = useI18n()
        const type = ref(0);
        const typeList = ref([]);
        const searchDate = ref([]);
        const status = ref(0);
        const nowPage = ref(1);
        const pageSize = ref(10);
        const tloading = ref(false);
        const tableData = ref([]);
        const total = ref('');
        const totalPage = ref(0);
        let firstTime = false;
        const DetailsModelFlag = ref(false);
        const DetailsModelFlagUSDT = ref(false);
        const validTime = ref('');//倒计时

        watchEffect(() => {
            if(props.startTime != '' && props.endTime != '' && !firstTime){
                searchDate.value = [props.startTime,props.endTime];
                search();
                firstTime = true
            }
        })
         /*
         * 查询-开始
         */
            function search(){
                let params = {
                    startTime:searchDate.value?searchDate.value[0]:'',
                    endTime:searchDate.value?searchDate.value[1]:'',
                    pageIndex:nowPage.value,
                    pageSize:pageSize.value,
                    type:'1',
                    status:status.value,
                    flowTypes:type.value
                }
                tloading.value = true;
                apiService.GetFlowLogRevision(params).then(res => {
                    if(res.status == 1){
                        tableData.value = res.data.list;
                        total.value = res.data.total;
                        totalPage.value = Number(res.data.count);

                    }
                    tloading.value = false;
                })
            }
        /*
         * 查询-结束
         */
        
        /*
         * 打开详情，链上入金才有详情 - 开始
         */
            const detailsObj = reactive({
                receivables:[],
                cnyAmount:null,
                coinAmount:null,
                logId:0,
                orderId:0,
                orderTime:null,
                payId:0,
                status:0,
                statusDesc:null,
                usdAmount:null,
                validTime:0,
            });

            let validTimeVlaue = 0;
            let timer;
            function details(e){
                if(e.payType == 4){
                    let params = {}
                    if(e.payId){
                        params.payId = e.payId
                    }
                    if(e.flowId){
                        params.flowId = e.flowId
                    }
                    apiService.RecentlyChainInOrder(params).then(res => {
                        if(res.status == 1){
                            Object.assign(detailsObj,res.data);
                            DetailsModelFlag.value = true;
                        }
                    })
                }else if(e.payType == 1){
                    let params = {}
                    if(e.payId){
                        params.payId = e.payId
                    }
                    if(e.flowId){
                        params.flowId = e.flowId
                    }
                    apiService.OTCPayingOrder(params).then(res => {
                        if(res.status == 1 || res.status -1){
                            Object.assign(detailsObj,res.data);
                            DetailsModelFlagUSDT.value = true;
                            if(res.data.orderId != 0 && res.data.status == 0){
                                validTimeVlaue = res.data.validTime;
                                timer = setInterval(() => {
                                validTimeVlaue -= 1;
                                validTime.value = formatTime(validTimeVlaue);
                                    if (validTimeVlaue <= 0) {
                                        clearInterval(timer);
                                        details(e);
                                    }
                                }, 1000);
                            }
                        }
                    })
                }
            }
        /*
         * 打开详情，链上入金才有详情 - 结束
         */

         // 秒转换成分
        function formatTime(seconds) {
        return `${[
            parseInt(seconds / 60),
            parseInt(seconds % 60),
        ]
        .join(t('deposit.text71'))
        .replace(/\b(\d)\b/g, '0$1')
        }${t('deposit.text72')}`;
        }

       //切换页面
        function handleCurrentChange(e){
            nowPage.value = e;
            search();
        }

        // 复制
        function copyWith(text) {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', text);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            ElMessage({
                message: t('deposit.text24'),
                type: 'success',
            });
        }

        //取消订单
        function Cancel(){
            ElMessageBox.confirm( t('fundHistory.text32'),{
                confirmButtonText: t('trade.text73'),
                cancelButtonText: t('trade.text72'),
            })
            .then(() => {
                    const params = {
                        payId:detailsObj.payId,
                        orderId: detailsObj.orderId,
                        logId: detailsObj.logId,
                    };
                    apiService.OTCOrderCancel(params).then((res) => {
                        if (res.status == 1) {
                            search();
                            ElMessage({
                                message: t('fundHistory.text33'),
                                type: 'success',
                            });
                            DetailsModelFlagUSDT.value = false;
                        }else{
                             ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    });
                })
            .catch(() => {
            })
            
        }

        //确认订单
        function Confirme(){
             ElMessageBox.confirm( t('fundHistory.text34'),{
                confirmButtonText: t('trade.text73'),
                cancelButtonText: t('trade.text72'),
            })
            .then(() => {
                    const params = {
                        payId:detailsObj.payId,
                        orderId: detailsObj.orderId,
                        logId: detailsObj.logId,
                    };
                    apiService.OTCOrderConfirm(params).then((res) => {
                        console.log(res);
                        if (res.status == 1) {
                            search();
                            DetailsModelFlagUSDT.value = false;
                            ElMessage({
                                message: t('fundHistory.text35'),
                                type: 'success',
                            });
                        }else{
                            ElMessage({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    });
                })
            .catch(() => {
            })
        }

         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }
        return {
            copyWith,
            typeList,
            type,
            searchDate,
            search,
            status,
            nowPage,
            pageSize,
            tloading,
            tableData,
            total,
            DetailsModelFlag,
            details,
            detailsObj,
            handleCurrentChange,
            totalPage,
            DetailsModelFlagUSDT,
            validTime,
            Confirme,
            Cancel
        }
    }
}
</script>
<style lang="less" scoped>
.FundHistoryDeposit{
    padding: 24px 20px;
    .line{
        overflow: hidden;
        margin-bottom: 16px;
    }
    .line_td{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-right: 40px;
        float: left;
        .lable{
            margin-right: 20px;
            color: @text-color-secondary;
            font-size: 14px;
        }
        .value{
            width: 240px;
        }
        .search_btn{
            width: 80px;
            margin-left: 20px;
        }
    }
    .statistics_title{
        font-size: 18px;
        display: flex;
        justify-content: left;
        margin-top: 30px;
        .hr{
            width: 4px;
            height: 20px;
            background: @primary-color;
            margin-right: 10px;
        }
        div{
            font-weight: 500;
        }
    }
    .table_box{
        margin-top: 16px;
        span{
            color: @primary-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .pagination{
        float: right;
        margin-top: 20px;
    }
    .dialog-box{
        .row{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            position: relative;
            .lable{
                width: 195px;
                float: left;
                
            }
            .value{
                float: left;
                span{
                    text-decoration: underline;
                    color: @primary-color;
                    font-size: 14px;
                    font-weight: 500;
                    position: absolute;
                    right: 40px;
                    cursor: pointer;
                }
            }
        }
        .time_hint{
                font-size: 14px;
                text-align: left;
                margin-top: 16px;
                margin-bottom: 20px;
                span{
                    color: #EF383C;
                }
            }
        .pro{
            margin-bottom: 16px;
            color: #666666;
        }
        .row-2{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            .lable{
                width: 96px;
                float: left;
            }
            .value{
                float: left;
                width: 332px;
                cursor: pointer;
                a{
                    color: #FB741B;
                }
            }
        }
        .warn{
            color: @red;
            margin-bottom: 16px;
            text-align: left;
        }
        .btn_box{
                display: flex;
                justify-content: right;
                margin-top: 30px;
                div{
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    border-radius: 4px;
                    font-weight: 500;
                    cursor: pointer;
                    padding: 0 16px;
                }
                .cancel{
                    background: #EBEBEB;
                    margin-right: 20px;
                }
                .confirm{
                    background: #FB741B;
                    color: white;
                }
            }
    }
}
</style>
<style lang="less">
.FundHistoryDeposit{
    .el-select{
        width:100%;
    }
    .el-radio{
        height: auto !important;
    }
}
</style>
