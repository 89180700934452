<template>
    <div class="BankTransfer"  @keyup="myKeydown">
        <div class="line_box">
            <div class="line">
                <div class="label">{{$t('withdraw.text1')}}</div>
                <div class="value">
                    <el-select v-model="payId" class="m-2" size="large" @change="payIdChange">
                        <el-option
                        v-for="item in payChannels"
                        :key="item.payId"
                        :label="item.name"
                        :value="item.payId"
                        />
                    </el-select>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text40')}}</div>
                <!-- <div class="value" v-if="currencyArr.length < 2">
                    <el-select v-model="currentType" class="m-2" placeholder="Select" size="large" @change="currentTypedChange">
                        <el-option
                        v-for="item in currencyArr"
                        :key="item"
                        :label="item"
                        :value="item"
                        />
                    </el-select>
                </div> -->
                <div class="value">
                    <div class="value_text">{{currentType}}</div>
                </div>
            </div>
            <div class="line">
                <div class="label">{{$t('withdraw.text16')}}(USD)</div>
                <div class="value">
                    <el-input
                        v-model="withdrawalAmount"
                        size="large"
                        :placeholder="placeholderText"
                        class="withdrawal_amount"
                        @input="countPayment"
                    >
                    <template #suffix>
                        <div class="fast_btn_r cursor" @click="withdrawalAmountAll">{{$t('withdraw.text6')}}</div>
                    </template>
                    </el-input>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text3')}}</div>
                <div class="value">
                    <div class="value_text">{{canDrawAmount}} USD</div>
                </div>
            </div>
             <div class="line" v-if="payId != 7030">
                <div class="label">{{$t('withdraw.text42')}}</div>
                <div class="value">
                    <div class="value_text">{{exchangeRate}} USD/{{currentType}}</div>
                </div>
            </div>
             <div class="line">
                <div class="label">{{$t('withdraw.text41')}}</div>
                <div class="value">
                    <div class="value_text">{{rmbAmount}} {{currentType}}</div>
                </div>
            </div>
            <div class="hr"></div>
             <div class="line">
                 <div class="short_line">
                    <div class="label">{{$t('withdraw.text43')}}</div>
                    <div class="value">
                        <div class="value_text">{{$t('withdraw.text44')}} <img class="cursor" @click="AvailableBanksFun" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_note%402x.png" alt=""></div>
                    </div>
                 </div>
                 <div class="short_line2" v-if="bankCardInfoShow.walletbankId != '' || bankCardInfoShow.walletbankid != ''">
                    <div class="label">{{$t('withdraw.text45')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfoShow.userName?bankCardInfoShow.userName:bankCardInfoShow.firstName + '' + bankCardInfoShow.lastName}}</div>
                    </div>
                 </div>
            </div>
             <div class="line" v-if="bankCardInfoShow.walletbankId != '' || bankCardInfoShow.walletbankid != ''">
                 <div class="short_line">
                    <div class="label">{{$t('withdraw.text46')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfoShow.bankName?bankCardInfoShow.bankName:bankCardInfoShow.bankTypeName}}</div>
                    </div>
                 </div>
                 <div class="short_line2">
                    <div class="label">{{$t('withdraw.text47')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfoShow.bankAddress?bankCardInfoShow.bankAddress:bankCardInfoShow.bankaddress}}</div>
                    </div>
                 </div>
            </div>
             <div class="line"  v-if="bankCardInfoShow.walletbankId != '' || bankCardInfoShow.walletbankid != ''">
                 <div class="short_line">
                    <div class="label">{{$t('withdraw.text48')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfoShow.bankNum?bankCardInfoShow.bankNum:bankCardInfoShow.bankAccount}}</div>
                    </div>
                 </div>
                 <div class="short_line2">
                    <div class="label">{{$t('withdraw.text49')}}</div>
                    <div class="value">
                        <div class="value_text">{{bankCardInfoShow.swiftCode}}</div>
                    </div>
                 </div>
            </div>
            <div class="line line_btn">
                <div class="add_bankcard cursor" @click="addBankCard">+ {{$t('withdraw.text9')}}</div>
                <div class="use_another cursor" v-if="bankCardInfoShow.walletbankId != '' || bankCardInfoShow.walletbankid != ''" @click="openUseAnotherBoundCard">{{$t('withdraw.text10')}}</div>
            </div>
            <div class="hr" v-if="addInfo.length != 0"></div>
            <p class="pro" v-if="addInfo.length != 0">{{$t('withdraw.text50')}}</p>
             <div class="line" v-for="item in addInfo">
                <div class="label">{{item.name}}</div>
                <div class="value">
                    <el-input
                        v-model="item[item.value]"
                        size="large"
                        :placeholder="$t('myAccount.text117')" />
                </div>
            </div>
            <el-button size="large" type="primary" class="confirm" :loading='btnLoading' :disabled="disabled" @click="Confirm">{{$t('withdraw.text12')}}</el-button>
            <p class="arrayTips" v-html="arrayTips"></p>
        </div>
        <AddBankCard :currentType="currentType" :payChannelId="payId" @addBankCardSuccsess="addBankCardSuccsess"/>
         <el-dialog
            v-model="dialogVisible"
            :title="banksTitle"
            width="400px"
        >
            <p>{{$t('withdraw.text51')}}</p>
            <div class="scroll">
                <div class="available_banks" v-for="item in AvailableBanks">*{{item.name}}</div>
            </div>
            <template #footer>
            <span class="dialog-footer">
                <el-button :type="banksTitle == $t('withdraw.text52')?'':'primary'" @click="dialogVisible = false">{{$t('withdraw.text32')}}</el-button>
                <el-button v-if="banksTitle == $t('withdraw.text52')" type="primary" @click="addBankCard"> {{$t('withdraw.text9')}}</el-button>
            </span>
            </template>
        </el-dialog>
        <UseAnotherBoundCard @changeBankCard="changeBankCard" :walletbankId='bankCardInfoShow.walletbankId || bankCardInfoShow.walletbankid'/>
    </div>
</template>
<script>
import {
 ref,toRefs,nextTick,computed,reactive,inject
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import AddBankCard from '../dialog/AddBankCard'
import UseAnotherBoundCard from '@/components/pc/UseAnotherBoundCard'
import { isNotEmpty } from '@/until/common';
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name:'BankTransfer',
    props: {
        payType: {
            type: Number,
        },
    },
     components:{
         AddBankCard,
         UseAnotherBoundCard
    },
    setup(props){
        const emitt = inject('emitt');
        const route = useRoute(); 
        const { t } = useI18n()

        const payChannels = ref([]);
        const payId = ref("");
        const { payType } = toRefs(props);
        const store = useStore();
        const payChannel = ref('');
        const exchangeRate = ref('');
        const currentType = ref('');
        const currencyArr =ref([]);
        const minWithdrawLimit = ref('');//最小入金
        const maxWithdrawLimit = ref('');//最大入金
        const arrayTips = ref('');
        const canDrawAmount = ref('');
        const withdrawalAmount = ref("");
        const rmbAmount = ref(0.0);
        const bankCardInfo = computed(() => store.getters.walletBank);
        const placeholderText = ref('');
        const dialogVisible = ref(false);
        let payChannelMinWalletfee = '';
        let payChannelWalletFeeRate = '';
        const banksTitle = ref('');
        const bankCardInfoShow = reactive({
            firstName:'',
            lastName:'',
            accountAddress:'',
            bankAccount:'',
            bankTypeName:'',
            swiftCode:'',
            walletbankId:0,
            bankName:'',
            bankNum:'',
            swiftCode:'',
            userName:'',
            walletbankid:'',
        });
        const AvailableBanks = ref([]);
        const btnLoading = ref(false);
        const addInfo = ref({});
        let loading;

         //切换通道
        function payIdChange(e){
            console.log(e)
            openFullScreen();
            getPayOutChannelPage(e)
        }
        openFullScreen();
        // 获取 payId
        apiService.PayOutChannel({
            userToken: store.getters.userToken,
            payType: Number(payType.value),
            currencyCategoryId: Number(route.query.id),
            }).then(async (res) => {
            if (res.status === 1) {
                payChannel.value = res.data?.list[0].name;
                payChannels.value.push(...res.data.list);
                payId.value = res.data.list[0].payId;
                getPayOutChannelPage(payId.value);
                LatestOnlineBankInfo();
            }
        });

        //切换单位
        function currentTypedChange(){

        }

         //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('withdraw.text22'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

         // 选择通道
        async function getPayOutChannelPage(payId) {
            // 获取通道的详情数据
            const res = await apiService.PayOutChannelPage({
                userToken: store.getters.userToken,
                payId:payId
            });
            if(res.data.currency == null || res.data.currency.length == 0){
                currentType.value = res.data.currentType;
            }else{
                currentType.value = res.data.currency[0].value;

            }
            addInfo.value = res.data.addInfo,
            // 最大最小限制
            minWithdrawLimit.value = res.data.minWithdrawLimit;
            maxWithdrawLimit.value = res.data.maxWithdrawLimit;
            payChannelMinWalletfee = res.data.payChannelMinWalletfee;
            payChannelWalletFeeRate = res.data.payChannelWalletFeeRate;
             if(minWithdrawLimit.value != '' && maxWithdrawLimit.value != ''){
                placeholderText.value = t('withdraw.text23') + minWithdrawLimit.value +'-'+ maxWithdrawLimit.value + 'USD';
            }else if(minWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text24') + maxWithdrawLimit.value + 'USD';
            }else if(maxWithdrawLimit.value == ''){
                placeholderText.value = t('withdraw.text25') + minWithdrawLimit.value + 'USD';
            }
            // 整理提示数据
            arrayTips.value = res.data.arrayTips[0].tips
            canDrawAmount.value = res.data.canDrawAmount;
            GetExchangeRate();
            OnlineBankInfoForApp();
            countPayment(withdrawalAmount.value);
            LatestOnlineBankInfo();
        }
        
        function GetExchangeRate(){
             apiService.GetExchangeRate({
                    bsType:2,
                    symbol:'USD/'+currentType.value
                }).then(res => {
                    exchangeRate.value = res.data.exchangeRates[0].exchangerate;
                    loading.close();
                }).catch(err => {
                    loading.close();
                })
        }

        // 计算入金金额
        function countPayment(v) {
            // 输入的数据进行初始化，将非数字的替换为空
            let val = v;
            let isMatch = /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
             while(!isMatch && val != ''){
                val = val.substring(0,val.length-1)
                isMatch = /^(([1-9][0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
               }
               if(Number(val) > Number(canDrawAmount.value)){
                   val = canDrawAmount.value;
               }
             nextTick(() => {
                withdrawalAmount.value = val;
                // 计算支付金额
                if (withdrawalAmount.value !== null) {
                    if(payId.value == 7030){
                        console.log('withdrawalAmount.value')
                        if(Number(withdrawalAmount.value * exchangeRate.value * payChannelWalletFeeRate) < Number(payChannelMinWalletfee)){
                            rmbAmount.value = withdrawalAmount.value * exchangeRate.value - payChannelMinWalletfee;
                        }else{
                            rmbAmount.value = Number(withdrawalAmount.value * exchangeRate.value) - Number(withdrawalAmount.value * exchangeRate.value * payChannelWalletFeeRate);
                        }
                        console.log('withdrawalAmount.value * payChannelWalletFeeRate',Number(withdrawalAmount.value * exchangeRate.value),withdrawalAmount.value * payChannelWalletFeeRate)
                        rmbAmount.value = Number(Math.floor(rmbAmount.value * 100)/100);
                        if(rmbAmount.value<0) rmbAmount.value = 0;
                    }else{
                        rmbAmount.value = withdrawalAmount.value * exchangeRate.value;
                        rmbAmount.value = rmbAmount.value < 0 ? 0 : rmbAmount.value.toFixed(2);
                    }
                }
            });
           
        }
        //获取网银出金银行卡信息
        function LatestOnlineBankInfo(){
            Object.assign(bankCardInfoShow,{
                firstName:'',
                lastName:'',
                accountAddress:'',
                bankAccount:'',
                bankTypeName:'',
                swiftCode:'',
                walletbankId:0,
                bankName:'',
                bankNum:'',
                swiftCode:'',
                userName:'',
                walletbankid:'',
                bankAddress:'',
                bankaddress:''
            })
            apiService.LatestOnlineBankInfo({
                payId:payId.value,
            }).then(res => {
                if(res.data.walletbankid != 0){
                    Object.assign(bankCardInfoShow,res.data)
                }else{
                    if(bankCardInfo.value.length !== 0){
                        let obj = bankCardInfo.value.filter(e => {return e.isDefault == 1})
                        if(obj.length == 0){
                            obj ==  bankCardInfo.value[0];
                        }
                        Object.assign(bankCardInfoShow,obj[0])
                    }
                }
            }).catch(err => {

            })
        }

        //添加银行卡
        function addBankCard(){
            dialogVisible.value = false;
            emitt.$pub('openAddBankCardAvailable');
        }

        //添加成功后更新银行卡信息
        function addBankCardSuccsess(e){
            console.log(e)
            Object.assign(bankCardInfoShow,{
                 firstName:'',
                lastName:'',
                accountAddress:'',
                bankAccount:'',
                bankTypeName:'',
                swiftCode:'',
                walletbankId:0,
                bankName:'',
                bankNum:'',
                swiftCode:'',
                userName:'',
                walletbankid:'',
                bankAddress:'',
                bankaddress:''
            })
            Object.assign(bankCardInfoShow,e)
            myKeydown();

        }

        //网银出金可用银行
        function OnlineBankInfoForApp(){
            apiService.OnlineBankInfoForApp({
                currency:currentType.value,
                payChannelId:payId.value
                }).then(res => {
                    if(res.status == 1){
                        AvailableBanks.value = res.data.list
                    }else{
                        AvailableBanks.value = [];
                    }
                })
        }
        //打开选择银行卡弹框
        function openUseAnotherBoundCard(){
            emitt.$pub('openUseAnotherBoundCard',{title:t('withdraw.text10')});
        }
        //选择其他银行卡
        function changeBankCard(item){
            console.log("changeBankCard")
            if(bankCardInfoShow.walletbankid == item.walletbankId) return;
            Object.assign(bankCardInfoShow,{
                firstName:'',
                lastName:'',
                accountAddress:'',
                bankAccount:'',
                bankTypeName:'',
                swiftCode:'',
                walletbankId:0,
                bankName:'',
                bankNum:'',
                swiftCode:'',
                userName:'',
                walletbankid:'',
                bankAddress:'',
                bankaddress:''
            })
            Object.assign(bankCardInfoShow,item)
            myKeydown();
        }

        /**
         * 按钮提交条件-开始
         */
            const disabled = ref(true)

            function myKeydown(){
                console.log('art123456art123456')
                let flag = true;
                console.log(addInfo.value)
                for(let item of addInfo.value){
                    if(!isNotEmpty(item[item.value])){
                        disabled.value = flag;
                        return
                    }
                }
                if(isNotEmpty(withdrawalAmount.value) && (isNotEmpty(bankCardInfoShow.walletbankid) || isNotEmpty(bankCardInfoShow.walletbankId)) && (bankCardInfoShow.walletbankId != 0 || bankCardInfoShow.walletbankid != 0) ){
                    flag = false
                }

                console.log(bankCardInfoShow)
                console.log(isNotEmpty(withdrawalAmount.value) && (isNotEmpty(bankCardInfoShow.walletbankid) || isNotEmpty(bankCardInfoShow.walletbankId)))

                disabled.value = flag;
            }
        /**
         * 按钮提交条件-结束
         */

        //提交
        async function Confirm(){
            if(minWithdrawLimit.value != '' && maxWithdrawLimit.value != ''){
                if(Number(withdrawalAmount.value) < Number(minWithdrawLimit.value) || Number(withdrawalAmount.value) > Number(maxWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text23') + minWithdrawLimit.value +'-'+ maxWithdrawLimit.value + 'USD')
                    return;
                }
            }else if(minWithdrawLimit.value == ''){
                if(Number(withdrawalAmount.value) > Number(maxWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text24') + maxWithdrawLimit.value + 'USD')
                    return;
                }
            }else if(maxWithdrawLimit.value == ''){
                    if(Number(withdrawalAmount.value) < Number(minWithdrawLimit.value)){
                    ElMessage.error(t('withdraw.text25') + minWithdrawLimit.value + 'USD')
                    return;
                }
            }

            let bankFlag = false;
            for(let item of AvailableBanks.value){
                if(item.name == bankCardInfoShow.bankName || item.name == bankCardInfoShow.bankTypeName){
                    bankFlag = true;
                    break;
                }
            }

            if(!bankFlag){
                banksTitle.value = t('withdraw.text52')
                dialogVisible.value = true;
                return
            }


            btnLoading.value = true;
            const res = await apiService.JudgeHavePos({});
            if(res.status == 1){
                btnLoading.value = false;
                if(res.data.hasPos){
                    if(res.data.hasCredit){
                        ElMessageBox.alert(res.data.tips,t('withdraw.text26'),
                         {
                            confirmButtonText:t('trade.text84'),
                        })
                    }else{
                        ElMessageBox.confirm(
                            res.data.tips,
                            t('withdraw.text26'),
                            {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: t('withdraw.text27'),
                            cancelButtonText: t('withdraw.text28'),
                            }
                        ).then(() => {
                            OnlineBankPayoutForApp();
                        }) .catch(() => {
                            btnLoading.value = false;
                        })
                    }
                }else{
                    OnlineBankPayoutForApp();
                }
            }
        }

        function OnlineBankPayoutForApp(){
             let obj = {};
            for(let item of addInfo.value){
                obj[item.name] = item[item.name]
            }
            let params = {
                walletbankId:bankCardInfoShow.walletbankId == 0?bankCardInfoShow.walletbankid:bankCardInfoShow.walletbankId,
                amount:withdrawalAmount.value,
                currentType:currentType.value,
                fundTerminal:1,
                payChannelId:payId.value,
                addinfo:JSON.stringify(obj)
                
            }
            console.log(params)
            apiService.OnlineBankPayoutForApp(params).then(res => {
                console.log(res)
                if(res.status == 1){
                    addInfo.value = [];
                    withdrawalAmount.value = '';
                    getPayOutChannelPage(payId.value);
                    ElMessage({
                        message:t('withdraw.text29'),
                        type:'success'
                    })
                }else{
                    ElMessage.error(res.message)
                }
                btnLoading.value = false;
            })
        }

        //清除输入框
        function clearValue(){
                withdrawalAmount.value = '';
                 for(let item of addInfo.value){
                   item[item.name] = ''
                }
        }

        //全部出金
        function withdrawalAmountAll(){
            withdrawalAmount.value = canDrawAmount.value;
            disabled.value = false;
            countPayment(withdrawalAmount.value);
        }

        //可用银行
        function AvailableBanksFun(){
            banksTitle.value = t('withdraw.text44')
            dialogVisible.value = true;
        }
        return {
            payChannels,
            payId,
            payIdChange,
            payChannel,
            exchangeRate,
            currentType,
            minWithdrawLimit,
            maxWithdrawLimit,
            currencyArr,
            arrayTips,
            canDrawAmount,
            withdrawalAmount,
            countPayment,
            rmbAmount,
            bankCardInfoShow,
            addBankCard,
            addBankCardSuccsess,
            dialogVisible,
            AvailableBanks,
            changeBankCard,
            openUseAnotherBoundCard,
            btnLoading,
            disabled,
            addInfo,
            Confirm,
            myKeydown,
            currentTypedChange,
            withdrawalAmountAll,
            placeholderText,
            banksTitle,
            AvailableBanksFun
        }
    }
}
</script>
<style lang="less" scoped>
.BankTransfer{
    padding: 24px 30px;
    .line_box{
        .line{
            overflow: hidden;
            margin-bottom: 16px;
            .label{
                float: left;
                height: 40px;
                line-height: 40px;
                width: 212px;
                text-align: left;
                font-size: 14px;
            }
            .value{
                float: left;
                width: 500px;
                text-align: left;
                font-size: 14px;
                .value_text{
                    line-height: 40px;
                    img{
                        width: 12px;
                        height: 12px;
                        position: relative;
                        top: 2px;
                        left: 7px;
                    }
                }
                .fast_btn_r{
                    width: 64px;
                    height: 40px;
                    background: @primary-color;
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    line-height: 40px;
                }
            }
            .fast{
                margin-top: 10px;
                display: flex;
                justify-content: left;
                .fast_choose{
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: 1px solid @border-color-base;
                    text-align: center;
                    color: @text-color-secondary;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .active_choose{
                    border-color:@primary-color;
                    color: @primary-color;
                }
            }
            .short_line{
                width: 485px;
                overflow: hidden;
                float: left;
                .value{
                    width: 273px;
                }
            }
            .short_line2{
                width: 439px;
                overflow: hidden;
                float: left;
                .label{
                    width: 173px;
                }
                .value{
                    width: 266px;
                }
            }
            .add_bankcard{
                width: 240px;
                color: @primary-color;
                background: @heading-bg-color;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                float: left;
            }
            .use_another{
                width: 240px;
                color: @primary-color;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                border: 1px solid @primary-color;
                float: left;
                margin-left: 30px;
            }
        }
        .line_btn{
            padding-left: 212px;

        }
        .hr{
            height: 1px;
            background: @border-color-base;
            margin-bottom: 10px;
        }
        .confirm{
            margin-top: 20px;
            width: 100%;
        }
        .pro{
            font-size: 14px;
            color: @text-color-secondary;
            text-align: left;
            margin: 20px 0
        }
    }
    .available_banks{
        margin-top: 10px;
    }
    .arrayTips{
        margin-top: 28px;
        text-align: left;
    }
    .scroll{
        height: 400px;
        overflow-y: scroll;
    }
}
</style>
<style lang="less">
.BankTransfer{
    .el-select{
        width:100%;
    }
    
    .withdrawal_amount{
        .el-input__suffix{
            right: 0;
        }
    }
   
}
</style>