<template>
    <div class="FundHistoryWithdrawal">
        <div class="line">
            <div class="line_td">
                <div class="lable">{{$t('fundHistory.text37')}}</div>
                <div class="value">
                      <el-select v-model="type" class="m-2" size="large">
                        <el-option
                            v-for="item in itemFlowType"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
            <div class="line_td">
                <div class="lable">{{$t('fundHistory.text7')}}</div>
                <div class="value">
                      <el-select v-model="status" class="m-2" size="large">
                        <el-option
                            v-for="item in itemStatus"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                
            </div>
        </div>
        <div class="line">
            <div class="line_td">
                <div class="lable">
                    {{$t('fundHistory.text8')}}
                </div>
                <div class="screening_picker">
                        <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                             :start-placeholder="$t('AccountAssets.text17')"
                            :end-placeholder="$t('AccountAssets.text18')"
                            size='large'
                            format="MM/DD/YYYY"
                            value-format="YYYY-MM-DD"
                        />
                </div>
                <div class="search">
                    <el-button type="primary" @click="search" class="search_btn" size="large">{{$t('AccountAssets.text19')}}</el-button>
                </div>
            </div>
        </div>
        <div class="statistics_title">
            <div class="hr"></div>
            <div>{{$t('AccountAssets.text13')}}: {{total}}</div>
        </div>
         <div class="table_box">
            <el-table v-loading="tloading" :data="tableData" style="width: 100%" :empty-text="$t('trade.text66')">
                <el-table-column prop="statusDesc" :label="$t('fundHistory.text9')" />
                <el-table-column prop="amount" :label="$t('fundHistory.text38')" width="160" />
                <el-table-column prop="payAmount" :label="$t('fundHistory.text39')" width="160" />
                <el-table-column prop="fee" :label="$t('fundHistory.text40')" width="110" />
                <el-table-column prop="bizTime" width="200" :label="$t('fundHistory.text8')" />
                <el-table-column  :label="$t('trade.text62')" width="220" >
                     <template #default="scope">
                            <span v-if="scope.row.payType == 52 || scope.row.payType == 50" class="details" @click="details(scope.row)">{{$t('fundHistory.text13')}}</span>
                            <span v-if="scope.row.status == 2" @click="revoke(scope.row)">{{$t('fundHistory.text41')}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination :page-size="10" layout="prev, pager, next"  :total="totalPage" @current-change="handleCurrentChange"/>
        </div>
          <el-dialog
            :title="$t('fundHistory.text13')"
            v-model="DetailsModelFlag"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
        >
            <div class="row">
                <div class="lable">{{$t('fundHistory.text14')}}:</div>
                <div :class="['value',detailsObj.status == 1 ?'green':detailsObj.status == 2 ?'blue':detailsObj.status == 3 ?'red':'primary']">{{detailsObj.statusStr}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text15')}}:</div>
                <div class="value">{{detailsObj.digiccyAmountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text38')}}:</div>
                <div class="value">{{detailsObj.amount}}{{detailsObj.amountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text42')}}:</div>
                <div class="value">{{detailsObj.digiccyAmount}}{{detailsObj.digiccyAmountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text43')}}:</div>
                <div class="value">{{detailsObj.walletfee}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text19')}}:</div>
                <div class="value">{{detailsObj.withdrawtime}}</div>
            </div>
            <div class="pro">{{$t('fundHistory.text20')}}</div>
            <div class="row-2">
                <div class="lable">{{$t('fundHistory.text21')}}:</div>
                <div class="value"><a target="__blank" :href="detailsObj.outCashAddressLink">{{detailsObj.outCashAddress}}</a></div>
            </div>
            <div class="row-2">
                <div class="lable">TxHash：</div>
                <div class="value"><a target="__blank" :href="detailsObj.txIdLink">{{detailsObj.txId?detailsObj.txId:'--'}}</a></div>
            </div>
          </el-dialog>
           <el-dialog
            :title="$t('fundHistory.text13')"
            v-model="DetailsModelFlagUSDT"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
        >
            <div class="row">
                <div class="lable">{{$t('fundHistory.text14')}}:</div>
                <div :class="['value',detailsObj.status == 1 ?'green':detailsObj.status == 2 ?'blue':detailsObj.status == 3 ?'red':'primary']">{{detailsObj.statusStr}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text38')}}:</div>
                <div class="value">{{detailsObj.amount}} {{detailsObj.amountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text39')}}:</div>
                <div class="value">{{detailsObj.arrivalAmount}} {{detailsObj.arrivalAmountCurrency}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text11')}}:</div>
                <div class="value">{{detailsObj.withdrawtime}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text44')}}:</div>
                <div class="value">{{detailsObj.bankName}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text28')}}:</div>
                <div class="value">{{detailsObj.bankAccount}}</div>
            </div>
            <div class="row">
                <div class="lable">{{$t('fundHistory.text29')}}:</div>
                <div class="value">{{detailsObj.realName}}</div>
            </div>
          </el-dialog>
    </div>
</template>
<script>
import {
 ref,watchEffect,reactive
} from 'vue';
import apiService from '@/api/request';
import { ElMessageBox, ElMessage } from 'element-plus'
import { useI18n } from "vue-i18n";

export default {
    name:'FundHistoryWithdrawal',
    props: {
        itemFlowType: {
            type: Object,
        },
        itemStatus: {
            type: Object,
        },
        endTime:{
            type:String,
        },
        startTime:{
            type:String,
        }
    },
    setup(props){
        const { t } = useI18n()
        const type = ref(0);
        const typeList = ref([]);
        const searchDate = ref([]);
        const status = ref(0);
        const nowPage = ref(1);
        const pageSize = ref(10);
        const tloading = ref(false);
        const tableData = ref([]);
        const total = ref('');
        let firstTime = false;
        const DetailsModelFlag = ref(false);
        const totalPage = ref(0);
        const DetailsModelFlagUSDT = ref(false);

        watchEffect(() => {
            if(props.startTime != '' && props.endTime != '' && !firstTime){
                searchDate.value = [props.startTime,props.endTime];
                search();
                firstTime = true
            }
        })
         /*
         * 查询-开始
         */
            function search(){
                let params = {
                    startTime:searchDate.value?searchDate.value[0]:'',
                    endTime:searchDate.value?searchDate.value[1]:'',
                    pageIndex:nowPage.value,
                    pageSize:pageSize.value,
                    type:'2',
                    flowTypes:type.value,
                    status:status.value
                }
                tloading.value = true;
                apiService.GetFlowLogRevision(params).then(res => {
                    if(res.status == 1){
                        tableData.value = res.data.list;
                        total.value = res.data.total;
                        totalPage.value = Number(res.data.count);
                    }
                    tloading.value = false;
                })
            }
        /*
         * 查询-结束
         */
        
        /*
         * 打开详情，链上入金才有详情 - 开始
         */
            const detailsObj = reactive({});
            function details(e){
                if(e.payType == 52){
                    let params = {}
                    if(e.payId){
                        params.payId = e.payId
                    }
                    if(e.flowId){
                        params.flowId = e.flowId
                    }
                    apiService.RecentlyChainOutOrder(params).then(res => {
                        if(res.status == 1){
                            Object.assign(detailsObj,res.data);
                            DetailsModelFlag.value = true;
                        }
                    })
                }else if(e.payType == 50){
                     let params = {}
                    if(e.payId){
                        params.payId = e.payId
                    }
                    if(e.flowId){
                        params.flowId = e.flowId
                    }
                    apiService.RecentlyRoutineOutOrder(params).then(res => {
                        if(res.status == 1){
                            Object.assign(detailsObj,res.data);
                            DetailsModelFlagUSDT.value = true;
                        }
                    })
                }
            }
        /*
         * 打开详情，链上入金才有详情 - 开始
         */

        //撤销订单
        function revoke(e){
            ElMessageBox.confirm(
                t('fundHistory.text45'),
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: t('trade.text73'),
                    cancelButtonText: t('trade.text72'),
                }
            ).then(() => {
                apiService.CancelWithdrawOrder({flowId:e.flowId}).then(res => {
                    if(res.status == 1){
                        ElMessage({
                            message: t('fundHistory.text46'),
                            type: 'success',
                        });
                        search();
                    }
                })
            }) .catch(() => {

            })
        }

         //切换页面
        function handleCurrentChange(e){
            nowPage.value = e;
            search();
        }
        return {
            typeList,
            type,
            searchDate,
            search,
            status,
            nowPage,
            pageSize,
            tloading,
            tableData,
            total,
            DetailsModelFlag,
            details,
            detailsObj,
            revoke,
            handleCurrentChange,
            totalPage,
            DetailsModelFlagUSDT
        }
    }
}
</script>
<style lang="less" scoped>
.FundHistoryWithdrawal{
    padding: 24px 20px;
    .line{
        overflow: hidden;
        margin-bottom: 16px;
    }
    .line_td{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-right: 40px;
        float: left;
        .lable{
            margin-right: 20px;
            color: @text-color-secondary;
            font-size: 14px;
        }
        .value{
            width: 240px;
        }
        .search_btn{
            width: 80px;
            margin-left: 20px;
        }
    }
    .statistics_title{
        font-size: 18px;
        display: flex;
        justify-content: left;
        margin-top: 30px;
        .hr{
            width: 4px;
            height: 20px;
            background: @primary-color;
            margin-right: 10px;
        }
        div{
            font-weight: 500;
        }
    }
    .table_box{
        margin-top: 16px;
        span{
            color: @primary-color;
            text-decoration: underline;
            cursor: pointer;
        }
        .details{
            margin-right: 20px;
        }
    }
    .pagination{
        float: right;
        margin-top: 20px;
    }
    .dialog-box{
        .row{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            .lable{
                width: 195px;
                float: left;
            }
            .value{
                float: left;
                font-weight: 500;
            }
        }
        .pro{
            margin-bottom: 16px;
            color: #666666;
        }
        .row-2{
            margin-bottom: 16px;
            overflow: hidden;
            font-size: 14px;
            .lable{
                width: 96px;
                float: left;
            }
            .value{
                float: left;
                width: 332px;
                font-weight: 500;
                cursor: pointer;
                a{
                    color: #FB741B;
                }
            }
        }
    }
}
</style>
<style lang="less">
.FundHistoryWithdrawal{
    .el-select{
        width:100%;
    }
}
</style>
