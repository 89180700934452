<template>
    <div class="Login">
        <div class="contact_banner">
            <div class="text">
                <h2>Become our friends & trading partners</h2>
                <p>Get unlimited learning resources</p>
                <p>Join our rewarding incentive programs</p>
                <p>Exlusive access to our communities</p>
            </div>
            <div class="login_box">
                <div class="lable">
                    Your Name (required)
                </div>
                <div class="value">
                    <div class="vlaue_box_l">
                        <input type="text" placeholder="First Name" v-model="firstName">
                    </div>
                    <div class="vlaue_box_r">
                        <input type="text" placeholder="Last Name" v-model="lastName">
                    </div>
                </div>
                <div class="lable">
                   Phone Number:(required)
                </div>
                <div class="value phone">
                    <div class="vlaue_box_l">
                        <div class="prefix"><span @click="openPrefix">{{prefix}}</span>
                            <img @click="openPrefix" src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/icon_sel_number_down%402x.png" alt="">
                            <ul v-if="prefixFlag">
                                <li v-for="item in countrysList" @click="changePrefix(item)" :key="item.code">{{item.name}} ({{item.code}})</li>
                            </ul>
                        </div>
                    </div>
                    <div class="vlaue_box_r">
                        <input type="text" placeholder="Enter your mobile phone number" v-model="phone">
                    </div>
                </div>
                <div class="lable">
                   SMS Verification:(required)
                </div>
                <div class="value code">
                    <div class="vlaue_box_l">
                        <input type="text" placeholder="Enter the SMS code" v-model="code">
                    </div>
                    <div class="vlaue_box_r">
                        <div class="obtain" @click="GetByMobile">{{sendMessage}}</div>
                    </div>
                </div>
                <div class="submit" @click="submit">
                     SUBMIT
                </div>
            </div>
        </div>
         <div class="wealth">
            <div class="text">
                <h4>WE ALWAYS TREAT OUR TRADERS AS NO.1</h4>
                <p>
                    Unlike others, we offer more than just a trading platform. Advantrade’s team focuses on helping clients to “develop their capital”, by sharing our knowledge and experience in wealth acquisition to enable a win-win concept between our clients and us. Our goal is to enhance our clients’ experience and allow them to get the most out of the financial markets. User friendly for beginners, free 1 on 1 trading guideline , free live trading webinar, big learning treasure , customized trading manager and more!
                </p>
                <div class="learn_more cursor" @click="routerTo('about')">Learn more</div>
                </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_pic%402x.png" alt="">
            </div>
        </div>
        <!-- <div class="latest">
            <h2>OUR LATEST PROMOTION</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_003%402x.png" alt="">
                    <div class="name">Lucky spin</div>
                    <p>Win Bitcoin, Ethereum and other cryptocurrencies</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_002%402x.png" alt="">
                    <div class="name">Deposit bonus</div>
                    <p>Get upto $5588 Bonus to maximize your trading potential</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_001%402x.png" alt="">
                    <div class="name">Referral program</div>
                    <p>Refer your friends and start your journey together</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_005%402x.png" alt="">
                    <div class="name">Switching bonus</div>
                    <p>Enjoy even more rewards when you switch from others brokers</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign%20up_banner_004%402x.png" alt="">
                    <div class="name">Exclusive account rankings</div>
                    <p>Become our VIP to enjoy unlimited trading discounts</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_pic_giftbox%402x.png" alt="">
                    <div class="name">coming soon!</div>
                    <p><router-link to="/promotion">Learn more ></router-link></p>
                </li>
            </ul>
        </div> -->
    </div>
</template>
<script>
import { ref } from 'vue';
import { assignParams,loadErrorCallback } from '@/until/common';
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router"
import { useStore } from 'vuex';

export default {
name:'Login',
    components:{
    },
     setup(){
        const store = useStore();
        const router = useRouter(); 
        const prefix = ref('');
        const countryid = ref('');
        const prefixFlag = ref(false);


        const firstName = ref("");
        const lastName = ref("");
        const phone = ref("");
        const code = ref('');
        const countrysList = ref([]);
        const sendMessage = ref('Obtain');

    GetCountrys();
    function GetCountrys(){
        let params = assignParams({clientType:'web'});
        apiService.GetCountrys(params).then((res) => {
            countrysList.value = res.data.list;
            prefix.value = res.data.list[0].code;
            countryid.value = res.data.list[0].countryid;
        })   
    }
    function changePrefix(e){
        prefix.value = e.code;
        countryid.value = e.countryid;
        prefixFlag.value = false;
    }
    
    function openPrefix(){
        prefixFlag.value = true
    }
    let timer = null;
    const time = ref(60);

    //获取验证码
    function GetByMobile(){
        if(phone.value == '') {
            ElMessage.error("Please enter your mobile phone number")
            return
        }
        if(timer) return;

        try {
            var captcha = new TencentCaptcha('190327966', sendCodeFunc, {'userLanguage':JSON.parse(localStorage.getItem('ehammer.state')).home.lang == 'cn'?'zh':'en','needFeedBack': false});
            captcha.show(); 
        } catch (error) {
            loadErrorCallback();
        }

        function sendCodeFunc(result){
            if(result.ret != 0) return;


            sendMessage.value = 'Obtain';
            let param = {
                smsType:21,
                mobileAreaCode:prefix.value,
                phone:window.btoa(phone.value),
            }
            let params = assignParams(param)
                apiService.GetByMobile(params).then((res) => {
                    if(res.status == 1){
                        sendMessage.value = `${time.value}S`;
                        timer = setInterval(() => {
                        sendMessage.value = `${time.value}S`;
                        time.value -= 1;
                        if (time.value <= 0) {
                            clearInterval(timer);
                            sendMessage.value = 'Obtain';
                            time.value = 60;
                            timer = null;
                        }
                        }, 1000);
                    }else{
                        ElMessage.error(res.message)
                    }
                
                })
        }
    }
    function routerTo(e){
            router.push(e)
       }
    //注册
    function submit(){
         if(phone.value == '') {
            ElMessage.error("Please enter your mobile phone number")
            return
        }
         if(firstName.value == '') {
            ElMessage.error("Please enter first name")
            return
        }
         if(lastName.value == '') {
            ElMessage.error("Please enter last name")
            return
        }
         if(code.value == '') {
            ElMessage.error("Please enter SMS Verification")
            return
        }
         let param = {
             firstName:firstName.value,
             lastName:lastName.value,
             phone:window.btoa(phone.value),
             vcode:code.value,
             mobileAreaCode:prefix.value,
             countryid:countryid.value,
             "registerTag.tag":sessionStorage.tag || 'Official Website',
            "registerTag.keywords":sessionStorage.keywords || '',
            "registerTag.campaign":sessionStorage.campaign || '',
            "registerTag.tag1":sessionStorage.tag1 || '',
            "registerTag.tag2":sessionStorage.tag2 || '',
            "registerTag.tag3":sessionStorage.tag3 || '',
            "registerTag.language":sessionStorage.language || '',
            }
            let params = assignParams(param)
            apiService.RegisterOverseas(params).then((res) => {
                console.log(res)
                if(res.status == 1){
                    //注册成功
                    router.push('congratulation')
                }else{
                    ElMessage.error(res.message)
                }
            })
    }

      return {
        prefix,
        firstName,
        lastName,
        phone,
        submit,
        code,
        GetCountrys,
        countrysList,
        changePrefix,
        openPrefix,
        prefixFlag,
        GetByMobile,
        sendMessage,
        routerTo
      };
    }
}
</script>
<style lang="less" scoped>
.Login{
    .contact_banner{
        height: 900px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/login/sign_banner%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        .text{
            width: 660px;
            height: 286px;
            text-align: left;
        }
        .login_box{
            width: 440px;
            height: 470px;
            background: white;
            border-radius: 8px;
            padding: 40px;
            text-align: left;
            .lable{
                color: black;
            }
        .value{
                display: flex;
                justify-content: left;
                height: 40px;
                margin-top: 10px;
                margin-bottom: 30px;
                border-radius: 8px;
                .vlaue_box_l{
                    border: 1px solid #D9D9D9;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    height: 40px;
                }
                .vlaue_box_r{
                    border-top: 1px solid #D9D9D9;
                    border-right: 1px solid #D9D9D9;
                    border-bottom: 1px solid #D9D9D9;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    height: 40px;
                }
                input{
                    width: 100%;
                    height: 100%;
                    padding-left: 10px;
                    border-radius: 8px;
                }
            }
            .phone{
                .vlaue_box_l{
                    width: 80px;
                    .prefix{
                        color: black;
                        line-height: 40px;
                        text-align: center;
                        cursor: pointer;
                        img{
                            width: 10px;
                            position: relative;
                            top: -3px;
                            left: 5px;
                        }
                        ul{
                            width: 355px;
                            text-align: left;
                            background: white;
                            position: absolute;
                            z-index: 9;
                            border: 1px solid #D9D9D9;
                            height: 400px;
                            overflow-y: scroll;
                            li{
                                padding: 0 20px;
                                border-bottom: 1px solid #D9D9D9;
                                cursor: pointer;
                            }   
                        }
                    }
                }
                .vlaue_box_r{
                    width: 280px;
                }
            }
            .code{
                .vlaue_box_l{
                    width: 280px;
                }
                .vlaue_box_r{
                    width: 80px;
                    overflow: hidden;
                }
                .obtain{
                    background: #FB741B;
                    color: white;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .submit{
            width: 100%;
            height: 40px;
            background: #FB741B;
            text-align: center;
            line-height: 40px;
            color: white;
            margin-top: 40px;
            cursor: pointer;
        }
        h2{
            font-size: 46px;
            font-weight: 900;
            width: 456px;
            margin-bottom: 30px;
        }
        p{
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            padding-top: 70px;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
            .learn_more{
                width: 150px;
                height: 40px;
                line-height: 40px;
                background: #FB741B;
                color: white;
                text-align: center;
                font-size: 16px;
                margin-top: 24px;
            }
        }
        .pic{
            display: flex;
            align-items:flex-end;
            img{
                width: 550px;
            }
        }
    }
    .latest{
        h2{
            font-size: 34px;
            margin-top: 100px;
        }
        ul{
            width: 1100px;
            margin: 0 auto;
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            li{
                width: 350px;
                margin-bottom: 60px;
                img{
                    width: 100%;
                }
                .name{
                    font-size: 20px;
                    margin-top: 16px;
                    font-weight: 600;
                }
                p{
                    margin-top: 12px;
                }
                a{
                    color: #FB741B;
                }
            }
        }
    }
}

</style>
