<template>
    <div class="FollowOrder">
        <div class="banner">
            <div class="banner_box w1200 flex space-between align-items-center">
                <h2>跟单交易</h2>
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_banner2%402x.png" alt="">
            </div>
        </div>
        <div class="content_one">
            <div class="content_one_box w1200">
                <div class="title">了解智能跟单系统</div>
                <p class="pro">没时间盯盘？交易总亏损</p>
                <p class="pro">试试AdvanTrade爱跟投跟单交易吧</p>
                <div class="content_box">
                    <div class="content_box_left">
                        <p>AdvanTrade爱跟投拥有强大的技术团队和研发能力，独立自主开发出一套更加适合新手的智能跟单系统。不仅如此，我们还设置了严格的交易员准入机制。</p>
                        <p>AdvanTrade爱跟投通过大数据和自动化等技术手段，对交易员的历史业绩进行分析评估，基于交易员的历史数据（包括收益率、胜率、最大回撤、交易品种、持仓时间、仓位控制等），以及交易员个人的风控习惯严格选拔出跟单交易员，并持续监控他们的账户表现，再设立淘汰机制，优中选精，确保跟单信号源质量。</p>
                        <ul>
                            <li><span></span><div>精选交易高手，降低跟随风险</div></li>
                            <li><span></span><div>提升交易能力，学习投资方法</div></li>
                            <li><span></span><div>全自动买卖，快速执行订单</div></li>
                            <li><span></span><div>无需盯盘，节约时间，坐享收益</div></li>
                        </ul>
                    </div>
                    <div class="content_box_right">
                        <video  controls="controls" poster="https://advantrade.oss-accelerate.aliyuncs.com/cn/operation-introduction/pic_cover_11.png" src="https://advantrade.oss-accelerate.aliyuncs.com/Video/FollowTrading.mp4"></video>
                    </div>
                </div>
            </div>
            <div class="btn cursor" @click="startCopying">
                <span>开始跟单</span><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png" alt="">
            </div>
        </div>
        <div class="content_two">
            <div class="title">如何订阅跟单信号？</div>
            <p class="pro">跟单交易，让交易变简单</p>
            <p class="pro">选择您喜欢的明星交易员，剩下的交给我们</p>
            <div class="content_two_box w1200">
                <img class="content_two_box_left" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_pic1.png" alt="">
                <div class="content_two_box_right">
                    <p>不是每个人都有资格成为AdvanTrade爱跟投的跟单交易员。他们必须在平台上交易3个月以上，只有合格的交易者才能提供信号，反之，如果交易者表现不佳将被取消资格。</p>
                    <p>AdvanTrade爱跟投 现在已经从数十万名交易者中筛选出 10 多位顶级交易员，客户不必自己审查数以千计的交易信号，只需根据自己的仓位金额和交易员历史表现对信号源进行订阅、跟随即可。</p>
                </div>
            </div>
        </div>  
        <div class="content_tree">
            <div class="title">跟单常见Q&A</div>
            <div class="prompt w1200">如果您在跟单过程中遇到任何疑问，请与我们联系</div>
            <ul class="w1200">
                <li>
                    <div class="prompt_top">
                        <img class="swich" @click="swichFlag1 = !swichFlag1" :src="swichFlag1?'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs2.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs1.png'" alt="">
                        <div>如何选择交易员进行跟单？</div>
                    </div>
                    <div class="prompt_bottom" v-if="swichFlag1">
                        下载AdvanTrade APP，从底部导航栏【跟单】进入，浏览选择您喜欢的交易员主页，点击【跟随】，输入您想分配的“跟单金额”“跟随模式”“止损位”提交后，即可自动实时复制他们的仓位。
                    </div>
                </li> 
                <div class="hr"></div>
                <li> 
                    <div class="prompt_top">
                        <img class="swich" @click="swichFlag2 = !swichFlag2" :src="swichFlag2?'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs2.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs1.png'" alt="">
                        <div>有哪些可用的跟单设置？</div>
                    </div>
                    <div class="prompt_bottom" v-if="swichFlag2">
                        <p>1、用户可以查看每位交易员的交易数据，包括收益率、回撤率、交易时长、个人简介等信息，并自由选择自己信任的交易员；</p>
                        <p>2、用户在选择跟单时，可以在最小跟随资金的基础上随意调整金额，并且可以设定最大强平线。当跟单账户下跌达到强平线时，系统将自动帮助平仓，以降低跟单风险；</p>
                        <p>3、用户可以在中途暂停跟随或者解除跟随。</p>
                    </div>
                </li> 
                <div class="hr"></div>
                <li> 
                   <div class="prompt_top">
                        <img class="swich" @click="swichFlag3 = !swichFlag3" :src="swichFlag3?'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs2.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs1.png'" alt="">
                        <div>跟单如何收取费用？</div>
                    </div>
                    <div class="prompt_bottom" v-if="swichFlag3">
                        用户可以通过注册认证、存入资金、邀请好友、分享周报、好友点赞等活动获取跟单优惠券，以抵扣跟单费用。最高优惠可实现0元跟随。
                    </div>
                </li> 
                <div class="hr"></div>
                 <li> 
                    <div class="prompt_top">
                        <img class="swich" @click="swichFlag4 = !swichFlag4" :src="swichFlag4?'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs2.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs1.png'" alt="">
                        <div>如何停止跟单？</div>
                    </div>
                    <div class="prompt_bottom" v-if="swichFlag4">
                        当跟随交易员后，发现一段时间交易员表现不佳，可以在APP交易页面，打开跟单账户，选择停止跟随即可。
                    </div>
                </li> 
                <div class="hr"></div>
                 <li> 
                   <div class="prompt_top">
                        <img class="swich" @click="swichFlag5 = !swichFlag5" :src="swichFlag5?'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs2.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/icon_faqs1.png'" alt="">
                        <div>盈利后如何提现？</div>
                    </div>
                    <div class="prompt_bottom" v-if="swichFlag5">
                        跟随交易盈利后，用户可以减少跟随金额或解除跟随，您的资金都将退还至您个人实盘账户，选择出金即可。
                    </div>
                </li> 
                <div class="hr"></div>
            </ul>
            <div class="prompt w1200">订阅跟单交易员</div>
            <div class="content_tree_box_two w1200">
                <div class="box_left">
                    <div class="line" @mouseenter="activeTab = 1">
                        <div class="number_box">
                            <div class="number" :class="activeTab == 1?'active':''">1</div>
                        </div>
                        <div class="text">
                            <div class="w_text">
                                选择交易员
                            </div>
                            <p>根据页面展示，选择您喜爱的跟单交易员</p>
                        </div>
                    </div>
                     <div class="line" @mouseenter="activeTab = 2">
                        <div class="number_box">
                            <div class="number" :class="activeTab == 2?'active':''">2</div>
                        </div>
                        <div class="text">
                            <div class="w_text">
                                设置跟单
                            </div>
                            <p>设置跟单数量、跟单模式和止损数额（非强制）</p>
                        </div>
                    </div>
                     <div class="line" @mouseenter="activeTab = 3">
                        <div class="number_box number_box_3">
                            <div class="number" :class="activeTab == 3?'active':''">3</div>
                        </div>
                        <div class="text">
                            <div class="w_text">
                                订阅跟随
                            </div>
                            <p>点击“确定跟随”，自动复制明星交易员的订单</p>
                        </div>
                    </div>
                    <div class="btn cursor" @click="startCopying">
                        <span>开启跟单之旅吧</span><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/icon_jump_white%402x.png" alt="">
                    </div>
                </div>
                <div class="box_right">
                    <img v-show="activeTab == 1" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_step1%402x.png" alt="">
                    <img v-show="activeTab == 2" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_step2%402x.png" alt="">
                    <img v-show="activeTab == 3" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_step3%402x.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from "vue-router"

export default {
    name:"FollowOrder",
    components:{
    },
    setup(){
        let swichFlag1 = ref(false);
        let swichFlag2 = ref(false);
        let swichFlag3 = ref(false);
        let swichFlag4 = ref(false);
        let swichFlag5 = ref(false);
        const router = useRouter(); 

        let activeTab = ref(1);
        function startCopying(){
            router.push('downloadCN')
        }

        return {
            startCopying,
            swichFlag1,
            swichFlag2,
            swichFlag3,
            swichFlag4,
            swichFlag5,
            activeTab
        }
    }
}
</script>
<style lang="less" scoped>
.FollowOrder{
  .banner{
    background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_banner%402x.png) no-repeat;
    background-size: cover;
    background-position: center;
    .banner_box{
        padding-right: 100px;
        img{
            width: 402px;
            height: 334px;
        }
        h2{
            font-size:50px; 
            font-weight: 600;
            color: white;
        }
    }
  }
  .content_one{
    padding: 98px 0;
    background: white;
    .title{
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 25px;
    }
    .pro{
        font-size: 20px;
    }
    .content_box{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        .content_box_left{
            width: 540px;
            p{
                font-size: 16px;
                margin-bottom: 20px;
                text-align: left;
                font-size: 16px;
            }
            &>ul{
                padding-top: 10px;
                li{
                    text-align: left;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    margin-bottom: 10px;
                    font-size: 16px;
                    span{
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: #FB741B;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    div{
                        font-weight: 600;
                    }
                }
            }
        }
        .content_box_right{
            width: 600px;
            height: 340px;
            video{
                width: 100%;
            }
        }
        
    }
  }
    .btn{
        width: 210px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FB741B;
        font-size: 16px;
        border-radius: 27px;
        margin: 0 auto;
        margin-top: 100px;
        color: white;
        img{
            width: 22px;
            height: 14px;
            display: block;
            margin-left: 8px;
        }
        span{
            display: block;
            font-weight: 600;
        }
    }
  .content_two{
    padding-top: 120px;
    padding-bottom: 122px;
    background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/FollowOrder/copytrade_bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    .title{
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 25px;
        color: white;
    }
    .pro{
        font-size: 20px;
        color: white;
    }
    .content_two_box{
        margin-top: 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content_two_box_left{
            width: 486px;
            height: 402px;
        }
        .content_two_box_right{
            width: 550px;
            text-align: left;
            color: white;
            p{
                &:first-child{
                    margin-bottom: 30px;
                }
            }
        }
    }
  }
  .content_tree{
    padding-top: 120px;
    background: white;
    .title{
        font-size: 40px;
        font-weight: 600;
    }
    .prompt{
        height: 120px;
        line-height: 120px;
        font-size: 32px;
        margin-top: 64px;
        background: linear-gradient(180deg, #FFF5EF 0%, #FFFFFF 100%);
    }
    &>ul{
        margin-top: 70px;
        padding-bottom: 81px;
        li{
            .prompt_top{
                display: flex;
                justify-content: left;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 22px;
                .swich{
                    width: 24px;
                    height: 24px;
                    margin-right: 30px;
                    cursor: pointer;
                }
            }
            .prompt_bottom{
                padding-left: 54px;
                text-align: left;
                margin-bottom: 40px;
            }
        }
        .hr{
            height: 2px;
            background: #DEE2E6;
            margin-bottom: 30px;
        }
    }
    .content_tree_box_two{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 122px;
        .box_left{
            .line{
                display: flex;
                justify-content: left;
                margin-bottom: 46px;
                cursor: pointer;
                .number_box{
                    height: 44px;
                    width: 44px;
                    border: 1px solid #FB741B;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    color: #FB741B;
                    &::after{
                        content: '';
                        display: block;
                        border-left: 2px dashed #FB741B;
                        width: 1px;
                        height: 46px;
                        position: absolute;
                        top: 51px;
                        left: 50%;
                    }
                }
                .number.active{
                    width: 32px;;
                    height: 32px;
                    background: #FB741B;
                    font-size: 20px;
                    color: white;
                    line-height: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
                .text{
                    text-align: left;
                    margin-left: 24px;
                }
                .w_text{
                    font-size: 20px;
                    font-weight: 600;
                    height: 44px;
                    line-height: 44px;
                }
                p{
                    color: #78838C;
                }
                .number_box_3{
                    &::after{
                        content: '';
                        display: none;
                    }
                }
            }
        }
        .box_right{
            width: 368px;
            height: 500px;
            img{
                width: 100%;
            }
        }
    }
  }
}
</style>
