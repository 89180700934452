<template>
    <div class="SignUp">
        <div class="SignUp_center">
            <div class="text_box">
                <h2>{{ $t('signUp.text1') }}<br />{{ $t('signUp.text2') }}</h2>
                <ul>
                    <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/demo_account_icon_green%402x.png" alt="">{{ $t('signUp.text3') }}</li>
                    <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/demo_account_icon_green%402x.png" alt="">{{ $t('signUp.text4') }}</li>
                    <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/demo_account_icon_green%402x.png" alt="">{{ $t('signUp.text5') }}</li>
                    <li><img src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/demo_account_icon_green%402x.png" alt="">{{ $t('signUp.text6') }}</li>
                </ul>
            </div>
            <div class="sign_up_box" v-if="!setPassword">
                <div v-if='!isForget'>
                    <div class="head"  v-if="isPwd">
                         <p class="title">{{ $t('signUp.text7') }}</p>
                        <p>{{ $t('signUp.text8') }}</p>
                    </div>
                <div class="email_or_phone" v-else>
                    <div :class="loginWay=='phone'?'login_tab_active':''" class="login_tab" @click="loginWay = 'phone'">{{ $t('signUp.text9') }}</div>
                    <div :class="loginWay=='email'?'login_tab_active':''" class="login_tab" @click="loginWay = 'email'">{{ $t('signUp.text10') }}</div>
                </div>
                <PhoneLogin v-show="loginWay =='phone'"/>
                <EmailLogin v-show="loginWay =='email'"/>
                <p class="agree">{{ $t('signUp.text11') }}<a target="__blank" href="https://advantrade.oss-accelerate.aliyuncs.com/pdf/CLIENT-AGREEMENT.pdf">{{ $t('signUp.text12') }}</a></p>
                </div>
                <div v-else>
                    <div class="email_or_phone">
                        <div :class="loginWay=='phone'?'login_tab_active':''" class="login_tab" @click="loginWay = 'phone'">{{ $t('signUp.text28') }}</div>
                        <div :class="loginWay=='email'?'login_tab_active':''" class="login_tab" @click="loginWay = 'email'">{{ $t('signUp.text29') }}</div>
                    </div>
                    <ForgetPwd v-show="loginWay =='phone'"/>
                    <ForgetEmailPwd v-show="loginWay =='email'"/>
                </div>
            </div>
            <div v-else class="sign_up_box">
                <div class="head"  v-if="isPwd">
                    <p class="title">{{ $t('signUp.text30') }}</p>
                </div>
                <div class="set_password">
                    <div class="set_password_tr">
                         <PasswordInputBox 
                            :blurFunc="pwdBlur" 
                            :value="password"
                            :placeholder="$t('signUp.text31')"
                        />  
                    </div>
                    <div class="set_password_tr">
                        <PasswordInputBox 
                            :blurFunc="pwdBlur2" 
                            :value="password2"
                            :placeholder="$t('signUp.text32')"
                            :errText="$t('signUp.text33')"
                            :errorFlag="errorFlag"
                        />
                    </div>
                    <div class="set_password_tr_btn">
                        <el-button class="set_password_btn skip" @click="Skip" size='large'>{{$t('signUp.text34')}}</el-button>
                        <el-button class="set_password_btn" type="primary" size='large' :loading='loading' :disabled="!submitFlag" @click="setPasswordConfirm"
                            >{{$t('signUp.text35')}}</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref,inject,computed,onBeforeUnmount} from 'vue';
import { assignParams } from '@/until/common';
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router"
import { useStore } from 'vuex';
import PhoneLogin from './components/PhoneLogin.vue';
import EmailLogin from './components/EmailLogin.vue';
import ForgetPwd from './components/ForgetPwd.vue';
import ForgetEmailPwd from './components/ForgetEmailPwd.vue';
import { useI18n } from "vue-i18n";

export default {
    name:'SignUp',
    components:{
        PhoneLogin,
        EmailLogin,
        ForgetPwd,
        ForgetEmailPwd
    },
     setup(){

        const loginWay = ref('phone')
        const emitt = inject('emitt');
        const isPwd = ref(true);// 控制是密码登录还是验证码登录 - 默认是验证码登陆
        const isForget = ref(false)//控制登陆或忘记密码
        const setPassword = ref(false)
        const password = ref('');
        const password2 = ref('');
        const errorFlag = ref(false);
        const loading = ref(false);
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n()

        /**
         * 注册改变登陆方式---验证码登陆或密码登陆
         * 开始
         */
        emitt.$sub('changeLoginWay', (val) => {
            const value = val[0];
            console.log('changeLoginWay', value.isPwdOrCode);
            isPwd.value = value.isPwdOrCode;
        })
        onBeforeUnmount(() => {
            emitt.$unsub("changeLoginWay");
            emitt.$unsub("switchForget");
          });
        /**
         * 注册改变登陆方式---验证码登陆或密码登陆
         * 结束
         */

        const submitFlag = computed(() => {
            return password.value != '' && 
                    password2.value != ''
        })
        function pwdBlur(val) {
            errorFlag.value = false;
            password.value = val;
        }
        function pwdBlur2(val) {
            errorFlag.value = false;
            password2.value = val;
        }
        /**
         * 注册忘记密码
         * 开始
         */
        emitt.$sub('switchForget', (val) => {
            console.log('sub');
            const { type } = val[0];
            if(type == 'phone'){
                isForget.value = true
                
            }else if(type == 'email'){
                isForget.value = true
            }else if(type == 'back'){
                isPwd.value = true;
                isForget.value = false;
                loginWay.value = 'phone'
            }else if(type == 'setPassword'){
                setPassword.value = true;
            }
        });
        /**
         * 注册忘记密码
         * 结束
         */

        //跳过
        function Skip(){
            router.push({
                name:'myAccount'
            });
        }
         /**
         * 设置密码-开始
         */
            function setPasswordConfirm(){
                 if(password.value !== password2.value){
                    errorFlag.value = true;
                    return
                }

                let mask = /^[\da-z]+$/i
                if(!mask.test(password.value)){
                    ElMessage({
                        message: t('signUp.text37'),
                        type: 'error',
                        })
                    return
                }

                let params = {
                    password:window.btoa(password.value),
                }
                console.log(params)
                apiService.SetPassword(params).then(res => {
                    if(res.status == 1){
                        store.dispatch('getUserStatus');
                        ElMessage({
                            message: t('signUp.text36'),
                            type: 'success',
                        });
                        router.push({
                            name:'myAccount'
                        });
                    }else{
                         ElMessage({
                            message: res.message,
                            type: 'error',
                        });
                    }
                })
            }
        /**
         * 设置密码-开始
         */
      return {
       loginWay,
       isPwd,
       isForget,
       setPassword,
       password,
       password2,
       errorFlag,
       pwdBlur,
       pwdBlur2,
       Skip,
       setPasswordConfirm,
       submitFlag,
       loading
      };
    }
}
</script>
<style lang="less" scoped>
.SignUp{
    min-height: 100vh;
    background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/promotion_indices_banner%402x.png) no-repeat;
    background-size: cover;
    background-position: center;
    .SignUp_center{
        overflow: hidden;
        padding-top: 153px;
        width: 1160px;
        min-width: 920px;/*no*/
        margin: 0 auto;
        padding-bottom: 153px;


    }
    .sign_up_box{
        width: 540px;/*no*/
        padding-bottom: 40px;/*no*/
        height: 100%;
        background: @white;
        border-radius: 10px;/*no*/
        padding-top: 50px;/*no*/;
        margin-left: 48px;
        float: right;
        .agree{
            font-size: 12px;/*no*/
            margin-top: 54px;/*no*/
            a{
                color: @primary-color;
            }
        }
        .head{
            .title{
            font-size: 18px;/*no*/
            font-weight: 600;
            color: @black;
            }
            p:last-child{
            color: @text-color-secondary;
            margin-top: 10px;/*no*/
            }
        }
         .email_or_phone{
            width: 400px;/*no*/
            margin: 0 auto;
            display: flex;
            justify-content: left;
            align-items: flex-end;
            .login_tab{
                width: 140px;/*no*/
                font-size: 18px;/*no*/
                color: @text-color-secondary;
                cursor: pointer;
            }
            .login_tab:last-child{
            margin-left: 40px;/*no*/
            }
            .login_tab_active{
            font-size: 22px;/*no*/
            font-weight: 500;
            color: @black;
            }
        }
    }
    .text_box{
        width: 450px;
        margin-bottom: 51px;/*no*/
        text-align: left;
        margin-top: 67px;/*no*/
        float: left;
        h2{
            font-size: 36px;
            color: @white;
            margin-bottom:51px; 
            font-weight: 600;
        }
        ul{
            li{
                font-size: 16px;/*no*/
                margin-top: 30px;/*no*/
                display: flex;
                align-items: center;
                color: @white;
                img{
                    width: 28px;/*no*/
                    margin-right: 23px;
                }
            }
        }
    }
    .set_password{
        width: 460px;/*no*/
        margin: 0 auto;
        margin-top: 40px;/*no*/
        .set_password_tr_btn{
            display: flex;
            justify-content: space-between;
            margin-top: 60px;/*no*/
        }
        .set_password_btn{
            width: 220px;/*no*/
            height: 48px;/*no*/
        }
        .skip{}
    }
}

</style>
