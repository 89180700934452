
const pipe = (name) => {
    return () => import(`@/views/pc/${name}.vue`);
};
//这里就可以引用你要显示的页面了
import home from '@/views/pc/Home.vue'
import homeCN from '@/views/pc/HomeCN.vue'
import contactUs from '@/views/pc/ContactUs.vue'
import downloadCN from '@/views/pc/ContactUsCN.vue'
import about from '@/views/pc/About.vue'
import aboutCN from '@/views/pc/AboutCN.vue'
import followOrder from '@/views/pc/FollowOrder.vue'
import iBProgram from '@/views/pc/IBProgram.vue'
import whyChooseUs from '@/views/pc/WhyChooseUs.vue'
import latestNewsIndex from '@/views/pc/LatestNews/index.vue'
import promotion from '@/views/pc/Promotion/index.vue'
import membership from '@/views/pc/Promotion/Subpage/Membership.vue'
import luckSpin from '@/views/pc/Promotion/Subpage/LuckSpin.vue'
import depositBonus from '@/views/pc/Promotion/Subpage/DepositBonus.vue'
import referralBonus from '@/views/pc/Promotion/Subpage/ReferralBonus.vue'
import switchingBonus from '@/views/pc/Promotion/Subpage/SwitchingBonus.vue'
import download from '@/views/pc/Download.vue'
import login from '@/views/pc/Login.vue'
import signUp from '@/views/pc/SignUp'
import congratulation from '@/views/pc/Congratulation.vue'
import privacy from '@/views/pc/Privacy.vue'
import accountInformation from '@/views/pc/AccountInformation/index.vue'
import depositCurrency from '@/views/pc/Deposit/deposit.vue'
import deposit from '@/views/pc/Deposit/index.vue'
import withdrawalCurrency from '@/views/pc/Withdrawal/withdrawal.vue'
import withdrawal from '@/views/pc/Withdrawal/index.vue'
import accountAssets from '@/views/pc/AccountAssets/index.vue'
import fundsDetails from '@/views/pc/FundsDetails/index.vue'
import fundHistory from '@/views/pc/FundHistory/index.vue'


//配置路由，path就是你浏览器地址，component就是跟着你要显示的页面，/表示默认地址。
export default [
	{path:"/",name:'home',component:home},//主页
	{path:"/ContactUs",meta: {transparent: true},component:contactUs},//联系我们
	{path:"/about",component:about},//关于我们
	{path:"/aboutCN",component:aboutCN,meta: {langFlag:'cn'}},//关于我们
	{path:"/iBProgram",component:iBProgram,meta: {langFlag:'cn'}},//IB计划
	{path:"/followOrder",component:followOrder,meta: {langFlag:'cn'}},//智能跟单
	{path:"/downloadCN",component:downloadCN,meta: {langFlag:'cn'}},//联系我们
	{path:"/whyChooseUs",component:whyChooseUs},//选择我们
	{path:"/promotion",component:promotion},
	{path:"/membership",component:membership},
	{path:"/luckSpin",component:luckSpin},
	{path:"/depositBonus",component:depositBonus},
	{path:"/referralBonus",component:referralBonus},
	{path:"/switchingBonus",component:switchingBonus},
	{path:"/download",component:download},
	{path:"/login",name:'login',component:login},
	{path:"/signUp",name:'signUp',component:signUp},
	{path:"/congratulation",component:congratulation},
	{path:"/privacy",component:privacy},
	{path:"/homeCN",name:'homeCN',component:homeCN,meta: {langFlag:'cn'}},
	{//个人信息
		path:"/accountInformation",
		name:'accountInformation',
		component:accountInformation,
		children:[
			{//认证流程
				path:'/accountInformation/certification',
				name:"certification",
				component: () => import('@/views/pc/AccountInformation/children/Certification.vue')
			},
			{//上传手持身份证
				path:'/accountInformation/selfieHolding',
				name:"selfieHolding",
				component: () => import('@/views/pc/AccountInformation/children/SelfieHolding.vue')
			},
			{//补充地址证明
				path:'/accountInformation/addressFailed',
				name:"addressFailed",
				component: () => import('@/views/pc/AccountInformation/children/AddressFailed.vue')
			},
			{//上传签名
				path:'/accountInformation/submitSign',
				name:"submitSign",
				component: () => import('@/views/pc/AccountInformation/children/SubmitSign.vue')
			},
			{//个人信息主页
				path:'/accountInformation/myAccount',
				name:"myAccount",
				component: () => import('@/views/pc/AccountInformation/children/MyAccount.vue')
			},
			{//补充地址结果页
				path:'/accountInformation/addressCompleted',
				name:"addressCompleted",
				component: () => import('@/views/pc/AccountInformation/children/AddressCompleted.vue')
			},
			{//资金详情
				path:'/fundsDetails',
				name:"fundsDetails",
				component: () => import('@/views/pc/AccountInformation/children/FundsDetails.vue')
			},
			{//资金划拨
				path:'/fundTransfer',
				name:"FundTransfer",
				component: () => import('@/views/pc/AccountInformation/children/FundTransfer.vue')
			},
			{//历史交易
				path:'/historicalOrders',
				name:"historicalOrders",
				component: () => import('@/views/pc/AccountInformation/children/HistoricalOrders.vue')
			},
			{//入金
				path:"/depositCurrency",
				name:"depositCurrency",
				component:depositCurrency
			},
			{//入金
				path:"/deposit",
				name:"deposit",
				component:deposit
			},
			{//出金
				path:"/withdrawalCurrency",
				name:"withdrawalCurrency",
				component:withdrawalCurrency
			},
			{//出金
				path:"/withdrawal",
				name:"withdrawal",
				component:withdrawal
			},
			{//账号资产
				path:"/accountAssets",
				name:"accountAssets",
				component:accountAssets
			},
			{//账号资产
				path:"/fundsDetails",
				name:"fundsDetails",
				component:fundsDetails
			},
			{//账号资产
				path:"/fundHistory",
				name:"fundHistory",
				component:fundHistory
			},

		],
		meta:{
			requireAuth:true,
		},
	},
	{//交易
		path: '/transaction',
		name: 'transaction',
		meta:{
			requireAuth:false,
			footHideFlag:false
		},
		component: pipe('Transaction/Transaction'),
	},
	{//模拟交易
		path: '/transactionMn',
		name: 'transactionMn',
		meta:{
			requireAuth:false,
			footHideFlag:false
		},
		component: pipe('Transaction/TransactionMn'),
	},
	{//最新消息
		path:"/",
		component:latestNewsIndex,
		children:[
			{
				path:'/latestNews',
				component: () => import('@/views/pc/LatestNews/children/LatestNews.vue')
			},
			{
				path:'/recommendations',
				component: () => import('@/views/pc/LatestNews/children/Recommendations.vue')
			},
			{
				path:'/analysis',
				component: () => import('@/views/pc/LatestNews/children/Analysis.vue')
			},
			{
				path:'/articleDetails',
				component: () => import('@/views/pc/LatestNews/children/ArticleDetails.vue')
			},
			{
				path:'/fastNews',
				component: () => import('@/views/pc/LatestNews/children/FastNews.vue')
			},
			{
				path:'/calender',
				component: () => import('@/views/pc/LatestNews/children/Calender.vue')
			},
			{
				path:'/articleDetails',
				component: () => import('@/views/pc/LatestNews/children/ArticleDetails.vue')
			},
		]
	},
]
