<template>
    <div class="Privacy">
        <div class="content">
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/top_logo3%402x.png" alt="">
            <h2>PRIVACY NOTICE</h2>
            <div class="paragraph">
                <p class="grey">TOPICAL WEALTH INTERNATIONAL LTD</p>
                <p class="grey">29 JULY 2021</p>
                <p class="grey">1st Floor, Dekk House, Zippora Street, Providence, Mahe，Seychelles</p>
                <p class="grey">WWW.TOPICALWEALTH.COM</p>
            </div>
            <div class="paragraph">
                <p class="title">RISK WARNING: </p>
                <p>CFDS ARE A LEVERAGED PRODUCT AND CAN RESULT IN THE LOSS OF YOUR ENTIRE CAPITAL. TRADING CFDS MAY NOT BE SUITABLE FOR EVERYONE. PLEASE CONSIDER OUR PRODUCT SHEET, RISK DISCLOSURE STATEMENT AND CLIENT AGREEMENT BEFORE USING OUR SERVICES AND ENSURE THAT YOU UNDERSTAND THE RISKS INVOLVED. YOU DO NOT OWN OR HAVE ANY INTEREST IN THE UNDERLYING ASSETS.</p>
                <p>SIB LICENCE NO. 1612446</p>
            </div>
            <hr />
            <div class="paragraph">
                <h3>1.  What does this Privacy Notice do and does it apply to you? </h3>
                <h4><span>1.1</span>Many countries have data protection laws that protect the privacy of individuals by regulating the way in which businesses handle and process personal information. Among other things, data protection laws require businesses that handle and process personal information to be transparent about why and how they handle and process personal information.</h4>
                <h4><span>1.2</span>The purpose of this Privacy Notice is to inform you why and how Topical Wealth International Limited (the Company) handles and processes personal and sensitive information about you in connection with the Company as the client (the Client) of the Company. This Privacy Notice is in compliance with the Seychelles Data Protection Act 2003 DPA (as amended from time to time).</h4>
                <h4><span>1.3</span>Nothing in this Privacy Notice creates any new relationship between you and the Company or alters any existing relationship between you and the Company. Nothing in this Privacy Notice affects any right you have under any applicable law, including the DPA and any other data protection law that applies to you.</h4>
                <h3>2.  Who is responsible for the proper handling and processing of my personal information?</h3>
                <h4>Physical address :Topical Wealth International Limited，1st Floor, Dekk House, Zippora Street, Providence, Mahe Seychelles</h4>
                <h4>Email :support@topicalwealth.com</h4>
                <h3>3.  What sort of personal information is collected by the Company? </h3>
                <h4><span>3.1</span>The types of personal information which we collect will vary significantly depending on numerous factors, including but not limited to, the regulatory and AML Regime of Seychelles and the discretion to exercise and obtain sufficient information in order to identify our clients.</h4>
                <h4><span>3.2</span>The personal information we obtain can be grouped into the following categories:</h4>
                <h5><span>a.</span>Your contact details such as title, name, known names, physical address, postal address, email address, fax number and phone number.</h5>
                <h5><span>b.</span>Information about you which we are obliged to check for legal or regulatory reasons, such as your date of birth, country of residence, tax residence, nationality, any ownership interest in any entity or asset you hold, and other like information concerning your identity and background (which may include, where applicable, sensitive information such as any criminal record you have, and any sanction or embargo enacted against you).</h5>
                <h5><span>c.</span>Information about you which we obtain in relation to you as Client of the Company.</h5>
                <h4><span>3.3</span>We will collect your personal information only where we are legally permitted to do so, and only to the extent it is appropriate and necessary for one or more of the purposes described in Section 4.</h4>
                <h3>4.  Why does the Company collect your personal information and what are the legal justifications?</h3>
                <h4><span>4.1</span>We handle and process your personal information for one or more of the following purposes:</h4>
                <h5><span>a.</span> In relation to the Company and future offerings.</h5>
                <h5><span>b.</span> To promote our Company and future offerings.</h5>
                <h5><span>c.</span> To manage, maintain, and develop our relationship with Clients of the Company.</h5>
                <h5><span>d.</span> To facilitate the effective management and administration of our business, including in relation to matters such as business planning, budgeting, and forecasting in relation to the Company offering.  </h5>
                <h5><span>e.</span> To ensure our compliance with all relevant legal and regulatory requirements, including, without limitation, legal requirements relating to money laundering, bribery and corruption, tax evasion, sanctions / embargoes export control and international tax and compliance initiatives (such as US FATCA and/or CRS)   </h5>
                <h4>Important Note: We will use your personal information to conduct various checks to ensure that we comply with all applicable legal and regulatory requirements before we formally accept you (or your business) as a client and from time to time after you (or your business) is accepted as our client. For example, we might check if you are included in official list published by the authorities which lists persons with whom we are by law not allowed to do business, or we might check if you are a politically exposed person in respect of whom we are required to undertake enhanced due diligence.</h4>
                <h4><span>4.2</span>In handling and processing personal information for the aforementioned purposes, we rely on the following:</h4>
                <h5><span>a.</span> Under paragraph 2, Schedule 2 of the DPL where it necessitates the handling or processing of your personal information it shall be to discharge the contractual obligations we owe to you.</h5>
                <h5><span>b.</span> Under paragraph 6, Schedule 2 of the DPL where it necessitates the handling and processing of your personal information in order to meet our own requirement to operate, manage, and develop our business.</h5>
                <h5><span>c.</span> Under paragraph 3, Schedule 2 of the DPL where it necessitates the handling and processing of your personal information for the purpose of Legal and Regulatory Compliance, for the purposes of 4.1(f). </h5>
                <h5><span>d.</span> Under paragraph 1, Schedule 2 of the DPL where it necessitates the handling and processing of your personal information based exclusively on your consent. We would not ordinarily rely on consent, but occasionally, where none of the other legal justifications are available to us (e.g., if we are to handle and process your personal information for any purpose other than those that are described in paragraph 4.1 above), we may choose to rely on Consent.</h5>
                <h4>Important Note: Where we rely on your consent to handle and process your personal information, you can expect us to explain what you are being asked to agree to and you may withdraw your consent at any time.</h4>
                <h3>5.  How do we obtain your personal information?</h3>
                <h4><span>5.1</span>We endeavour to collect your personal information directly from you wherever possible. However, the context in which we handle and process your personal information can often result in us collecting your personal information indirectly from third party sources.</h4>
                <h4><span>5.2</span>Additionally, there may be circumstances where we are required to seek your personal information from independent sources (for example where we need to use your personal information to comply with legal requirement to validate your identity and background).</h4>
                <h4><span>5.3</span>Sources from which we may obtain your personal information can be described as follows:</h4>
                <h5><span>a.</span>Those who have referred you to us, such as your business contact, or another entity.</h5>
                <h5><span>b.</span>Your lawyer, accountant, tax advisor, wealth manager, and other such advisors who provide your personal information to us on your behalf.</h5>
                <h5><span>c.</span>Publicly accessible websites, registers, and databases, including official registers of companies and businesses, database of journals and news articles, and social media such as LinkedIn. Providers of background check and business risk screening services, such as operators of fraud and financial crime databases, and operators of sanctions/ embargoes databases (in some cases they can include authorities such as government departments and the police).</h5>
                <h3>6.  Do we share your personal information with others?</h3>
                <h4><span>6.1</span>We will share your information with others only if and to the extent it is appropriate and necessary to do so for one or more of the purposes outlined in Section 4.</h4>
                <h4><span>6.2</span>The extent to which we share your personal information will vary based on the following:</h4>
                <h5><span>a.</span>Those who support our business operations, for example data centre operators, IT service providers, administrative support service providers, insurers, accountants, consultants, auditors, etc.</h5>
                <h5><span>b.</span>Providers of background check and business risk screening services, such as operators of fraud and financial crime databases, and operators of sanctions/embargoes databases.</h5>
                <h5><span>c.</span>Those who provide ancillary services for the Company.</h5>
                <h5><span>d.</span>Government departments and agencies, police, regulators, courts, tribunals, and other like authorities with whom we are legally obliged to share your personal information, or with whom we decide to cooperate voluntarily (but only to the extent we are legally permitted to do so).</h5>
                <h4>Important Note: Please note that where we share your personal information with the authorities, we may, depending on the circumstances, be forbidden from advising you of the fact that your personal information was disclosed to or requested by the authorities (e.g., when doing so is illegal or might prejudice an on-going investigation).</h4>
                <h3>7.  Will my personal information be kept secure?</h3>
                <h4><span>7.1</span>We take information security very seriously and we use a broad range of tools and techniques to prevent and detect incidents that might adversely affect information we hold, such as unauthorised access or disclosure, and accidental change or loss, whether they are caused by external factors or internal factors.</h4>
                <h4><span>7.2</span>The tools and techniques we use include technical measures such as, backup and disaster recovery systems, antimalware, as well as other measures such as vetting of other third-party service providers who are entrusted with our information, awareness training for our staff, and the continuous evaluation and enhancement of our information security controls.</h4>
                <h3>8.  What happens if there is a data breach?</h3>
                <h4>In the unlikely and unfortunate event your personal information under our control becomes compromised due to a breach of our security, we will act promptly to identify the cause and take the necessary steps to contain and mitigate the consequences of the breach. Where appropriate, we will also notify you of the breach in accordance with the DPA, which requires us to notify you of the breach.</h4>
                <h3>9.  How long will my personal information be retained?</h3>
                <h4><span>9.1</span>The rules concerning prevention of money-laundering mean that if any of your personal information forms part of know your client records or international tax compliance matters (concerning US FATCA and/or CRS), we will have to retain it for as long as you are a Client of the Company, and no less than 6 years following full redemption from the Company.</h4>
                <h4><span>9.2</span>In the absence of any specific legal or regulatory record-keeping requirement which applies, we may retain your personal information for an appropriate period where we consider this to be necessary to protect ourselves from any legal claim or dispute that may arise in connection with the relevant services we have provided. Where we do so, the retention period applied to your personal information will reflect the relevant limitation periods.</h4>
                <h3>10. Will this Privacy Notice change in the future?</h3>
                <h4>This Privacy Notice may change from time to time to reflect changes in the DPA, or changes in our business model, but where such revision becomes necessary in the future, we will circulate such changes to all the Clients of the Company.</h4>
                <h3>11.  What rights do you have in respect of your personal information?</h3>
                <h4><span>11.1</span>Under the DPA, you have certain legal rights in respect of your personal information which is handled or processed by the Company. These include the following:</h4>
                <h5><span>a.</span>The right to ask us to confirm whether or not we handle and process any personal information about you.</h5>
                <h5><span>b.</span>The right to ask us to provide you with copies of your personal information we hold.</h5>
                <h5><span>c.</span>The right to ask us to correct any inaccuracy or incompleteness in your personal information we hold.</h5>
                <h5><span>d.</span>The right to ask us to stop handling and processing your personal information or to not begin the handling and processing of your personal information.</h5>
                <h5><span>e.</span>The right to ask us not to subject you to automated decision-making that uses your personal information.</h5>
                <h5><span>f.</span>The right to object to us using your personal information for direct marketing purposes.</h5>
                <h4>Important Note: The rights you have in respect of your personal information are not absolute and are subject to a range of legal conditions and exemptions. If and to the extent a relevant legal condition or exemption applies (including exemptions that preserve the legal professional privilege), we reserve the right not to comply with your request. Additionally, while the rights you have can normally be exercised free of charge, the DPL allows us to charge you in certain limited circumstances. In such cases, we reserve the right to charge you a fee for processing your request.</h4>
                <h3>12. Who do you contact about your personal information?</h3>
                <h4><span>12.1</span>If you would like to exercise any of the rights you have in respect of your personal information, or if you have any question or concern regarding the way in which we handle and process your personal information, then please reach out to Customer Services Department at support@topicalwealth.com. If you have a complaint regarding the way in which we handle and process your personal information, please contact our Compliance Officer in the first instance. You can do so by emailing your complaint to support@topicalwealth.com.</h4>
                <h4><span>12.2</span>We will endeavour to respond satisfactorily to any request, query, or complaint you may have in respect of your personal information, but if you are dissatisfied with our response and wish to make a formal complaint, or if you simply wish to learn more about your rights, you can contact the Seychelles office of the Ombudsman:</h4>
                <h4>Suite 306, Chambres AARTI, Mont Fleuri, B.P 736 VICTORIA(Seychelles)</h4>
                <h4>Tel: +248 225 147</h4>
                <h4>Fax: +248 225 737</h4>
                <h4>Email: ombuds@seychelles.net</h4>
                <h4>This Privacy Notice is in accordance with the principles enshrined in the Data Protection Principles in Schedule 1 of the DPA and its interpretation in Schedules 2-4. This Privacy Notice and our policies and procedures are in compliance with the DPA and shall be amended as new guidance is released and as the DPA is revised from time to time.</h4>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Privacy',
    components:{
    },
}
</script>

<style lang="less" scoped>
.Privacy{
    .content{
        width: 1100px;
        margin: 0 auto;
        padding-top: 50px;
        text-align: left;
        img{
            widows: 130px;
            height: 50px;
            margin-bottom: 40px;
        }
    }
    .paragraph{
        margin-bottom: 30px;
    }
    p{
        text-align: left;
        margin-bottom: 10px;
    }
    .grey{
        font-size: 14px;
        color: #666666;
    }
    h2{
        font-size: 24px;
        text-align: center;
        font-weight: 600;
    }
    h3{
        font-weight: bold;
        margin-top: 30px;
    }
    h4{
        padding-left: 30px;
        position: relative;
        margin-top: 15px;
        span{
            position: absolute;
            left: -2px;
        }
    }
    h5{
        font-size: 16px;
        padding-left: 55px;
        margin-top: 15px;
        position: relative;
        span{
            position: absolute;
            left:30px; 
        }
    }
    hr{
        height: 1px; /*no*/
        background: #EBEBEB;
        margin-bottom: 30px;
    }
}
</style>

