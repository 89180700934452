<template>
  <div>
    <div class="forget-phone-pwd public-forget-pwd public-tab-container">
      <div class="phone-con input-row">
      <div class="prefix" @click="selectOpen">
            <span class="selected" >{{code}}</span><img class="icon_choice" src="https://advantrade.oss-accelerate.aliyuncs.com/web/signUp/icon_an%402x.png" alt="">
          </div>
          <div class="value"><input type="text" v-model="phoneData.phoneVal" :placeholder="$t('signUp.text13')" /></div>
          <div class="select_region" v-show="selectFlag">
            <h3>{{$t('signUp.text14')}}<img class="close" src="https://advantrade.oss-accelerate.aliyuncs.com/investing/h5_pop_icon_del%402x.png" /></h3>
            <div class="seach">
              <img src="https://advantrade.oss-accelerate.aliyuncs.com/investing/icon_search%402x.png" alt="">
              <input type="text" @input="seachList" v-model="seachWord" id="seachVal" />
            </div>
            <ul class="seach_list sort_box">
              <li :class="code == item.code?'sort_list_active':''" class="sort_list" v-for="item in countrysListShow" @click="countrysChoose(item)" :key="item.name">{{item.name}}（{{item.code}}）</li>
            </ul>
          </div>
    </div>
    <div class="code-input input-row">
      <div class="value">
        <input class="value_code" v-model="codeData.codeVal" type="text" :placeholder="$t('signUp.text15')" />
      </div>
        <el-button class="send" link @click="sendCode">{{codeData.sendMessage}}</el-button>
    </div>
      <div class="pwd-input input-row" >
        <div class="value">
          <input :type="pwdData.isPasswordType?'text':'password'" v-model="pwdData.pwdVal"  :placeholder="$t('signUp.text37')"/>
        </div>
        <span class="password-icon">
          <img :src="pwdData.isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
          : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
          @click="pwdData.isPasswordType = !pwdData.isPasswordType;"
          class="pw-icon"
          >
        </span>
      </div>
      <div class="pwd-input input-row">
        <div class="value">
          <input :type="newPwdData.isPasswordType?'text':'password'" v-model="newPwdData.newPwdVal"  :placeholder="$t('signUp.text32')"/>
        </div>
        <span class="password-icon">
          <img :src="newPwdData.isPasswordType ? 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_yes.png'
          : 'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/icon_eye_no.png'"
          @click="newPwdData.isPasswordType = !newPwdData.isPasswordType;"
          class="pw-icon"
          >
        </span>
      </div>
    <div :class="ConfirmFlag?'':'btn-disabled'" class="confirm-btn" @click="Confirm">
      {{$t('signUp.text35')}}
    </div>
      <div class="btn-text-con">
        <button class="forget-pwd public-text-btn btn-text-right" @click="switchForget">{{$t('signUp.text38')}}</button>
      </div>
    </div>
    <div class="verify" v-show="verifyFlag">
      <div id="slide-verify2" ref="slideVer"></div>
    </div>
  </div>
</template>
<script>
import {
 reactive, ref, getCurrentInstance, inject,computed,onMounted
} from 'vue';
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { assignParams } from '@/until/common';

import Verify from '@/until/verify';
import { useI18n } from "vue-i18n";
import { loadErrorCallback } from '@/until/common';

export default {
  name: 'ForgetPwd',
  components: {
    // SpecialInput,
  },
  setup() {
    const { ctx } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const emitt = inject('emitt');
    const ostatus = computed(() => store.getters.userStatus.ostatus || 0);
    const { t } = useI18n()
    const lang = computed({ get: () => store.getters.getNowTheme.lang });

    /**
     * 监听按钮可否点击状态
     * 开始
     */
     const ConfirmFlag = computed(() => {
          return phoneData.phoneVal!='' && codeData.codeVal !='' && pwdData.pwdVal !='' && newPwdData.newPwdVal !='';
    });
    /**
     * 监听按钮可否点击状态
     * 结束
     */

      onMounted(() => {
      if(localStorage.phoneValue){
        phoneData.phoneVal = localStorage.phoneValue;
        code.value = localStorage.mobileAreaCode;
        countryId.value = localStorage.countryId;
      } 
    })
     /**
     * 获取国家编码-start
     */
    const countrysList = ref([]);//储存所有区号
    const countrysListShow = ref([]);//渲染用
    const countryId = ref('');
    const code = ref('');//电话区号
    GetCountrys();
    function GetCountrys(){
        let params = assignParams({clientType:'web'});
        apiService.GetCountrys(params).then((res) => {
            countrysList.value = res.data.list;
            countrysListShow.value = res.data.list;
             if(!localStorage.mobileAreaCode){
              code.value = res.data.defaultCountry.code;
              countryId.value = res.data.defaultCountry.countryid;
            }
        })   
    }
    /** 获取国家编码-end */

    /**
     * 选择国家区号-start
     */
    function countrysChoose(item){
      code.value = item.code;
      countryId.value = item.countryid;
      selectFlag.value = false;
    }
    /**
     * 选择国家区号-end
     */

    /**
     * 搜索国家取号-start
     */
    const seachWord = ref('')
    function seachList(e){
      let list = [];
      countrysList.value.forEach(item => {
            if(item.name.toLowerCase().indexOf(seachWord.value.toLowerCase()) != -1 || item.code.toLowerCase().indexOf(seachWord.value) != -1){
                list.push(item)
            }
        }) 
      countrysListShow.value = list
    }
    /**
     * 搜索国家取号-end
     */

    /**
     * 选择国家编码弹框消失与隐藏切换-start
     */
    const selectFlag = ref(false);//选择区号框
    function selectOpen(){
      selectFlag.value = !selectFlag.value
    }
    /**
     * 选择国家编码弹框消失与隐藏切换-end
     */
  

    function routeTo(name) {
      router.push(name);
    }

    // 重构
    // 公共
    // 手机输入框
    const phoneData = reactive({
      isPhoneActive: false, // 输入框是否处于激活状态
      phoneVal: '', // 手机输入框的值
      isPhoneErr: false, // 手机输入错误提示
    });

    // 手机输入框获取焦点的回调事件
    function phoneFocus(activeSta) {
      phoneData.isPhoneActive = activeSta;
      phoneData.isPhoneErr = false;
    }
    // 检测手机号码是否符合规范
    function checkPhonePatern(value) {
      const cpVal = value.replace(/\s+/g, '');
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(cpVal)) {
        return false;
      }
      return true;
    }


    // 密码登录
    const pwdData = reactive({
      isPwdActive: false,
      isPasswordType: false,
      isPwdErr: false,
      pwdVal: '',
      errText: '',
    });
    function pwdFocus(activeSta) {
      pwdData.isPwdActive = activeSta;
      pwdData.isPwdErr = false;
    }
    function pwdBlur(val) {
      pwdData.pwdVal = val;
    }

    // 验证码
    const codeData = reactive({
      sendMessage: t('signUp.text16'), // 验证码的文本
      isCodeActive: false, // 是否激活
      codeVal: '', // 输入框的值
      isVerticodeErr: false, // 验证码的问题
      errText: '',
    });
    function codeFocus(activeSta) {
      codeData.isCodeActive = activeSta;
      codeData.isVerticodeErr = false;
    }
    function codeBlur(val) {
      codeData.codeVal = val;
    }
    let timer = null;
    const time = ref(60);
    const isSending = ref(false);
    const verifyFlag = ref(true);
    const slideVer = ref('');
    const sendCode = async () => {
      console.log('sendCode');
      if (isSending.value) {
        return;
      }
      // 发送验证码的时候，验证表单是否都通过
     
      // 没填电话号码
      if (phoneData.phoneVal == '') {
         ElMessage({
            message:t('signUp.text20'),
            type: 'error',
          })
          return
      }

      try {
          var captcha = new TencentCaptcha('190327966', sendCodeFunc, {'userLanguage':lang.value == 'cn'?'zh':'en','needFeedBack': false});
          captcha.show(); 
      } catch (error) {
            loadErrorCallback();
      }
    };

    // 发送验证码动作的逻辑
    async function sendCodeFunc(result) {
        console.log("result",result)
      if(result.ret != 0) return;

      // 发起请求
      if (timer) {
        return;
      }
      phoneData.isPhoneErr = false;
      isSending.value = true;

      codeData.sendMessage = t('signUp.text18');
      let res;
      const params = {
        smsType: 22,
        phone: window.btoa(phoneData.phoneVal),
        mobileAreaCode:code.value,
        ticket:result.ticket,
        randstr:result.randstr
      };
        res = await apiService.GetByMobile(params);
      if (res.status === 1) {
        codeData.sendMessage = `${time.value}S`;
        timer = setInterval(() => {
          codeData.sendMessage = `${time.value}S`;
          time.value -= 1;
          if (time.value <= 0) {
            isSending.value = false;
            clearInterval(timer);
            codeData.sendMessage = t('signUp.text17');
            time.value = 60;
            timer = null;
          }
        }, 1000);
      } else {
        ElMessage({
            message: res.message,
            type: 'error',
          })
        codeData.sendMessage = t('signUp.text16');
        isSending.value = false;
      }
    }

     // 再次确认密码
    const newPwdData = reactive({
      isNewPwdActive: false,
      isNewPasswordType: false,
      newPwdVal: '',
      isNewPwdErr: false,
    });
    function newPwdFocus(activeSta) {
      newPwdData.isNewPwdActive = activeSta;
      newPwdData.isNewPwdErr = false;
    }
    function newPwdBlur(val) {
      newPwdData.newPwdVal = val;
    }


    let loginNow = false;
    function setPhonePwd() {
      if(loginNow){
        return
      }
      if (!phoneData.phoneVal) {
        return;
      }
      // 验证码是否填写
      if (!codeData.codeVal) {
        return;
      }
      // 密码框是否输入
      if (!pwdData.pwdVal) {
        return;
      }
      // 密码是否一致
      if (pwdData.pwdVal !== newPwdData.newPwdVal) {
        ElMessage({
            message: t('signUp.text39'),
            type: 'error',
          })
        return;
      }
    let mask = /^[\da-z]+$/i
    if(!mask.test(pwdData.pwdVal)){
      ElMessage({
            message: t('signUp.text37'),
            type: 'error',
          })
        return
    }

      const params = {
        account: window.btoa(phoneData.phoneVal),
        newPwd: window.btoa(newPwdData.newPwdVal),
        code: codeData.codeVal,
        mobileAreaCode:code.value
      };
      loginNow = true
      apiService.ResetPassword(params).then(async (res) => {
        if (res.status === 1) {
          localStorage.phoneValue = phoneData.phoneVal;
          localStorage.mobileAreaCode = code.value;
          localStorage.countryId = countryId.value;
          
          ElMessage({
            message: t('signUp.text40'),
            type: 'success',
          })
          store.commit('USERTOKEN', res.data.userToken);
          await store.dispatch('getUserInfo');
          await store.dispatch('getUserStatus');
          if (ostatus.value !== 3) {
                router.push({
                  name:'myAccount'
                });
              } else {
                router.push('transaction');
              }
        } else {
          ElMessage({
            message: res.message,
            type: 'error',
          })
        }
      loginNow = false
      });
    }

    function switchForget(){
      const jumpParams = {
        type: 'back',
      };
       emitt.$pub('switchForget', jumpParams);
    }

    // 发布事件
    // 点击登录
    const jumpToLogin = () => {
      const jumpParams = {
        status: true,
      };
      emitt.$pub('switchLogin', jumpParams);
    };

    function Confirm() {
        setPhonePwd();
    }

    return {
      switchForget,
      jumpToLogin,
      Confirm,
      routeTo,
      ConfirmFlag,
      phoneData,
      phoneFocus,
      codeData,
      codeFocus,
      codeBlur,
      pwdData,
      pwdFocus,
      pwdBlur,
      newPwdData,
      newPwdFocus,
      newPwdBlur,
      sendCode,
      verifyFlag,
      slideVer,
      code,
      countrysListShow,
      countryId,
      countrysList,
      countrysChoose,
      seachList,
      seachWord,
      selectFlag,
      selectOpen,
    };
  },
};
</script>
<style lang="less" scoped>
.public-tab-container {
}
.input-row {
      margin: 0 auto;
      margin-top: 24px;/*no*/
      background: white;
      display: flex;
      justify-content: left;
      margin-bottom: 16px;/*no*/
      border-radius: 4px;/*no*/
      height: 48px;/*no*/
      position: relative;
      border: 1px solid #EBEBEB;/*no*/
      width: 400px;/*no*/
      .value{
        width: 100%;
      }
      .password-icon{
        position: absolute; 
        right: 16px;/*no*/
        top: 16px;/*no*/
        cursor: pointer;
      }
       .send{
         position: absolute;
         right: 10px;/*no*/
         top: 10px;/*no*/
         font-size: 14px;/*no*/
        }
      .prefix{
        width: 85px;/*no*/
        padding-left: 10px;/*no*/
        border-right: 1px solid #EBEBEB;/*no*/
        text-align: left;
        cursor: pointer;
        position: relative;
        line-height: 48px;/*no*/
        .icon_search{
          position: relative;
          top: 8px;/*no*/
        }
        .icon_choice{
          width: 8px;/*no*/
          position: absolute;
          right: 9px;/*no*/
          top: 22px;/*no*/
        }
      }
      input{
        height: 46px;/*no*/
        line-height: 46px;/*no*/
        outline: none;
         border-radius: 4px;/*no*/
        padding-left: 10px;/*no*/
        width: 100%;
        font-size: 14px;/*no*/
      }
      .value_code{
        padding-left: 10px;
      }
      .select_region{
        position: absolute;
        left: 0;
        padding:20px 0;
        background: white;
        width: 300px;
        height: 490px;
        z-index: 9;
        top: 50px;/*no*/
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);/*no*/
        h3{
          font-size: 16px;
          text-align: center;
          color: #111E36;
          position: relative;
          img{
            display: none;
          }
        }
        .seach{
          width: 284px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 2px;
          margin: 0 auto;
          border: 1px solid #DADADA;/*no*/
          display: flex;
          justify-content: space-between;
          padding-left: 13px;
          margin-top: 19px;
          img{
            display: block;
            width: 12px;
            height: 12px;
            position: relative;
            top: 8px;
          }
          input{
            width: 243px;
            height: 28px;
            line-height: 28px;
            padding-left: 0;
          }
        }
        ul{
          width: 100%;
          height: 360px;
          margin-top: 12px;
          overflow-y: scroll;
          text-align: left;
          li{
            height: 45px;
            padding: 13px 16px;
            border-bottom: 1px solid #F8F9FB;
            cursor: pointer;
          }
          li:hover{
            background: #F7F8FA;
            color: #FB741B;
          }
          .sort_list_active{
            background: #F7F8FA;
            color: #FB741B;
          }
        }
    }
  }
.confirm-btn {
  width: 400px;/*no*/
    height: 48px;/*no*/
    margin: 0 auto;
    margin-top: 50px;/*no*/
    line-height: 48px;/*no*/
    background: #FB5B39;
    border-radius: 2px;/*no*/
    font-size: 14px;/*no*/
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    cursor: pointer;
}
.btn-con {
  padding-top: 16px;/*no*/
  .cancel-btn {
    width: 192px;/*no*/
    height: 48px;/*no*/
    margin-right: 16px;/*no*/
    background: @hover-color;
    border-radius: 2px;
    border: none;
    font-size: 14px;/*no*/
    font-family: MicrosoftYaHei;
    color: #FB5B39;
    line-height: 19px;/*no*/
    cursor: pointer;
  }
  .confirm-btn {
    width: 192px;/*no*/
    height: 48px;/*no*/
    background: #FB5B39;
    border-radius: 2px;/*no*/
    border: none;
    font-size: 14px;/*no*/
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 19px;/*no*/
    cursor: pointer;
  }
}
.btn-text-con {
    width: 400px;/*no*/
    position: relative;
    height: 20px;/*no*/
    margin: 0 auto;
    margin-top: 16px;/*no*/
    .public-text-btn {
      position: absolute;
      font-size: 14px;/*no*/
      font-family: MicrosoftYaHei;
      color: @primary-color;
      background: transparent;
      cursor: pointer;
    }
    .btn-text-right {
      right: 0;
    }
    .btn-text-left {
      left: 0;
    }
  }

.verify{
  width: 100%;
  position: fixed;
  // top: 1.8rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  #slide-verify2{
      width: 280px;
      margin: 0 auto;
      background: white;
      font-size: 14px;/*no*/
      .slider-container {
        background-color: #E7EAF3;
        .sliderMask {
          height: 38px;/*no*/
          border-width: 1px;
        }
      }
  }
}
.slider-container {
  position: relative;
  text-align: center;
  width: 280px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
  background: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
}

.sliderContainer_active {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #1991FA;
  }
}

.sliderContainer_success {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #52CCBA;
    background-color: #52CCBA !important;
  }
}

.sliderContainer_success {
  .sliderMask {
    height: 38px;
    border: 1px solid #52CCBA;
    background-color: #D2F4EF;
  }
}

.sliderContainer_success {
  .sliderIcon {
    background-position: 0 0 !important;
  }
}

.sliderContainer_fail {
  .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #f57a7a;
    background-color: #f57a7a !important;
  }
}

.sliderContainer_fail {
  .sliderMask {
    height: 38px;
    border: 1px solid #f57a7a;
    background-color: #fce1e1;
  }
}

.sliderContainer_fail {
  .sliderIcon {
    top: 14px;
    background-position: 0 -82px !important;
  }
}
.sliderContainer_active .sliderText,
.sliderContainer_success .sliderText,
.sliderContainer_fail .sliderText {
  display: none;
}

.sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991FA;
  background: #D1E9FE;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background .2s linear;
}

.slider:hover {
  background: #1991FA;
}

.slider:hover {
  .sliderIcon {
    background-position: 0 -13px;
  }
}

.sliderIcon {
  position: absolute;
  top: 15px;
  left: 13px;
  width: 14px;
  height: 12px;
  background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -26px;
  background-size: 34px 471px;
}

.refreshIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -437px;
  background-size: 34px 471px;
}
.bottom{
  padding-top: 0.12rem;
  padding-bottom: 0.22rem;
  background: linear-gradient(180deg, #FB5B39 0%, #FB5B39 100%);
  margin-top: 0.12rem;
  img{
    display: block;
    width: 2.78rem;
    margin:0 auto;
  }
}
.block {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
