<template>
    <div class="SwitchingBonus">
        <div class="banner">
            
        </div>
        <div class="wealth">
            <div class="text">
                <h4>Looking for other reliable trading platform?</h4>
                <p>
                    Advantrade is an all-in-one trading application developed under ET Fintech with a user-friendly and easy-to-use app interface. the cutting-edge application has been subscribed by more than 500,000 users worldwide and has been adopted by different brokers globally.
                </p>
                <p>
                    For our promotion period, you can enjoy additional switching bonus when you switch your trading platform to Advantrade. It is only provided for the traders in another trading platform, and you can get attractive bonus after deposited in Advantrade!
                </p>
                <div class="btn cursor" @click="routerTo('download')">
                    Download Now
                </div>
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/promotion_pic_007%402x.png" alt="">
            </div>
        </div>
        <div class="membership">
            <h2>What can you get from Advantrade ?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/promotion_icon_004%402x.png" alt="">
                    <div class="text">
                        <h3>Excellent customer service</h3>
                        <p>We understand our customers who has many uncertainty and difficulty in forex trading, our teams promise to optimize and strictly control quality of customer service to ensure the best experience for our customers.</p>
                    </div>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/promotion_icon_011%402x.png" alt="">
                    <div class="text">
                        <h3>Free 1 on 1 training</h3>
                        <p>New registered users in Advantrade can get exclusive 1 on 1 master trading consultations for free, you can discuss anything with our investment master. They are ready to provide you first-class support.</p>
                    </div>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/promotion_icon_001%402x.png" alt="">
                    <div class="text">
                        <h3>Exclusive trading incentive</h3>
                        <p>Our users can get numerous cash credit bonus or rebates via our trading promotion, including deposit bonus, referral bonus, platform switching bonus, membership. More promotion is coming, anyone can enjoy our trading incentive！</p>
                    </div>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/promotion_icon_007%402x.png" alt="">
                    <div class="text">
                        <h3>Lucky spin to win bitcoin</h3>
                        <p>In order to encourage more traders investing, we will regularly launch big event to carry the atmosphere of investment such as Lucky drawing. We also provided a community for our traders to discuss each others.</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="membership2">
            <h2>How to get switching bonus ?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_001%402x.png" alt="">
                    <p>Sign up & Verify</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_005%402x.png" alt="">
                    <p>Deposit in Advantrade</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_009%402x.png" alt="">
                    <p>Notice us in-app</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/luckSpin/promotion_icon_008%402x.png" alt="">
                    <p>Get your prize</p>
                </li>
            </ul>
            <div class="btn join cursor" @click="routerTo('download')">DOWNLOAD NOW!</div>
        </div>
        <div> 
            <Advertise></Advertise>
        </div>
    </div>
</template>
<script>
import {
} from 'vue';
import Advertise from '../components/Advertise.vue'
import { useRouter } from "vue-router"

export default {
    name:"SwitchingBonus",
    props: {
    
    },
    components:{
        Advertise
    },
    setup() {
        const router = useRouter(); 
        function routerTo(e){
            router.push(e)
       }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.SwitchingBonus{
    h2{
        color: #FB741B;
        font-size: 30px;/*no*/
    
    }
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
    }
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/switchingBonus/wb%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        height: 400px;/*no*/
        padding-top: 85px;/*no*/
        padding-left: 360px;
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 400px;
            img{
                width: 100%;
            }
        }
    }
    .membership{
        padding-top: 40px;
        padding-bottom: 54px;
        ul{
            width: 1088px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 504px;
                display: flex;
                justify-content: left;
                text-align: left;
                margin-bottom: 25px;
                img{
                    width: 88px;
                    height: 88px;
                }
                .text{
                    color: #4E4E4E;
                    margin-left: 16px;
                }
                h3{
                    font-size: 20px;
                    color: #FA6400;
                }
                p{
                    color: #858585;
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
    
    .referral_detail{
        padding-top: 90px;
        padding-bottom:77px; 
        .content{
            width: 1200px;
            margin: 0 auto;
        }
        .title{
            display: flex;
            justify-content: left;
            color: #FB741B;
            font-size: 30px;
            .hr{
                height: 42px;/*no*/
                width: 8px;/*no*/
                background: #FB741B;
                border: none;
                margin-right:37px; 
            }
        }
        p{
            text-align: left;
            margin-top: 24px;/*no*/
            color: #858585;
        }
        .content_tr{
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;
            margin-top: 24px;
            
            .item{
                width: 600px;
                border-bottom: 1px solid #F2F2F2;/*no*/
                height: 44px;/*no*/
                line-height: 44px;/*no*/
                color: #858585;
            }
        }
        .text_color{
            .item{
                color: #FB741B;
                font-weight: 500;
            }
        }
        .mar{
               margin-top: 48px;/*no*/
           }
    }
    .membership2{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        padding-top: 40px;
        padding-bottom: 50px;
        ul{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 300px;
                img{
                    width: 88px;
                    height: 88px;
                }
                p{
                    color: #4E4E4E;
                    margin-top: 16px;/*no*/
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
}
</style>
