<template>
    <div class="LatestNews">
        <div class="LatestNews_banner">
            <div class="LatestNews_left">
               <router-view></router-view>
            </div>
            <div class="LatestNews_right">
                <InformationRight />
            </div>
        </div>
    </div>
</template>
<script>
import InformationRight from 'components/pc/InformationRight.vue'

export default {
    name:'LatestNewsIndex',
    components:{
        InformationRight,
    },
     setup(){
         return {
            
         }
     }

}
</script>
<style lang="less" scoped>
.LatestNews{
    .banner{
        height: 300px; /*no*/
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/latest_news/news_banner%402x.png) no-repeat;
        background-size: 100% 100%;
    }
    .LatestNews_banner{
        display: flex;
        justify-content: space-between;
        width: 1100px;
        margin: 0 auto;
        padding-top: 30px;
        .LatestNews_left{
            width: 800px;
            border-right: 1px solid #EBEBEB;
            padding-right: 39px;
            padding-bottom: 60px;
        }
        .LatestNews_right{
           
        }
    }
}
</style>
