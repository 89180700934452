<template>
    <div class="ReferralBonus">
        <div class="banner">
            <h1>Get over $200 USD cash bonus? Yes!</h1>
            <p>If you are the traders in Advantrade, and have invited your friends to register our live account, both member can get the $100 USD bonus !</p>
            <div class="primary cursor" @click="routerTo('login')">Start now</div>
        </div>
        <div class="wealth">
            <div class="text">
                <h4>Share free USD rebates and happiness with your friends</h4>
                <p>
                    If you are the traders in Advantrade, and have invited your friends to register our live account, both member can get the $100 USD bonus!
                </p>
                <p>
                    Meanwhile your friends (or you) are the traders from other platfoem, you can get additional switching bonus to maximize your rewards!
                </p>
                <p>
                    You can trade more to further develop your capital strategies!
                </p>
            </div>
            <div class="pic">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/referralBonus/promotion_pic_004%402x.png" alt="">
            </div>
        </div>
        <div class="membership">
            <h2>How to join referral program?</h2>
            <ul>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/referralBonus/promotion_icon_001%402x.png" alt="">
                    <p>Refer your friends as referee</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/referralBonus/promotion_icon_005%402x.png" alt="">
                    <p>Referee deposited in Advantrade</p>
                </li>
                <li>
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/referralBonus/promotion_icon_006%402x.png" alt="">
                    <p>Both parties get $USD prize</p>
                </li>
            </ul>
            <div class="btn join cursor" @click="routerTo('login')">JOIN NOW!</div>
        </div>
        <div class="referral_detail">
            <div class="content">
                <div class="title">
                    <div class="hr"></div>Referral bonus in detail
                </div>
                <p>1. User (Referrer) referring a friend (Referee) to register a live account, can get USD 100 credit bonus.</p>
                <p>2. Both Referee and Referrer can get USD 100 Credit Bonus, as the Referee makes their first deposit.</p>
                <p>3. Referrers can get referral rebates once the referees fall into certain account types.</p>
                <p>For details, please refer to the table below:</p>
                <div class="content_tr">
                    <div class="text_color">Account Type of Referee(Your Friend) </div>
                    <div class="item">Standard Account </div>
                    <div class="item">Silver Account</div>
                    <div class="item">Gold Account</div>
                    <div class="item">Platinum Account</div>
                </div>
                <div class="content_tr">
                    <div class="text_color">Referrer Rebate (USD) </div>
                    <div class="item">0/ lot </div>
                    <div class="item">3/ lot</div>
                    <div class="item">4/ lot </div>
                    <div class="item">5/ lot</div>
                </div>
                <div class="title mar">
                    <div class="hr"></div>Terms & Condition
                </div>
                <p>1. Referral Rebates will be calculated according to the account types of referees.</p>
                <p>2. Referral Rebate is only valid for one referee account type (Standard, Silver, Gold, Platinum). If the referee is a gold account owner, the referrer can get $4/lot rebate.</p>
                <p>3. Referrer can also enjoy the personal rebate, while getting the referral rebate.</p>
                <p>4. HK & US CFDs are excluded from the personal rebate and referral rebate scheme.</p>
                <p>5. Real time settlements will be made proportionally for personal and referral rebates once all trades have been offset.</p>
                <p>6. Credit bonuses can be redeemed once trading volume has reached 20% of the credited bonus amounts have been met; direct withdrawal is valid for referral rebate.</p>
                <p>7. If any customer does not fulfil requirements for opening an account or conditions of any promotion/s, or abuses any activity offers, Regain Capital reserves the right to cancel the customer’s application at any time.</p>
                <p>8. AdvanTrade reserves the right to make changes to these terms and condition at any time without prior notice.</p>
            </div>
        </div>
        <div style="background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);"> 
            <Advertise></Advertise>
        </div>
    </div>
</template>
<script>
import {
} from 'vue';
import Advertise from '../components/Advertise.vue'
import { useRouter } from "vue-router"

export default {
    name:"ReferralBonus",
    props: {
    
    },
    components:{
        Advertise
    },
    setup() {
        const router = useRouter(); 
       function routerTo(e){
            router.push(e)
       }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.ReferralBonus{
    h2{
        color: #FB741B;
        font-size: 30px;/*no*/
    
    }
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
    }
    .banner{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/referralBonus/promotion_top_banner_002%402x.png) no-repeat;
        background-size: cover;
        background-position:center;
        height: 400px;/*no*/
        padding-top: 85px;/*no*/
        padding-left: 360px;
        text-align: left;
        color: white;
        .primary{
            width: 180px;/*no*/
            height: 44px;/*no*/
            line-height: 42px;/*no*/
            text-align: center;
            border-radius: 4px;/*no*/
            color: white;/*no*/
            background: #FA6400;
            margin-top: 48px;/*no*/
        }
        p{
            width: 614px;
            margin-top: 23px;
        }
    }
    .wealth{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 340px;
            img{
                width: 100%;
            }
        }
    }
    .membership{
        background: linear-gradient(180deg, #FFFCFA 0%, #FFF7F2 100%);
        padding-top: 40px;
        padding-bottom: 50px;
        ul{
            width: 900px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 50px;/*no*/
            li{
                width: 300px;
                img{
                    width: 88px;
                    height: 88px;
                }
                p{
                    color: #4E4E4E;
                    margin-top: 16px;/*no*/
                }
            }
        }
        .join{
            margin: 0 auto;
            margin-top: 47px;/*no*/
        }
    }
    .referral_detail{
        padding-top: 90px;
        padding-bottom:77px; 
        .content{
            width: 1200px;
            margin: 0 auto;
        }
        .title{
            display: flex;
            justify-content: left;
            color: #FB741B;
            font-size: 30px;
            .hr{
                height: 42px;/*no*/
                width: 8px;/*no*/
                background: #FB741B;
                border: none;
                margin-right:37px; 
            }
        }
        p{
            text-align: left;
            margin-top: 24px;/*no*/
            color: #858585;
        }
        .content_tr{
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;
            margin-top: 24px;
            .text_color{
                color: #FB741B;
                width: 224px;
                font-weight: 500;
            }
            .item{
                width: 224px;
                border-bottom: 1px solid #F2F2F2;/*no*/
                height: 44px;/*no*/
                line-height: 44px;/*no*/
            }
        }
        .mar{
               margin-top: 48px;/*no*/
           }
    }
}
</style>
