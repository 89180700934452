<template>
    <div class="Advertise">
        <div class="pic">
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/promotion/membership/promotion_pic_003%402x.png" alt="">
        </div>
        <div class="text">
            <h4>You trade more, We give you more</h4>
            <p>
                We want to motivate more traders to start developing their investment strategies from now and equip themselves to succeed in this competitive forex market early. Therefore, we will launch more and more incentive programs to make it true.
            </p>
            <p>
                You can enjoy a wonderful trading experience in Advantrade, even you are newbies, because we will share our entire trading knowledge with over 10+ experiences to you. You don’t need to worry about any uncertainty！
            </p>
            <div class="flex">
                <div class="primary cursor" @click="routerTo('login')">
                    SIGN UP NOW
                </div>
                <div class="btn cursor" @click="routerTo('whyChooseUs')" style="margin:0 0 0 40px">
                    OTHER PROMOTION
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import {
} from 'vue';
import { useRouter } from "vue-router"

export default {
    name:"Advertise",
    props: {
    
    },
    components:{
    },
    setup() {
        const router = useRouter(); 

        function routerTo(e){
            router.push(e)
        }
        return {
            routerTo
        }
    },
    
}
</script>
<style lang="less" scoped>
.Advertise{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        padding: 64px 0 48px 0;
        .text{
            width: 600px;
            text-align: left;
            h4{
                font-size: 30px;/*no*/
                color: #FB741B;
                margin-bottom: 24px;/*no*/
            }
            p{
                font-size: 16px;/*no*/
                color: #858585;
                margin-bottom: 10px;/*no*/
            }
         
        }
        .pic{
            width: 530px;
            height: 340px;
            img{
                width: 100%;
            }
        }
    .btn{
        width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        border:1px solid #FA6400;/*no*/
        color: #FA6400;/*no*/
        margin-top: 60px;
        font-size: 16px;
    }
    .primary{
          width: 180px;
        height: 44px;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;/*no*/
        color: white;/*no*/
        background: #FA6400;
        font-size: 16px;
    }
    .flex{
        display: flex;
        justify-content: left;
        margin-top: 60px;
    }
}
</style>
