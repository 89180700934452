<template>
    <div class="AboutCN">
        <div class="banner">
            <div class="banner_box w1200">
                <div class="left_box">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/top_logo%402x.png" alt="">
                    <h2>关于 AdvanTrade爱跟投</h2>
                </div>
                <div class="right_box">
                    <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_banner_logo%402x.png" alt="">
                </div>
            </div>
        </div>
        <div class="content_one">
            <div class="w1200">
                <div class="bg_title">
                    <div class="title">AdvanTrade，让投资更容易</div>
                    <div class="bg_retouch"></div>
                </div>
                <div class="content_box">
                    <div class="line_one">
                        <div class="pro">
                            AdvanTrade爱跟投是Top Wealth集团旗下的外汇交易品牌，作为知名的金融科技集团，Top Wealth成立的初衷就是希望打破MetaQuotes公司在外汇市场的垄断，为客户提供更加人性化的投资服务。2017年，我们上线了首款自主研发交易软件，随后几年里，我们不断优化产品，增加了数据分析工具、市场分析资讯、特色交易功能以及各种优惠活动，为投资者提供了更加高效、便捷的服务。
                        </div>
                        <div class="pic">
                            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_pic_1%402x.png" alt="">
                        </div>
                    </div>
                    <div class="line_one">
                        <div class="pic">
                            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_pic_2%402x.png" alt="">
                        </div>
                        <div class="pro">
                           <p>近期，AdvanTrade爱跟投再次升级，推出自主研发的跟单功能。区别于传统跟单平台，AdvanTrade设置了严格的交易员准入机制，利用大数据、人工智能等技术手段从成千上万的投资者中筛选出优秀的交易员，为客户提供跟单交易服务。</p>
                           <p>目前，AdvanTrade爱跟投已经发展成为一个以交易为核心，集成各种功能为一体的投资服务平台，受到来自全球各地不同类型用户的广泛好评。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_two">
            <div class="content_two_box w1200">
                <div class="bg_title">
                    <div class="title">发展历程</div>
                    <div class="bg_retouch"></div>
                </div>
                <p class="pro">为满足不同客户、IB 和合作伙伴的需求，AdvanTrade爱跟投团队不断推陈出新，优化原有系统，开发实用、多样化的新功能和工具。</p>
                <div class="time_line">
                    <div class="hr_line"></div>
                    <div class="year margin_center">2017</div>
                    <div class="month margin_center mt30">
                        6月
                        <div class="text_right">首个移动交易应用上线</div>
                    </div>
                    <div class="month margin_center mt20">
                        9月
                        <div class="text_right">推出IB计划</div>
                    </div>
                    <div class="year margin_center mt50">2018</div>
                    <div class="month margin_center mt30">
                        1月
                        <div class="text_left">伊斯兰账户功能发布</div>
                    </div>
                    <div class="month margin_center mt20">
                        6月
                        <div class="text_left">直播间功能上线</div>
                    </div>
                    <div class="month margin_center mt20">
                        9月
                        <div class="text_left">账户级别功能上线</div>
                    </div>
                    <div class="year margin_center mt50">2019</div>
                    <div class="month margin_center mt30">
                        1月
                        <div class="text_right">上线新交易品种 — 美股差价合约</div>
                    </div>
                    <div class="month margin_center mt30">
                        6月
                        <div class="text_right">上线新交易品种 — 港股差价合约</div>
                    </div>
                    <div class="month margin_center mt30">
                        10月
                        <div class="text_right">上线新交易品种 — 加密货币</div>
                    </div>
                    <div class="year margin_center mt50">2020</div>
                    <div class="month margin_center mt30">
                        3月
                        <div class="text_left">推出市场分析工具</div>
                    </div>
                    <div class="month margin_center mt20">
                        6月
                        <div class="text_left">上线新交易品种 — 铜、铅、锌、镍</div>
                    </div>
                    <div class="month margin_center mt20">
                        10月
                        <div class="text_left">发布多级代理功能</div>
                    </div>
                      <div class="year margin_center mt50">2021</div>
                    <div class="month margin_center mt20">
                        3月
                        <div class="text_right">推出推荐亲友计划</div>
                    </div>
                    <div class="month margin_center mt20">
                        5月
                        <div class="text_right">推出个人交易返佣功能</div>
                    </div>
                    <div class="month margin_center mt20">
                        6月
                        <div class="text_right">网上交易平台上线</div>
                    </div>
                    <div class="month margin_center mt20">
                        7月
                        <div class="text_right">“一键平仓”功能上线</div>
                    </div>
                    <div class="month margin_center mt20">
                        11月
                        <div class="text_right">“一键反手”功能上线</div>
                    </div>
                    <div class="year margin_center mt50">2022</div>
                    <div class="month margin_center mt30">
                        2月
                        <div class="text_left">跟单交易订阅功能上线</div>
                    </div>
                    <div class="month margin_center mt20">
                        3月
                        <div class="text_left">推出百万美元交易者支持计划</div>
                    </div>
                    <div class="month margin_center mt20">
                        6月
                        <div class="text_left">推出跟单交易优惠券计划</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_three">
            <div class="content_three w1200">
                <div class="bg_title">
                    <div class="title">团队支持</div>
                    <div class="bg_retouch"></div>
                </div>
                <div class="content_show">
                    <div class="box_left">
                        <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_pic_team%402x.png" alt="">
                    </div>
                    <div class="box_right">
                        <p>我们成立于2017年，现有成员100余人，其中技术人员60余人。我们的核心成员大部分来自知名金融机构、科技公司和顶级数字营销机构。在这些经验丰富的金融专业人士和技术人才支持下，AdvanTrade爱跟投不断优化系统，始终为交易者持续提供高效、便捷的交易撮合服务。</p>
                        <img class="logo" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_logo_team%402x.png" alt="">
                        <img class="comma" src="https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_pic_team2%402x.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="content_fourth">
            <div class="content_fourth_box w1200">
                <div class="title">公司愿景</div>
                <p>打造稳定、安全、可靠、智能的交易系统，为用户提供卓越的交易体验。</p>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from "vue-router"

export default {
    name:"AboutCN",
    components:{
    },
    setup(){
        const router = useRouter(); 

        function routerTo(e){
            router.push(e)
       }
        return {
        
        }
    }
}
</script>
<style lang="less" scoped>
.AboutCN{
    background: white;
    .banner{
        height: 500px;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_banner.png) no-repeat;
        background-size: cover;
        background-position: center;
        .banner_box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding-left: 30px;
            padding-right: 60px;
            .left_box{
                &>img{
                    width: 130px;
                    height: 50px;
                }
                h2{
                    font-size: 40px;
                    color: white;
                    margin-top: 34px;
                }
            }
            .right_box{
                width: 380px;
                height: 380px;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
     .w1200{
        width: 1200px;
        margin: 0 auto;
    }
    .content_one{
        background: white;
        padding: 134px 0;
        .bg_title{
            position: relative;
            .bg_retouch{
                width: 200px;
                height: 16px;
                background: linear-gradient(90deg, #FF8742 0%, #FFFFFF 100%);
                position: absolute;
                bottom: 6px;
                z-index: 1;
            }
            .title{
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                position: relative;
                z-index: 2;
            }
        }
        .content_box{
            background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_pic_map%402x.png) no-repeat;
            background-size: 1200px 600px;
            background-position-y: 191px;
            .line_one{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 37px;
                &>.pro{
                    width: 520px;
                    text-align: left;
                    p:first-child{
                        margin-bottom: 30px;
                    }
                }   
                &>.pic{
                    img{
                        display: block;
                        width: 440px;
                        height: 410px;
                    }
                }  
                &:first-child{
                    margin-top: 39px;
                } 
                &:nth-child(2){
                    margin-top: 111px;
                } 
            }
        }
    }
    .content_two{
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_bg_group.png) no-repeat;
        padding-top: 104px;
        background-size: cover;
        .content_two_box{
            width: 1200px;
        }
        .bg_title{
            position: relative;
            .bg_retouch{
                width: 200px;
                height: 16px;
                background: linear-gradient(90deg, #FF8742 0%, rgba(255,255,255,0) 100%);
                position: absolute;
                bottom: 6px;
                z-index: 1;
            }
            .title{
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                position: relative;
                z-index: 2;
                color: white;
            }
        }
        .pro{
            color: white;
            margin-top: 30px;
            text-align: left;
            font-size: 20px;
        }
        .time_line{
            margin-top: 70px;
            color: white;
            padding-bottom: 133px;
            position: relative;
            .hr_line{
                width: 2px;
                height: 100%;
                background: linear-gradient(141deg, #FFD00A 0%, #FB741B 100%);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
            }
            div{
                position: relative;
                z-index: 2;
            }
            .margin_center{
                margin: 0 auto;
            }
            .year{
                width: 80px;
                height: 32px;
                text-align: center;
                color: white;
                line-height: 32px;
                background: linear-gradient(360deg, #FF850A 0%, #FF551C 100%);
                font-size: 20px;
                border-radius: 4px;
            }
            .month{
                width: 34px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background: #FFFFFF;
                font-size: 12px;
                border-image: linear-gradient(360deg, rgba(255, 208, 10, 1), rgba(255, 85, 28, 1)) 1 1;
                color: #FB741B;
                border-radius: 50%;
                position: relative;
            }
            .text_right{
                position: absolute;
                left:54px;
                top: 0px;
                color: white;
                white-space:nowrap;
                font-size: 18px;

            }
            .text_left{
                position: absolute;
                right:54px;
                top: 0;
                color: white;
                white-space:nowrap;
                font-size: 18px;
            }
            .mt30{
                margin-top: 30px;
            }
            .mt20{
                margin-top: 20px;
            }
            .mt50{
                margin-top: 50px;
            }
        }
    }
    .content_three{
        padding-top: 114px;
        padding-bottom: 126px;
        background: #F6F7F9;
        .bg_title{
            position: relative;
            .bg_retouch{
                width: 200px;
                height: 16px;
                background: linear-gradient(90deg, #FF8742 0%, rgba(255,255,255,0) 100%);
                position: absolute;
                bottom: 6px;
                z-index: 1;
            }
            .title{
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                position: relative;
                z-index: 2;
            }
        }
        .content_show{
            display: flex;
            justify-content: left;
            margin-top: 74px;
            background: white;
            .box_left{
                width: 340px;
                height: 340px;
                img{
                    width: 100%;
                }
            }
            .box_right{
                width: 860px;
                height: 340px;
                padding: 84px 100px 0;
                text-align: left;
                position: relative;
                p{
                    font-size: 14px;
                    position: relative;
                    z-index: 2;
                }
                .logo{
                    width: 95px;
                    height: 32px;
                    margin-top: 30px;
                }
                .comma{
                    width: 100px;
                    height: 86px;
                    position: absolute;
                    right: 65px;
                    top: 57px;
                }
            }
        }
    }
    .content_fourth{
        padding: 44px 0 58px 0 ;
        background: url(https://advantrade.oss-accelerate.aliyuncs.com/web/homeCN/AboutUs/about_bg_mission%402x.png) no-repeat;
        background-size: cover;
        background-position: center;
        .content_fourth_box{
            .title{
                text-align: left;
                font-size: 40px;
                color: white;
                font-weight: 600;
            }
            p{
                text-align: left;
                color: white;
                margin-top: 16px;
            }
        }
    }
}
</style>
