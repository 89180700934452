<template>
    <div class="AssetsLiveAccount">
        <div class="head">
            {{$t('AccountAssets.text2')}}
        </div>
        <div class="line—box">
            <div class="line">
                <div class="line-item border-r">
                    <div class="item-top">
                        {{data.equity}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text3')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top">
                        {{data.balance}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text4')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top" :class="Number(data.dynamicProfit)>0?'up':'down'">
                        {{data.dynamicProfit}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text5')}}
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-top">
                        {{data.credit}}USD
                    </div>
                    <div class="item-bottom">
                       {{$t('AccountAssets.text6')}}
                    </div>
                </div>
            </div>
            <div class="hr">

            </div>
             <div class="line">
                <div class="line-item border-r">
                    <div class="item-top">
                        {{data.freeMargin}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text7')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top">
                        {{data.margin}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text8')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top">
                        {{data.marginRate}}
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text9')}}
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-top" :class="Number(data.pnl) > 0 ?'up':'down'">
                        {{data.pnl}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text10')}}
                    </div>
                </div>
            </div>
            <div class="hr">

            </div>
             <div class="line">
                <div class="line-item border-r">
                    <div class="item-top">
                        {{data.commission}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text11')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top">
                        {{data.deposit}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text12')}}
                    </div>
                </div>
                <div class="line-item border-r long_w">
                    <div class="item-top">
                        {{data.withdrawl}}USD
                    </div>
                    <div class="item-bottom">
                        {{$t('AccountAssets.text13')}}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import {
 ref,reactive,onMounted,computed,onBeforeUnmount
} from 'vue';
import apiServer from "@/api/request";
import { useStore } from 'vuex'

export default {
    name:"AssetsLiveAccount",
    setup(){
        const data = reactive({});
        const store = useStore();

        const userToken = computed({ get: () => store.getters.userToken });
        let timer;
        function getUserBalance(){
            let params = {
                usertoken:localStorage.userToken
            }
            apiServer.getUserBalance(params).then(res => {
                console.log("getUserBalance",res.data)
                Object.assign(data,res.data)
            })
        }

        onMounted(() => {
            if(userToken.value){
                getUserBalance()
                timer = setInterval(() => {
                    getUserBalance()
                },5000);
            }
        })

        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
        })
        return {
            data
        }
    }
}
</script>
<style lang="less" scoped>
.AssetsLiveAccount{
    padding: 0 30px;
    .head{
        height: 40px;
        line-height: 40px;
        background: @bg-color;
        font-size: 14px;
        font-weight: 500;
    }
    .line—box{
        padding-top: 14px;
    }
    .line{
        overflow: hidden;
        text-align: center;
        margin-top: 26px;
        .line-item{
            float: left;
            width: 222px;
            .item-top{
                font-size: 18px;
                font-weight: 500;
            }
            .item-bottom{
                font-size: 14px;
                color: @text-color-secondary;
            }
        }
        .long_w{
            width: 240px;
        }
        .border-r{
            border-right: 1px solid @border-color-base; /*no*/
        }
    }
    .hr{
        height: 1px; /*no*/
        background: @border-color-base;
        margin-top: 26px;
    }
}
</style>