<template>
  <div class="ChangeTradingPassword">
    <el-dialog
      :title="$t('components.text1')"
      v-model="ChangeTradingPasswordModelFlag"
      width="500px"
      custom-class="dialog-box"
      :close-on-click-modal="false"
      destroy-on-close
      @closed="beforeClose"
    >
      <PhoneInputBox
        :blurFunc="phoneBlur"
        :value="phoneData.phone"
        :code="phoneData.mobileAreaCode"
      />
      <CodeInputBox
        :blurFunc="codeBlur"
        :mobileAreaCode="phoneData.mobileAreaCode"
        :phone="phoneData.phone"
        :value="phoneData.code"
        :smsType="phoneData.smsType"
        :placeholder="$t('signUp.text15')"
      />
      <PasswordInputBox
        :blurFunc="pwdBlur"
        :value="phoneData.pwd"
        :placeholder="$t('components.text2')"
        :maxlength="6"
        valueType="number"
      />
      <PasswordInputBox
        :blurFunc="pwdBlur2"
        :value="phoneData.pwd2"
        :placeholder="$t('signUp.text32')"
        :maxlength="6"
        :errorFlag="errorFlag2"
        :errText="passwordErrText"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">{{ $t("trade.text72") }}</el-button>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="!submitFlag"
            @click="Confirm"
            >{{ $t("trade.text73") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, reactive, computed, inject, onBeforeUnmount } from "vue";
import apiService from "@/api/request";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "ChangeTradingPassword",
  setup() {
    const emitt = inject("emitt");
    const store = useStore();
    const { t } = useI18n();

    const ChangeTradingPasswordModelFlag = ref(false);
    const loading = ref(false);
    const errorFlag = ref(false); //密码错误提示
    const errorFlag2 = ref(false); //密码错误提示
    const passwordErrText = ref("");

    function cancel() {
      errorFlag.value = false;
      errorFlag2.value = false;
      ChangeTradingPasswordModelFlag.value = false;
    }
    /**
     * 打开弹框-开始
     */
    emitt.$sub("openChangeTradingPassword", (val) => {
      phoneData.phone = userInfo.value.phone;
      phoneData.mobileAreaCode = userInfo.value.mobileAreaCode;
      ChangeTradingPasswordModelFlag.value = true;
    });
    onBeforeUnmount(() => {
      emitt.$unsub("openChangeTradingPassword");
    });
    /**
     * 打开弹框-开始
     */

    /**
     * 电话验证参数-开始
     */
    const phoneData = reactive({
      phone: "", //手机号
      code: "", //验证码
      smsType: "20", //验证类型
      mobileAreaCode: "", //国际区号
      pwd: "",
      pwd2: "",
    });
    /**
     * 电话验证参数-结束
     */

    const userInfo = computed({
      get: () => {
        return store.state.user.userInfo;
      },
    });
    /**
     * 组件赋值操作-开始
     */
    function codeBlur(val) {
      phoneData.code = val;
    }
    function phoneBlur(val) {
      phoneData.phone = val;
    }
    function pwdBlur(val) {
      errorFlag2.value = false;
      phoneData.pwd = val;
    }
    function pwdBlur2(val) {
      errorFlag2.value = false;
      phoneData.pwd2 = val;
    }

    /**
     * 组件赋值操作-结束
     */

    /**
     * 手机验证按钮提交条件-开始
     */
    const submitFlag = computed(() => {
      return (
        phoneData.phone != "" &&
        phoneData.pwd != "" &&
        phoneData.pwd2 != "" &&
        phoneData.code != ""
      );
    });
    /**
     * 手机验证按钮提交条件-结束
     */

    /**
     * 手机验证提交-开始
     */

    function Confirm() {
      if (phoneData.pwd !== phoneData.pwd2) {
        passwordErrText.value = t("signUp.text33");
        errorFlag2.value = true;
        return;
      }
      if (Number(phoneData.pwd) + "" !== NaN + "") {
      } else {
        passwordErrText.value = t("components.text3");
        errorFlag2.value = true;
        return;
      }
      //6位密码不能相同
      if (/(\w)*(\w)*(\w)*(\w)*(\w)\5{5}(\w)*/g.test(phoneData.pwd)) {
        passwordErrText.value = t("components.text4");
        errorFlag2.value = true;
        return;
      }
      if (phoneData.pwd.length != 6) {
        passwordErrText.value = t("components.text5");
        errorFlag2.value = true;
        return;
      }

      let arr = phoneData.pwd.split("");
      let index1 = arr[0];
      let index2 = arr[1];
      let index3 = arr[2];
      let index4 = arr[3];
      let index5 = arr[4];
      let index6 = arr[5];
      if (
        index1 - index2 == 1 &&
        index2 - index3 == 1 &&
        index3 - index4 == 1 &&
        index4 - index5 == 1 &&
        index5 - index6 == 1
      ) {
        passwordErrText.value = t("components.text6");
        errorFlag2.value = true;
        return;
      }
      if (
        index6 - index5 == 1 &&
        index5 - index4 == 1 &&
        index4 - index3 == 1 &&
        index3 - index2 == 1 &&
        index2 - index1 == 1
      ) {
        passwordErrText.value = t("components.text6");
        errorFlag2.value = true;
        return;
      }

      if (!submitFlag.value) {
        return;
      }

      loading.value = true;
      const params = {
        smsType: phoneData.smsType,
        code: phoneData.code,
        account: window.btoa(phoneData.phone),
        mobileAreaCode: phoneData.mobileAreaCode,
      };
      apiService.CheckVerifyCode(params).then((res) => {
        if (res.status == 1) {
          SetPassword();
        } else {
          ElMessage({
            message: res.message,
            type: "error",
            appendTo: ".business",
          });
        }
        loading.value = false;
      });
    }
    /**
     * 手机验证提交-结束
     */

    /**
     * 设置密码-开始
     */
    function SetPassword() {
      let params = {
        tradePwd: phoneData.pwd,
      };
      console.log(params);
      apiService.TradePasswordSet(params).then((res) => {
        if (res.status == 1) {
          ElMessage({
            message: t("components.text7"),
            type: "success",
            appendTo: ".business",
          });
          ChangeTradingPasswordModelFlag.value = false;
          store.dispatch("getUserStatus");
        } else {
          ElMessage({
            message: res.message,
            type: "error",
            appendTo: ".business",
          });
        }
      });
    }
    /**
     * 设置密码-开始
     */

    /**
     * 关闭弹框-开始
     */
    function beforeClose() {
      phoneData.phone = "";
      phoneData.pwd = "";
      phoneData.pwd2 = "";
      phoneData.code = "";
      errorFlag2.value = false;
      errorFlag.value = false;
    }
    /**
     * 关闭弹框-结束
     */
    return {
      ChangeTradingPasswordModelFlag,
      phoneData,
      submitFlag,
      loading,
      Confirm,
      codeBlur,
      phoneBlur,
      pwdBlur,
      pwdBlur2,
      errorFlag,
      passwordErrText,
      errorFlag2,
      cancel,
      beforeClose,
    };
  },
};
</script>
<style lang="less" scoped>
.ChangeTradingPassword {
  .input-row {
    margin: 0 auto;
    background: white;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    border-radius: 4px;
    height: 40px; /*no*/
    position: relative;
    border: 1px solid #ebebeb; /*no*/
    .value {
      padding-top: 10px; /*no*/
      width: 100%; /*no*/
    }
    .password-icon {
      position: absolute;
      right: 16px; /*no*/
      top: 10px; /*no*/
      cursor: pointer;
    }
    .send {
      position: absolute;
      right: 10px; /*no*/
      top: 4px; /*no*/
    }
    .prefix {
      width: 80px; /*no*/
      padding-left: 10px; /*no*/
      border-right: 1px solid #ebebeb; /*no*/
      text-align: left;
      cursor: pointer;
      position: relative;
      line-height: 40px; /*no*/
      .icon_search {
        position: relative;
        top: 8px; /*no*/
      }
      .icon_choice {
        width: 8px; /*no*/
        position: absolute;
        right: 9px; /*no*/
        top: 18px; /*no*/
      }
    }
    input {
      height: 20px; /*no*/
      outline: none;
      padding-left: 16px; /*no*/
      width: 100%;
    }
    .value_code {
      padding-left: 10px; /*no*/
    }
    .select_region {
      position: absolute;
      left: 0;
      padding: 20px 0; /*no*/
      background: white;
      width: 300px; /*no*/
      height: 490px; /*no*/
      z-index: 9;
      top: 50px; /*no*/
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      h3 {
        font-size: 16px;
        text-align: center;
        color: #111e36;
        position: relative;
        img {
          display: none;
        }
      }
      .seach {
        width: 284px; /*no*/
        height: 32px; /*no*/
        background: #ffffff;
        border-radius: 2px;
        margin: 0 auto;
        border: 1px solid #dadada; /*no*/
        display: flex;
        justify-content: space-between;
        padding-left: 13px; /*no*/
        margin-top: 19px; /*no*/
        img {
          display: block;
          width: 12px; /*no*/
          height: 12px; /*no*/
          position: relative;
          top: 8px; /*no*/
        }
        input {
          width: 243px; /*no*/
          height: 28px; /*no*/
          line-height: 28px; /*no*/
          padding-left: 0;
        }
      }
      ul {
        width: 100%;
        height: 360px; /*no*/
        margin-top: 12px; /*no*/
        overflow-y: scroll;
        text-align: left;
        li {
          height: 45px; /*no*/
          padding: 13px 16px; /*no*/
          border-bottom: 1px solid #f8f9fb; /*no*/
          cursor: pointer;
        }
        li:hover {
          background: #f7f8fa;
          color: #fb741b;
        }
        .sort_list_active {
          background: #f7f8fa;
          color: #fb741b;
        }
      }
    }
  }
}
</style>
