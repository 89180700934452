<template>
    <div class="AddBankCard">
        <el-dialog
            :title="$t('myAccount.text110')"
            v-model="AddBankCardModelFlag"
            width='500px'
            custom-class="dialog-box"
            :close-on-click-modal="false"
            destroy-on-close
            @closed="beforeClose"
        >
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                       {{$t('myAccount.text111')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        {{name}}
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                       {{$t('myAccount.text112')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        {{currentType}}
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                       {{$t('myAccount.text113')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                      <el-select v-model="bankName" allow-create  class="m-2" :placeholder="placeholder" :no-data-text="$t('trade.text66')">
                        <el-option
                        v-for="item in bankNameList"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                        />
                    </el-select>
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                       {{$t('myAccount.text114')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input  @input="specification" v-model="bankAccountNumber" :placeholder="$t('myAccount.text117')" maxlength='30'/>
                    </div>
                </div>
            </div>
            <div class="box-inline">
                <div class="dialog-left">
                    <div class="label">
                      {{$t('myAccount.text115')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input v-model="bankCode" :placeholder="$t('myAccount.text117')" />
                    </div>
                </div>
            </div>
            <div class="box-inline textarea">
                <div class="dialog-left">
                    <div class="label">
                       {{$t('myAccount.text116')}}
                    </div>
                </div>
                <div class="dialog-right">
                    <div class="value">
                        <el-input type="textarea" resize="none" v-model="bankAddress"  :placeholder="$t('myAccount.text117')" />
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="AddBankCardModelFlag = false">{{$t('trade.text72')}}</el-button>
                <el-button type="primary" :loading='loading' :disabled="!submitFlag" @click="Save"
                    >{{$t('myAccount.text118')}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  inject,
  toRefs,
  onBeforeUnmount,
  nextTick
} from 'vue';
import { useStore } from 'vuex';
import apiService from '@/api/request';
import { ElMessage } from 'element-plus'
import { useI18n } from "vue-i18n";

export default {
    name:'AddBankCard',
    props:{
        currentType: { 
            type: String,
            default: '',
        },
        payChannelId: { 
            default: '',
        }
    },
    setup(props,ctx){
        const emitt = inject('emitt');
        const AddBankCardModelFlag = ref(false);
        const { t } = useI18n()
        const store = useStore();
        const loading = ref(false);
        const placeholder = ref(t('myAccount.text119'));
        const bankAccountNumber = ref('');
        const bankCode = ref('');
        const bankAddress = ref('')

        /**
         * 打开弹框-开始
         */ 
          emitt.$sub('openAddBankCardAvailable', (val) => {
            currencyChangeFunc();
            AddBankCardModelFlag.value = true;
          });
          onBeforeUnmount(() => {
            emitt.$unsub("openAddBankCardAvailable");
          });
        /**
         * 打开弹框-开始
         */ 
        
        /**
         * 获取展示信息-开始
         */
            // 实名认证需要的姓名
            const name = computed({
                get: () => store.state.user.userInfo.name,
            });
          
        /**
         * 获取展示信息-开始
         */

        /**
         * 按钮提交条件-开始
         */
            const submitFlag = computed(() => {
                return  bankName.value != '' &&
                        bankAccountNumber.value != '' &&
                        bankCode.value != '' &&
                        bankAddress.value != ''
            })
        /**
         * 按钮提交条件-结束
         */

        /**
         * 添加银行卡-开始
         */
            function Save(){
                let params = {
                    banktypename:bankName.value,
                    bankaccount:bankAccountNumber.value,
                    swiftcode:bankCode.value,
                    bankaddress:bankAddress.value,
                    currency:props.currentType,
                    firstname:name.value
                }
                loading.value = true;
                apiService.AddBankWire(params).then(res => {
                    loading.value = false;
                    if(res.status == 1){
                        AddBankCardModelFlag.value = false;
                        store.dispatch('getWalletBank');
                        ElMessage({
                            message: t('myAccount.text120'),
                            type: 'success',
                        });
                        console.log("AddBankWire",res.data)

                         ctx.emit('addBankCardSuccsess',{
                            bankTypeName:bankName.value,
                            bankAccount:bankAccountNumber.value,
                            swiftCode:bankCode.value,
                            bankAddress:bankAddress.value,
                            walletbankid:res.data.walletbankid,
                            userName:name.value
                        })
                    }else{
                        ElMessage({
                            message: res.message,
                            type: 'error',
                        });
                    }
                })
            }
        /**
         * 添加银行卡-结束
         */
        

        /**
         * 选择币种获取银行名称列表-开始
         */
            const bankName = ref('');
            const bankNameList = ref([])
            
            function currencyChangeFunc(e){
                let params = {
                    currency:props.currentType,
                    payChannelId:props.payChannelId
                }
                apiService.OnlineBankInfoForApp(params).then(res => {
                    if(res.status == 1){
                        bankNameList.value = res.data.list;
                        placeholder.value = t('myAccount.text121')
                    }else{
                        bankNameList.value = [];
                    }
                })
            }
        /**
         * 选择币种获取银行名称列表-结束
         */

         /**
         * 关闭弹框-开始
         */
            function beforeClose(){
                bankName.value = '';
                bankAccountNumber.value = '';
                bankCode.value = '';
                bankAddress.value = '';
            }
        /**
         * 关闭弹框-结束
         */


        //规范银行卡号
        function specification(v){
            // 输入的数据进行初始化，将非数字的替换为空
            let val = v;
            let isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
             while(!isMatch && val != ''){
                val = val.substring(0,val.length-1)
                isMatch = /^(([0-9]*)|(([0]\.\d{0,2}|[1-9][0-9]*\.\d{0,2})))$/g.test(val);
               }
             nextTick(() => {
                bankAccountNumber.value = val;
            });
        }
        return {
            AddBankCardModelFlag,
            name,
            loading,
            submitFlag,
            Save,
            bankNameList,
            bankName,
            placeholder,
            bankAccountNumber,
            bankCode,
            bankAddress,
            beforeClose,
            specification
        }
    }
}
</script>
<style lang="less" scoped>
.AddBankCard{
    .box-inline{
        font-size: 14px;/*no*/
        display: flex;
        justify-content: space-between;
        height: 48px;/*no*/
        line-height: 48px;/*no*/
        .dialog-left{
            width: 196px;/*no*/
        }
        .dialog-right{
            width: 240px;/*no*/
        }
        .el-select{
            width: 100%;
        }
        .el-checkbox{
            height: 48px;/*no*/
        }
    }
    .textarea{
        height: auto;
        .value{
            margin-top: 14px;/*no*/
        }
    }
}
</style>
