<template>
    <div class="BankWireTransfer">
        <div class="step">
            <div class="step_box">
                <div class="step_number">1</div>
                <div class="step_name">{{$t('deposit.text3')}}</div>
            </div>
            <div class="step_box">
                <div class="step_number">2</div>
                <div class="step_name">{{$t('deposit.text4')}}</div>
            </div>
            <div class="step_box">
                <div class="step_number">3</div>
                <div class="step_name">{{$t('deposit.text5')}}</div>
            </div>
            <div class="hr"></div>
        </div>
        <div class="bank_information">
            <div class="title_div">
                <div class="title"><span>*</span>{{$t('deposit.text6')}}</div>
                <div class="reveal cursor" @click="Reveal">{{$t('deposit.text7')}}</div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text8')}}:</div>
                <div class="information_right">{{receiveMessage.accountName}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text9')}}:</div>
                <div class="information_right">{{receiveMessage.bankCountry}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
        </div>
        <div class="fill_information">
            <div class="title_div">
                <div class="title"><span>*</span>{{$t('deposit.text10')}}</div>
            </div>
            <div class="input_line">
                <div class="information_left">{{$t('deposit.text11')}}</div>
                <div class="information_right">
                    <el-input
                        v-model="bank"
                        size="large"
                        :placeholder="$t('myAccount.text117')"
                    />
                </div>
            </div>
            <div class="input_line">
                <div class="information_left">{{$t('deposit.text12')}}($)</div>
                <div class="information_right">
                    <el-input
                        v-model="amount"
                        size="large"
                        :placeholder="$t('myAccount.text117')"
                        @input="amountInput"
                    />
                </div>
            </div>
            <div class="input_line_upload">
                <div class="upload_left">{{$t('deposit.text13')}}</div>
                <div class="upload_right">
                    <div class="holding upload_box">
                        <div class="example-holding" @click="chooseDocumentation">
                            <input type="file" name="documentation" accept="image/*" capture="camera" class="documentation-holding" ref="documentation">
                            <img v-if="documentationFlag" class="icon-photo" src="" alt="">
                        </div>
                        <img class="delete" v-if="!documentationFlag" src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/opinion_icon_photo_del%402x.png" @click="deleteFunc()" alt="">
                    </div>
                    <div class="example">
                       {{$t('deposit.text14')}}
                    </div>
                </div>
            </div>
        </div>
        <el-button :disabled="!disabled" :loading="btnLoading" class="confirm" type="primary" size="large" @click="Confirm">{{$t('trade.text73')}}</el-button>
        <p class="arrayTips" v-html="arrayTips"></p>
         <el-dialog
            v-model="dialogVisible"
            :title="$t('deposit.text15')"
            width="640px"
        >
        <div class="bank_information_dialog" ref="receiveInfo">
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text16')}}:</div>
                <div class="information_right">{{receiveMessage.accountName}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text17')}}:</div>
                <div class="information_right">{{receiveMessage.bankCountry}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text18')}}:</div>
                <div class="information_right">{{receiveMessage.accountAddress}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text19')}}:</div>
                <div class="information_right">{{receiveMessage.bankName}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text20')}}:</div>
                <div class="information_right">{{receiveMessage.accountNumber}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text21')}}:</div>
                <div class="information_right">{{receiveMessage.bankAddress}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
            <div class="information_line">
                <div class="information_left">{{$t('deposit.text22')}}:</div>
                <div class="information_right">{{receiveMessage.bankSwift}}</div>
                <div class="information_copy"><img @click="copyWithLi" src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_copy%402x.png" alt=""></div>
            </div>
        </div>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('trade.text72')}}</el-button>
                <el-button @click="CopyAll" type="primary">{{$t('deposit.text23')}}</el-button
                >
            </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
 ref,computed,toRefs,nextTick
} from 'vue';
import apiService from '@/api/request';
import { useStore } from 'vuex';
import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'
import $ from 'jquery';
import compressImg from '@/until/compressImage';
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name:'BankWireTransfer',
    props: {
        payType: {
            type: Number,
        },
    },
    setup(props){
        const route = useRoute(); 
        const { t } = useI18n()
        const documentationFlag = ref(true);
        const { payType } = toRefs(props);
        const store = useStore();
        const amount = ref('');
        const bank = ref('');
        const documentation = ref('')
        const btnLoading = ref(false);
        const arrayTips = ref('');
        const payId = ref('');
        const minDepositLimit = ref('');//最小入金
        const maxDepositLimit = ref('');//最大入金
        const receiveMessage = ref([]);
        const dialogVisible = ref(false);
        let payUrl = ''//入金地址

        let loading;
        
        openFullScreen();
        apiService.PayInChannel({
            userToken: store.getters.userToken,
            payType: Number(payType.value),
            currencyCategoryId: Number(route.query.id),
        }).then(async (res) => {
            if (res.status === 1) {
                console.log(res.data.list[0].payId);
                payId.value = res.data.list[0].payId;
                getPayInChannelPage(res.data.list[0].payId);
                getInfo(res.data.list[0].payId);
            }
            loading.close();
        });

        // 获取每个通道的详情数据
        async function getPayInChannelPage(payId) {
            const res = await apiService.PayInChannelPage({
                userToken: store.getters.userToken,
                payId,
            });
            maxDepositLimit.value = res.data.maxDepositLimit;
            minDepositLimit.value = res.data.minDepositLimit;
            
            arrayTips.value = res.data.arrayTips[0].tips;
            payUrl = res.data.payUrl;
            return res;
        }
        
        //打开loding
        function openFullScreen(){
            loading = ElLoading.service({
                lock: true,
                text: t('fundHistory.text36'),
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }
         /**
         * 按钮提交条件-开始
         */
            const disabled = computed(() => {
                return  bank.value != '' &&
                        amount.value != '' &&
                        documentation.value != ''
            })
        /**
         * 按钮提交条件-结束
         */
        function chooseDocumentation(){
            $('.documentation-holding').click()
            var eleFile = document.querySelector('.documentation-holding');
            var preview = document.querySelector('.example-holding');
            eleFile.addEventListener('change', function() {
                openFullScreen()
                var file = this.files[0];   
                if(!file) return;    
                // 确认选择的文件是图片                
                if(file.type.indexOf("image") == 0) {
                    compressImg(file,400).then(res=>{
                        preview.style.backgroundImage = 'url(' + res.compressBase64 + ')';
                        documentationFlag.value = false
                        documentation.value = res.compressBase64
                        loading.close();
                    }).catch(res=>{
                        preview.style.backgroundImage = 'url(' + res.compressBase64 + ')';
                        documentationFlag.value = false
                        documentation.value = res.compressBase64
                        loading.close();
                    })
                }
            });
        }

        //格式化入金金额
        function amountInput(v){
            // 输入的数据进行初始化，将非数字的替换为空
            const  value = v
            if(value == ''){
                    amount.value = ""
                    return
                }
            let reg=new RegExp(/\d+(\.\d{0,2})?/,'g')

            let val = value.match(reg)?value.match(reg)[0]:""
            
            if (maxDepositLimit.value != '' &&  Number(val) > Number(maxDepositLimit.value)) {
                val = maxDepositLimit.value;
            }
                // 重新赋值
                nextTick(() => {
                    amount.value = val;
                });
        }

        /**
         * 清除选择的照片-开始
         */
            function deleteFunc(){
                document.querySelector('.documentation-holding').value = '';
                $('.example-holding').css('backgroundImage','url(https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_Verification_upload%402x.png)')
                documentationFlag.value = true;
                documentation.value = '';
            }
        /**
         * 清除选择的照片-开始
         */

         // 获取电汇入金信息
        function getInfo() {
            apiService.TelegraphicTransferInfo({ payId: payId.value }).then((res) => {
                if (res.status === 1) {
                    res.data.list.forEach((element) => {
                        receiveMessage.value[element.field] = element.fieldValue;
                    });
                    console.log("receiveMessage",receiveMessage.value)
                }
            });
        }

        function Reveal(){
            dialogVisible.value = true;
        }

        // 复制信息的函数
        function copyWithInfo(text) {
            const textCP = text.replace(/复制/g, '\r\n');
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', textCP);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
                // 成功弹窗提醒
                ElMessage({
                message: t('deposit.text24'),
                type: 'success',
                })
            }
            document.body.removeChild(input);
        }

        // 复制单条
        function copyWithLi(ev) {
            console.log('ev.target:', ev.target.parentNode.parentNode.innerText);
            copyWithInfo(ev.target.parentNode.parentNode.innerText);
        }

        //全部复制
        const receiveInfo = ref(null);
        function CopyAll(){
            copyWithInfo(receiveInfo.value.innerText);
            dialogVisible.value = false;
        }
        function Confirm(){
            
            if(Number(amount.value) < Number(minDepositLimit.value)){
                ElMessage.error(`${t('deposit.text25')}${minDepositLimit.value}USD`)
                return;
            }
             const params = {
                payId: payId.value,
                payAmount: amount.value,
                tTBankName: bank.value,
                formFile: documentation.value,
            };
            btnLoading.value = true;
            apiService.filedata(params).then((res) => {
                btnLoading.value = false;
                if (res.status == 1) {
                    ElMessageBox.alert(t('deposit.text26'),{confirmButtonText: t('withdraw.text32')})
                    .then(() => {
                    })
                    amount.value = ''; 
                    bank.value = ''; 
                    deleteFunc();
                }else{
                    ElMessage({
                        message: res.message,
                        type: 'error',
                    });
                }
            });
        }
        return {
            documentationFlag,
            chooseDocumentation,
            amount,
            deleteFunc,
            btnLoading,
            disabled,
            Confirm,
            bank,
            arrayTips,
            payId,
            receiveMessage,
            dialogVisible,
            copyWithLi,
            CopyAll,
            receiveInfo,
            Reveal,
            amountInput
        }
    }
}
</script>
<style lang="less" scoped>
.BankWireTransfer{
    padding: 30px;
    .step{
        padding: 0 27px;
        display: flex;
        justify-content: space-between;
        .step_box{
            position: relative;
            z-index: 2;
            .step_number{
                width: 30px;
                height: 30px;
                background: @primary-color;
                line-height: 30px;
                border-radius: 50%;
                color: @white;
                margin: 0 auto;
            }
            .step_name{
                color: @text-color-secondary;
                font-size: 14px;
                margin-top: 10px;
            }
        }
        .hr{
            width: 760px;
            height: 1px;
            background: @primary-color;
            position: absolute;
            left: 124px;
            top: 44px;
            z-index: 1;
        }
    }
    .bank_information,.fill_information,.bank_information_dialog{
        text-align: left;
        .title_div{
            background: @bg-color;
            padding: 12px 16px;
            margin-top: 24px;
            overflow: hidden;
            padding-right: 196px;
        }
        .title{
            font-size: 18px;
            font-weight: 500;
            float: left;
            span{
                color: red;
            }
        }
        .reveal{
            float: right;
            color: @primary-color;
            border: 1px solid @primary-color;
            font-size: 14px;
            width: 80px;
            text-align: center;
            height: 28px;
            line-height:26px;
            border-radius: 40px;
        }
    }
    
    .information_line{
        display: flex;
        justify-content: left;
        height: 47px;
        align-items: center;
        font-size: 14px;
        border-bottom:1px solid @border-color-base;
        padding: 0 16px;
        .information_left{
            width: 212px;
        }
        .information_right{
            width: 279px;
        }
        .information_copy{
            img{
                display: block;
                width: 16px;
                height: 16px;
                cursor: pointer;
            }
        }
    }
    .bank_information_dialog{
        .information_left{
            width: 180px;
        }
        .information_right{
            width: 367px;
            padding-right: 20px;
        }
        .information_line{
            border: none;
            min-height: 36px;
        }
    }
    .fill_information{
        .title_div{
            margin-top: 0;
        }
        .input_line{
            height: 40px;
            line-height: 40px;
            margin-top: 20px;
            display: flex;
            justify-content: left;
        }
        .information_left{
            width: 212px;
            font-size: 14px;
        }
        .information_right{
            width: 500px;
           
        }
        .input_line_upload{
            display: flex;
            justify-content: left;
            margin-top: 24px;
        }
        .upload_left{
            width: 212px;
            font-size: 14px;
        }
        .upload_right{
            font-size: 14px;
            text-align: center;
            display: flex;
            justify-content: left;
            .upload_box{
                position: relative;
                .delete{
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    cursor: pointer;
                }
            }
            .holding{
                margin-right: 16px;
                cursor: pointer;
            }
            .example-holding{
                width:120px;
                height: 120px;
                background: url('https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_Verification_upload%402x.png') no-repeat;
                background-size: contain;
                background-position: center;
            }
            
            .documentation-holding{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
            .example{
                font-size: 12px;
                color: @red;
                line-height: 1rem;
            }
        }
    }
    .confirm{
        width:100%;
        margin-top: 40px;
    }
    .arrayTips{
        margin-top: 28px;
        text-align: left;
    }
}
</style>
