<template>
    <div class="AccountInformation">
        <div class="navigation">
            <ul>
                <router-link to="/accountInformation/myAccount"><li :class="this.$route.path.indexOf('/accountInformation') != -1?'active_nav':''"><i class="iconfont icon-lefticon_customer_white"></i>{{$t('myAccount.text1')}}</li></router-link>
                <router-link to="/accountAssets"><li :class="this.$route.path.indexOf('/accountAssets') != -1?'active_nav':''"><i class="iconfont icon-lefticon_Deposit__gray"></i>{{$t('myAccount.text2')}}</li></router-link>
                <!-- <router-link to="/historicalOrders"><li :class="this.$route.path.indexOf('/historicalOrders') != -1?'active_nav':''"><i class="iconfont icon-lefticon_orders_gray"></i>{{$t('myAccount.text3')}}</li></router-link> -->
                <router-link to="/depositCurrency"><li :class="this.$route.path.indexOf('/deposit') != -1?'active_nav':''"><i class="iconfont icon-lefticon_Deposit__gray"></i>{{$t('myAccount.text4')}}</li></router-link>
                <router-link to="/withdrawalCurrency"><li :class="this.$route.path.indexOf('/withdrawal') != -1?'active_nav':''"><i class="iconfont icon-lefticon_withdrawal_gray"></i>{{$t('myAccount.text5')}}</li></router-link>
                <router-link to="/fundsDetails"><li :class="this.$route.path.indexOf('/fundsDetails') != -1?'active_nav':''"><i class="iconfont icon-lefticon_Deposit__gray"></i>{{$t('myAccount.text6')}}</li></router-link>
                <router-link to="/fundHistory"><li :class="this.$route.path.indexOf('/fundHistory') != -1?'active_nav':''"><i class="iconfont icon-lefticon_history_gray"></i>{{$t('myAccount.text7')}}</li></router-link>
                <router-link to="/fundTransfer" v-if="ostatus == 3"><li :class="this.$route.path.indexOf('/fundTransfer') != -1?'active_nav':''"><i class="iconfont icon-lefticon_transfer_gray"></i>{{$t('myAccount.text8')}}</li></router-link>
                 <!-- <router-link to="/about"><li><i class="iconfont icon-lefticon_Deposit__gray"></i>Deposit</li></router-link>
                <router-link to="/about"><li><i class="iconfont icon-lefticon_withdrawal_gray"></i>Withdrawal</li></router-link>
                <router-link to="/about"><li><i class="iconfont icon-lefticon_history_gray"></i>Fund history</li></router-link>
                <router-link to="/about"><li><i class="iconfont icon-lefticon_orders_gray"></i>Historical orders</li></router-link>
                <router-link to="/about"><li><i class="iconfont icon-lefticon_analysis_gray"></i>Transaction analysis</li></router-link>
                <router-link to="/accountInformation/fundsDetails"><li><i class="iconfont icon-lefticon_bill_gray"></i>Funds bill</li></router-link> -->
            </ul>
        </div>
        <div class="page">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import {
  computed
} from 'vue';
import { useStore } from 'vuex';

export default {
    name:"AccountInformation",
    components:{
        
    },
    setup(){

        const store = useStore();

         const ostatus = computed({
            get: () => store.state.user.userInfo.ostatus,
        });

        return {
            ostatus
        }
    }
}
</script>
<style lang="less" scoped>
.AccountInformation{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    justify-content: left;
    .navigation{
        width: 200px;
        min-height: 900px;
        background: @white;
        ul{
            text-align: left;
            li{
                height: 48px;
                padding-left: 20px;
                line-height: 48px;
                font-size: 14px;
                cursor: pointer;
                .iconfont{
                    margin-right: 12px;
                }
            }
            .active_nav{
                background: @primary-color;
                color: @white;
            }
        }
    }
    .page{
        width: 984px;
        margin-left: 16px;
        background: @white;
    }
}
</style>
